import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import QuestionStepper from 'Components/QuestionStepper';
import { styled } from '@mui/system';
import colors from '../../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';
import { calculateAdlIndex, calculateAdlTotal } from 'Routes/DiagnosticSurvey/utils';
import InfoPopper from 'Components/InfoPopper';
import { exists } from 'neuro-utils';

const StyledScoreContent = styled(Container)({
  marginLeft: '12px',
  paddingLeft: '20px',
  paddingRight: '8px',
  marginBottom: '4.5rem',
});

const StyledScoreContainer = styled(Container)({
  padding: '3rem 0 3rem',
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '-8px',
  width: 'calc(100% + 8px)',
  marginLeft: '-8px',
  justifyContent: 'space-evenly',
});

const StyledScore = styled(Container)({
  display: 'flex',
  fontWeight: 'bold',
  marginBottom: '1.5rem',
  justifyContent: 'center',
  alignItems: 'center',
});

const InlineDiv = styled('div')({ display: 'inline-flex' });

const ReferenceText = styled('div')({ color: colors.tertiaryText });

const steps = ['washing', 'dressing', 'wcUsage', 'movementAtHome', 'continence', 'eating'];

const ADLForm = ({ formData, view, fm }: IFormContext<IADL>): JSX.Element => {
  const viewing = !!view?.viewing;

  const getStepContent = (
    stepName: string,
  ): ReturnType<React.ComponentProps<typeof QuestionStepper>['getStepContent']> => ({
    opts: ['0', '1', '2', '3', 'unknown'],
    optionFormatter: (number: string | number) => fm(`diagnosticSurvey.adl.${stepName}.opts.${number}`),
    centered: true,
  });

  const notFilledFields = steps.filter((field) => !formData.document[field as keyof typeof formData.document]);

  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormSection>
        <QuestionStepper
          viewing={viewing}
          formData={formData}
          steps={steps}
          getStepContent={getStepContent}
          labelFormatter={(name: string | number) => fm(`diagnosticSurvey.adl.${name}.title`)}
          numberFormatter={(number: string | number) => fm(`diagnosticSurvey.adl.levels.${number}`)}
          hiddenNumbers={['unknown']}
        />
      </FormSection>
      <FormSection header="diagnosticSurvey.adl.total">
        <StyledScoreContent>
          <StyledScoreContainer spacing={1}>
            {(['0', '1', '2', '3', 'unknown'] as TADLLevel[]).map((level, index) => (
              <Item key={level + index} xs={2} style={{ textAlign: 'center' }}>
                <div style={{ fontWeight: 'bold', marginBottom: '1.5rem' }}>
                  {fm(`diagnosticSurvey.adl.levels.${level}`)}
                </div>
                <div>{calculateAdlTotal(formData.document, level) ?? '-'}</div>
              </Item>
            ))}
          </StyledScoreContainer>
          <StyledScoreContainer spacing={1}>
            {[null, null, 'index', null, null].map((title, index) => (
              <Item key={index} xs={2} style={{ textAlign: 'center' }}>
                {index === 2 && (
                  <React.Fragment>
                    <StyledScore>
                      <InlineDiv style={{ padding: notFilledFields.length > 0 ? '0 1rem 0 1rem' : undefined }}>
                        {fm(`diagnosticSurvey.adl.${title}`)}
                      </InlineDiv>
                    </StyledScore>
                    <div>
                      {exists(calculateAdlIndex(formData.document)) ? (
                        calculateAdlIndex(formData.document) === 'unknown' ? (
                          <InfoPopper
                            text={
                              <span style={{ fontWeight: 600 }}>{fm('diagnosticSurvey.adl.notFilledUnknown')}</span>
                            }
                            color="primary"
                          />
                        ) : (
                          calculateAdlIndex(formData.document)
                        )
                      ) : (
                        <InfoPopper
                          text={
                            <div style={{ whiteSpace: 'pre-line' }}>
                              <div style={{ fontWeight: 600 }}>{fm('diagnosticSurvey.adl.notFilled')}</div>
                              {notFilledFields
                                .map((field) => `${fm(`diagnosticSurvey.adl.${field}.title`)}\n`)
                                .join('')}
                            </div>
                          }
                          color="primary"
                        />
                      )}
                    </div>
                  </React.Fragment>
                )}
              </Item>
            ))}
          </StyledScoreContainer>
        </StyledScoreContent>
        <ReferenceText>{fm('diagnosticSurvey.adl.referenceText')}</ReferenceText>
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(ADLForm);
