/**
 * Create a section for history listing. Used for main categories (darker header background-color).
 */
import * as React from 'react';

import colors from '../../config/theme/colors';
import { styleDocument } from '../../config/theme/componentTheme';
import { Container, Item } from '../Grid';
import ToolTip from '../ToolTip';
import { createID } from '../../utility/appendIDs';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';

interface IStyleProps {
  inactive?: string;
  mode?: string;
}

const StyledSection = styled('div')``;

const StyledHeader = styled('div')`
  margin: 0 -${styleDocument.padding};
  padding: 0 ${styleDocument.padding};
  height: ${(props: IStyleProps): string => (props.mode === 'noBar' ? '2rem' : '5rem')};
  background-color: ${(props: IStyleProps): string =>
    props.mode === 'noBar' ? 'transparent' : !props.inactive ? colors.primary : colors.quaternary};
  color: ${(props: IStyleProps): string => (props.mode === 'noBar' ? colors.primary : colors.white)};
  font-size: 2rem;
  font-weight: 600;
`;

const SectionContent = styled('div')`
  padding: ${(props: { hasHistoryRows?: boolean }): string => (props.hasHistoryRows ? '1rem 0 0 0' : '3rem 0 4rem 0')};
  &:empty {
    padding: 3rem 0 4rem 0;
  }
`;

// 4.5rem margin for HistoryRowControls three dots menu button
const ButtonArea = styled('div')`
  margin-right: 4.5rem;
`;

const InfoText = styled('div')`
  font-weight: normal;
  font-size: 1.4rem;
  color: ${styleDocument.documentHeader.infoTextColor};
`;

const HistorySection = ({
  headerText,
  children,
  inactive = false,
  mode = 'default',
  newButton,
  hasHistoryRows = false,
  sectionInfoKey,
}: IOwnProps): JSX.Element => {
  const headerId = React.useRef(createID());
  const headerElement = document.getElementById(headerId.current);
  return (
    <StyledSection>
      <StyledHeader mode={mode} inactive={inactive ? 'true' : undefined}>
        <Container alignItems="center" style={{ height: '100%' }}>
          <Item
            id={headerId.current}
            xs={true}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {headerText &&
              (headerElement && headerElement?.scrollWidth > headerElement?.clientWidth ? (
                <ToolTip
                  title={''}
                  description={typeof headerText === 'number' ? headerText.toString() : headerText}
                  content={<span>{typeof headerText === 'number' ? headerText.toString() : headerText}</span>}
                  hover={true}
                />
              ) : (
                headerText
              ))}
          </Item>
          {newButton && (
            <Item>
              <Container justifyContent="flex-end">
                <Item>
                  <ButtonArea>{newButton}</ButtonArea>
                </Item>
              </Container>
            </Item>
          )}
        </Container>
      </StyledHeader>
      {sectionInfoKey && (
        <Container style={{ marginBlock: '2rem' }}>
          <Item xs={7}>
            <InfoText>
              <FormattedMessage id={sectionInfoKey} />
            </InfoText>
          </Item>
        </Container>
      )}
      <SectionContent hasHistoryRows={hasHistoryRows}>{children}</SectionContent>
    </StyledSection>
  );
};

interface IOwnProps {
  headerText?: string | number | JSX.Element;
  children: JSX.Element | Array<JSX.Element | undefined> | undefined;
  inactive?: boolean;
  mode?: 'default' | 'noBar';
  newButton?: JSX.Element;
  hasHistoryRows?: boolean; // If the HistorySection has HistoryRows, then have a smaller margin after section header
  sectionInfoKey?: string;
}

export default HistorySection;
