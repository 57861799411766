import * as React from 'react';
import { MyServiceContext } from '..';
import { Bdi } from './SurveyForms/Bdi';
import { Bai } from './SurveyForms/Bai';
import NINMTPreInquiry from './SurveyForms/NINMTPreInquiry';
import { ContraIndicationsInquiry } from './SurveyForms/ContraIndicationsInquiry';
import TreatmentMonitoringInquiry from './SurveyForms/TreatmentMonitoringInquiry';
import TDCSReport from './SurveyForms/TDCSReport';
import { ICompactVaultUser } from 'neuro-data-structures';
import { Copd } from './SurveyForms/COPD';
import { Dss } from './SurveyForms/DSS';
import { Deps } from './SurveyForms/DEPS';
import { Ess } from './SurveyForms/ESS';
import { Des } from './SurveyForms/DES';
import { Isi } from './SurveyForms/ISI';
import { SleepApneaFirstVisitInquiry } from './SurveyForms/SleepApneaFirstVisitInquiry';
import { DeviceTherapyControlVisitInquiry } from './SurveyForms/DeviceTherapyControlVisitInquiry';
import { SatisfactionWithCare } from './SurveyForms/SatisfactionWithCare';
import { EQ5D } from './SurveyForms/EQ5D';
import SupplyShortagesInquiry from './SurveyForms/SupplyShortagesInquiry';

const SurveyForm = ({ documents, users, patientMyid }: IOwnProps): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, viewing } = myServContext;

  const { type } = editing || viewing || { type: null };

  switch (type) {
    case 'bdi': {
      return <Bdi patientMyid={patientMyid} users={users} />;
    }
    case 'bai': {
      return <Bai patientMyid={patientMyid} users={users} />;
    }
    case 'ninmtPreInquiry': {
      return <NINMTPreInquiry patientMyid={patientMyid} users={users} />;
    }
    case 'contraIndicationsInquiry': {
      return <ContraIndicationsInquiry patientMyid={patientMyid} users={users} />;
    }
    case 'treatmentMonitoringInquiry': {
      const interestingDocs = documents.filter(
        (d) => d._type === 'symptomsAndLocations' || d._type === 'ninmtTreatmentPeriod',
      );
      return <TreatmentMonitoringInquiry documents={interestingDocs} patientMyid={patientMyid} users={users} />;
    }
    case 'tdcsReport': {
      return <TDCSReport documents={documents} patientMyid={patientMyid} users={users} />;
    }
    case 'supplyShortagesInquiry': {
      return <SupplyShortagesInquiry patientMyid={patientMyid} users={users} />;
    }
    case 'copd': {
      return <Copd patientMyid={patientMyid} users={users} />;
    }
    case 'deps': {
      return <Deps patientMyid={patientMyid} users={users} />;
    }
    case 'des': {
      return <Des patientMyid={patientMyid} users={users} />;
    }
    case 'dss': {
      return <Dss patientMyid={patientMyid} users={users} />;
    }
    case 'ess': {
      return <Ess patientMyid={patientMyid} users={users} />;
    }
    case 'isi': {
      return <Isi patientMyid={patientMyid} users={users} />;
    }
    case 'sleepApneaFirstVisitInquiry': {
      return <SleepApneaFirstVisitInquiry patientMyid={patientMyid} users={users} />;
    }
    case 'deviceTherapyControlVisitInquiry': {
      return <DeviceTherapyControlVisitInquiry patientMyid={patientMyid} users={users} />;
    }
    case 'satisfactionWithCare': {
      return <SatisfactionWithCare patientMyid={patientMyid} users={users} />;
    }
    case 'eq5d': {
      return <EQ5D patientMyid={patientMyid} users={users} />;
    }
    default:
      return <></>;
  }
};

interface IOwnProps {
  documents: Array<IRTMS | ITDCS | ISymptomsAndLocations | IDoctorsOrder>;
  users: ICompactVaultUser[];
  patientMyid: string;
}

export default SurveyForm;
