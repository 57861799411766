import DataTable from 'Components/DataTable';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import QuestionAnswerPair from 'Components/_NewElements/QuestionAnswerPair';
import { ICompactVaultUser } from 'neuro-data-structures';
import { isi, IISI, isLocaleKey, Task_Progress } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { omitControlProps } from 'Utility/documentHandling';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import { surveyCreator, surveyModifyer } from '../../HistoryRowData/SurveysHistory';

const isiFields: Array<keyof IISI> = [
  'fallingAsleepDifficulty',
  'stayingAsleepDifficulty',
  'wakingUpTooEarly',
  'currentSleepSatisfaction',
  'interferingDailyFunction',
  'noticeableToOthers',
  'worriedAboutSleepProblem',
];

export const Isi = ({ patientMyid, users }: IOwnProps): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing, setViewingObj, setEditingObj } = myServContext;
  const isiData = (editing?.data || viewing?.data) as IISI & IControlProps;
  const isiLocales = isi.locales;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';
  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = field === 'date' ? Object.values(values)[0] : parseInt(`${Object.values(values)[0]}`);
    setEditingData?.({
      ...isiData,
      [field]: value,
    });
  };

  return (
    <>
      {viewing ? (
        <div style={{ marginBottom: '4rem' }}>
          <QuestionAnswerPair
            question={fm('myService.reporter')}
            answer={surveyCreator(isiData, patientMyid, users, fm)}
          />
          {surveyModifyer(isiData, patientMyid, users, fm) && (
            <QuestionAnswerPair
              question={fm('myService.edited')}
              answer={surveyModifyer(isiData, patientMyid, users, fm)}
            />
          )}
        </div>
      ) : (
        <div style={{ width: '80%', color: colors.tertiaryText, marginBottom: '2rem' }}>
          <p>{isiLocales[useLocale]['surveyInfo']}</p>
          <p>{isiLocales[useLocale]['surveyFillingInfo']}</p>
        </div>
      )}
      <BlockWrapper
        title={viewing ? formatPartialDate(isiData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'isi', data: isiData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'} headerWidth={5.5}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: isiData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <div style={{ width: '60%', marginBottom: '2rem', marginTop: viewing ? '6rem' : undefined }}>
          <DataTable
            headers={viewing ? ['', '', ''] : ['', '']}
            data={{
              rowData: isiFields.map((f) => {
                const rowSet = [
                  isiLocales[useLocale][f],
                  <div key={f} style={viewing ? { width: '15rem' } : undefined}>
                    <InputHandler
                      name={f}
                      type="Radio"
                      editing={!!editing}
                      formData={{
                        document: { [f]: isiData?.[f] },
                        onChange: onChangeSurveyForm,
                      }}
                      options={[0, 1, 2, 3, 4]}
                      optionFormatter={(o) => isiLocales[useLocale][`opts.${f}.${o}`]}
                    />
                  </div>,
                ];
                if (viewing && f !== 'date') {
                  rowSet.push(
                    <div style={{ fontWeight: 600, width: '3rem', display: 'flex', justifyContent: 'center' }}>
                      {isiData[f] || isiData[f] === 0 ? `${isiData[f]}` : ''}
                    </div>,
                  );
                }
                return rowSet;
              }),
              rowStyle: viewing ? undefined : isiFields.map(() => ({ rowAlign: 'top' })),
            }}
          />
        </div>
        <FormRow
          title={isiLocales[useLocale]['score']}
          headerStyle={{ fontWeight: 600 }}
          headerWidth={viewing ? 6.75 : 5.5}
          formatTitle={false}
        >
          <div style={{ color: colors.tertiaryText, fontWeight: 600 }}>
            <span>
              {typeof Task_Progress.calculateProgress('isi', omitControlProps(isiData)).yielded === 'number'
                ? Task_Progress.calculateProgress('isi', omitControlProps(isiData)).yielded
                : '-'}
            </span>
            <span>{` / ${Task_Progress.calculateProgress('isi', omitControlProps(isiData)).ceiling}`}</span>
          </div>
        </FormRow>
        <div style={{ color: colors.tertiaryText, marginTop: '-4rem', display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '2rem' }}>
            <span>{'0-7'}</span>
            <span>{'8-14'}</span>
            <span>{'15-21'}</span>
            <span>{'22-28'}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{isiLocales[useLocale]['opts.interpretation.noInsomnia']}</span>
            <span>{isiLocales[useLocale]['opts.interpretation.subTreshold']}</span>
            <span>{isiLocales[useLocale]['opts.interpretation.moderate']}</span>
            <span>{isiLocales[useLocale]['opts.interpretation.severe']}</span>
          </div>
        </div>
      </BlockWrapper>
      <div style={{ color: colors.tertiaryText, fontStyle: 'italic' }}>
        <p>{isiLocales[useLocale]['reference']}</p>
      </div>
    </>
  );
};

interface IOwnProps {
  patientMyid: string;
  users: ICompactVaultUser[];
}
