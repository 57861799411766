import * as React from 'react';
import { path } from 'ramda';
import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import { fm } from 'Components/FormatMessage';
import { TDocTypes, TDocument } from '../../utils';
import { treatmentEndReasons } from '../config';

export const EndForm = ({
  formData,
  edit,
  type,
}: {
  formData: IFormData<TDocument>;
  edit: boolean;
  type: TDocTypes;
}): JSX.Element => {
  const ended = path(['hasEnded', 0], formData.document) === true;

  const isOtherTreatment = type === 'otherTreatment';

  // If viewing and the treatment has not been ended, do not render the treatment ending -section
  if (path(['hasEnded', 0], formData.document) !== true && !edit) return <React.Fragment></React.Fragment>;

  return (
    <React.Fragment>
      <FormSection header="treatment.treatmentEndedTitle">
        <FormRow title="treatment.hasEnded">
          <InputHandler
            type="Checkbox"
            editing={edit}
            name="hasEnded"
            formData={formData}
            preset="yes"
            dependentFieldsList={(): string[] => ['endDate', 'endReason', 'endReasonOther']}
            dependentFieldsRemovalWarning={true}
          />
        </FormRow>
        {ended && (
          <React.Fragment>
            <FormRow title="treatment.endDate">
              <InputHandler
                type="PartialDate"
                editing={edit}
                name="endDate"
                formData={formData}
                dateDefault="now"
                dateHook={{ dateHookFloor: formData.document.date }}
              />
            </FormRow>
            {!isOtherTreatment && (
              <React.Fragment>
                <FormRow title="treatment.endReason">
                  <InputHandler
                    type="Checkbox"
                    editing={edit}
                    name="endReason"
                    formData={formData}
                    options={treatmentEndReasons[type]}
                    optionFormatter={(name: string | number): string | JSX.Element =>
                      fm(`treatment.opts.${type}EndReason.${name}`)
                    }
                  />
                </FormRow>
                {path(['endReason'], formData.document) &&
                (path(['endReason'], formData.document) as TTreatmentEndReason[]).length > 0 &&
                (path(['endReason'], formData.document) as TTreatmentEndReason[]).includes('other') ? (
                  <FormRow title="treatment.endReasonOther">
                    <InputHandler
                      type="TextArea"
                      editing={edit}
                      name="endReasonOther"
                      formData={formData}
                      placeholder="treatment.endReasonOther"
                    />
                  </FormRow>
                ) : null}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </FormSection>
    </React.Fragment>
  );
};
