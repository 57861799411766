import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';

import { getStepContent as getSteps3, steps as steps3 } from '../updrsIIISettings';
import { getStepContent as getSteps3Old, steps as steps3Old } from '../updrsIIIOldSettings';
import FormSection from '../../../../../components/FormSection';
import { isUpdrsComplete, updrsScoreSum } from '../../../utils';
import { FormattedMessage } from 'react-intl';
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import InfoPopper from '../../../../../components/InfoPopper';

const phaseFormatter = (o: string | number): JSX.Element => (
  <div style={{ display: 'inline-block', textTransform: 'capitalize' }}>{o}</div>
);

const StyledScore = styled.b`
  font-size: 2rem;
  font-weight: 600;
  color: ${colors.primary};
  text-transform: uppercase;
  margin-right: 2rem;
  margin-left: 2lem;
`;

const UPDRSIIIForm = ({ formData, viewing, documents }: IOwnProps): JSX.Element => {
  React.useEffect(() => {
    // Make here the decision of which test is rendered and which test is the alternative one
    let temp: 'UPDRS' | 'MDSUPDRS';
    if (documents && documents.length > 0) {
      if (documents.filter((item) => item.testType === 'UPDRS' && !item.type).length > 0) {
        temp = 'UPDRS';
      } else {
        temp = 'MDSUPDRS';
      }
    } else {
      temp = 'MDSUPDRS';
    }
    if (!formData.document.testType) {
      formData.onChange &&
        formData.onChange({
          testType: temp,
        });
    }
  }, [documents, formData]);

  const onTestTypeChange = (values: TOnChangeValues): void => {
    const value = values['testType'];
    formData.onChange && formData.onChange({ testType: value });
    if (value === 'MDSUPDRS' && formData.document?.retrospectiveData?.[0] === true) {
      formData.onChange && formData.onChange({ retrospectiveData: [] });
    }
  };

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.dateEnglish">
          <InputHandler
            type="PartialDate"
            editing={!viewing}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow
          title="updrs.state"
          description={<FormattedMessage id="updrs.stateDescription" values={{ ln: <br /> }} />}
        >
          <InputHandler
            type="Radio"
            editing={!viewing}
            name="state"
            formData={formData}
            options={['on', 'off']}
            optionFormatter={phaseFormatter}
          />
        </FormRow>
        <FormRow title="updrs.minutesSinceLevodopa">
          <InputHandler
            type="NumberField"
            editing={!viewing}
            name="minutesSinceLevodopa"
            formData={formData}
            placeholder="updrs.minutes"
            width={6}
            height={3}
            maxLength={6}
            min={0}
            max={1440}
            precision={0}
          />
        </FormRow>
        <FormRow title={'updrs.updrsOrMdsupdrs'}>
          <InputHandler
            type="Radio"
            name="testType"
            editing={!viewing}
            formData={{
              onChange: onTestTypeChange,
              document: formData.document,
            }}
            options={['UPDRS', 'MDSUPDRS']}
            optionFormatter={(n: string | number): JSX.Element => (
              <FormattedMessage id={`updrs.${n === 'UPDRS' ? 'updrsIII' : 'mds'}`} />
            )}
            disabledOptions={
              updrsScoreSum(formData.document) > 0 || formData.document.speech ? ['UPDRS', 'MDSUPDRS'] : []
            }
          />
        </FormRow>
        <FormRow title="updrs.totalScoreEntry">
          <InputHandler
            type="Checkbox"
            editing={!viewing}
            name="retrospectiveData"
            formData={formData}
            preset="yesForceEn"
            disabled={formData.document.testType === 'MDSUPDRS'}
          />
        </FormRow>
      </FormSection>
      {!formData.document.retrospectiveData?.[0] && (
        <React.Fragment>
          {formData.document?.testType && formData.document.testType === 'UPDRS' ? (
            <FormSection header="updrs.updrsIII">
              <QuestionStepper
                formData={formData}
                steps={steps3Old}
                pageType={'updrs'}
                docType={'iii'}
                getStepContent={getSteps3Old}
                viewing={viewing}
              />
            </FormSection>
          ) : (
            <FormSection header="updrs.mds">
              <div style={{ fontStyle: 'italic', color: colors.darkGray, margin: '2rem 0 3rem 0' }}>
                MDS-Unified Parkinson’s Disease Rating Scale (MDS-UPDRS). The MDS-UPDRS is protected by U.S. Copyright
                law. Permission to use and develop the MDS-UPDRS into an electronic format was granted by MDS, the
                copyright holder. All requests to use the scale within research must be approved by MDS. To request
                study specific licensing permissions for the MDS-UPDRS please contact
                ratingscales@movementdisorders.org. Licensing fees may apply.
              </div>
              <QuestionStepper
                formData={formData}
                steps={steps3}
                pageType={'updrs'}
                docType={'iii'}
                getStepContent={getSteps3}
                viewing={viewing}
              />
            </FormSection>
          )}
          {formData.document?.testType && formData.document.testType === 'MDSUPDRS' && (
            <FormSection header="updrs.dyskinesiaImpact">
              <FormRow title="updrs.dyskinesiaPresent">
                <InputHandler
                  type="Radio"
                  editing={!viewing}
                  name="dyskinesiaPresent"
                  formData={formData}
                  preset="yesnoForceEn"
                />
              </FormRow>
              <FormRow title="updrs.dyskinesiaInterference">
                <InputHandler
                  type="Radio"
                  editing={!viewing}
                  name="dyskinesiaInterference"
                  formData={formData}
                  preset="yesnoForceEn"
                />
              </FormRow>
            </FormSection>
          )}
        </React.Fragment>
      )}
      <FormSection header={formData.document?.retrospectiveData?.[0] ? 'updrs.updrsIII' : undefined}>
        {!formData.document.retrospectiveData?.[0] ? (
          <React.Fragment>
            <FormRow title="updrs.scoreEng" bottomMargin={true}>
              {!isUpdrsComplete(formData.document, formData.document.testType) ? (
                <InfoPopper text="general.notFilled" color="primary" />
              ) : (
                <StyledScore>{updrsScoreSum(formData.document)}</StyledScore>
              )}
            </FormRow>
            {formData.document?.testType && formData.document.testType === 'MDSUPDRS' ? (
              <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
                Copyright © 2008 International Parkinson and Movement Disorder Society (MDS). All Rights Reserved. Used
                with permission of MDS. This scale will not be copied, distributed or otherwise used in whole or in part
                without prior written consent of MDS.
              </span>
            ) : (
              <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
                Fahn S, Elton R, Members of the UPDRS Development Committee. In: Fahn S, Marsden CD, Calne DB, Goldstein
                M, eds. Recent Developments in Parkinson’s Disease. Vol.2. Florham Park, NJ. Macmillan Health Care
                Information 1987, pp153163, 293¬304
              </span>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FormRow title="updrs.manualScoreEng" bottomMargin={true}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="manualScore"
                formData={formData}
                placeholder="updrs.scoreManual"
                min={0}
                max={108}
                width={12.6}
                height={3}
                maxLength={3}
              />
            </FormRow>
            <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
              Fahn S, Elton R, Members of the UPDRS Development Committee. In: Fahn S, Marsden CD, Calne DB, Goldstein
              M, eds. Recent Developments in Parkinson’s Disease. Vol.2. Florham Park, NJ. Macmillan Health Care
              Information 1987, pp153163, 293¬304
            </span>
          </React.Fragment>
        )}
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IUPDRSIII>;
  viewing: boolean;
  documents?: IUPDRSIII[];
}

export default UPDRSIIIForm;
