import { isPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

import { withFormContext, IFormContext } from '../../../../../containers/FormContextHandler';
import { endingReasons } from '../Medication';

const EndingForm = ({ formData, fm }: { formData: IFormData; fm: IFormContext['fm'] }): JSX.Element => {
  const platform = useSelector((s: { session?: ISessionStore }) => s.session?.platforms?.selected);
  return (
    <React.Fragment>
      {formData && (
        <FormSection header="medication.otherTreatment.ended">
          <FormRow title="medication.otherTreatment.stopTreatment">
            <InputHandler
              type="Checkbox"
              editing={true}
              name="hasEnded"
              formData={formData}
              options={['true']}
              optionFormatter={(): string => fm('medication.otherTreatment.stopTreatment')}
            />
          </FormRow>
          <FormRow title="medication.endedDate" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler
              type="PartialDate"
              editing={true}
              name="endDate"
              formData={formData}
              dateDefault="now"
              dateHook={{ dateHookFloor: formData.document.startDate }}
            />
          </FormRow>
          <FormRow title="medication.otherTreatment.endedReason" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler
              type="Checkbox"
              editing={true}
              name="endReason"
              formData={formData}
              options={endingReasons(formData.document.endReason, platform)}
              optionFormatter={(n: string | number): string => fm(`medication.opts.${n}`)}
            />
          </FormRow>

          <FormRow title="medication.endedReasonOther" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler
              type="TextArea"
              editing={true}
              name="endReasonOther"
              formData={formData}
              placeholder={'medication.pausationAdditionalInfoPlaceholder'}
            />
          </FormRow>
        </FormSection>
      )}
    </React.Fragment>
  );
};

const VitaminDForm = ({ formData, fm }: IFormContext<IOtherTreatmentBasics & IVitaminD>): JSX.Element => {
  const { document } = formData;
  const locPath = 'medication.otherTreatment';

  return (
    <>
      <FormSection header={`${locPath}.opts.${document.type}`}>
        <FormRow title={`${locPath}.dosage`}>
          <InputHandler
            type="Select"
            editing={true}
            name="dosage"
            formData={formData}
            options={['10µg', '25µg', '50µg', '60µg', '75µg', '100µg', '125µg', '150µg']}
            placeholder={`${locPath}.dosagePlaceholder`}
          />
        </FormRow>
        <FormRow title={`${locPath}.frequency`}>
          <InputHandler
            type="Radio"
            editing={true}
            name="frequency"
            formData={formData}
            options={['onceADay', 'twiceADay', 'other']}
            optionFormatter={(o: string | number): string => fm(`${locPath}.opts.${o}`)}
          />
        </FormRow>
        <FormRow title={`${locPath}.otherFrequency`} condition={document.frequency === 'other'}>
          <InputHandler
            type="TextField"
            editing={true}
            name="otherFrequency"
            formData={formData}
            placeholder={`${locPath}.otherFrequencyPlaceholder`}
          />
        </FormRow>
      </FormSection>
      <EndingForm formData={formData} fm={fm} />
    </>
  );
};

const PlasmaferesisForm = ({ formData, fm }: IFormContext<IOtherTreatmentBasics & IPlasmaferesis>): JSX.Element => {
  const { document } = formData;
  const locPath = 'medication.otherTreatment';

  return (
    <>
      <FormSection header={`${locPath}.opts.${document.type}`}>
        <FormRow title={`${locPath}.frequency`}>
          <InputHandler
            type="TextField"
            editing={true}
            name="frequency"
            formData={formData}
            placeholder={`${locPath}.frequencyPlaceholder`}
          />
        </FormRow>
      </FormSection>
      <EndingForm formData={formData} fm={fm} />
    </>
  );
};

const OtherTreatmentForm = ({ editing, formData, fm }: IFormContext<IMedicationOtherTreatment>): JSX.Element => {
  const { document } = formData;
  const locPath = 'medication.otherTreatment';

  return (
    <>
      <FormSection>
        <FormRow title="medication.startDate">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="startDate"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
            dateHook={{
              dateHookCeiling: 'endDate' in document && isPartialDate(document.endDate) ? document.endDate : undefined,
            }}
          />
        </FormRow>
        <FormRow title={`${locPath}.type`}>
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="type"
            formData={formData}
            options={['vitaminD', 'plasmaferesis']}
            optionFormatter={(o: string | number): string => fm(`${locPath}.opts.${o}`)}
            dependentFieldsList={(): string[] => ['dosage', 'frequency', 'otherFrequency']}
          />
        </FormRow>
      </FormSection>
      {document.type === 'vitaminD' && (
        <VitaminDForm formData={formData as IFormData<IOtherTreatmentBasics & IVitaminD>} fm={fm} />
      )}
      {document.type === 'plasmaferesis' && (
        <PlasmaferesisForm formData={formData as IFormData<IOtherTreatmentBasics & IPlasmaferesis>} fm={fm} />
      )}
    </>
  );
};

export default withFormContext(OtherTreatmentForm);
