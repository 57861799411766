import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import HistorySection from '../../../../components/HistorySection';
import { IHistoryContext, withHistoryContext } from '../../../../containers/FormContextHandler';
import { DocumentCreationButtonWithContext } from '../../utils';
import { TreatmentHistorySection } from './components';

const PAPTherapy = ({ documents = [], startEdit, view }: IHistoryContext<IPAPTherapy>): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="treatment.papTherapy.title" />}
    newButton={<DocumentCreationButtonWithContext name="papTherapy" text="treatment.papTherapy.newPapTherapy" />}
  >
    <TreatmentHistorySection documents={documents} startEdit={startEdit} type="papTherapy" view={view} />
  </HistorySection>
);

export default withHistoryContext(PAPTherapy);
