import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import DocumentWrapper from '../../DocumentWrapper';
import colors from '../../../config/theme/colors';

const CensoredInfoView = styled.div`
  color: ${colors.primaryText};
  font-size: 4.5rem;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const CensoredInfo = (): JSX.Element => {
  return (
    <>
      <DocumentWrapper>
        <CensoredInfoView>
          <FormattedMessage id="profile.censoredModeHiddenContentMsg" />
        </CensoredInfoView>
      </DocumentWrapper>
    </>
  );
};
