import * as React from 'react';
import { fm } from 'Components/FormatMessage';

import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import { codes, fieldNameToCodeString, getFields, loincCodeToFieldName } from 'Routes/Background/utils';
import { useAppSelector as useSelector } from 'Store/index';

const optionFormatter = (opt: number | string) => {
  return fm(`background.${opt}`);
};

const getMin = (code?: string) => {
  if (!code) return undefined;
  const field = loincCodeToFieldName(code)?.name;
  switch (field) {
    default:
      return 0;
  }
};
const getMax = (code?: string) => {
  if (!code) return undefined;
  const field = loincCodeToFieldName(code)?.name;
  switch (field) {
    case 'gaf':
      return 100;
    case 'bmi':
    case 'madrs':
      return 60;
    case 'ybopa':
    case 'ybopt':
      return 20;
    case 'ybocs':
      return 40;
    default:
      return undefined;
  }
};
const getPrecision = (code?: string) => {
  if (!code) return undefined;
  const field = loincCodeToFieldName(code)?.name;
  switch (field) {
    default:
      return 2;
  }
};

const MeasurementFormSingle = ({ formData, editableFields }: IOwnProps) => {
  const platform = useSelector((s: IState) => s.session.platforms?.selected);

  React.useEffect(() => {
    // Ensure that the value exists. null -> empty string
    if (formData.document.value === null) {
      formData.onChange?.({ value: '' });
    }
  }, [formData.document.value]);

  if (!platform) return null;
  return (
    <FormSection>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          name="date"
          editing={false}
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="general.time">
        <InputHandler
          type="TimePicker"
          name="time"
          editing={editableFields.includes('time')}
          formData={formData}
          timeDefault="now"
        />
      </FormRow>
      <FormRow title="background.type">
        <InputHandler
          type="Radio"
          name="code"
          editing={false}
          formData={formData}
          options={getFields(platform).map((o) => fieldNameToCodeString(o) || '')} // Only display options that are available to the current platform and map the name to loinc code
          optionFormatter={(o) => (
            <>
              <span>{optionFormatter(codes[o].name)}</span>
              {codes[o].unit && <span> ({codes[o].unit})</span>}
            </>
          )}
        />
      </FormRow>
      <FormRow title="background.value">
        <InputHandler
          type="NumberField"
          name="value"
          editing={editableFields.includes('value')}
          formData={formData}
          placeholder="background.value"
          min={getMin(formData.document.code)}
          max={getMax(formData.document.code)}
          precision={getPrecision(formData.document.code)}
          saveAsString // Save the value as string to comply with the interface
        />
      </FormRow>
    </FormSection>
  );
};

interface IOwnProps {
  formData: IFormData<Partial<IMeasurement>>;
  editableFields: Array<'time' | 'value'>;
}

export default MeasurementFormSingle;
