/**
 * Wrapper that displays a title (date/type/etc) for the history item.
 * Renders child elements under the title.
 * Also renders possible buttons next to the child elements.
 */

import { IconButton, Menu, MenuItem, Theme, styled } from '@mui/material';
import { ArrowDropDown, ArrowRight, MoreVert } from '@mui/icons-material';
import * as React from 'react';

import LeftBarWrapper from '../LeftBarWrapper';
import ActionButtonRounded, { IActionButtonRounded } from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';

const StyledMenuIcon = styled(({ onClick }: { onClick?: (e: React.MouseEvent<Element | HTMLElement>) => void }) => (
  <IconButton onClick={onClick}>
    <MoreVert />
  </IconButton>
))`
  color: #6c96ae;
  cursor: pointer;
`;

// Horizontal bar shown between blocks
const StyledDivider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  minWidth: '100%',
  minHeight: '0.2rem',
  margin: '0.7rem 0',
}));

const StyledTitle = styled('h2')(({ theme, collapseOpen }: { theme?: Theme; collapseOpen: boolean | null }) => ({
  color: theme?.palette.primary.main,
  margin: 0,
  marginBottom: collapseOpen || collapseOpen === null ? '3.5rem' : 0,
  cursor: collapseOpen !== null ? 'pointer' : 'default',
}));

// Horizontal bar shown under the title
const TitleDivider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  minWidth: '100%',
  minHeight: '0.2rem',
  margin: '0.7rem 0 0.7rem -2.5rem',
}));

const HistoryTitle = ({ children, collapseOpen }: IHistoryTitle) => {
  return <StyledTitle collapseOpen={collapseOpen}>{children}</StyledTitle>;
};

interface IHistoryTitle {
  children: React.ReactNode;
  collapseOpen: boolean | null;
}

const CollapseArrowArea = ({ collapseOpen, onClick }: { collapseOpen: boolean; onClick: (() => void) | undefined }) => {
  const iconProps = {
    fontSize: 'large' as const,
    color: 'primary' as const,
    style: {
      marginTop: '-0.7rem',
    },
  };
  return (
    <Item style={{ width: '3.5rem', cursor: 'pointer' }} onClick={onClick}>
      <Container justifyContent="center">
        {collapseOpen ? <ArrowDropDown {...iconProps} /> : <ArrowRight {...iconProps} />}
      </Container>
    </Item>
  );
};

const BlockWrapper = ({
  title,
  children,
  divider = true,
  titleDivider = false,
  collapse,
  buttons,
  menuItems,
}: IBlockWrapper) => {
  const [collapseOpen, setCollapseOpen] = React.useState<boolean | null>(collapse ? collapse.open || false : null);

  const [anchor, setAnchor] = React.useState<null | Element | HTMLElement>(null);
  const toggleMenu = (e: React.MouseEvent<Element | HTMLElement>): void => {
    setAnchor(!anchor ? e.currentTarget : null);
  };
  return (
    <div>
      {divider && <StyledDivider />}
      <LeftBarWrapper leftPadding={!(collapseOpen !== null)}>
        <Container>
          {collapseOpen !== null && (
            <CollapseArrowArea
              collapseOpen={collapseOpen}
              onClick={collapseOpen === null ? undefined : () => setCollapseOpen(!collapseOpen)}
            />
          )}
          <Item xs={true}>
            {title && (
              <HistoryTitle collapseOpen={collapseOpen}>
                <Container alignItems="center">
                  <Item xs={true} onClick={collapseOpen === null ? undefined : () => setCollapseOpen(!collapseOpen)}>
                    {title}
                  </Item>
                  {buttons && (
                    <Item xs={'auto'}>
                      <Container>
                        {buttons.map((b, i) => (
                          <Item key={i} style={{ marginLeft: '2rem' }}>
                            <ActionButtonRounded
                              text={b.title}
                              onClick={b.onClick}
                              width={10}
                              height={3.5}
                              fontSize={16}
                              {...b.props}
                            />
                          </Item>
                        ))}
                      </Container>
                    </Item>
                  )}
                  {menuItems && menuItems.length > 0 && (
                    <Item xs="auto">
                      <StyledMenuIcon onClick={toggleMenu} />
                      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={toggleMenu}>
                        {menuItems.map((mi, i) => (
                          <MenuItem
                            key={'mi' + i}
                            onClick={() => {
                              mi.onClick();
                              setAnchor(null);
                            }}
                          >
                            {mi.title}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Item>
                  )}
                </Container>
                {titleDivider && collapseOpen && <TitleDivider />}
              </HistoryTitle>
            )}
            {collapseOpen === null ? children : collapseOpen ? children : null}
          </Item>
        </Container>
      </LeftBarWrapper>
    </div>
  );
};

interface IBlockWrapper {
  title?: React.ReactNode;
  divider?: boolean;
  titleDivider?: boolean;
  collapse?: { open?: boolean };
  buttons?: {
    title: IActionButtonRounded['text'];
    onClick: IActionButtonRounded['onClick'];
    props?: Omit<IActionButtonRounded, 'text' | 'onClick'>;
  }[];
  menuItems?: {
    title: JSX.Element;
    onClick: () => void;
  }[];
  children?: React.ReactNode | React.ReactNode[] | null;
}

export default BlockWrapper;
