import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Item } from '../../../../../components/Grid';
import { formatPartialDate } from 'neuro-utils';
import { capitalize } from '../../../../../utility/string';
import HistoryRowControls from '../../../../../components/HistoryRowControls';
import { ContainerEvent } from './components';

const AdverseEventListItem = ({ adverseEvent, startEdit }: IAdverseEventItemProps): JSX.Element => {
  return (
    <ContainerEvent style={{ marginBottom: '1rem' }}>
      <Item xs={2}>
        <div style={{ fontWeight: 600 }}>{formatPartialDate(adverseEvent.date)}</div>
      </Item>
      <Item xs={7}>
        {adverseEvent.adverseEffects && (
          <Container>
            <Item xs={3}>
              <FormattedMessage id="medication.adverseEffects" />
            </Item>
            <Item xs={true} style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
              {adverseEvent.adverseEffects?.map((a: IAdverseEffect, i: number) =>
                a.eventName === 'Muu haittavaikutus' ? (
                  <React.Fragment key={a.eventName + i}>
                    <span style={{ fontWeight: 600 }}>
                      {a.eventName && `${capitalize(a.eventName)} (${adverseEvent.otherAdverseEffect || ''})`}
                    </span>
                    {i !== (adverseEvent.adverseEffects?.length as number) - 1 && ', '}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={`${a.eventName || ''}${i}`}>
                    <span style={{ fontWeight: 600 }}>{a.eventName && capitalize(a.eventName)}</span>
                    {i !== (adverseEvent.adverseEffects?.length as number) - 1 && ', '}
                  </React.Fragment>
                ),
              )}
            </Item>
          </Container>
        )}
        {adverseEvent.eventDetails && (
          <Container>
            <Item xs={3}>
              <FormattedMessage id="medication.eventDetails" />
            </Item>
            <Item xs={true} style={{ paddingRight: '2rem' }}>
              <span style={{ fontWeight: 600 }}>{adverseEvent.eventDetails}</span>{' '}
            </Item>
          </Container>
        )}
      </Item>
      <Item xs={3}>
        <HistoryRowControls document={adverseEvent} startEdit={startEdit} />
      </Item>
    </ContainerEvent>
  );
};

interface IAdverseEventItemProps {
  adverseEvent: IMedicationAdverseEvent;
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
}

export default AdverseEventListItem;
