import { IconButton, Popover } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

const StyledText = styled('div')(({ maxWidth }: { maxWidth?: number }) => ({
  fontSize: '1.6rem',
  padding: '1rem',
  maxWidth: `${maxWidth ? maxWidth : '40'}rem`,
  whiteSpace: 'pre-line',
}));

const Button = styled(IconButton)`
  padding: 0 !important;
  margin: 0 !important;
`;

const InfoPopper = ({ text, values, maxWidth, color, size = 'normal' }: IOwnProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'infopopover' : undefined;
  return (
    <React.Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <StyledText maxWidth={maxWidth} onClick={handleClose}>
          {typeof text === 'string' ? (
            values ? (
              <FormattedMessage id={text} values={{ value: values }} />
            ) : (
              <FormattedMessage id={text} />
            )
          ) : (
            text
          )}
        </StyledText>
      </Popover>
      <Button onClick={handleClick}>
        {!color ? (
          <div style={{ color: 'white' }}>
            <HelpIcon style={{ padding: 0, margin: 0, fontSize: size === 'large' ? '5rem' : '3rem' }} />
          </div>
        ) : (
          <HelpIcon color={color} style={{ padding: 0, margin: 0, fontSize: size === 'large' ? '5rem' : '3rem' }} />
        )}
      </Button>
    </React.Fragment>
  );
};

interface IOwnProps {
  text: string | JSX.Element;
  values?: string | number; // Mainly for rulm test score popover, can be modified of course for other uses
  maxWidth?: number;
  color?: 'primary' | 'secondary';
  size?: 'normal' | 'large';
}

export default InfoPopper;
