import * as React from 'react';
import styled from 'styled-components';

import InputHandler from '../../../../../components/InputHandler';
import { Container, Item } from '../../../../../components/Grid';

import colors from '../../../../../config/theme/colors';

import { options, tooltips } from '../optionsAndTooltips';
import { resolvePath, rootOf } from '../../utils';
import { DocumentSelectMenu, DisplaySelectedOption } from './index';
import { exists } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { resolveSubScore } from '../calculateEdss';

const StyledButton = styled.a`
  font-weight: 600;
  cursor: pointer;
  margin-right: 2rem;
  display: block;
  line-height: 2.5rem;
  &:hover {
    color: ${colors.primary};
  }
`;

const StyledValue = styled.div`
  font-weight: 600;
  cursor: default;
`;

const StyledFormRowButton = styled(StyledButton as any)`
  color: ${colors.primary};
  font-weight: 600;
  text-align: center;
`;

const StyledSuperScoreButton = styled.div`
  border: 1px solid #c8c8c8;
  border-top: 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const StyledScoreContainer = styled.div`
  background-color: ${colors.lightGray};
  text-align: center;
  min-width: 4.5rem;
  height: 2.5rem;
`;

const StyledScoreItem = styled.div`
  font-weight: 600;
`;

export const ScoreSelectRow = ({
  documents,
  formData,
  viewing,
  subject,
  normalValue,
  maxValue,
}: IScoreSelectRow): JSX.Element => {
  const [showDocumentMenu, setShowDocumentMenu] = React.useState<SVGSVGElement | HTMLElement | null>(null);
  const toggleDocumentMenu = (e: React.MouseEvent<SVGSVGElement | HTMLElement>): void => {
    setShowDocumentMenu(!showDocumentMenu ? e.currentTarget : null);
  };

  const setToValue = (target: string, value: string | number | TAnyObject) => (): void => {
    target === 'super'
      ? Object.keys(normalValue).forEach(
          (section) =>
            formData.onChange?.({
              [section]: resolvePath(section, value),
            }),
        )
      : formData.onChange?.({ [target]: value });
  };

  const toStringSafely = (value?: number | null) => (value || value === 0 ? value.toString() : '');

  return subject === 'super' ? (
    // Top bar with set normals and previous buttons
    <Container alignItems="center" style={{ minHeight: '3.2rem' }}>
      <Item xs={8} />
      <Item xs={4}>
        <Container alignItems="center">
          <Item xs={7}>
            <StyledFormRowButton onClick={setToValue('super', normalValue)}>
              <StyledSuperScoreButton>{fm('neurologicalStatusAndEDSS.setToNormal')}</StyledSuperScoreButton>
            </StyledFormRowButton>
          </Item>
          <Item xs={5}>
            <StyledFormRowButton onClick={toggleDocumentMenu}>
              <StyledSuperScoreButton>
                {fm('neurologicalStatusAndEDSS.previous')}
                {DocumentSelectMenu(documents, toggleDocumentMenu, showDocumentMenu, setToValue, subject)}
              </StyledSuperScoreButton>
            </StyledFormRowButton>
          </Item>
        </Container>
      </Item>
    </Container>
  ) : (
    // Row with 2 score boxes, text and buttons
    <Container alignItems="center" style={{ minHeight: '3.2rem' }}>
      <Item xs={viewing ? 12 : 8}>
        <Container alignItems="center">
          <Item style={{ marginRight: '1rem' }}>
            <StyledScoreContainer>
              <StyledScoreItem
                style={{
                  height: '100%',
                  color: exists(resolvePath(`${rootOf(subject)}.score`, formData.document)) ? colors.gray : undefined,
                }}
              >
                {rootOf(subject) !== 'ambulatoryScore'
                  ? resolveSubScore(rootOf(subject), formData.document)
                  : undefined}
              </StyledScoreItem>
            </StyledScoreContainer>
          </Item>
          <Item style={{ marginRight: '2rem' }}>
            {!viewing ? (
              <StyledScoreContainer>
                <InputHandler
                  type="NumberField"
                  editing={true}
                  name={subject}
                  formData={formData}
                  width={3}
                  min={0}
                  max={maxValue}
                  centered
                />
              </StyledScoreContainer>
            ) : (
              <StyledScoreContainer>
                <StyledScoreItem>
                  {rootOf(subject) !== 'ambulatoryScore'
                    ? resolvePath(`${rootOf(subject)}.score`, formData.document)
                    : resolvePath('ambulatoryScore', formData.document)}
                </StyledScoreItem>
              </StyledScoreContainer>
            )}
          </Item>
          <Item xs={true}>
            {viewing && !exists(resolvePath(subject, formData.document)) ? (
              exists(resolveSubScore(`${rootOf(subject)}`, formData.document)) ? (
                <DisplaySelectedOption
                  optionInput={<></>}
                  viewing={viewing}
                  option={toStringSafely(resolveSubScore(`${rootOf(subject)}`, formData.document))}
                  tooltips={tooltips(subject)}
                />
              ) : (
                <StyledValue>{fm('neurologicalStatusAndEDSS.opts.notDone')}</StyledValue>
              )
            ) : (
              <DisplaySelectedOption
                optionInput={
                  <InputHandler
                    type="RadioButtonRow"
                    editing={!viewing}
                    name={subject}
                    formData={formData}
                    options={resolvePath(subject, options)}
                    tooltips={tooltips(subject)}
                  />
                }
                viewing={viewing}
                option={
                  exists(resolvePath(subject, formData.document))
                    ? resolvePath(subject, formData.document)
                    : exists(resolveSubScore(`${rootOf(subject)}`, formData.document))
                      ? toStringSafely(resolveSubScore(`${rootOf(subject)}`, formData.document))
                      : undefined
                }
                tooltips={tooltips(subject)}
              />
            )}
          </Item>
        </Container>
      </Item>
      {!viewing && (
        <Item xs={4}>
          <Container>
            <Item xs={7}>
              <StyledFormRowButton onClick={setToValue(rootOf(subject), normalValue)}>
                {fm('neurologicalStatusAndEDSS.setToNormal')}
              </StyledFormRowButton>
            </Item>
            <Item xs={5}>
              <StyledFormRowButton onClick={toggleDocumentMenu}>
                {fm('neurologicalStatusAndEDSS.previous')}
                {DocumentSelectMenu(documents, toggleDocumentMenu, showDocumentMenu, setToValue, subject)}
              </StyledFormRowButton>
            </Item>
          </Container>
        </Item>
      )}
    </Container>
  );
};

interface IScoreSelectRow {
  documents: INeurologicalStatusAndEDSS[] | undefined;
  formData: IFormData<INeurologicalStatusAndEDSS>;
  viewing?: boolean;
  subject: string;
  normalValue: string | number | TAnyObject;
  maxValue?: number;
}
