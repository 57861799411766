import * as React from 'react';
import { Error, CheckCircle } from '@mui/icons-material';
import colors from '../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';
import { MyServiceContext } from '..';
import HistoryTabs from 'Components/HistoryTabs';
import TaskListHistory from './TaskListHistory';
import { useAppSelector as useSelector } from 'Store/index';
import SurveysHistory from './SurveysHistory';
import { dateFromPartial } from 'Components/DashboardGraph/Utils';
import { nowPartialDate } from 'neuro-utils';
import { TSurveyType } from 'Routes/MyService/util';
import InvitesHistory from './InvitesHistory';
import { equals } from 'ramda';
import { ICompactVaultUser } from 'neuro-data-structures';
import { ninmtSurveyTypes, srSurveyTypes } from '../config';
import SuppliesHistory from './SuppliesHistory';

const PatientMyServiceStatus = ({
  myServiceStatus,
  fm,
}: {
  myServiceStatus: boolean;
  fm: (id: string) => string | React.ReactNode;
}): JSX.Element => {
  return (
    <Container style={{ marginBottom: '5rem' }}>
      <Item xs={5}>
        {!myServiceStatus ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Error style={{ color: colors.warning.default, margin: '0 0.5rem 0 0.3rem' }} />
              <span style={{ color: colors.tertiaryText, fontWeight: 600 }}>
                {fm('myService.patientNotUsingMyService')}
              </span>
            </div>
          </>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircle style={{ color: '#7BA015', margin: '0 0.5rem 0 0.3rem' }} />
            <span style={{ color: colors.tertiaryText, fontWeight: 600 }}>{fm('myService.patientUsingMyService')}</span>
          </div>
        )}
      </Item>
    </Container>
  );
};

const MyServiceHistory = ({ selectedTab, setSelectedTab, users, inviteErrors }: IOwnProps) => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, platform } = myServContext;

  const [onGoingTasks, setOnGoingTasks] = React.useState<Array<ITaskList>>([]);

  const taskLists = useSelector((s: IState) => s.myapp.taskLists) || [];
  const invites = useSelector((s: IState) => s.myapp.sentInvites);
  const sortedAndMergedDocs = useSelector((s: IState) => s.documents.sortedAndMergedDocuments) || [];
  const sortedAndMergedMyDocs = useSelector((s: IState) => s.myapp.sortedAndMergedDocuments) || [];

  React.useEffect(() => {
    const ongTasks = taskLists.filter(
      (tl) => tl.deadline && dateFromPartial(tl.deadline) > dateFromPartial(nowPartialDate()),
    );
    if (!equals(onGoingTasks, ongTasks)) setOnGoingTasks(ongTasks);
  }, [taskLists]);

  const treatmentDecisionDocs: ININMTTreatmentPeriod[] = sortedAndMergedDocs.filter(
    (d) => d._type === 'ninmtTreatmentPeriod',
  );
  const surveyDocs: Array<TSurveyType> = sortedAndMergedMyDocs.filter(
    (d) =>
      (platform === 'ninmt' && ninmtSurveyTypes.filter((t) => t !== 'supplyShortagesInquiry').includes(d._type)) ||
      (platform === 'sleepApnea' && d._type !== 'background' && srSurveyTypes.includes(d._type)),
  );
  const suppliesDocs: Array<TSurveyType> = sortedAndMergedMyDocs.filter(
    (d) => platform === 'ninmt' && d._type === 'supplyShortagesInquiry',
  );

  const patientMySQStatus = useSelector((s: IState) => s.myapp.mysqUserId);

  return (
    <React.Fragment>
      <PatientMyServiceStatus myServiceStatus={!!patientMySQStatus} fm={fm} />
      <HistoryTabs indexSelectionTools={{ index: selectedTab, changeFunction: (i: number): void => setSelectedTab(i) }}>
        {[
          {
            title: 'myService.surveys',
            content: (
              <SurveysHistory
                treatmentDecisionDocs={platform === 'ninmt' ? treatmentDecisionDocs : []}
                surveyDocs={surveyDocs}
                onGoingTasks={onGoingTasks}
                users={users}
                taskLists={taskLists}
              />
            ),
          },
          {
            title: 'myService.taskLists',
            content: <TaskListHistory treatmentDecisionDocs={treatmentDecisionDocs} taskLists={taskLists} />,
          },
          {
            title: 'myService.invites',
            content: <InvitesHistory invites={invites} inviteErrors={inviteErrors} />,
          },
          {
            title: 'myService.supplies',
            content: <SuppliesHistory supplyShortagesInquiryDocs={suppliesDocs} users={users} />,
          },
        ].slice(['ninmt', 'sleepApnea'].includes(platform ?? '') ? 0 : 2, platform === 'ninmt' ? undefined : 3)}
      </HistoryTabs>
    </React.Fragment>
  );
};

interface IOwnProps {
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  users: ICompactVaultUser[];
  inviteErrors: Array<{ method: 'sms' | 'email'; statusCode: number }>;
}

export default MyServiceHistory;
