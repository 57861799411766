import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import { CheckCircle, Lens } from '@mui/icons-material';
import { path, values, sum, includes, keys } from 'ramda';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import InfoPopper from '../InfoPopper';
import InputHandler from '../InputHandler';
import ActionButton from '../ActionButton';
import FormRow from '../FormRow';
import { Container, Item } from '../Grid';

import colors from '../../config/theme/colors';
import { isNumber } from '../../utility/isNumber';
import { isIE } from '../../utility/isIE';

import QuestionItem from './QuestionItem';
import { mfmD1Steps, mfmD2Steps } from '../../routes/Motor/utils';
import { steps as mfmSteps } from '../../routes/Motor/Document/Form/mfmSettings';

const StepIconComponent = () => <span />;

const StyledStepLabel = styled(({ ...other }) => <StepLabel StepIconComponent={StepIconComponent} {...other} />)`
  padding: 0 3rem;
  height: 5rem;
`;

const StyledSubStepLabel = styled(({ ...other }) => <StepLabel {...other} />)`
  height: 3rem;
  cursor: pointer;
`;

const StyledLabelText = styled.div`
  color: ${colors.white} !important;
  font-size: 2rem;
  font-weight: 600;
`;

const StyledSubLabelText = styled.div`
  color: ${colors.primaryText} !important;
  font-size: 2rem;
  font-weight: 600;
`;

const StepTitleQuestionScalable = styled('td')({
  maxWidth: '90rem',
  '@media only screen and (max-width: 1400px)': {
    maxWidth: '40rem',
  },
});

interface IHfmsEElements {
  formData: IOwnProps['formData'];
  name: string;
  viewing: boolean;
  disabled?: boolean;
}

/**
 * Elements specific to HFMS-E form
 */
const HfmsEElements = ({ formData, name, viewing, disabled }: IHfmsEElements): JSX.Element => {
  const intl = useIntl();
  const optionFormatter = (name?: string | number): string =>
    name || name === 0 ? intl.formatMessage({ id: 'motor.' + name }) : '-';
  return (
    <div style={{ padding: '4rem 6rem 0rem 6rem' }}>
      <FormRow title="motor.lbc">
        <InputHandler
          type="Radio"
          editing={!viewing}
          name={name + 'LBC'}
          formData={formData}
          preset="yesnoForceEn"
          disabledOptions={disabled && disabled === true ? ['yes', 'no'] : undefined}
        />
      </FormRow>
      {name === 'oneHandToHeadInSitting' && (
        <FormRow title="motor.side">
          <InputHandler
            type="Radio"
            editing={!viewing}
            name={name + 'Side'}
            formData={formData}
            options={['right', 'left']}
            optionFormatter={optionFormatter}
          />
        </FormRow>
      )}
    </div>
  );
};

interface IPbaSElements {
  formData: IOwnProps['formData'];
  viewing: boolean;
  moveToNext: () => void;
}

/**
 * Modality question of hallucinations
 */
const PbaSElements = ({ formData, viewing, moveToNext }: IPbaSElements): JSX.Element => {
  const intl = useIntl();
  const optionFormatter = (name?: string | number): string =>
    name || name === 0 ? intl.formatMessage({ id: `behaviour.opts.pbaS.modality.${name}` }) : '-';

  return (
    <div style={{ padding: '4rem 6rem 0rem 6rem' }}>
      <FormRow title="behaviour.labels.pbaS.hallucinationsModality">
        <InputHandler
          type="Checkbox"
          editing={!viewing}
          name="hallucinationsModality"
          formData={formData}
          options={['auditory', 'visual', 'tactile', 'olfactory', 'gustatory']}
          optionFormatter={optionFormatter}
        />
        {!viewing ? (
          <div style={{ marginTop: '2rem' }}>
            <ActionButton text="general.next" onClick={moveToNext} width={18} height={4} fontSize={18} />
          </div>
        ) : undefined}
      </FormRow>
    </div>
  );
};

interface INsaaElements {
  formData: IOwnProps['formData'];
  name: string;
  viewing: boolean;
}

// Elements specific to NSAA form
const NsaaElements = ({ formData, name, viewing }: INsaaElements): JSX.Element => {
  return (
    <div style={{ padding: '4rem 6rem 0rem 6rem' }}>
      {name === 'riseFromFloor' && (
        <FormRow title="motor.timeSeconds">
          <InputHandler
            type="TextField"
            editing={!viewing}
            name={name + 'Time'}
            formData={formData}
            placeholder="motor.timePlaceholder"
          />
        </FormRow>
      )}
      {name === 'run10M' && (
        <FormRow title="motor.timeSeconds">
          <InputHandler
            type="TextField"
            editing={!viewing}
            name={name + 'Time'}
            formData={formData}
            placeholder="motor.timePlaceholder"
          />
        </FormRow>
      )}
    </div>
  );
};

interface IMfmElements {
  formData: IOwnProps['formData'];
  name: string;
  viewing: boolean;
}

const MfmElements = ({ formData, name, viewing }: IMfmElements): JSX.Element => {
  const intl = useIntl();
  const optionFormatter = (name?: string | number): string =>
    name || name === 0 ? intl.formatMessage({ id: `motor.opts.mfm.${name}` }) : '-';

  return (
    <div style={{ padding: '4rem 6rem 0rem 6rem' }}>
      {(name === 'flexesTheHip' ||
        name === 'dorsiflexesTheFoot' ||
        name === 'raisesTheHand' ||
        name === 'turnsOverIntoProne' ||
        name === 'reachesThePencil' ||
        name === 'picksUpAndHoldsTenCoins' ||
        name === 'goesRoundTheEdgeOfTheCd' ||
        name === 'picksUpPencil' ||
        name === 'picksUpTheBall' ||
        name === 'raisesTheFinger' ||
        name === 'raisesTheFoot' ||
        name === 'hopsTenTimes') && (
        <FormRow title={name === 'raisesTheFoot' ? 'motor.opts.mfm.whichSideSupport' : 'motor.opts.mfm.whichSide'}>
          <InputHandler
            type="Radio"
            editing={!viewing}
            name={name + 'Side'}
            formData={formData}
            options={['right', 'left']}
            optionFormatter={optionFormatter}
          />
        </FormRow>
      )}
    </div>
  );
};

const instructionElement = (element: JSX.Element): JSX.Element => (
  <div style={{ padding: '4rem 6rem 0rem 6rem' }}>{element}</div>
);

// Some dynamic settings for QuestionItem sizes for them to scale better based on how many there are
const itemSize = (opts: []): number => (opts.length < 4 ? 3 : opts.length < 5 ? 2 : opts.length < 6 ? 2 : 3);
const itemSpacing = (opts: []): number => (opts.length < 4 ? 4 : opts.length < 5 ? 2 : 1);

// Choose icon for substep headers
const IconChooser =
  (value?: string | number, color?: string) =>
  (props: StepIconProps): JSX.Element => {
    const { active } = props;
    return value || value === 0 ? (
      <CheckCircle style={{ color: color ? color : colors.primary }} />
    ) : active ? (
      <Lens style={{ color: color ? color : colors.primary }} />
    ) : (
      <Lens color="disabled" />
    );
  };

// Get assets when needed, such as stick figures or other images
const getThisAssetElement = (
  name: string,
  number: number | string,
  docType: string,
): (() => JSX.Element) | undefined => {
  const type = docType === 'hineMM' ? 'HineMM' : docType === 'rulm' ? 'RULM' : '';
  return require(`./assets/${type}/${name}-${number}`).default || undefined;
};

// Sum substep values for main headers
const sumOfAllValues = (
  docType: string | undefined,
  index: number,
  formData: any,
  steps: IOwnProps['steps'],
): string | number => {
  const nameArray = values(steps[index] as { [key: string]: string[] })[0];

  // If UPDRS III or UHDRS Motor rating scale, calculate actual sum of all values...
  if (docType === 'iii' || docType === 'uhdrsMotorRatingScale') {
    const values: (number | string)[] = nameArray.map((name: string) => path(['document', name], formData)) as (
      | number
      | string
    )[];

    // If no subitem is answered
    if (values.some((v) => (!v && v !== 0) || v === '')) return '';
    // If all subitem answers are 'UR'
    if (values.length === values.filter((v) => v === 'UR').length) return 'UR';

    const summed = sum(values.filter((n) => (n === 'UR' ? 0 : n)) as number[]);
    return summed || summed === 0 ? summed : '';
  }

  if (docType === 'pbaS') {
    // Implement pba-s score calculation for each part here
    const severity = nameArray.find((n) => n.includes('Severity'));
    const severityValue: string | number | undefined = severity ? path(['document', severity], formData) : undefined;
    const frequency = nameArray.find((n) => n.includes('Frequency'));
    const frequencyValue: string | number | undefined = frequency ? path(['document', frequency], formData) : undefined;
    const worst = nameArray.find((n) => n.includes('Worst'));
    const worstValue: string | number | undefined = worst ? path(['document', worst], formData) : undefined;
    const score =
      (severityValue || severityValue === 0) &&
      ![8, 9, 'U', 'N'].includes(severityValue) &&
      (frequencyValue || frequencyValue === 0)
        ? parseInt(severityValue.toString()) * parseInt(frequencyValue.toString())
        : (severityValue || severityValue === 0) && (frequencyValue || frequencyValue === 0)
          ? 'N/A'
          : '-';
    return `Score: ${
      (typeof score === 'number' && !Number.isNaN(score)) || typeof score === 'string' ? score : 'N/A'
    }, Worst: ${worstValue ?? '-'}`;
  }

  if (docType === 'mfm') {
    const score = path(['document', mfmSteps[index]], formData) ?? '-';
    return mfmD1Steps.includes(mfmSteps[index])
      ? `D1: ${score}`
      : mfmD2Steps.includes(mfmSteps[index])
        ? `D2: ${score}`
        : `D3: ${score}`;
  }

  // Ignore property which includes sub-string 'State'
  const subString = 'State';
  const notStateArr = nameArray.filter((item) => {
    return !item.includes(subString);
  });

  // Points array (numbers and 'CNT's)
  const pointsArr = notStateArr.map((name: string) => path(['document', name], formData)) as number[] | any[];

  // Array only with numbers
  const numbersArr = pointsArr.filter((item: number) => {
    return !Number.isNaN(item);
  });

  let CNTArr = [] as string[];

  if (numbersArr.length === 0) {
    // Array only with 'CNT'
    CNTArr = pointsArr.filter((item: number) => {
      return Number.isNaN(item);
    });

    // If left -and right test 'CNT'
    if (CNTArr[0] === 'CNT' && CNTArr[1] === 'CNT') {
      return 'CNT';
    }

    // If only one test (left nor right)
    if (CNTArr.length === 1 && CNTArr[0] === 'CNT') {
      return 'CNT';
    } else return '';
  }

  // IF all values in the array are ''
  if (numbersArr.filter((n: string | number) => n === '').length === numbersArr.length) return '';

  // Which number is biggest. If only one number, it's biggest naturally ..
  const biggestNumber = Math.max(...numbersArr);
  return biggestNumber || biggestNumber === 0 ? biggestNumber : '';
};

// Make references for steps which are used with automatic scrolling
const makeRefsForSteps = (steps: IOwnProps['steps']): React.RefObject<HTMLDivElement>[] =>
  steps.map(() => React.useRef(null));

// Note: this can only work after transition is over
const elementInViewport = (el: HTMLDivElement, parent?: HTMLElement | null): boolean => {
  const bb = el.getBoundingClientRect();
  const lowerBound = 80;
  return (
    bb.top >= lowerBound &&
    bb.top <=
      ((parent
        ? parseInt(getComputedStyle(parent).getPropertyValue('height'))
        : window.innerHeight || document.documentElement.clientHeight) -
        lowerBound) /
        3
  );
};

const QuestionStepper = ({
  formData,
  steps,
  pageType,
  docType,
  labelFormatter,
  numberFormatter,
  hiddenNumbers,
  getStepContent,
  viewing = false,
  parent,
  jumpStep,
}: IOwnProps): JSX.Element => {
  const intl = useIntl();
  const fm = (id?: string): string => (id ? intl.formatMessage({ id: id }) : '-');

  const [activeStep, setActiveStep] = React.useState(0);
  const [subStep, setSubStep] = React.useState(0);
  const [previousWasSubStep, setPreviousWasSubStep] = React.useState<boolean>(false);

  const refs = makeRefsForSteps(steps);

  const subRefs = React.useRef<HTMLDivElement[]>([]);

  React.useEffect(() => {
    subRefs.current = subRefs.current.slice(
      0,
      typeof steps[activeStep] === 'object' ? (values(steps[activeStep] as object)[0] as Array<string>).length : 0,
    );
  });

  // jump to step if prop `jumpStep` was provided
  React.useEffect(() => {
    if (isNumber(jumpStep)) {
      setActiveStep(jumpStep);
    }
  }, [jumpStep]);

  const handleStepAndScroll = (nextIndex?: number, headerClicked?: boolean): void => {
    // const currentNode = refs[activeStep]?.current || undefined;
    const nextNode =
      refs[
        nextIndex || nextIndex === 0
          ? nextIndex - (previousWasSubStep && !headerClicked ? 2 : 0)
          : activeStep + (previousWasSubStep && !headerClicked ? 0 : 1)
      ]?.current || undefined;
    setActiveStep(nextIndex || nextIndex === 0 ? nextIndex : activeStep + 1);
    setSubStep(0);

    if (nextNode && !elementInViewport(nextNode)) {
      const scroll = (): void => {
        const scrollOffset = parent
          ? previousWasSubStep && !headerClicked
            ? 0
            : 50
          : previousWasSubStep && !headerClicked
            ? 50
            : 150;
        if (!isIE()) {
          (parent ? parent : window).scrollTo({ behavior: 'smooth', top: nextNode.offsetTop - scrollOffset });
        }
        // currentNode.removeEventListener('transitionend', scroll, false);
        // nextNode.removeEventListener('transitionend', scroll, false);
      };
      // currentNode.addEventListener('transitionend', scroll, false);
      // nextNode.addEventListener('transitionend', scroll, false);
      setTimeout(scroll, previousWasSubStep && !headerClicked ? 0 : 600);
    }
    setPreviousWasSubStep(false);
  };

  const handleNextStep = (): void => {
    handleStepAndScroll();
  };

  const handleNextSubStep = (nextIndex?: number): void => {
    const nextNode = subRefs.current[subStep + 1] || undefined;
    if (typeof steps[activeStep] === 'object') {
      const optValues = values(steps[activeStep] as object)[0] as string[];
      if (subStep < optValues.length - 1) {
        // If not the last sub question
        setSubStep((prevSubStep) => prevSubStep + 1);
        if (nextNode && nextNode.offsetParent !== null && !elementInViewport(nextNode, parent)) {
          const scroll = (): void => {
            const scrollOffset = parent ? 150 : 250;
            if (!isIE()) {
              (parent ? parent : window).scrollTo({ behavior: 'smooth', top: nextNode.offsetTop - scrollOffset });
            }
          };
          setTimeout(scroll, 600);
        }
      } else {
        handleStepAndScroll(nextIndex);
      }
    }
    setPreviousWasSubStep(true);
  };

  const onClickHeader = (index: number) => (): void => {
    handleStepAndScroll(index, true);
  };
  const onClickSubHeader = (index: number) => (): void => {
    setSubStep(index);
  };

  const onClickItem = (
    name: string,
    qNumber: number | string | undefined,
    onChange?: IOwnProps['formData']['onChange'],
    disabledNextIndex?: number,
  ): void => {
    if (!onChange) return;
    if (qNumber === formData.document[name]) {
      onChange({ [name]: '' });
    } else {
      if (disabledNextIndex && (disabledNextIndex > 0 || disabledNextIndex === -1)) {
        onChange({ [name]: qNumber });
        handleStepAndScroll(disabledNextIndex);
      } else {
        onChange({ [name]: qNumber });
        handleNextStep();
      }
    }
  };

  const onClickSubItem = (
    name: string,
    qNumber: number | string | undefined,
    onChange?: IOwnProps['formData']['onChange'],
    disabledNextIndex?: number,
  ): void => {
    if (!onChange) return;
    if (qNumber === formData.document[name]) {
      onChange({ [name]: '' });
    } else {
      onChange({ [name]: qNumber });
      handleNextSubStep(disabledNextIndex);
    }
  };

  // For finding next non-disabled question. Returns undefined if next one is not disabled. Returns last index when there are only disabled indexes left.
  const findNextNonDisabledIndex = (array: any[], currentIndex: number): number | undefined => {
    for (let i = 0; i < array.length; i++) {
      if (
        i > currentIndex &&
        ((typeof array[i] === 'string' && getStepContent(array[i]).disabled !== true) ||
          (typeof array[i] === 'object' && getStepContent(keys(array[i])[0] as string).disabled !== true))
      ) {
        if (i === currentIndex + 1) {
          return undefined;
        }
        return i;
      }
      if (
        i === array.length - 1 &&
        ((typeof array[i] === 'string' && getStepContent(array[i]).disabled === true) ||
          (typeof array[i] === 'object' && getStepContent(keys(array[i])[0] as string).disabled === true))
      ) {
        return -1;
      }
    }
    return undefined;
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical" style={{ margin: '0', padding: '0' }} nonLinear={true}>
      {steps.map((name: string | object, index: number) =>
        typeof name === 'string' ? (
          <Step key={name} ref={refs[index]}>
            <StyledStepLabel
              style={
                getStepContent(name).disabled && getStepContent(name).disabled === true
                  ? { backgroundColor: colors.gray, cursor: 'default' }
                  : { backgroundColor: colors.primary, cursor: 'pointer' }
              }
              onClick={onClickHeader(index)}
            >
              <StyledLabelText>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <StepTitleQuestionScalable
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          paddingRight: '5rem',
                        }}
                      >
                        {labelFormatter || (pageType && docType && name)
                          ? labelFormatter
                            ? labelFormatter(name)
                            : fm(`${pageType}.labels.${docType}.${name}`)
                          : ''}
                        {getStepContent(name).info && (
                          <span style={{ marginLeft: '2rem' }}>
                            <InfoPopper
                              text={
                                labelFormatter ? (
                                  <span>{labelFormatter(getStepContent(name).info ?? '')}</span>
                                ) : (
                                  `${pageType}.labels.${docType}.${getStepContent(name).info}`
                                )
                              }
                            />
                          </span>
                        )}
                      </StepTitleQuestionScalable>
                      <td
                        style={{
                          textAlign: 'right',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          maxWidth: '20rem',
                          minWidth: '10rem',
                        }}
                      >
                        {docType === 'mfm'
                          ? sumOfAllValues(docType, index, formData, steps) // Stupid hack, should be refactored
                          : numberFormatter && path(['document', name], formData)
                            ? numberFormatter(path(['document', name], formData) ?? '')
                            : path(['document', name], formData)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </StyledLabelText>
            </StyledStepLabel>
            <StepContent>
              {getStepContent(name).instruction && instructionElement(getStepContent(name).instruction || <span />)}
              {getStepContent(name).instruction2 && instructionElement(getStepContent(name).instruction2 || <span />)}
              {getStepContent(name).instruction3 && instructionElement(getStepContent(name).instruction3 || <span />)}
              {docType === 'hfmsE' && (
                <HfmsEElements
                  formData={formData}
                  name={name}
                  viewing={viewing}
                  disabled={getStepContent(name).disabled}
                />
              )}
              {docType === 'nsaa' && <NsaaElements formData={formData} name={name} viewing={viewing} />}
              {docType === 'mfm' && <MfmElements formData={formData} name={name} viewing={viewing} />}
              <Container
                justifyContent={isIE() ? 'space-around' : 'space-evenly'}
                style={{ padding: '3rem 0' }}
                spacing={itemSpacing(getStepContent(name).opts as [])}
              >
                {getStepContent(name).opts.map((qNumber: number | string, i) => (
                  <Item
                    key={name + qNumber + i}
                    xs={itemSize(getStepContent(name).opts as [])}
                    style={{ marginBottom: getStepContent(name).opts.length > 5 ? '2rem' : undefined }}
                  >
                    <QuestionItem
                      qNumber={
                        hiddenNumbers && hiddenNumbers.includes(qNumber)
                          ? ''
                          : numberFormatter
                            ? numberFormatter(qNumber, name)
                            : qNumber
                      }
                      text={
                        (getStepContent(name).optionFormatter || (pageType && docType)) &&
                        (qNumber || (typeof qNumber === 'number' && qNumber === 0)) ? (
                          <div style={{ textAlign: getStepContent(name).centered ? 'center' : undefined }}>
                            {getStepContent(name).optionFormatter
                              ? `${getStepContent(name).optionFormatter?.(qNumber, name, i)}`
                              : fm(`${pageType}.opts.${docType}.${getStepContent(name).optsLocale}.${qNumber}`)}
                          </div>
                        ) : (
                          ''
                        )
                      }
                      onClick={
                        getStepContent(name).disabled && getStepContent(name).disabled === true
                          ? undefined
                          : !viewing
                            ? (): void =>
                                onClickItem(
                                  name,
                                  qNumber,
                                  formData.onChange,
                                  findNextNonDisabledIndex(steps, index) || undefined,
                                )
                            : undefined
                      }
                      selected={path(['document', name], formData) === qNumber}
                      asset={
                        includes(qNumber, getStepContent(name)?.assets || []) && docType
                          ? getThisAssetElement(name, qNumber, docType)
                          : undefined
                      }
                      height={getStepContent(name)?.height || undefined}
                      viewing={viewing}
                      disabled={getStepContent(name).disabled && getStepContent(name).disabled === true ? true : false}
                    />
                  </Item>
                ))}
              </Container>
            </StepContent>
          </Step>
        ) : (
          // For steps with substeps. New stepper for them
          <Step key={Object.keys(name)[0]} ref={refs[index]}>
            <StyledStepLabel
              style={
                getStepContent(keys(name)[0]).disabled && getStepContent(keys(name)[0]).disabled === true
                  ? { backgroundColor: colors.gray, cursor: 'default' }
                  : { backgroundColor: colors.primary, cursor: 'pointer' }
              }
              onClick={onClickHeader(index)}
              icon={undefined}
            >
              <StyledLabelText>
                <Container alignItems="center">
                  <Item xs={10}>
                    {labelFormatter
                      ? labelFormatter(Object.keys(name ?? {})[0])
                      : pageType && docType && (name || name === 0)
                        ? fm(`${pageType}.labels.${docType}.${Object.keys(name)[0]}`)
                        : ''}
                    {getStepContent(values(name)[0][0]).info && (
                      <span style={{ marginLeft: '2rem' }}>
                        <InfoPopper
                          text={
                            labelFormatter ? (
                              <span>{labelFormatter(getStepContent(values(name)[0][0]).info ?? '')}</span>
                            ) : (
                              `${pageType}.labels.${docType}.${getStepContent(values(name)[0][0]).info}`
                            )
                          }
                        />
                      </span>
                    )}
                  </Item>
                  <Item xs={2} style={{ textAlign: 'right' }}>
                    {sumOfAllValues(docType, index, formData, steps)}
                  </Item>
                </Container>
              </StyledLabelText>
            </StyledStepLabel>
            <StepContent>
              {getStepContent(values(name)[0][0]).instruction &&
                instructionElement(getStepContent(values(name)[0][0]).instruction || <span />)}
              {getStepContent(values(name)[0][0]).instruction2 &&
                instructionElement(getStepContent(values(name)[0][0]).instruction2 || <span />)}
              {getStepContent(values(name)[0][0]).instruction3 &&
                instructionElement(getStepContent(values(name)[0][0]).instruction3 || <span />)}

              {/* PBA-S Hallucination instruction */}
              {values(name)[0][0] === 'hallucinationsModality' &&
                instructionElement(getStepContent('hallucinationsSeverity').instruction || <span />)}

              <Stepper activeStep={subStep} orientation="vertical">
                {values(name as { [key: string]: string[] })[0].map((n: string, i: number) => (
                  <Step
                    key={n}
                    ref={(el: HTMLDivElement): void => {
                      el && el !== null ? (subRefs.current[i] = el) : undefined;
                    }}
                  >
                    <StyledSubStepLabel
                      onClick={onClickSubHeader(i)}
                      StepIconComponent={IconChooser(
                        path(['document', n], formData),
                        getStepContent(keys(name)[0]).disabled && getStepContent(keys(name)[0]).disabled === true
                          ? colors.gray
                          : undefined,
                      )}
                    >
                      <StyledSubLabelText>
                        <Container alignItems="center">
                          <Item xs={8}>
                            {labelFormatter
                              ? labelFormatter(n)
                              : pageType && docType && n
                                ? fm(`${pageType}.labels.${docType}.${n}`)
                                : ''}
                          </Item>
                          <Item xs={4} style={{ textAlign: 'right' }}>
                            {n === 'hallucinationsModality' &&
                            n in formData.document &&
                            Array.isArray(formData.document[n]) ? (
                              <>
                                {formData.document[n].map(
                                  (
                                    item: 'auditory' | 'visual' | 'tactile' | 'olfactory' | 'gustatory',
                                    index: number,
                                  ) => (
                                    <span key={item}>
                                      {item ? fm(`behaviour.opts.pbaS.modality.${item}`) : ''}
                                      {index < formData.document[n].length - 1 ? ', ' : ''}
                                    </span>
                                  ),
                                )}
                              </>
                            ) : (
                              path(['document', n], formData)
                            )}
                          </Item>
                        </Container>
                      </StyledSubLabelText>
                    </StyledSubStepLabel>
                    <StepContent>
                      {docType === 'pbaS' && n === 'hallucinationsModality' && (
                        <PbaSElements formData={formData} viewing={viewing} moveToNext={handleNextSubStep} />
                      )}

                      <Container
                        justifyContent={isIE() ? 'space-around' : 'space-evenly'}
                        style={{ padding: '3rem 0' }}
                        spacing={itemSpacing(getStepContent(n).opts as [])}
                      >
                        {getStepContent(n).opts.map((qNumber: number | string, i) => (
                          <Item key={n + qNumber + i} xs={itemSize(getStepContent(n).opts as [])}>
                            <QuestionItem
                              qNumber={qNumber}
                              text={
                                getStepContent(n).optionFormatter ||
                                (pageType && docType && n && (qNumber || qNumber === 0)) ? (
                                  <div style={{ textAlign: getStepContent(n).centered ? 'center' : undefined }}>
                                    {getStepContent(n).optionFormatter
                                      ? `${getStepContent(n).optionFormatter?.(qNumber, n)}`
                                      : fm(`${pageType}.opts.${docType}.${getStepContent(n).optsLocale}.${qNumber}`)}
                                  </div>
                                ) : (
                                  ''
                                )
                              }
                              onClick={
                                !viewing
                                  ? (): void =>
                                      onClickSubItem(
                                        n,
                                        qNumber,
                                        formData.onChange,
                                        findNextNonDisabledIndex(steps, index) || undefined,
                                      )
                                  : undefined
                              }
                              selected={path(['document', n], formData) === qNumber}
                              height={getStepContent(n).height || undefined}
                              asset={
                                includes(qNumber, getStepContent(n)?.assets || []) && docType
                                  ? getThisAssetElement(n, qNumber, docType)
                                  : undefined
                              }
                              viewing={viewing}
                              disabled={
                                getStepContent(keys(name)[0]).disabled === true ||
                                includes(qNumber, getStepContent(n)?.disabledOptions || [])
                              }
                              disabledMessage={getStepContent(n)?.disabledMessage}
                            />
                          </Item>
                        ))}
                      </Container>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </StepContent>
          </Step>
        ),
      )}
    </Stepper>
  );
};

interface IOwnProps {
  formData: IFormData<any>;
  steps: Array<string | { [key: string]: string[] }>;
  /**
   ** Default labels locale prefix: `${pageType}.labels.${docType}`
   ** Default opts locale prefix: `${pageType}.opts.${docType}`
   */
  pageType?: string;
  /**
   ** Default labels locale prefix: `${pageType}.labels.${docType}`
   ** Default opts locale prefix: `${pageType}.opts.${docType}`
   */
  docType?: string;
  /** Labels are the titles of steps - this formatter overrides pageType and docType props */
  labelFormatter?: (name: string | number) => string | number;
  /** Numbers are the titles of step options */
  numberFormatter?: (number: string | number, name?: string | number) => string | number;
  /** Numbers (titles of step options) to hide. Will still be shown in step label if selected */
  hiddenNumbers?: Array<string | number>;
  getStepContent: (step: string) => {
    opts: (number | string)[];
    /** pageType and docType define a prefix for optsLocale */
    optsLocale?: string;
    /* Opts are the content of step options - this formatter overrides optsLocale prop */
    optionFormatter?: (number: string | number, name?: string | number, index?: number) => string | number;
    /** Align text to center */
    centered?: true;
    info?: string;
    assets?: number[];
    height?: number;
    instruction?: JSX.Element;
    instruction2?: JSX.Element;
    instruction3?: JSX.Element;
    disabled?: boolean;
    disabledOptions?: (number | string)[];
    disabledMessage?: JSX.Element | string;
  };
  viewing?: boolean;
  parent?: HTMLElement | null;
  jumpStep?: number;
}

export default QuestionStepper;
