import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select, MenuItem, SelectChangeEvent, styled } from '@mui/material';
import { field } from '../../../../config/theme/componentTheme';

const StyledDiv = styled('div')`
  max-width: ${(props: { width?: number }): string => (props.width ? props.width + 'rem' : field.width)};
`;

const optionFormatter = (name: string | number): JSX.Element => <FormattedMessage id={`geneTest.opts.${name}`} />;

export const StyledSelect = ({ onChange, options, value, width, placeholder }: ISelectInputProps): JSX.Element => {
  return (
    <StyledDiv width={width} style={{ padding: '0 2rem 0 0' }}>
      <Select
        onChange={(e: SelectChangeEvent<string | number>): void => onChange(e.target.value)}
        value={value}
        displayEmpty={true}
        style={value === '' ? { fontStyle: 'italic' } : {}}
        fullWidth
      >
        {placeholder && (
          <MenuItem key={'placeholder'} value={''} disabled={false} style={{ fontStyle: 'italic' }}>
            {placeholder}
          </MenuItem>
        )}
        {options &&
          options.length > 0 &&
          options.map((o: TGene | 'allGenesNotKnown') => (
            <MenuItem key={o} value={o}>
              {optionFormatter(o)}
            </MenuItem>
          ))}
      </Select>
    </StyledDiv>
  );
};

interface ISelectInputProps {
  options: (TGene | 'allGenesNotKnown')[];
  onChange: (e: unknown) => void;
  value?: string | number;
  width?: number;
  placeholder?: string | number | JSX.Element;
}
