import { path } from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import ActionButton from '../../../../../../../../components/ActionButton';
import FormRow from '../../../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../../../components/FormSectionHistoryAcceptor';
import { Container, Item } from '../../../../../../../../components/Grid';
import InputHandler from '../../../../../../../../components/InputHandler';
import colors from '../../../../../../../../config/theme/colors';
import { fm } from 'Components/FormatMessage';
import { numberOfGivenAnswers, redFlags } from '../../../../../../utils/NMOSD';

const Header = styled.div`
  font-weight: bold;
  margin: 2rem 0;
`;

const SubHeader = styled.div`
  margin: 1rem 0;
  font-weight: 400;
`;

const GroupStyle = styled.div`
  padding: 2rem;
`;

const GroupElement = (elem?: JSX.Element): JSX.Element => (
  <GroupStyle
    style={{
      backgroundColor:
        path(['props', 'children', 0, 'props', 'children'], elem) === null ? undefined : colors.lightestGray,
    }}
  >
    {elem}
  </GroupStyle>
);

const generalOptionFormatter = (s?: React.ReactText | boolean): JSX.Element => fm(`general.${s || 'empty'}`);

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  leftMargin,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  name: string;
  formData: IOwnProps['formData'];
  viewing: boolean;
  leftMargin?: number;
  bottomMargin?: boolean;
}): JSX.Element => {
  if (!viewing && !(formData.document as { [key: string]: any })[name]) {
    formData?.onChange?.({
      [name]: 'unknown',
    });
  }

  return (
    <FormRow
      title={title}
      description={description}
      headerWidth={6}
      bottomMargin={bottomMargin}
      headerStyle={{ marginLeft: `${leftMargin ?? 0}rem` }}
    >
      <InputHandler
        editing={!viewing}
        formData={formData}
        name={name}
        type="RadioButtonRow"
        preset="yesNoUnknown"
        height={3.5}
        width={9}
        optionFormatter={generalOptionFormatter}
      />
    </FormRow>
  );
};

const RedFlags = ({ documents, formData, view, editingID }: IOwnProps): JSX.Element => {
  const [isEditing, setEditing] = React.useState<boolean>(false);

  const isViewing = !editingID && !!view?.viewing;
  const docID = editingID ?? view?.viewing;

  React.useEffect(() => {
    redFlags.forEach((name: string) => {
      if (!isViewing && !(formData.document as { [key: string]: any })[name]) {
        formData?.onChange?.({
          [name]: 'unknown',
        });
      }
    });
  }, [formData, isViewing]);

  // Ref for scrolling to top of the section
  const ref = React.createRef() as React.RefObject<HTMLDivElement>;

  const toggleEditing = (): void => {
    setEditing(!isEditing);
    if (isEditing && ref.current) {
      scroll({ top: ref.current.offsetTop - 75, behavior: 'smooth' });
    }
  };

  return (
    <React.Fragment>
      <FormSectionHistoryAcceptor
        formData={formData}
        documentID={docID}
        documents={documents}
        optionFormatter={generalOptionFormatter}
        header="diagnosis.nmosdDiagnosticCriteria.RedFlags.title"
        hideCopyButton={isViewing || !isEditing}
        headerRef={ref}
      >
        {{
          edit: {
            header: (
              <Container style={{ marginTop: '-4rem', paddingRight: '2rem' }} justifyContent="flex-end">
                <Item>
                  <ActionButton
                    text={`general.${isViewing ? 'view' : 'edit'}`}
                    onClick={toggleEditing}
                    width={16}
                    height={3}
                    fontSize={16}
                  />
                </Item>
              </Container>
            ),
            condition: !isEditing,
          },
          answerCount: {
            element: (
              <React.Fragment>
                <Container style={{ marginTop: '0' }}>
                  <Item xs={6} style={{ paddingRight: '1rem' }}>
                    <SubHeader style={{ fontWeight: 600 }}>{fm('diagnosis.nmosdDiagnosticCriteria.answers')}</SubHeader>
                  </Item>
                </Container>
                {['yes', 'no', 'unknown'].map(
                  (answer: string): JSX.Element => (
                    <Container key={`${answer}RedFlags`} style={{ marginTop: '1rem' }}>
                      <Item xs={6} style={{ paddingLeft: '3rem' }}>
                        {fm(`general.${answer}`)}
                      </Item>
                      <Item xs={true} style={{ fontWeight: 600 }}>
                        {numberOfGivenAnswers(formData.document, redFlags, answer as 'yes' | 'no' | 'unknown')}
                      </Item>
                    </Container>
                  ),
                )}
              </React.Fragment>
            ),
            condition: !isEditing,
          },
          group1: {
            group: {
              condition: isEditing,
              groupElement: GroupElement,
              children: {
                redFlagsClinicalLaboratory: {
                  header: (
                    <Header style={{ marginTop: '0' }}>
                      {fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.redFlagsClinicalLaboratory')}
                    </Header>
                  ),
                  condition: isEditing,
                },
                clinicalFeaturesAndLaboratoryFindings: {
                  header: (
                    <SubHeader>
                      {fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.clinicalFeaturesAndLaboratoryFindings')}
                    </SubHeader>
                  ),
                  condition: isEditing,
                },

                progressiveOverallClinicalCourse: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.progressiveOverallClinicalCourse"
                      name="progressiveOverallClinicalCourse"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                atypicalTimeToAttackNadir: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.atypicalTimeToAttackNadir"
                      name="atypicalTimeToAttackNadir"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                partialTransverseMyelitis: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.partialTransverseMyelitis"
                      name="partialTransverseMyelitis"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                presenceOfCSFOligoclonalBands: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.presenceOfCSFOligoclonalBands"
                      name="presenceOfCSFOligoclonalBands"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                comorbiditiesAssociatedWithNeurologicSyndromes: {
                  header: (
                    <SubHeader>
                      {fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.comorbiditiesAssociatedWithNeurologicSyndromes')}
                    </SubHeader>
                  ),
                  condition: isEditing,
                },
                sarcoidosisEstablishedOrSuggestiveFindings: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.sarcoidosisEstablishedOrSuggestiveFindings"
                      name="sarcoidosisEstablishedOrSuggestiveFindings"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                cancerEstablishedOrSuggestiveFindings: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.cancerEstablishedOrSuggestiveFindings"
                      name="cancerEstablishedOrSuggestiveFindings"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                chronicInfectionEstablishedOrSuggestiveFindings: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.chronicInfectionEstablishedOrSuggestiveFindings"
                      name="chronicInfectionEstablishedOrSuggestiveFindings"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },

                redFlagsConventionalNeuroimaging: {
                  header: (
                    <Header>{fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.redFlagsConventionalNeuroimaging')}</Header>
                  ),
                  condition: isEditing,
                },
                brain: {
                  header: <SubHeader>{fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.brain')}</SubHeader>,
                  condition: isEditing,
                },
                imagingFeatures: {
                  header: <SubHeader>{fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.imagingFeatures')}</SubHeader>,
                  condition: isEditing,
                },
                lesionsWithOrientationPerpendicularToSurface: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.lesionsWithOrientationPerpendicularToSurface"
                      name="lesionsWithOrientationPerpendicularToSurface"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                lesionsAdjacentToLateralVentricle: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.lesionsAdjacentToLateralVentricle"
                      name="lesionsAdjacentToLateralVentricle"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                juxtacorticalLesionsInvolvingSubUFibers: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.juxtacorticalLesionsInvolvingSubUFibers"
                      name="juxtacorticalLesionsInvolvingSubUFibers"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                corticalLesions: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.corticalLesions"
                      name="corticalLesions"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },

                imagingCharacteristics: {
                  header: (
                    <SubHeader>{fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.imagingCharacteristics')}</SubHeader>
                  ),
                  condition: isEditing,
                },

                lesionsWithPesistentGadoliniumEnhancement: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.lesionsWithPesistentGadoliniumEnhancement"
                      name="lesionsWithPesistentGadoliniumEnhancement"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },

                spinalCord: {
                  header: <SubHeader>{fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.spinalCord')}</SubHeader>,
                  condition: isEditing,
                },
                characteriscticsMoreSuggestive: {
                  header: (
                    <SubHeader>
                      {fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.characteriscticsMoreSuggestive')}
                    </SubHeader>
                  ),
                  condition: isEditing,
                },

                lesionsCompleteVertebralSegmentsOnSagittalT2Seq: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.lesionsCompleteVertebralSegmentsOnSagittalT2Seq"
                      name="lesionsCompleteVertebralSegmentsOnSagittalT2Seq"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                lesionsLocatedPredominantly: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.lesionsLocatedPredominantly"
                      name="lesionsLocatedPredominantly"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: isEditing,
                },
                diffuseIndistinctSignalChangeOnT2Seq: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.RedFlags.diffuseIndistinctSignalChangeOnT2Seq"
                      name="diffuseIndistinctSignalChangeOnT2Seq"
                      formData={formData}
                      viewing={isViewing}
                      bottomMargin={true}
                    />
                  ),
                  condition: isEditing,
                },
                accept: {
                  header: (
                    <Container justifyContent="flex-end">
                      <Item>
                        <ActionButton
                          text={`general.${isViewing ? 'close' : 'accept'}`}
                          onClick={toggleEditing}
                          width={16}
                          height={3}
                          fontSize={16}
                        />
                      </Item>
                    </Container>
                  ),
                  condition: isEditing,
                },
              },
            },
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<INMOSDCriteria>;
  formData: IFormData<INMOSDCriteria>;
  view?: IView;
  editingID?: string;
}

export default RedFlags;
