import * as React from 'react';
import styled from 'styled-components';
import InfoPopper from '../../../../../../../components/InfoPopper';
import { medicationDescriptions } from './medicationDescriptions';

interface IOwnProps {
  medication: IMedication;
}

const StyledDiv = styled.div`
  // center vertically
  height: 100%;
  display: flex;
  align-items: center;
  // separate from left
  padding-left: 1rem;
`;

/** Whether a medication contains substance _mitoxantron_. */
const containsMitoxantron = (medication: IMedication): boolean => {
  const { medicationSubstances: substs } = medication;
  if (!substs) return false;
  else {
    return !!substs.match(/mitoksantroni|mitoxantron/);
  }
};

/** Popper containing instructions, or null if there are no instructions for given medication. */
const DosingInstructions = (props: IOwnProps): JSX.Element | null => {
  // parameter validation
  const { medication: med } = props;
  if (!med.medicationName) return null;

  // key used to index `medicationDescriptions`
  let descriptionKey: string = med.medicationName.toLowerCase();
  if (containsMitoxantron(med)) descriptionKey = 'mitoxantroni';
  const description = medicationDescriptions[descriptionKey];

  return !description ? null : (
    <StyledDiv>
      <InfoPopper text={medicationDescriptions[descriptionKey]} color="primary" maxWidth={70} />
    </StyledDiv>
  );
};

export default DosingInstructions;
