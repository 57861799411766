import ActionButtonRounded from 'Components/ActionButtonRounded';
import DataTable from 'Components/DataTable';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import InputHandler from 'Components/InputHandler';
import { formatPartialDate, formatTime, isPartialDate, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';
import colors from '../../../../config/theme/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import {
  formatOptionIndent,
  formatSeizureClassification,
  getOwnSeizureTypesClassifications,
  GroupHeader,
} from 'Routes/Seizure/utils';
import { actions } from 'Store/myapp/actions';
import { useDispatch } from 'react-redux';
import { MenuItem } from '@mui/material';
import { seizureClassifications } from 'Routes/Diagnosis/utils/definitions';

const PatientReportedSeizuresHistory = ({
  documents,
}: {
  documents: Array<IPatientReportedSeizure & IControlProps>;
}) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const uniqueSeizureNames = Array.from(new Set(documents.map((d) => d?.seizureName))).filter(
    (name) => name,
  ) as string[];
  const [classificationFilterInput, setClassificationFilterInput] = React.useState<string>('all');
  const [statusFilterInput, setStatusFilterInput] = React.useState<'unhandled' | 'rejected'>('unhandled');
  const [selectedDocs, setSelectedDocs] = React.useState<Array<string>>([]);
  const [selectListValue, setSelectListValue] = React.useState<boolean>(false);
  const [listedDocs, setListedDocs] = React.useState<Array<IPatientReportedSeizure & IControlProps>>(
    documents?.filter((d) => !d?.status),
  );
  const [listedDocsHistoryData, setListedDocsHistoryData] = React.useState<
    (string | number | JSX.Element | (string | number | string[] | JSX.Element | number[])[])[]
  >([]);

  const controlOpts = ['other', 'notSeizure', 'return'];
  const controlOptsOG = ['other', 'notSeizure'];
  const originalSet = getOwnSeizureTypesClassifications();
  const [classificationOpts, setClassificationOpts] = React.useState<string[]>(
    getOwnSeizureTypesClassifications().concat(controlOptsOG),
  );
  const [classificationMenuOpen, setClassificationMenuOpen] = React.useState<string | undefined>(undefined);
  const [tempClassifications, setTempClassifications] = React.useState<
    Array<{ [key: string]: TSeizureClassification | undefined }>
  >(listedDocs.map((d) => ({ [d._id]: d?.seizureType })));

  const classificationWithHeaders: string[] = [];
  classificationOpts.forEach((clas, i) => {
    // Add headers to all top level classifications that have children
    if (clas.length === 3 && classificationOpts[i + 1].match('^' + clas)) {
      classificationWithHeaders.push('header' + clas);
    }
    classificationWithHeaders.push(clas);
  });

  const onCloseClassification = (e: React.BaseSyntheticEvent): void => {
    if (e.target.dataset.value === 'other' || e.target.dataset.value === 'return') return;
    setClassificationMenuOpen(undefined);
  };

  const onChangeSelection = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const docId = name.split('@')[1];
    if (value) {
      setSelectedDocs((prev) => [...prev, docId]);
    } else {
      setSelectedDocs((prev) => prev?.filter((id) => id !== docId));
    }
  };

  const onChangeClassificationFilter = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (typeof value === 'string') {
      setSelectedDocs([]);
      setSelectListValue(false);
      setClassificationFilterInput(value);
    }
  };

  const onChangeStatusFilter = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (value === 'unhandled' || value === 'rejected') {
      setSelectedDocs([]);
      setSelectListValue(false);
      setStatusFilterInput(value);
    }
  };

  const onChangeSeizureType = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const docId = name.split('@')[1];
    if (controlOpts.includes(value as any)) {
      switch (value) {
        case 'other':
          setClassificationOpts([...seizureClassifications, 'notSeizure', 'return']);
          break;
        case 'return':
          setClassificationOpts([...originalSet, 'other', 'notSeizure']);
          setTempClassifications((prev) => {
            const docIdIndex = prev.findIndex((p) => Object.keys(p)[0] === docId);
            if (docIdIndex >= 0) prev.splice(docIdIndex, 1, { [docId]: undefined });
            return prev;
          });
          break;
        case 'notSeizure':
          setTempClassifications((prev) => {
            const docIdIndex = prev.findIndex((p) => Object.keys(p)[0] === docId);
            if (docIdIndex >= 0) prev.splice(docIdIndex, 1, { [docId]: value });
            return prev;
          });
          break;
      }
    } else {
      setTempClassifications((prev) => {
        const docIdIndex = prev.findIndex((p) => Object.keys(p)[0] === docId);
        if (docIdIndex === -1) {
          return [...prev, { [docId]: value as TSeizureClassification }];
        } else {
          prev.splice(docIdIndex, 1, { [docId]: value as TSeizureClassification });
          return prev;
        }
      });
    }
  };

  const [updating, setUpdating] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const handleAcceptClick = async () => {
    setUpdating(true);
    for (const id of selectedDocs) {
      const oldData = documents.find((d) => d._id === id);
      await actions.putNewCommit(
        'patientReportedSeizure',
        id,
        {
          ...oldData,
          status: 'accepted',
          seizureType:
            tempClassifications.find((td) => Object.keys(td)[0] === oldData?._id)?.[id] ?? oldData?.seizureType,
          seizureName:
            tempClassifications.find((td) => Object.keys(td)[0] === oldData?._id)?.[id] !== oldData?.seizureType
              ? undefined
              : oldData?.seizureName,
        },
        dispatch,
      );
    }
    setUpdating(false);
  };

  const handleRejectClick = async () => {
    setUpdating(true);
    for (const id of selectedDocs) {
      const oldData = documents.find((d) => d._id === id);
      await actions.putNewCommit(
        'patientReportedSeizure',
        id,
        {
          ...oldData,
          status: 'rejected',
          seizureType:
            tempClassifications.find((td) => Object.keys(td)[0] === oldData?._id)?.[id] ?? oldData?.seizureType,
          seizureName:
            tempClassifications.find((td) => Object.keys(td)[0] === oldData?._id)?.[id] !== oldData?.seizureType
              ? undefined
              : oldData?.seizureName,
        },
        dispatch,
      );
    }
    setUpdating(false);
  };

  React.useEffect(() => {
    setListedDocs(
      documents?.filter(
        (d) =>
          (statusFilterInput === 'unhandled' ? !d?.status : d.status === 'rejected') &&
          (classificationFilterInput === 'all' ? true : classificationFilterInput === d.seizureName),
      ),
    );
  }, [documents]);

  React.useEffect(() => {
    listedDocs?.forEach((d) => {
      onChangeSelection({ [`selected@${d._id}`]: selectListValue });
    });
  }, [selectListValue]);

  React.useEffect(() => {
    if (statusFilterInput === 'rejected') {
      setListedDocs(
        documents?.filter(
          (d) =>
            d?.status === 'rejected' &&
            (classificationFilterInput === 'all' ? true : d?.seizureName === classificationFilterInput),
        ),
      );
    } else {
      setListedDocs(
        documents?.filter(
          (d) =>
            !d?.status && (classificationFilterInput === 'all' ? true : d?.seizureName === classificationFilterInput),
        ),
      );
    }
  }, [statusFilterInput]);

  React.useEffect(() => {
    if (classificationFilterInput === 'all') {
      if (statusFilterInput === 'unhandled') {
        setListedDocs(documents?.filter((d) => !d?.status));
      } else {
        setListedDocs(documents?.filter((d) => d?.status === 'rejected'));
      }
    } else {
      if (statusFilterInput === 'unhandled') {
        setListedDocs(documents?.filter((d) => d.seizureName === classificationFilterInput && !d?.status));
      } else {
        setListedDocs(
          documents?.filter((d) => d.seizureName === classificationFilterInput && d?.status === 'rejected'),
        );
      }
    }
  }, [classificationFilterInput]);

  React.useEffect(() => {
    setTempClassifications((prev) => {
      const newListed = listedDocs
        .map((d) => ({ [d._id]: d.seizureType }))
        .filter((ld) => !prev.find((p) => Object.keys(p)[0] === Object.keys(ld)[0]));
      return [...prev, ...newListed];
    });
  }, [listedDocs]);

  React.useEffect(() => {
    setListedDocsHistoryData(
      listedDocs
        ?.sort((d1, d2) => sortPartialDate(d2.startDate, d1.startDate))
        .map((d, i) => [
          <div key={i} style={{ display: 'flex', marginTop: '0.9rem' }}>
            <InputHandler
              type={'CheckboxSingle'}
              name={`selected@${d._id}`}
              editing={true}
              formData={{
                document: { [`selected@${d._id}`]: selectedDocs.includes(d._id) },
                onChange: onChangeSelection,
              }}
              option="true"
              showLabel={false}
              style={{ margin: 0 }}
            />
          </div>,
          <div key={d._docCreateDate} style={{ paddingRight: '1rem' }}>
            <div style={{ fontWeight: 600, display: 'inline-block', whiteSpace: 'nowrap' }}>
              {formatPartialDate(d?.startDate)}
              {d?.endDate && !d?.startTime ? '\u00A0-\u00A0' : d?.startTime ? '\u00A0' : ''}
            </div>
            <div
              style={{
                fontWeight: d?.endDate && isPartialDate(d.endDate) ? 600 : 400,
                display: 'inline-block',
                whiteSpace: 'nowrap',
              }}
            >
              {d?.endDate ? `${formatPartialDate(d.endDate)}` : d?.startTime ? `${formatTime(d?.startTime)}` : ''}
            </div>
          </div>,
          d?.count ?? '-',
          <div key={d._cdate}>
            <div style={{ fontWeight: 600 }}>{d?.seizureName ?? fm('patientReportedSeizure.unNamed')}</div>
            <div>
              <InputHandler
                name={`seizureType@${d._id}`}
                type="Select"
                editing={selectedDocs.includes(d._id)}
                formData={{
                  document: {
                    [`seizureType@${d._id}`]: selectedDocs.includes(d._id)
                      ? tempClassifications.find((c) => Object.keys(c)[0] === d._id)?.[d._id]
                      : listedDocs.find((ld) => ld._id === d._id)?.seizureType,
                  },
                  onChange: onChangeSeizureType,
                }}
                options={classificationWithHeaders}
                optionFormatter={(o): string | JSX.Element => {
                  return controlOpts.includes(`${o}`) ? (
                    <span style={{ fontWeight: 400 }}>{fm(`seizure.${o}`)}</span>
                  ) : (
                    <span style={{ fontWeight: 400 }}>
                      {classificationMenuOpen &&
                      seizureClassifications.every(
                        (c) => classificationOpts.includes(c) && selectedDocs.includes(d._id),
                      )
                        ? formatOptionIndent(`${o} ${fm(`seizure.opts.${o}`)}`)
                        : selectedDocs.includes(d._id)
                          ? formatSeizureClassification(o as TSeizureClassification, 'short')
                          : formatSeizureClassification(o as TSeizureClassification, 'long')}
                    </span>
                  );
                }}
                menuItemComponent={(o: string | number, optFormatter) => {
                  const option = `${o}`;
                  if (option.substring(0, 6) === 'header') {
                    // Different menuitems for group headers
                    return (
                      <GroupHeader
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <MenuItem disabled>
                          {optFormatter ? optFormatter(option.substring(6)) : option.substring(6)}
                        </MenuItem>
                      </GroupHeader>
                    );
                  }
                  return (
                    <MenuItem value={option} key={option}>
                      {optFormatter ? (
                        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                          {optFormatter(option)}
                          {/** Show parent group for deep child items when big selection is open */}
                          {classificationMenuOpen &&
                          seizureClassifications.every((c) => classificationOpts.includes(c)) &&
                          option.split('.').length > 3 ? (
                            <>
                              <span style={{ padding: '0.4rem 0.5rem 0 0.5rem', fontSize: '1.2rem' }}>-</span>
                              <span style={{ paddingTop: '0.4rem', fontSize: '1.2rem' }}>
                                {fm('seizure.opts.' + option.split('.').slice(0, -1).join('.'))}
                              </span>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : (
                        option
                      )}
                    </MenuItem>
                  );
                }}
                width={40}
                minWidth={18}
                open={classificationMenuOpen === d._id}
                onOpen={(): void => setClassificationMenuOpen(d._id)}
                onClose={onCloseClassification}
              />
            </div>
          </div>,
          d?.medicCompliance === 'yes' ? (
            <div style={{ display: 'flex' }}>
              <CheckCircleIcon style={{ marginRight: '0.5rem', color: colors.success }} />
              {fm('general.yes')}
            </div>
          ) : d.medicCompliance === 'no' ? (
            <div style={{ display: 'flex' }}>
              <WarningIcon style={{ marginRight: '0.5rem', color: colors.warning.dark }} />
              {fm('general.no')}
            </div>
          ) : (
            '-'
          ),
          d?.freeDescription ?? '-',
        ]),
    );
  }, [tempClassifications, selectedDocs, classificationMenuOpen, classificationOpts]);

  return (
    <div>
      <HistoryRowSubHeader header={fm('patientReportedSeizure.title')} />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '2rem' }}>
        <div
          style={{
            backgroundColor: colors.myms.primary,
            color: colors.white,
            fontSize: 14,
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            textAlign: 'center',
            minWidth: '1rem',
            marginRight: '1rem',
            borderRadius: 10,
          }}
        >
          {documents?.filter((d) => !d.status).length ?? 0}
        </div>
        <div style={{ color: colors.tertiaryText }}>{fm('patientReportedSeizure.unhandledReports')}</div>
      </div>
      <div
        style={{
          width: '100%',
          height: '5.6rem',
          backgroundColor: colors.appBlue.lightest,
          borderRadius: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '6rem' }}>
          <span style={{ color: colors.appBlue.default, paddingLeft: '2rem', marginRight: '1rem' }}>
            {fm('patientReportedSeizure.classificationFilter')}
          </span>
          <div>
            <InputHandler
              type="Select"
              name="classificationFilter"
              editing={true}
              options={['all', ...uniqueSeizureNames]}
              optionFormatter={(o) => (o === 'all' ? fm('patientReportedSeizure.all') : `${o}`)}
              formData={{
                onChange: onChangeClassificationFilter,
                document: { classificationFilter: classificationFilterInput },
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: colors.appBlue.default, paddingLeft: '2rem', marginRight: '1rem' }}>
            {fm('patientReportedSeizure.statusFilter')}
          </span>
          <div>
            <InputHandler
              type="Select"
              name="statusFilter"
              editing={true}
              options={['unhandled', 'rejected']}
              optionFormatter={(o) => fm(`patientReportedSeizure.opts.status.${o}`)}
              formData={{
                onChange: onChangeStatusFilter,
                document: { statusFilter: statusFilterInput },
              }}
            />
          </div>
        </div>
      </div>
      <DataTable
        headers={[
          <div key={'headerCheckbox'} style={{ marginTop: '0.9rem' }}>
            <InputHandler
              type={'CheckboxSingle'}
              name={'selectList'}
              editing={true}
              formData={{
                document: { selectList: selectListValue },
                onChange: () => setSelectListValue(!selectListValue),
              }}
              option="true"
              showLabel={false}
              style={{ margin: 0 }}
            />
          </div>,
          'patientReportedSeizure.timeframe',
          <div key={'count'} style={{ whiteSpace: 'pre-line' }}>
            {fm('patientReportedSeizure.count').replace(' ', '\n')}
          </div>,
          <div key={'medicCompliance'} style={{ whiteSpace: 'nowrap', width: '40rem' }}>
            {fm('patientReportedSeizure.seizureNameSeizureType')}
          </div>,
          <div key={'medicCompliance'} style={{ whiteSpace: 'pre-line' }}>
            {fm('patientReportedSeizure.medicCompliance').replace(' ', '\n')}
          </div>,
          'patientReportedSeizure.freeDescription',
        ]}
        headersFormatter={fm}
        data={{
          rowData: listedDocsHistoryData,
        }}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
        <div style={{ marginRight: '2rem' }}>
          <ActionButtonRounded
            text={'patientReportedSeizure.rejectSelected'}
            width={15}
            height={4}
            fontSize={16}
            onClick={handleRejectClick}
            disabled={selectedDocs.length < 1 || statusFilterInput === 'rejected'}
            loading={updating}
          />
        </div>
        <div>
          <ActionButtonRounded
            text={'patientReportedSeizure.acceptSelected'}
            width={15}
            height={4}
            fontSize={16}
            filled
            onClick={handleAcceptClick}
            disabled={selectedDocs.length < 1}
            loading={updating}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientReportedSeizuresHistory;
