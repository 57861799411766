import { uniq, mergeRight } from 'ramda';
import * as React from 'react';

import FormRow from '../../../../../../../components/FormRow';
import InputHandler from '../../../../../../../components/InputHandler';

import { sortStrengths } from '../../../../../../../utility/randomUtil';
import styled from 'styled-components';
import { StrengthSelect } from './../components/StrengthSelect';
import { IRegimenContext } from '..';
import UnitSelect from '../components/UnitSelect';
import { fm } from 'Components/FormatMessage';

const StyledUnit = styled.span`
  margin: 0.5rem;
`;

// Generate strengths array for current medication from available packages
const strengthsArray = (packages: IMedicationPackage[]): { [key: string]: string }[] => {
  const strengths = packages.map((p: IMedicationPackage) => p.strengths);
  return uniq(strengths).sort((a1, a2) => sortStrengths(a1, a2));
};

const strengthsOnChange =
  (
    onChange: IFormData['onChange'],
    strArr: IStrengths[],
    thisRegimen: IRegimenOnDemand,
    allRegimen: IRegimenOnDemand[],
    index: number,
  ) =>
  (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = [strArr[values[name] as number]];

    const thisRegimens = { ...thisRegimen };
    const allRegimens = [...allRegimen];

    allRegimens[index] = mergeRight(thisRegimens, { [name]: value as any, vnr: '' });
    onChange?.({ regimen: allRegimens });
  };

// OnChange for other fields
const otherOnChange =
  (onChange: IFormData['onChange'], thisRegimen: IRegimenOnDemand, allRegimen: IRegimenOnDemand[], index: number) =>
  (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const thisRegimens = { ...thisRegimen };
    const allRegimens = [...allRegimen];

    allRegimens[index] = mergeRight(thisRegimens, { [name]: value } as any);
    // Update all regimen
    onChange?.({ regimen: allRegimens });
  };

const OnDemand = ({ formData, editIndex, packages }: IRegimenContext): JSX.Element => {
  const regimen = formData.document.regimen?.[editIndex] as IRegimenBasics & IRegimenOnDemand;
  const strengths = React.useMemo(() => regimen.strengths || [], [regimen.strengths]);
  const availableStrengths = packages ? strengthsArray(packages) : [];

  // Use this for most onChange needs. Complicated due to the need of updating regimen array
  const generalOnChange = otherOnChange(
    formData.onChange,
    regimen,
    formData.document.regimen as IRegimenOnDemand[],
    editIndex,
  );

  return (
    <React.Fragment>
      <StrengthSelect
        formData={formData}
        availableStrengths={availableStrengths}
        strengthsOnChange={strengthsOnChange(
          formData.onChange,
          availableStrengths,
          regimen,
          formData.document.regimen as IRegimenOnDemand[],
          editIndex,
        )}
        strengths={strengths}
      />
      <UnitSelect unitValue={regimen.unit} onChange={generalOnChange} />

      <FormRow title="medication.doseSize" headerWidth={3}>
        <InputHandler
          type="TextField"
          name="dose"
          editing={true}
          formData={{
            onChange: generalOnChange,
            document: { dose: regimen.dose },
          }}
          placeholder="medication.doseSize"
        />

        <StyledUnit>{regimen?.unit ? fm(`medication.opts.unit.${regimen.unit}`) : ''}</StyledUnit>
      </FormRow>
      <FormRow title="medication.maxDoses" headerWidth={3}>
        <InputHandler
          type="NumberField"
          name="maxDoses"
          editing={true}
          formData={{
            onChange: generalOnChange,
            document: { maxDoses: regimen.maxDoses },
          }}
          placeholder="medication.maxDoses"
          precision={2}
        />
        <StyledUnit>{regimen?.unit ? fm(`medication.opts.unit.${regimen.unit}`) : ''}</StyledUnit>
      </FormRow>
    </React.Fragment>
  );
};

interface IStrengths {
  [key: string]: string;
}

export default OnDemand;
