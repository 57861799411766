import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import styled from 'styled-components';
import { Container, Item } from '../../../../../../components/Grid';
import colors from '../../../../../../config/theme/colors';

export const ContainerEvent = styled(({ ...other }) => <Container {...other} />)`
  width: auto !important;
  margin: 0 -2.5rem 0 -2.5rem;
  padding: 0rem 2.5rem;
`;

const ContainerBar = styled(({ ...other }) => <Container {...other} />)`
  background-color: ${colors.defaultBackground};
  width: auto !important;
  height: 5rem;
  margin: 0 -2.5rem 2rem -2.5rem;
  padding: 0 2.5rem;
`;

const StyledBarHeader = styled.div`
  font-weight: 600;
`;

export const MakeHeaderBar = ({
  date,
  dateExtended,
  additionalInfo,
  newButton,
  title,
}: {
  date?: PartialDate;
  dateExtended?: string | JSX.Element;
  additionalInfo?: string | JSX.Element;
  newButton?: JSX.Element;
  title?: string | JSX.Element;
}): JSX.Element => (
  <ContainerBar alignItems="center">
    <Item xs={true}>
      <Container>
        <StyledBarHeader>
          {date && formatPartialDate(date)}
          {dateExtended || null}
          {title || null}
        </StyledBarHeader>
        {additionalInfo && (
          <>
            <Item style={{ margin: '0 1rem' }}>{'-'}</Item>
            <Item>{additionalInfo}</Item>
          </>
        )}
      </Container>
    </Item>
    {newButton && (
      <>
        <Item xs={2}>
          <Container justifyContent="flex-end">
            <Item>{newButton}</Item>
          </Container>
        </Item>
      </>
    )}
  </ContainerBar>
);
