import { filter } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';
import { Radio } from '@mui/material';

import { ICapabilityContextProps, withCapabilities } from '../../../containers/CapabilityHandler';
import DocumentCreationButton from '../../../components/DocumentCreationButton';
import { FormContextProvider } from '../../../containers/FormContextHandler';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryRow from '../../../components/HistoryRow';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import HistoryRowControls from '../../../components/HistoryRowControls';
import HistoryTabs from '../../../components/HistoryTabs';
import { Container, Item } from 'Components/Grid';

import { sortDocuments } from '../../../utility/randomUtil';
import { assertCapabilities } from '../../../store/index';
import PlatformCapabilities from '../../../config/capabilities';

import {
  findActiveMedications,
  findEndedMedications,
  findOtherTreatments,
  findPausedMedications,
  findPeriodsWithoutMedication,
} from '../utils';
import getEditingDocType from '../utils/getEditingDocType';

import MedicationForm from './Form/Medication';
import AdverseEffectForm from './Form/Medication/AdverseEffect';
import OtherTreatmentForm from './Form/OtherTreatment';
import PeriodWithoutMedicationForm from './Form/PeriodWithoutMedication';

import MedicationHistoryRowData from './HistoryRowData/Medication';
import OtherTreatmentsHistoryRowData from './HistoryRowData/OtherTreatment';
import PeriodWithoutMedicationHistoryRowData from './HistoryRowData/PeriodWithoutMedication';
import { actions } from 'Store/session';
import PagingWrapper from 'Components/PagingWrapper';

const ActiveMedication = ({
  documents,
  adverseEvents,
  startEdit,
  setMedID,
  collapseStatus,
}: IMedicationHistory): JSX.Element => {
  return (
    <PagingWrapper
      limitOfItemsInPage={10}
      list={documents.sort((n1, n2) =>
        sortDocuments([
          { type: 'date', sortField: 'startDate' },
          { type: 'time', sortField: 'startTime' },
        ])(n1, n2),
      )}
      listItemComponent={(d: IMedication) => (
        <HistoryRow
          headerText={
            d.medicationName && d.isClinicalStudy?.[0] === true ? (
              <span style={{ textTransform: 'uppercase' }}>
                <FormattedMessage id="medication.clinicalStudyMedication" />
                {`: ${d.medicationName}`}
              </span>
            ) : d.medicationName ? (
              d.medicationName.toUpperCase() + (d.dosageForm ? ' - ' + d.dosageForm : '')
            ) : (
              ''
            )
          }
          key={d._id}
          rowButton={
            <div id={'medicationHistoryControl'}>
              <HistoryRowControls document={d} startEdit={startEdit} />
            </div>
          }
          controlsMargin={false}
        >
          <MedicationHistoryRowData
            d={d}
            startEdit={startEdit}
            setMedID={setMedID}
            adverseEvents={adverseEvents}
            collapseStatus={collapseStatus}
          />
        </HistoryRow>
      )}
    />
  );
};

const PausedMedication = ({
  documents,
  adverseEvents,
  startEdit,
  setMedID,
  collapseStatus,
}: IMedicationHistory): JSX.Element => {
  return (
    <React.Fragment>
      {documents.length > 0 ? (
        <>
          {documents
            .sort((n1, n2) =>
              sortDocuments([
                { type: 'date', sortField: 'startDate' },
                { type: 'time', sortField: 'startTime' },
              ])(n1, n2),
            )
            .map((d: IMedication) => (
              <HistoryRow
                headerText={
                  d.medicationName ? d.medicationName.toUpperCase() + (d.dosageForm ? ' - ' + d.dosageForm : '') : ''
                }
                key={d._id}
                rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                controlsMargin={false}
              >
                <MedicationHistoryRowData
                  d={d}
                  paused={true}
                  startEdit={startEdit}
                  setMedID={setMedID}
                  adverseEvents={adverseEvents}
                  collapseStatus={collapseStatus}
                />
              </HistoryRow>
            ))}
        </>
      ) : null}
    </React.Fragment>
  );
};

const EndedMedication = ({
  documents,
  adverseEvents,
  startEdit,
  setMedID,
  collapseStatus,
}: IMedicationHistory): JSX.Element => {
  return (
    <PagingWrapper
      limitOfItemsInPage={10}
      list={documents.sort((n1, n2) =>
        sortDocuments([
          { type: 'date', sortField: 'endDate' },
          { type: 'time', sortField: 'startTime' },
        ])(n1, n2),
      )}
      listItemComponent={(d: IMedication) => (
        <HistoryRow
          headerText={
            d.medicationName ? d.medicationName.toUpperCase() + (d.dosageForm ? ' - ' + d.dosageForm : '') : undefined
          }
          key={d._id}
          rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
          controlsMargin={false}
        >
          <MedicationHistoryRowData
            d={d}
            ended={true}
            startEdit={startEdit}
            setMedID={setMedID}
            adverseEvents={adverseEvents}
            collapseStatus={collapseStatus}
          />
        </HistoryRow>
      )}
    />
  );
};

const OtherTreatments = ({
  documents,
  startEdit,
}: {
  documents: IMedicationOtherTreatment[];
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
}): JSX.Element => {
  return (
    <React.Fragment>
      {documents.length > 0 ? (
        <>
          {documents
            .sort((n1, n2) =>
              sortDocuments([
                { type: 'date', sortField: 'endDate' },
                { type: 'time', sortField: 'startTime' },
              ])(n1, n2),
            )
            .map((d: IMedicationOtherTreatment) => (
              <HistoryRow
                headerText={d.type && <FormattedMessage id={'medication.otherTreatment.opts.' + d.type} />}
                key={d._id}
                rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                controlsMargin={false}
              >
                <OtherTreatmentsHistoryRowData d={d} />
              </HistoryRow>
            ))}
        </>
      ) : null}
    </React.Fragment>
  );
};

const PeriodWithoutMedication = ({
  documents,
  startEdit,
}: {
  documents: IPeriodWithoutMedication[];
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
}): JSX.Element => {
  return (
    <React.Fragment>
      {documents.length > 0 ? (
        <>
          {documents
            .sort((n1, n2) =>
              sortDocuments([
                { type: 'date', sortField: 'endDate' },
                { type: 'time', sortField: 'startTime' },
              ])(n1, n2),
            )
            .map((d: IPeriodWithoutMedication) => (
              <HistoryRow
                headerText={<FormattedMessage id={'medication.periodWithoutMedication.title'} />}
                key={d._id}
                rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                controlsMargin={false}
              >
                <PeriodWithoutMedicationHistoryRowData d={d} />
              </HistoryRow>
            ))}
        </>
      ) : null}
    </React.Fragment>
  );
};

interface IMedicationHistory {
  documents: IMedication[];
  adverseEvents: IMedicationAdverseEvent[];
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
  setMedID: (id: string) => void;
  collapseStatus: ICollapseStatus;
}

const Form = ({ editing, formData, consumeMedID, documents = [] }: IForm): JSX.Element => {
  const docType = getEditingDocType(documents, editing);
  const document = documents.find((d: { _id: string }) => d._id === editing);
  return (
    <React.Fragment>
      {docType === 'medication' && (
        <FormContextProvider context={{ formData, editing }}>
          <MedicationForm medication={document} />
        </FormContextProvider>
      )}
      {docType === 'adverseEffect' && (
        <AdverseEffectForm formData={formData} consumeMedID={consumeMedID} allDocuments={documents} />
      )}
      {docType === 'otherTreatment' && (
        <FormContextProvider context={{ formData, editing }}>
          <OtherTreatmentForm />
        </FormContextProvider>
      )}
      {docType === 'periodWithoutMedication' && (
        <FormContextProvider context={{ formData, editing }}>
          <PeriodWithoutMedicationForm />
        </FormContextProvider>
      )}
    </React.Fragment>
  );
};

interface IForm {
  editing: string;
  formData: IFormData<IMedicationAdverseEvent | IMedication>;
  consumeMedID: () => string | null;
  documents?: Array<IMedicationAdverseEvent | IMedication>;
}

const Medication = ({ documents, ...props }: IOwnProps): JSX.Element => {
  // Reference for adding adverse events, which are bound to medications by medication id
  const medIDReference = React.useRef<string | null>(null);

  const setMedIDRef = (id: string): void => {
    medIDReference.current = id;
  };

  const consumeMedIDRef = (): string | null => {
    const ret = medIDReference.current;
    medIDReference.current = null;
    return ret;
  };

  // Control collapses statuses. Which documents in history should stay open between render, etc
  const [unCollapsedDocs, setUnCollapsedDocs] = React.useState<ICollapseStatus['collapseIDArray']>([]);
  const setCollapseID = (id: string): void => {
    if (unCollapsedDocs.includes(id)) {
      const newCol = unCollapsedDocs.filter((u) => u !== id);
      setUnCollapsedDocs(newCol);
    } else {
      setUnCollapsedDocs([...unCollapsedDocs, id]);
    }
  };

  const platform = useSelector((s: IState) => s.session.platforms?.selected);

  const docType = (id: string, documents?: IMedication[]): string | undefined | false => {
    return Array.isArray(documents) && documents.find((d) => d._id === id)?._type;
  };

  const [selected, setSelected] = React.useState<number>(0);

  const visitreason = useSelector((s: IState) => s.session.data?.visitreason);

  const userOrg = useSelector((s: IState) => s.session.data?.orgid);
  const selectedMedicationOrg = useSelector((s: IState) => s.session.selectedMedicationOrg) || userOrg;
  const orgGroupArray = useSelector((s: IState) => s.session.orgGroupArray);

  const getDisplayName = (orgId: string) => (orgGroupArray || []).find((og) => og.orgId === orgId)?.displayName;

  const dispatch = useDispatch();
  const setSelectedMedicationOrg = (org: string) => {
    org && dispatch(actions.setSelectedMedicationOrg(org));
  };

  const medicationDocs = (documents || []).filter((d) => d._type === 'medication');
  //const medicationOrgs = medicationDocs.map((d) => d._ownerOrg || ''); // ownerOrg cant actually be undefined
  const medicationOrgs = (useSelector((s: IState) => s.patient.grants) || []).filter(
    (grant) => grant.granteeOrg === userOrg,
  );

  const shownMedicationDocuments = medicationDocs.filter((d) => {
    return (
      d._ownerOrg === selectedMedicationOrg ||
      (d._pastOwners && Object.keys(d._pastOwners).some((org) => org === selectedMedicationOrg))
    );
  });

  const documentCreationButtonDisabled = assertCapabilities(
    [PlatformCapabilities.MEDICATION_CREATE],
    props.capabilityGroups ?? {},
  )
    ? false
    : assertCapabilities([PlatformCapabilities.MEDICATION_CREATE_RETROSPECTIVE], props.capabilityGroups ?? {}) &&
        visitreason === 'retrospectiveDataTransfer'
      ? false
      : true;

  return (
    <FormEditingHandler name="medication" documents={documents}>
      {(editing, startEdit, formData): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'medication'}
            headerId={
              docType(editing, documents) === 'otherTreatment'
                ? 'medication.otherTreatment.title'
                : docType(editing, documents) === 'periodWithoutMedication'
                  ? 'medication.periodWithoutMedication.title'
                  : 'medication.title'
            }
            editing={editing}
            editButtons={
              <DocumentCreationButton
                name="medication"
                text={'medication.newMedication'}
                onClick={startEdit({})}
                disabled={documentCreationButtonDisabled || userOrg !== selectedMedicationOrg}
                disabledTooltip={
                  documentCreationButtonDisabled ? <FormattedMessage id="medication.noCreateTooltip" /> : undefined
                }
              />
            }
            infoText={
              !editing && medicationOrgs && medicationOrgs.length > 0 ? (
                <div>
                  <FormattedMessage id="medication.showSharedMedications" />
                  {[userOrg || '', ...medicationOrgs.map((org) => org.grantorOrg)].map((org) => (
                    <Container
                      key={org}
                      alignItems={'center'}
                      style={{ marginTop: '0.5rem', cursor: 'pointer' }}
                      onClick={() => setSelectedMedicationOrg(org)}
                    >
                      <Item>
                        <Radio
                          checked={!!(selectedMedicationOrg && selectedMedicationOrg === org)}
                          sx={{
                            '& svg': {
                              width: '2rem',
                              height: '2rem',
                            },
                            padding: '0rem 1rem 0.2rem 1rem',
                          }}
                        />
                      </Item>
                      <Item>{getDisplayName(org).toUpperCase()}</Item>
                    </Container>
                  ))}
                </div>
              ) : undefined
            }
            secondaryButtons={
              platform === 'ms'
                ? [
                    {
                      headerId: 'medication.otherTreatment.title',
                      infoText: 'medication.otherTreatment.description',
                      button: (
                        <DocumentCreationButton
                          name="otherTreatment"
                          text={'medication.otherTreatment.new'}
                          onClick={startEdit({}, 'otherTreatment')}
                          height={4}
                          fontSize={16}
                        />
                      ),
                    },
                    {
                      headerId: 'medication.periodWithoutMedication.title',
                      infoText: 'medication.periodWithoutMedication.description',
                      button: (
                        <DocumentCreationButton
                          name="periodWithoutMedication"
                          text={'medication.periodWithoutMedication.new'}
                          onClick={startEdit({}, 'periodWithoutMedication')}
                          height={4}
                          fontSize={16}
                        />
                      ),
                    },
                  ]
                : undefined
            }
          />
          {!editing && documents && documents.length > 0 ? (
            <HistoryTabs indexSelectionTools={{ index: selected, changeFunction: (i: number): void => setSelected(i) }}>
              {[
                {
                  title: 'medication.activeMedications',
                  content: (
                    <ActiveMedication
                      documents={findActiveMedications(shownMedicationDocuments)}
                      adverseEvents={filter((d: IMedicationAdverseEvent) => d._type === 'adverseEffect', documents)}
                      startEdit={startEdit}
                      setMedID={setMedIDRef}
                      collapseStatus={{ collapseIDArray: unCollapsedDocs, setCollapseID }}
                    />
                  ),
                  count: findActiveMedications(shownMedicationDocuments).length,
                },
                {
                  title: 'medication.pausedMedications',
                  content: (
                    <PausedMedication
                      documents={findPausedMedications(shownMedicationDocuments)}
                      adverseEvents={filter((d: IMedicationAdverseEvent) => d._type === 'adverseEffect', documents)}
                      startEdit={startEdit}
                      setMedID={setMedIDRef}
                      collapseStatus={{ collapseIDArray: unCollapsedDocs, setCollapseID }}
                    />
                  ),
                  count: findPausedMedications(documents).length,
                },
                {
                  title: 'medication.endedMedications',
                  content: (
                    <EndedMedication
                      documents={findEndedMedications(shownMedicationDocuments)}
                      adverseEvents={filter((d: IMedicationAdverseEvent) => d._type === 'adverseEffect', documents)}
                      startEdit={startEdit}
                      setMedID={setMedIDRef}
                      collapseStatus={{ collapseIDArray: unCollapsedDocs, setCollapseID }}
                    />
                  ),
                  count: findEndedMedications(shownMedicationDocuments).length,
                  ended: true,
                },
                ...(platform === 'ms'
                  ? [
                      {
                        title: 'medication.otherTreatment.otherTreatments',
                        content: <OtherTreatments documents={findOtherTreatments(documents)} startEdit={startEdit} />,
                        count: findOtherTreatments(documents).length,
                      },
                      {
                        title: 'medication.periodWithoutMedication.periodWithoutMedications',
                        content: (
                          <PeriodWithoutMedication
                            documents={findPeriodsWithoutMedication(documents)}
                            startEdit={startEdit}
                          />
                        ),
                        count: findPeriodsWithoutMedication(documents).length,
                      },
                    ]
                  : []),
              ]}
            </HistoryTabs>
          ) : undefined}
          {editing ? (
            <Form editing={editing} formData={formData} consumeMedID={consumeMedIDRef} documents={documents} />
          ) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

export interface ICollapseStatus {
  collapseIDArray: string[];
  setCollapseID: (id: string) => void;
}

interface IOwnProps extends ICapabilityContextProps {
  documents?: Array<IMedication | IMedicationAdverseEvent>;
}

export default withCapabilities(Medication);
