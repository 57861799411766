import ActionButtonRounded from 'Components/ActionButtonRounded';
import DataTable from 'Components/DataTable';
import { Container, Item } from 'Components/Grid';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import { formatPartialDate, nowPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '..';
import NewTreatmentDecisionCell from './components/treatmentDecisionCell';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';
import { actions } from 'Store/myapp/actions';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { surveysByPlatform } from '../config';
import { Clear, Replay } from '@mui/icons-material';
import RecurringTasksList from './components/RecurringTasksList';
import { getNextRecurringDeadline } from 'Routes/MyService/util';

const ClearIconStyled = styled(Clear)`
  display: block;
  width: 3rem;
  color: ${colors.secondaryText};
  cursor: pointer;
  align-self: center;
  &:hover {
    color: red;
  }
`;

const TaskListHistory = ({
  treatmentDecisionDocs,
  taskLists,
}: {
  treatmentDecisionDocs: Array<ININMTTreatmentPeriod>;
  taskLists: Array<ITaskList>;
}): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, setEditingObj, platform, locale } = myServContext;

  const historyData: Array<ININMTTreatmentPeriod | ITaskList> = [
    ...(platform === 'ninmt' ? treatmentDecisionDocs : []),
    ...taskLists.filter((tl) => tl.tasks?.every((t) => platform && surveysByPlatform[platform]?.includes(t))),
  ];

  const taskOrgSettings = useSelector((s: IState) => s.settings?.orgSettings?.settings?.taskUiSettings);

  const dispatch = useDispatch();

  const [deleteId, setDeleteId] = React.useState<string | null>(null);

  const historyDataToTable = historyData
    .sort((a, b) =>
      sortPartialDate(
        'deadline' in b ? b.deadline : 'date' in b ? b.date : undefined,
        'deadline' in a ? a.deadline : 'date' in a ? a.date : undefined,
      ),
    )
    .map((data, i) => {
      if ('date' in data && data.date && platform === 'ninmt') {
        return <NewTreatmentDecisionCell fm={fm} date={data.date} key={i} />;
      } else if ('deadline' in data || 'recurringTaskDeadline' in data) {
        const tasks = data.tasks?.map((t) => fm(`myService.${platform}.opts.${t}`)).join(', ');
        const title =
          platform && data?.title && taskOrgSettings?.[platform]?.[data?.title]?.titleLocalized
            ? taskOrgSettings[platform]?.[data?.title]?.titleLocalized?.[locale] ??
              taskOrgSettings[platform]?.[data?.title]?.titleLocalized?.['fi']
            : data?.title === 'other'
              ? fm('myService.opts.other')
              : data?.title
                ? data.title
                : '';
        return [
          data.recurring && data.weeklySurveyInterval && data.recurringTaskDeadline
            ? formatPartialDate(getNextRecurringDeadline(data.recurringTaskDeadline, data.weeklySurveyInterval))
            : formatPartialDate(data.deadline),
          title,
          tasks ?? '-',
          data.recurring ? (
            <div style={{ display: 'flex' }}>
              <Replay
                fontSize="small"
                sx={{
                  color:
                    partialDateToValue(data?.deadline) > partialDateToValue(nowPartialDate())
                      ? colors.success
                      : undefined,
                }}
              />
            </div>
          ) : (
            ''
          ),
          formatPartialDate(data.sendDate),
          <div key={`${i}${data.id ?? ''}`} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ActionButtonRounded
              text="general.edit"
              filled={true}
              width={9.6}
              height={2.5}
              fontSize={16}
              uppercase={false}
              onClick={() => {
                setEditingObj({ type: 'taskList', data: data });
              }}
            />
            <ClearIconStyled onClick={() => setDeleteId(data.id)} />
          </div>,
        ];
      } else return 'filter';
    })
    .filter((d) => d !== 'filter');

  const patientMySQStatus = useSelector((s: IState) => s.myapp.mysqUserId);
  const sentInvites = useSelector((s: IState) => s.myapp.sentInvites);

  const recurringTasks = historyData.filter(
    (d) => 'recurring' in d && d.recurring && partialDateToValue(d?.deadline) > partialDateToValue(nowPartialDate()),
  ) as Array<ITaskList>;

  return (
    <>
      <Container style={{ justifyContent: 'space-between' }}>
        <Item>
          <HistoryRowSubHeader header={fm('myService.taskLists')} />
        </Item>
        <Item>
          <ActionButtonRounded
            text="general.new"
            filled={true}
            uppercase={false}
            width={9.6}
            height={3}
            fontSize={16}
            onClick={() => {
              setEditingObj({
                type: !patientMySQStatus && (!sentInvites || sentInvites.length === 0) ? 'invite' : 'taskList',
                data: null,
              });
            }}
          />
        </Item>
      </Container>
      {platform && recurringTasks && recurringTasks.length > 0 && (
        <>
          <div style={{ width: '48%' }}>
            <RecurringTasksList platform={platform} recurringTasks={recurringTasks} showEndButton />
          </div>
          <div>
            <HistoryRowSubHeader header={fm('myService.all')} fontSize="small" />
          </div>
        </>
      )}
      <DataTable
        headers={['deadline', 'taskListTitle', 'surveysAndDiaries', '', 'sendDate', 'hidden']}
        headersFormatter={(h) => (h === 'hidden' || h === '' ? '' : fm(`myService.${h}`))}
        data={{ rowData: historyDataToTable }}
      />
      <ConfirmationDialog
        open={!!deleteId}
        text={fm('general.reallyWantToDelete')}
        cancel={{ callback: () => setDeleteId(null) }}
        confirm={{
          callback: () => {
            const deleteList = taskLists.find((d) => d.id === deleteId);
            deleteList && actions.deleteTasklist(deleteList, dispatch);
            setDeleteId(null);
          },
        }}
      />
    </>
  );
};

export default TaskListHistory;
