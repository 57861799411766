import { includes, find, uniq, values, intersperse, path } from 'ramda';
import {
  regimenTypesByMed,
  regimenTypesByPlatform,
  medicationSubstancesByCategory,
  atcCodesByPlatform,
  regimenTypesBySubstanceAndDosageform,
} from '../Document/config';
import { a2String } from '../../../utility/string';
import { isPartialDate, nowPartialDate, nowTime, sortPartialDate, sortTime } from 'neuro-utils';
import { isParkinsonInfusionType } from '../Document/Form/Medication/RegimenDialog/utils';

/**
 * Map medications and their substances to categories
 * @param {IMedication[]} documents - Medication documents
 * @returns {ISubstanceCategories} Medication categories object with number of medications in each category
 */
export const medicationSubstanceCategories = (documents: IMedication[]): ISubstanceCategories => {
  const catNumbers = {} as { [key: string]: number };
  documents.forEach((d: IMedication) => {
    Object.keys(medicationSubstancesByCategory).forEach((c: string) => {
      if (medicationSubstancesByCategory[c].test(d.medicationSubstances ?? '')) {
        if (catNumbers[c]) {
          catNumbers[c]++;
        } else catNumbers[c] = 1;
      }
    });
  });
  return catNumbers;
};

interface ISubstanceCategories {
  levodopa?: number;
  maobInhibitors?: number;
  dopamineAgonists?: number;
}

/**
 * Get disease modifying medications by platform-specific ATC-codes
 * @param {IMedication[]} documents - All medication documents
 * @param {string} platform - Platform (eg. sma, parkinson, ...)
 * @returns {IMedication[]} Medication documents filtered by platform-specific ATC-codes to only include disease modifying medications
 */
export const getDiseaseModifyingMedications = (documents: IMedication[], platform?: string): IMedication[] => {
  if (platform && atcCodesByPlatform[platform] && Array.isArray(documents)) {
    return documents?.filter((document) =>
      atcCodesByPlatform[platform].find((code: string) => code === (document.atc ?? '')?.substring(0, code.length)),
    );
  }
  return documents;
};

/**
 * Regimen strengths into a string
 * @param {IStrengths} s - Strengths
 */
export const formatStrenghtString = (s: IStrengths): string => a2String(intersperse('/', values(s)));

/**
 * Sort given strengths
 * @param {IStrengths} s1 - First strengths
 * @param {IStrengths} s2 - Second strengths
 */
export const sortByStr = (s1?: IStrengths, s2?: IStrengths): number => {
  // By which key the strengths are sorted by (usually the first one)
  const sortedKey = s1 ? Object.keys(s1)[0] : s2 ? Object.keys(s2)[0] : '';

  const first =
    s1 && !isNaN(parseInt(s1[sortedKey]?.split(' ')[0], 10)) ? parseInt(s1[sortedKey].split(' ')[0], 10) : 0;
  const second =
    s2 && !isNaN(parseInt(s2[sortedKey]?.split(' ')[0], 10)) ? parseInt(s2[sortedKey].split(' ')[0], 10) : 0;

  return first > second ? 1 : second > first ? -1 : 0;
};

/**
 * Match medication name to its regimen type
 * @param {string} medication - Medication name
 * @param {string} dosageForm - Medication dosage form
 * @returns {string} Regimen type
 */
export const getRegimenType = (
  medication: string | undefined,
  substances?: string,
  dosageForm?: string,
): string | undefined => {
  if (substances && dosageForm) {
    const matchRegimen =
      regimenTypesBySubstanceAndDosageform.find((s) => s.substance.test(substances) && s.dosageForm.test(dosageForm))
        ?.regimen || null;
    if (matchRegimen) return matchRegimen;
  }
  const type = Object.keys(regimenTypesByMed).map((reg) => {
    if (includes(medication?.toUpperCase(), regimenTypesByMed[reg])) {
      return reg;
    } else return undefined;
  });
  return find((t) => !!t, type) || 'default';
};

/**
 * Get possible medication regimen types by medication, platform and dosage form
 * @param {string} medication - Medication name
 * @param {string} platform - Platform (eg. sma, parkinson, ...)
 * @param {string} dosageForm - Dosage form, i.e. a string possibly containing word "liuos"
 * @return {string[]} List of regimen types
 */
export const getRegimenOptions = (
  medication: string | undefined,
  platform?: string,
  isClinicalStudy?: boolean[],
): string[] => {
  if (path([0], isClinicalStudy) === true) {
    return regimenTypesByPlatform['other'];
  }

  const regimenType = getRegimenType(medication);
  let regimens = regimenType ? [regimenType] : [];

  // regimen types by platform
  if (platform && regimenTypesByPlatform[platform]) {
    regimens = [...regimens, ...regimenTypesByPlatform[platform]];
  } else {
    regimens = [...regimens, ...regimenTypesByPlatform['other']];
  }
  const regimTypes = uniq(regimens);

  return regimTypes;
};

/**
 * Check if medication has ended
 * @param {IMedication} document - Medication document to be checked
 * @return {boolean} True if hasEnded is true and endDate has passed or is empty, false otherwise
 */
export const medicationEnded = (document: IMedication): boolean => {
  if (document.hasEnded?.[0] === true) {
    if (document.endDate && sortPartialDate(document.endDate, nowPartialDate()) === 1) {
      return false;
    }
    return true;
  }
  return false;
};

export const numberOfMedicines = (documents: IMedication[]): number | undefined => {
  // Filter out medications that have ended
  const meds =
    documents.length > 0
      ? documents.filter((item: IMedication) => medicationEnded(item) !== true).map((item) => item.medicationName)
      : [];

  if (meds.length > 0) {
    // Filter out duplicates here
    return meds.filter((item, index) => meds.indexOf(item) === index).length;
  } else {
    return undefined;
  }
};

/**
 * Check whether document has an active pause.
 * @param doc document whose pauses to check
 * @returns true if document has an active pause, false if not
 */
export const pauseIsActive = (doc: IMedication): boolean => {
  // pause is not active if there are no pauses or if medication is ended
  if (!doc.pauses || doc.pauses.length < 1 || medicationEnded(doc)) return false;
  // pause has been started if start time is now or in the past, not in the future
  const startedPauses = doc.pauses.filter((p: IMedicationPause) => {
    const startInPast: boolean = sortPartialDate(p.startDate, nowPartialDate()) === -1;
    const startNowOrEarlierToday: boolean =
      sortPartialDate(p.startDate, nowPartialDate()) === 0 && sortTime(p.startTime, nowTime()) <= 0;
    return startInPast || startNowOrEarlierToday;
  });
  // pause is still active if it has no end, it ends in a later day or later today
  const stillActivePauses = startedPauses.filter((p: IMedicationPause) => {
    const noEnd = !p.endDate || (p.endDate && !isPartialDate(p.endDate));
    const endInFuture = sortPartialDate(nowPartialDate(), p.endDate) === -1;
    const endLaterToday = sortPartialDate(nowPartialDate(), p.endDate) === 0 && sortTime(p.endTime, nowTime()) > 0;
    return noEnd || endInFuture || endLaterToday;
  });
  return stillActivePauses.length >= 1;
};

/**
 * Find active medication documents
 * @param {Array<any>} d Array of documents
 * @returns {Array<IMedication>} Array of active medication documents
 */
export const findActiveMedications = (d: Array<any>): Array<IMedication> => {
  const activeMeds = d.filter((d) => d._type === 'medication' && !medicationEnded(d) && !pauseIsActive(d));
  return activeMeds;
};

/**
 * Find paused medication documents
 * @param {Array<any>} d Array of documents
 * @returns {Array<IMedication>} Array of paused medication documents
 */
export const findPausedMedications = (d: Array<any>): Array<IMedication> => {
  const pausedMeds = d.filter((d) => d._type === 'medication' && !medicationEnded(d) && pauseIsActive(d));
  return pausedMeds;
};

/**
 * Find ended medication documents
 * @param {Array<any>} d Array of documents
 * @returns {Array<IMedication>} Array of ended medication documents
 */
export const findEndedMedications = (d: Array<any>): Array<IMedication> =>
  d.filter((d) => d._type === 'medication' && medicationEnded(d));

/**
 * Find other treatments
 * @param {Array<any>} d Array of documents
 * @returns {Array<IMedicationOtherTreatment>} Array of other treatments
 */
export const findOtherTreatments = (d: Array<any>): Array<IMedicationOtherTreatment> =>
  d.filter((d) => d._type === 'otherTreatment');

/**
 * Find periods without medication
 * @param {Array<any>} d Array of documents
 * @returns {Array<IPeriodWithoutMedication>} Array of periods
 */
export const findPeriodsWithoutMedication = (d: Array<any>): Array<IPeriodWithoutMedication> =>
  d.filter((d) => d._type === 'periodWithoutMedication');

/**
 * See if locked regimen can be edited based on regimen type and document status
 * @param {IMedication} d - Medication document
 * @returns {boolean} Can or cannot edit
 */
export const canEditLockedRegimen = (d: IMedication, regimen: IRegimenBasics): boolean => {
  if (!d._lockedFor) return false;
  if (isParkinsonInfusionType(d) && regimen.regimenType === 'custom') return true;
  return false;
};
