import * as React from 'react';
import { styled } from '@mui/material';

import { Container, Item } from 'Components/Grid';
import colors from '../../../config/theme/colors';

const QuestionContainer = styled(Container)({
  '* > &:not(:last-child)': {
    marginBottom: '2rem',
  },
});

const QuestionAnswerPair = ({ question, answer, questionSize = 1 }: IQuestionAnswerPair) => {
  return (
    <QuestionContainer>
      <Item xs={2} md={questionSize} style={{ color: colors.tertiaryText }}>
        {question}
      </Item>
      <Item xs={true} style={{ fontWeight: 600 }}>
        {answer}
      </Item>
    </QuestionContainer>
  );
};

interface IQuestionAnswerPair {
  question: React.ReactNode;
  answer: React.ReactNode;
  questionSize?: number;
}

export default QuestionAnswerPair;
