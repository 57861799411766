/**
 * Creates a single header-value pair for history listing.
 * Vertical items are stacked vertically and a single item uses most of the space horizontally
 *
 * * Array typed values are prefixed with a Bullet
 */
import * as React from 'react';
import styled from 'styled-components';

import { Container, Item } from '../Grid';

import colors from '../../config/theme/colors';
import { appendIDs } from '../../utility/appendIDs';
import { historyHeader, historyValue, historyValuePadding } from '../../config/theme/componentTheme';

const StyledWrapper = styled(({ ...other }) => <Container {...other} />)`
  * > &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

const StyledList = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;
const Bullet = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1rem;
  background-color: ${colors.primary};
  margin-right: 2rem;
`;
const DescriptionText = styled.div`
  font-size: 14px;
  padding: 1rem 10rem 0 0;
  color: ${colors.darkGray};
`;

const displayValue = (value: IOwnProps['value'], noBullet: boolean): JSX.Element => {
  if (Array.isArray(value) && value.length > 1) {
    return (
      <StyledList>
        {appendIDs(value).map((val: { value: string | number | JSX.Element; id: string }) => (
          <React.Fragment key={val.id}>
            <Container alignItems="center">
              {!noBullet && (
                <Item>
                  <Bullet />
                </Item>
              )}
              <Item xs={true}>
                <div style={historyValue}>{val.value}</div>
              </Item>
            </Container>
          </React.Fragment>
        ))}
      </StyledList>
    );
  }
  return <div style={historyValue}>{Array.isArray(value) ? value[0] : value}</div>;
};

const HistoryRowVerticalItem = ({
  header,
  description,
  condition = true,
  value,
  headerWidth = 5, // Same as FormRow
  noBullet = false,
  noDash = false,
}: IOwnProps): JSX.Element | null =>
  condition ? (
    <StyledWrapper>
      <Container>
        <Item xs={headerWidth}>
          <div style={{ ...historyHeader, padding: historyValuePadding }}>{header}</div>
          {description && <DescriptionText>{description}</DescriptionText>}
        </Item>
        <Item xs={true} style={{ whiteSpace: 'pre-line' }}>
          {value || value === 0 ? displayValue(value, noBullet) : noDash ? undefined : '-'}
        </Item>
      </Container>
    </StyledWrapper>
  ) : null;

interface IOwnProps {
  header: string | number | JSX.Element;
  description?: string | JSX.Element;
  condition?: boolean;
  value?: string | number | JSX.Element | Array<string | number | JSX.Element> | null | false;
  headerWidth?: number | boolean;
  noBullet?: boolean;
  noDash?: boolean;
}

export default HistoryRowVerticalItem;
