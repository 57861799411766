import { find, omit } from 'ramda';
import * as React from 'react';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import { FormContextProvider, HistoryContextProvider } from '../../../containers/FormContextHandler';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import {
  getHeaderId,
  mapPatientDoesNotWantRespiratorySupportTherapyEndDates,
  patientHasRespiratoryFailureDiagnosis,
  patientHasSleepApneaDiagnosis,
  TDocument,
} from '../utils';
import Form from './Form';

import PatientDoesNotWantRespiratorySupportTherapyHistory from './HistoryRowData/PatientDoesNotWantRespiratorySupportTherapy';
import OxygenTherapyHistory from './HistoryRowData/OxygenTherapy';
import HFNCTherapyHistory from './HistoryRowData/HFNCTherapy';
import PAPTherapyHistory from './HistoryRowData/PAPTherapy';
import MADTherapyHistory from './HistoryRowData/MADTherapy';
import SurgicalTreatmentHistory from './HistoryRowData/SurgicalTreatment';
import OtherTreatmentHistory from './HistoryRowData/OtherTreatment';
import { actions, TCreationPromiseType } from '../../../store/documents/actions';
import { controlProps } from '../../../utility/documentHandling';
import { useAppDispatch, useAppSelector } from 'Store/index';

const TreatmentHistory = ({ documents }: { documents: Array<IDiagnosis> }): JSX.Element => {
  return (
    <React.Fragment>
      <PatientDoesNotWantRespiratorySupportTherapyHistory />
      {(patientHasRespiratoryFailureDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <OxygenTherapyHistory />
      )}
      {(patientHasRespiratoryFailureDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <HFNCTherapyHistory />
      )}
      <PAPTherapyHistory />
      {(patientHasSleepApneaDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <MADTherapyHistory />
      )}
      {(patientHasSleepApneaDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <SurgicalTreatmentHistory />
      )}
      <OtherTreatmentHistory />
    </React.Fragment>
  );
};

const Treatment = ({ documents }: IOwnProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const createCommit = async (id: string): Promise<TCreationPromiseType> =>
    actions.createCommit({ id, name: 'patientDoesNotWantRespiratorySupportTherapy' })(dispatch);

  const closeCommitWithData = (id: string, data: Record<string, unknown>) =>
    actions.closeCommit({ id, name: 'patientDoesNotWantRespiratorySupportTherapy' }, data)(dispatch);

  const isEditing = documents.some((d) => d._editing === true);

  React.useEffect(() => {
    const docsWithUpdatedEndDates = mapPatientDoesNotWantRespiratorySupportTherapyEndDates(documents);
    // PDNWRST endDates are only updated if they don't exist, not if there isn't a matching treatment start/decision date
    if (!isEditing) {
      docsWithUpdatedEndDates?.forEach((d) => {
        createCommit(d._id)
          .then(() => {
            closeCommitWithData(d._id, omit(controlProps, d));
          })
          .catch(() => {
            return;
          });
      });
    }
  }, [isEditing]);

  const visitReasonRetro = useAppSelector((s: IState) => s.session.data?.visitreason === 'retrospectiveDataTransfer');

  return (
    <FormEditingHandler name="treatment" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="treatment"
            headerId={getHeaderId(documents, editing, view?.viewing)}
            editing={editing}
            editButtons={<div />}
          />
          {editing ? (
            <FormContextProvider context={{ formData, documents, editing }}>
              <Form document={documents && (find((d: TDocument) => d._id === editing, documents) as TDocument)} />
            </FormContextProvider>
          ) : null}
          {view?.viewing ? (
            <FormContextProvider context={{ formData, documents, view }}>
              <Form document={documents && (find((d: TDocument) => d._id === view?.viewing, documents) as TDocument)} />
            </FormContextProvider>
          ) : null}
          {!editing && !view?.viewing ? (
            <HistoryContextProvider
              context={{ documents: documents, startEdit: startEdit, view: view, additionalData: { visitReasonRetro } }}
            >
              <TreatmentHistory documents={documents.filter((d) => d._type === 'diagnosis') as Array<IDiagnosis>} />
            </HistoryContextProvider>
          ) : null}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: (
    | TDocument
    | IDiagnosis
    | ISleepApneaFirstVisit
    | IRespiratoryFirstVisit
    | ISleepPolygraphy
    | ISleepStudy
  )[];
}

export default Treatment;
