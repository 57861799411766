import { styled } from '@mui/system';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Theme } from '@mui/material';
import { formatPartialDate, formatTime, sortPartialDate } from 'neuro-utils';
import { equals } from 'ramda';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Container, Item } from 'Components/Grid';
import ToolTip from 'Components/ToolTip';
import {
  calculateBMI,
  fieldNameToCodeString,
  isLatestHeightRecent,
  TCombinedMeasurementData,
} from 'Routes/Background/utils';
import colors from '../../../../config/theme/colors';

const TableCell = styled('td')(() => ({
  width: '16%',
  padding: '0.2rem 0.1rem',
}));
const TableCellContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noHover' && prop !== 'noValueHover' && prop !== 'valueIndicator',
})(
  ({
    noHover,
    noValueHover,
    valueIndicator,
    theme,
  }: {
    noHover?: boolean;
    noValueHover?: boolean;
    valueIndicator?: boolean;
    theme?: Theme;
  }) => ({
    width: '100%',
    padding: '0.6rem',
    boxSizing: 'border-box' as const,
    border: `0.2rem solid #ffffff00`, // Use invisible border to take up space that the hover border would take
    backgroundColor: valueIndicator ? colors.info.lightest : undefined,
    borderRadius: '0.3rem',
    overflow: 'hidden',
    ':hover': noHover
      ? { cursor: 'default' }
      : noValueHover
        ? {
            //border: `0.2rem solid #00000000`,
            backgroundColor: colors.info.lightest,
            cursor: 'pointer',
            'span#noHover': {
              display: 'none',
            },
            'span#hover': {
              display: 'inline',
            },
          }
        : {
            border: `0.2rem solid ${theme?.palette.info.light}`,
            backgroundColor: colors.info.lightest,
            cursor: 'pointer',
          },

    'span#noHover': {
      display: 'inline',
    },
    'span#hover': {
      display: 'none',
    },
  }),
);

const StyledArrow = styled(ArrowDropUpIcon)(({ theme }) => ({
  color: theme.palette.info.main + 'aa',
  fontSize: '3.5rem',
  padding: 0,
  margin: 0,
  transform: 'rotate(45deg)',
  position: 'relative' as const,
  top: -21,
  right: -21,
}));

const getSpecificDayDataByType = (date: PartialDate, type: string, data: TCombinedMeasurementData): IMeasurement[] => {
  const dayData = data.find((d) => equals(d.date, date));
  const typeData = dayData?.data.find((data) => data.type === type);
  return typeData?.documents || [];
};

const ValueCell = ({
  index,
  inScopeDates,
  fieldData,
  data,
  setDialogDocs,
}: {
  index: number;
  inScopeDates: PartialDate[];
  fieldData: { name: string; unit: string | null } | null;
  data: TCombinedMeasurementData;
  setDialogDocs: (docs: IMeasurement[] | Partial<IMeasurement>) => void;
}) => {
  const { formatMessage } = useIntl();
  const fm = (id?: string) => (id ? formatMessage({ id }) : '');

  if (fieldData === null) return null;
  const thisDate = inScopeDates[index];

  const allDocsForThisCell = getSpecificDayDataByType(thisDate, fieldData.name, data);
  const allLocked = allDocsForThisCell.length > 0 && allDocsForThisCell.every((doc) => doc._lockedFor);

  const value = getSpecificDayDataByType(thisDate, fieldData.name, data)?.[0]?.value ?? null;
  const fieldCode = fieldNameToCodeString(fieldData.name);
  const noValue = !(value || value === '0' || value === '');
  const disableEditing = allLocked;
  const hasUserEnteredValue = !noValue && !allLocked;

  const onClick = !disableEditing
    ? () => setDialogDocs(!value && fieldCode ? { date: thisDate, code: fieldCode } : allDocsForThisCell)
    : undefined;

  const useCalculatedBMI = fieldData.name === 'bmi' && noValue;

  const calculatedBMI = () => {
    const dataArrays = data.map((data) => data.data);
    // Find valid weight for this date
    const findWeight = dataArrays
      ?.find((a) =>
        a.find(
          (types) =>
            types.type === 'weight' &&
            types.documents.some((weight) => equals(weight.date, thisDate)) &&
            types.documents.some((weight) => weight.value),
        ),
      )
      ?.find((w) => w.type === 'weight');
    // Find a height that has been recorded on this date or before
    const findHeight = dataArrays
      ?.find((a) =>
        a.find(
          (types) =>
            types.type === 'height' &&
            types.documents.some((height) => sortPartialDate(thisDate, height.date) >= 0) &&
            types.documents.some((height) => height.value),
        ),
      )
      ?.find((w) => w.type === 'height');

    const weight = findWeight?.documents.find((d) => d.value);
    const height = findHeight?.documents.find((d) => d.value);

    if (!weight?.value || !height?.value || !isLatestHeightRecent(height, thisDate)) return null;
    return calculateBMI(parseFloat(height.value), parseFloat(weight.value));
  };

  const descriptionText =
    fieldData.name === 'gaf' ? (
      <div style={{ fontSize: '1.4rem' }}>
        <FormattedMessage
          id="background.gafDescription"
          values={{
            br: <br />,
            str: (msg) => <strong>{msg}</strong>,
          }}
        />
      </div>
    ) : fieldData.name === 'madrs' ? (
      <FormattedMessage
        id="background.madrsDescription"
        values={{
          br: <br />,
          str: (msg) => <strong>{msg}</strong>,
        }}
      />
    ) : null;

  return (
    <TableCell onClick={onClick}>
      <ToolTip
        title={fm('background.' + fieldData.name) + ' - ' + formatPartialDate(thisDate)}
        description={
          useCalculatedBMI ? (
            fm('background.bmiCalculated')
          ) : (
            <>
              {allDocsForThisCell.map((doc) => (
                <Container key={doc._id}>
                  <Item xs={true}>{formatTime(doc.time)}</Item>
                  <Item xs={true}>{doc.value + ` ${fieldData.unit ? fieldData.unit : ''}`}</Item>
                </Container>
              ))}

              {descriptionText && <div style={{ marginTop: '1rem' }}>{descriptionText}</div>}

              {allLocked && (
                <div style={{ fontWeight: 600, marginTop: '1rem' }}>{fm('background.allDataFromIntegration')}</div>
              )}
            </>
          )
        }
        hover
        placement={'top'}
        disabled={(useCalculatedBMI && !calculatedBMI()) || (!useCalculatedBMI && noValue)}
        cursor={!thisDate && !value ? 'Default' : 'Pointer'}
        content={
          <TableCellContent
            noHover={!thisDate && !value}
            noValueHover={!!(thisDate && !value)}
            valueIndicator={hasUserEnteredValue}
          >
            <Container>
              <Item xs={true}>
                {useCalculatedBMI
                  ? calculatedBMI() ??
                    (thisDate && (
                      <>
                        <span id="noHover">-</span>
                        <span id="hover">{fm('background.addValue')}</span>
                      </>
                    ))
                  : value
                    ? value
                    : thisDate && (
                        <>
                          <span id="noHover">-</span>
                          <span id="hover">{fm('background.addValue')}</span>
                        </>
                      )}
              </Item>
              {!useCalculatedBMI && !noValue ? (
                <Item
                  style={{
                    height: 0, // Arrow size should not affect cell size
                  }}
                >
                  <StyledArrow />
                </Item>
              ) : null}
            </Container>
          </TableCellContent>
        }
        leaveTouchDelay={300}
        enterTouchDelay={300}
        disableInteractive={fieldData.name === 'gaf' ? false : true}
        minWidth={'20rem'} // 20rem for values to fit nicely
        width={fieldData.name === 'gaf' ? '60rem' : undefined} // 60rem for gafs large description
        height={fieldData.name === 'gaf' ? '35rem' : undefined}
        overflow={fieldData.name === 'gaf' ? { y: 'scroll' } : undefined}
      />
    </TableCell>
  );
};

export default ValueCell;
