import * as React from 'react';
import colors from '../../config/theme/colors';
import { styled } from '@mui/material';

const StyledTH = styled('th')({
  color: colors.tertiaryText,
  textAlign: 'left',
  fontWeight: 400,
  borderBottom: '1px solid #6a6a6a',
  paddingLeft: '0.5rem',
});
const StyledTD = styled('td')({
  paddingLeft: '0.5rem',
});

const DataTable = ({ data, headers, headersFormatter }: IOwnProps): JSX.Element => {
  return (
    <table style={{ width: '100%', borderSpacing: '0px' }}>
      <thead>
        <tr>
          {headers.map((h, i) => (
            <StyledTH key={`${h}-${i}`} style={{}}>
              {typeof h !== 'string' || !headersFormatter ? h : headersFormatter(h)}
            </StyledTH>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.rowData.map((row, ind) => (
            <tr
              key={ind}
              style={{
                backgroundColor:
                  data?.rowStyle?.[ind] && data?.rowStyle?.[ind]?.rowColor
                    ? data.rowStyle[ind].rowColor
                    : ind % 2 === 0 && Array.isArray(row)
                      ? colors.lightestGray
                      : colors.white,
                height: '3.3rem',
                verticalAlign: data?.rowStyle?.[ind]?.rowAlign,
              }}
            >
              {Array.isArray(row) ? (
                row.map((cell, idx) => (
                  <StyledTD key={`${cell}-${idx}`} style={{ color: colors.tertiaryText }}>
                    {Array.isArray(cell) ? cell.join(', ') : cell}
                  </StyledTD>
                ))
              ) : (
                <StyledTD colSpan={headers.length}>{row}</StyledTD>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

interface IOwnProps {
  data?: {
    rowData: Array<number | string | Array<string | number | string[] | number[] | JSX.Element> | JSX.Element>;
    rowStyle?: Array<{ rowColor?: string; rowAlign?: string }>;
  };
  headers: Array<string | JSX.Element>;
  headersFormatter?: (header: string) => string | JSX.Element | React.ReactNode;
}

export default DataTable;
