import * as React from 'react';
import { MyServiceContext } from '../../..';
import colors from '../../../../../../config/theme/colors';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import QuestionAnswerPair from 'Components/_NewElements/QuestionAnswerPair';
import { surveyCreator, surveyModifyer } from 'Routes/MyService/Document/HistoryRowData/SurveysHistory';
import { ICompactVaultUser } from 'neuro-data-structures';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import { formatPartialDate, isPartialDate, nowPartialDate, sortPartialDate } from 'neuro-utils';
import TableContent from '../components/TableContent';
import { deviceFields, sideEffectsFields, treatmentsFields } from './config';
import { isLocaleKey, tdcsReport } from 'neuro-schemas';
import Unit from 'Components/Unit';
import { isEmpty } from 'ramda';

const TDCSReport = ({ documents, patientMyid, users }: IOwnProps): JSX.Element => {
  const tdcsReportLocales = tdcsReport.locales;
  const myServiceContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing, setViewingObj, setEditingObj } = myServiceContext;

  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const fmTdcsReport = (id: string | number) => {
    return tdcsReportLocales[useLocale][id];
  };

  const tdcsReportData = ((editing?.data || viewing?.data) ?? {}) as ITDCSReport & IControlProps;

  const surveyData = {
    ...tdcsReportData,
    treatments: tdcsReportData.treatments ?? [],
  };

  /**
   * @param {'treatments'} key - Set key to 'treatments' to change treatments -- expand type for more array types if needed
   * @param {number} index - Specify which treatment (which index) to change
   * @returns {void}
   */
  const handleFieldChange =
    (key?: 'treatments', index?: number) =>
    (e: TOnChangeValues): void => {
      const name = Object.keys(e)[0];
      const value = e[name];
      if (
        key &&
        (index || index === 0) &&
        Array.isArray(surveyData[key as keyof typeof surveyData]) &&
        (surveyData[key as keyof typeof surveyData] as [])[index]
      ) {
        const newValues = JSON.parse(JSON.stringify(surveyData[key as keyof typeof surveyData]));
        newValues[index] = { ...newValues[index], [name]: value };
        setEditingData?.({
          ...surveyData,
          [key as keyof typeof surveyData]: newValues,
        });
      } else setEditingData?.({ ...surveyData, [name]: value });
    };

  const getFieldValue = (data: TAnyObject, name: string) => {
    const value = data[name];
    return value;
  };

  const doctorsOrdersWithTdcs: IDoctorsOrder[] = documents.filter((doc) => {
    if (doc._type === 'doctorsOrders') {
      const d = doc as IDoctorsOrder;
      return (
        d._type === 'doctorsOrders' &&
        Array.isArray(d.treatmentType) &&
        d.treatmentType.includes('tdcs') &&
        d.tdcs &&
        Array.isArray(d.tdcs.subjectOfTreatment)
      );
    }
    return false;
  });

  const currentTreatments = doctorsOrdersWithTdcs
    .sort((a, b) => (b._cdate ?? 0) - (a._cdate ?? 0))
    .sort((a, b) => sortPartialDate(b?.date, a?.date))[0]
    ?.tdcs?.subjectOfTreatment?.map((s: { [key: string]: any }) => ({
      id: s.id,
      name: s.name,
      nameOther: s.nameOther,
      specifier: s.specifier,
      explanationOther: s.explanationOther,
    }));

  // Set date if editing and no date found
  React.useEffect(() => {
    if (!tdcsReportData.date && isEditing) {
      setEditingData?.({ ...tdcsReportData, date: nowPartialDate() });
    }
  }, []);

  // Initialize document fields
  React.useEffect(() => {
    if (
      !viewing &&
      surveyData.date &&
      [
        'treatments',
        'deviceBatteryCharge',
        'deviceUses',
        'sideEffectsDuringTreatments',
        'sideEffectsDuringTreatmentsDetails',
      ].every((name) => tdcsReportData[name as keyof typeof tdcsReportData] === undefined)
    ) {
      const initData: { [key: string]: any } = {};

      const treatments: Array<{ [key: string]: string | number | null }> = [];
      (currentTreatments ?? []).forEach((t: (typeof treatments)[0]) => {
        const treatment: (typeof treatments)[0] = {};
        if (t.id) treatment.doctorsOrderId = t.id;
        if (t.name) treatment.name = t.name;
        if (t.specifier) treatment.specifier = t.specifier;
        if (t.nameOther) treatment.nameOther = t.nameOther;
        if (t.explanationOther) treatment.explanationOther = t.explanationOther;
        treatment.amountOfTreatmentSessions = null;
        treatment.isSkinIntactUnderElectrodes = null;
        treatments.push(treatment);
      });

      if (!isEmpty(treatments)) initData.treatments = treatments;
      initData.deviceBatteryCharge = null;
      initData.deviceUses = null;
      initData.sideEffectsDuringTreatments = null;
      initData.sideEffectsDuringTreatmentsDetails = null;

      setEditingData?.({ ...surveyData, ...initData });
    }
  }, [surveyData.date]);

  const isEditing = !viewing && !!editing;

  // The table can fit 5 treatments (there should be max 4)
  const tableWidth = {
    1: 53.8,
    2: 53.8,
    3: 69.1,
    4: 84.5,
    5: 100,
  }[Array.isArray(surveyData.treatments) ? surveyData.treatments.length : 1];

  return (
    <React.Fragment>
      {viewing && (
        <div style={{ marginBottom: '4rem' }}>
          <QuestionAnswerPair
            question={fm('myService.reporter')}
            answer={surveyCreator(surveyData, patientMyid, users, fm)}
          />
          {surveyModifyer(surveyData, patientMyid, users, fm) && (
            <QuestionAnswerPair
              question={fm('myService.edited')}
              answer={surveyModifyer(surveyData, patientMyid, users, fm)}
            />
          )}
        </div>
      )}
      <BlockWrapper
        title={viewing ? formatPartialDate(surveyData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'tdcsReport', data: tdcsReportData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow
            title="general.date"
            // Hack
            headerWidth={!editing ? 1 : (surveyData.treatments?.length ?? 0) > 0 ? 2.77 : 2.59}
          >
            <InputHandler
              name="date"
              type="PartialDate"
              editing={!!editing}
              isNotCancellable={true}
              formData={{
                document: { date: surveyData?.date },
                onChange: (e: TOnChangeValues) => {
                  const value = e.date;
                  if (value && isPartialDate(value)) {
                    setEditingData?.({ ...surveyData, date: value });
                  }
                },
              }}
              dateDefault="now"
            />
          </FormRow>
        )}
        {/**
         * Treatments
         */}
        {Array.isArray(surveyData.treatments) && surveyData.treatments.length > 0 && (
          <React.Fragment>
            <TableContent
              width={tableWidth}
              titleRow={{
                title: { content: fm('myService.ninmt.treatments') },
                content: surveyData.treatments.map((t, _, arr) => ({
                  content: `${
                    t.name === 'other' && t.nameOther
                      ? t.nameOther
                      : t.name
                        ? fmTdcsReport(`subjectOfTreatmentNames.${t.name}`)
                        : '-'
                  }${t.specifier ? ` ${t.specifier}` : ''}`,
                  xs: arr.length === 1 ? 4 : undefined,
                })),
              }}
            >
              {
                treatmentsFields.map((field) => ({
                  title: {
                    content: fmTdcsReport(field.name),
                  },
                  content: surveyData.treatments?.map((t, i, arr) => ({
                    content: (
                      <React.Fragment>
                        <InputHandler
                          type={field.type as any}
                          name={field.name}
                          editing={isEditing}
                          formData={{
                            onChange: (e) => handleFieldChange('treatments', i)(e),
                            document: { [field.name]: getFieldValue((t as TAnyObject) ?? {}, field.name) },
                          }}
                          options={field.options}
                          optionFormatter={
                            field.type === 'Radio' ? (id: string | number) => fmTdcsReport(`opts.${id}`) : undefined
                          }
                          placeholder={field.placeholder}
                          min={field.name === 'amountOfTreatmentSessions' ? 0 : undefined}
                          max={field.name === 'amountOfTreatmentSessions' ? 14 : undefined}
                          width={field.type === 'NumberField' ? 15 : undefined}
                          compact={field.type === 'Radio' || undefined}
                        />
                      </React.Fragment>
                    ),
                    xs: arr.length === 1 ? 4 : undefined,
                    style: {
                      overflow: 'visible',
                    },
                  })),
                })) as []
              }
            </TableContent>
          </React.Fragment>
        )}
        {/**
         * Device
         */}
        <div style={{ fontSize: '1.8.rem', fontWeight: 600, color: colors.darkGray }}>
          {fm('myService.ninmt.device')}
        </div>
        <TableContent width={tableWidth}>
          {
            deviceFields.map((field) => ({
              title: {
                content: fmTdcsReport(field.name),
              },
              content: {
                content: (
                  <React.Fragment>
                    <Unit unit={field.name === 'deviceBatteryCharge' ? '%' : undefined}>
                      <InputHandler
                        type={field.type as any}
                        name={field.name}
                        editing={isEditing}
                        formData={{
                          onChange: (e) => handleFieldChange()(e),
                          document: { [field.name]: getFieldValue(surveyData, field.name) },
                        }}
                        placeholder={field.placeholder}
                        min={0}
                        max={field.name === 'deviceBatteryCharge' ? 100 : undefined}
                        width={15}
                      />
                    </Unit>
                  </React.Fragment>
                ),
                xs: surveyData.treatments?.length < 2 ? 4 : (surveyData.treatments?.length ?? 1) * 2,
                style: {
                  overflow: 'visible',
                },
              },
            })) as []
          }
        </TableContent>
        {/**
         * Side effects
         */}
        <div style={{ fontSize: '1.8.rem', fontWeight: 600, color: colors.darkGray }}>
          {fm('myService.ninmt.sideEffects')}
        </div>
        <TableContent width={tableWidth}>
          {
            sideEffectsFields
              .slice(0, surveyData.sideEffectsDuringTreatments !== 'yes' ? 1 : undefined)
              .map((field) => ({
                title: {
                  content: fmTdcsReport(field.name),
                },
                content: [
                  {
                    content: (
                      <React.Fragment>
                        <InputHandler
                          type={field.type as any}
                          name={field.name}
                          editing={isEditing}
                          formData={{
                            onChange: (e) => handleFieldChange()(e),
                            document: { [field.name]: getFieldValue(surveyData, field.name) },
                          }}
                          options={field.options}
                          optionFormatter={
                            field.type === 'Radio' ? (id: string | number) => fmTdcsReport(`opts.${id}`) : undefined
                          }
                          compact={field.type === 'Radio' || undefined}
                          placeholder={field.placeholder}
                        />
                      </React.Fragment>
                    ),
                    xs:
                      (surveyData.treatments?.length ?? 1) < 2
                        ? 4
                        : field.name === 'sideEffectsDuringTreatmentsDetails' &&
                            (surveyData.treatments?.length ?? 0) > 2
                          ? 4
                          : (surveyData.treatments?.length ?? 1) * 2,
                    style: {
                      overflow: 'visible',
                    },
                  },
                ].concat(
                  field.name === 'sideEffectsDuringTreatmentsDetails' && (surveyData.treatments?.length ?? 0) > 2
                    ? // Filler column
                      [
                        {
                          content: <></>,
                          xs: surveyData.treatments?.length === 3 ? 2 : 4,
                          style: { overflow: 'visible' },
                        },
                      ]
                    : [],
                ),
              })) as []
          }
        </TableContent>
      </BlockWrapper>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<
    (IDoctorsOrder | IRTMS | ITDCS | ITreatmentMonitoringInquiry | ISymptomsAndLocations) & IControlProps
  >;
  patientMyid: string;
  users: ICompactVaultUser[];
}

export default TDCSReport;
