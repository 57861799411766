/**
 * Create a blue subheader for history rows
 */
import * as React from 'react';

import colors from '../../config/theme/colors';
import { styled } from '@mui/material';

interface StyledProps {
  fontSize: string;
}

const StyledSubHeader = styled('div')`
  font-size: ${(props: StyledProps): string => (props.fontSize === 'small' ? '1.6' : '2.2')}rem;
  color: ${colors.primary};
  font-weight: 600;
  margin: 3rem 0 1rem 0;

  * > &:first-of-type {
    margin: 0 0 1rem 0;
  }
`;

const HistoryRowSubHeader = ({ header, fontSize = 'large' }: IOwnProps): JSX.Element => (
  <StyledSubHeader fontSize={fontSize}>{header}</StyledSubHeader>
);

interface IOwnProps {
  header: JSX.Element | string | number;
  fontSize?: 'small' | 'large';
}

export default HistoryRowSubHeader;
