import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import HistorySection from '../../../../components/HistorySection';
import HistoryRowSubHeader from '../../../../components/HistoryRowSubHeader';
import { Container, Item } from '../../../../components/Grid';

import { abilitiesToArray, formatTheDates, IMotorFunctionEvent } from '../../utils';
import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const StyledItem = styled.div`
  margin-bottom: 1rem;
`;

const eventsItem = (event: IMotorFunctionEvent): JSX.Element => (
  <Item xs={4}>
    <StyledItem>
      <HistoryRowBasicItem
        header={<FormattedMessage id={'motorFunctionAndMobilityAid.motorfunction.' + event.name} />}
        value={formatTheDates(event.start, event.end)}
      />
    </StyledItem>
  </Item>
);

const MotorFunctionHistory = ({ documents, startEdit }: IOwnProps): JSX.Element => {
  const [gainedAbilities, setGainedAbilities] = React.useState<Array<IMotorFunctionEvent>>([]);
  const [lostAbilities, setLostAbilities] = React.useState<Array<IMotorFunctionEvent>>([]);

  React.useEffect(() => {
    const arrayAbilities = abilitiesToArray(documents);

    setGainedAbilities(arrayAbilities.gained);
    setLostAbilities(arrayAbilities.lost);
  }, [documents]);
  return (
    <HistorySection
      headerText={<FormattedMessage id={'motorFunctionAndMobilityAid.motorfunction.title'} />}
      newButton={
        <DocumentCreationButton
          name="motorfunction"
          document={documents?.[0]}
          text={'general.edit'}
          onClick={startEdit(documents?.[0] ?? {}, 'motorfunction')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      {documents && (
        <React.Fragment>
          {gainedAbilities.length > 0 && (
            <>
              <HistoryRowSubHeader
                header={<FormattedMessage id="motorFunctionAndMobilityAid.motorfunction.gainedAbilities" />}
              />
              <Container>
                {gainedAbilities.map((event) => (
                  <React.Fragment key={event.name}>{eventsItem(event)}</React.Fragment>
                ))}
              </Container>
            </>
          )}
          {lostAbilities.length > 0 && (
            <>
              <HistoryRowSubHeader
                header={<FormattedMessage id="motorFunctionAndMobilityAid.motorfunction.lostAbilities" />}
              />
              <Container>
                {lostAbilities.map((event) => (
                  <React.Fragment key={event.name}>{eventsItem(event)}</React.Fragment>
                ))}
              </Container>
            </>
          )}
        </React.Fragment>
      )}
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IMotorFunction[];
  startEdit: (document: IMotorFunction, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default MotorFunctionHistory;
