import { formatPartialDate, isPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { mergeDocuments } from '../utils/index';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { IData } from 'Components/DashboardTile/TileContentMaker/components/definitions';
import { Container, Item } from 'Components/Grid';

const ValueArea = ({ children }: { children: JSX.Element }) => (
  <Container justifyContent={'flex-end'}>
    <Item style={{ width: '18rem' }}>{children}</Item>
  </Container>
);

const fm = (id: string, values?: { [key: string]: string }): JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const Content = ({ array }: IContentProps): JSX.Element => {
  const exercise = array.find((obj) => obj.name === 'physicalExercise');
  const meals = array.find((obj) => obj.name === 'mealsPerDay');
  const smoking = array.find((obj) => obj.name === 'smoking');

  const lifeStyleData: IData[] = [];

  exercise &&
    lifeStyleData.push({
      date: isPartialDate(exercise.date) ? formatPartialDate(exercise.date) : '',
      title: fm('lifestyle.physicalExercise.shortTitle'),
      value: exercise.value ? (
        <ValueArea>
          <FormattedMessage id={`lifestyle.physicalExercise.${exercise.value}`} />
        </ValueArea>
      ) : null,
      key: 'exercise',
    });

  meals &&
    lifeStyleData.push({
      date: isPartialDate(meals.date) ? formatPartialDate(meals.date) : '',
      title: fm('lifestyle.mealsPerDay.shortTitle'),
      value: meals.value ? (
        <ValueArea>
          <FormattedMessage id={`lifestyle.mealsPerDay.${meals.value}`} />
        </ValueArea>
      ) : null,
      key: 'meals',
    });

  smoking &&
    lifeStyleData.push({
      date: isPartialDate(smoking.date) ? formatPartialDate(smoking.date) : '',
      title: fm('lifestyle.smoking.shortTitle'),
      value: smoking.value ? (
        <ValueArea>
          <FormattedMessage id={`lifestyle.smoking.${smoking.value}`} />
        </ValueArea>
      ) : null,
      key: 'smoking',
    });

  return <TileContentMaker type="specificListMultipleRows" itemsCanFitAmount={3} data={lifeStyleData} />;
};

interface IContentProps {
  array: Array<{ name: string; value: any; date: PartialDate | undefined }>;
}

const LifestyleDash = ({ documents }: IOwnProps): JSX.Element | undefined => {
  const document = documents ? mergeDocuments('dash', documents) : [];
  return <Content array={document} />;
};

interface IOwnProps {
  documents: ILifestyle[];
}

export default LifestyleDash;
