import * as React from 'react';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { useAppSelector as useSelector } from 'Store/index';
import { localizeCodenames, smaLabResultTypes } from '../utils';
import { useIntl } from 'react-intl';

const ExaminationDash = ({ documents }: IOwnProps): JSX.Element => {
  const platform = useSelector((s: { session: ISessionStore }) => s.session.platforms?.selected);

  /** Filter documents if platform is SMA or DMD */
  const docs =
    platform && ['sma', 'dmd'].includes(platform)
      ? documents.filter(
          (d) =>
            d && d._type === 'examination' && typeof d.codeName === 'string' && smaLabResultTypes.includes(d.codeName),
        )
      : documents;

  const sortedArr = docs ? docs.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse() : [];

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  return platform === 'mgravis' ? (
    <TileContentMaker
      type="specificListNoDate"
      data={['S-AChR-Ab', 'S-MuSKAb', 'S-TitiAb', 'LRP4_antibody', 'ryanodine_antibody'].map((codeName) => {
        const doc = sortedArr.find((d) => d.codeName === codeName);
        return {
          key: codeName,
          title: localizeCodenames(codeName, fm),
          value: doc && doc.date ? formatPartialDate(doc.date) : fm('examination.notDone'),
        };
      })}
    />
  ) : (
    <TileContentMaker
      type="recentList"
      data={sortedArr.map((d, i) => ({
        date: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
        title: d?.codeName ? localizeCodenames(d?.codeName, fm) : '',
        value: d.value && `${d.value}${d.units ? ' ' + d.units : ''}`,
        key: `${i}`,
      }))}
    />
  );
};

interface IOwnProps {
  documents: Array<IExamination>;
}

export default ExaminationDash;
