import * as React from 'react';
import { styled, Theme } from '@mui/system';
import { fm } from 'Components/FormatMessage';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import { assertCapabilities } from 'Store/index';
import { useAppDispatch as useDispatch } from 'Store/index';
import { actions } from 'Store/session';
import { Autorenew } from '@mui/icons-material';
import PlatformConditional from 'Components/PlatformConditional';
import { filter } from 'ramda';
import { TDocument } from 'Routes/Treatment/utils';
import PlatformCapabilities from '../../../../config/capabilities';
import DEXAHistory from './DEXA';
import CMAPHistory from './CMAP';
import MuscleImagingHistory from './MuscleImaging';
import EchocardiographyHistory from './Echocardiography';
import ScoliosisHistory from './Scoliosis';
import PelvisHistory from './Pelvis';
import MriHistory from './Mri';
import ScintigraphyHistory from './Scintigraphy';
import TTHistory from './TT';
import ThymusCTHistory from './ThymusCT';
import ThymusMRIHistory from './ThymusMRI';

const RefreshButtonArea = styled('div', {
  shouldForwardProp: (prop) => prop !== 'loading',
})(({ theme, loading }: { theme?: Theme; loading: boolean }) => ({
  position: 'relative' as const,
  top: '-3rem',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
  maxHeight: '1rem',
  display: 'flex',
  cursor: 'pointer',
  color: loading ? theme?.palette.grey[500] : undefined,
}));

const RefreshIcon = styled(Autorenew, {
  shouldForwardProp: (prop) => prop !== 'loading',
})(({ theme, loading }: { theme?: Theme; loading: boolean }) => ({
  margin: '0 0.5rem',
  display: loading ? 'none' : 'block',
  fontSize: '2rem',
  color: theme?.palette.primary.main,
}));

const History = ({ documents, startEdit, capabilityGroups }: IImagingHistory): JSX.Element => {
  const DEXADocs = documents && filter((d) => d._type === 'dexa', documents);
  const CMAPDocs = documents && filter((d) => d._type === 'cmap', documents);
  const MuscleImagingDocs = documents && filter((d) => d._type === 'muscleImaging', documents);
  const EchocardiographyDocs = documents && filter((d) => d._type === 'echocardiography', documents);
  const ScoliosisDocs = documents && filter((d) => d._type === 'scoliosis', documents);
  const PelvisDocs = documents && filter((d) => d._type === 'pelvis', documents);
  const MriDocs = documents && filter((d) => d._type === 'mri', documents);
  const TTDocs = documents && filter((d) => d._type === 'tt', documents);
  const ScintigraphyDocs = documents && filter((d) => d._type === 'scintigraphy', documents);
  const ThymusCTDocs = documents && filter((d) => d._type === 'thymusCt', documents);
  const ThymusMRIDocs = documents && filter((d) => d._type === 'thymusMri', documents);

  const hasImagingIntegrationCapability = assertCapabilities(
    [PlatformCapabilities.LFORCE_IMAGING_SYNC],
    capabilityGroups,
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);

  const doImagingSync = () => {
    setLoading(true);
    actions
      .imagingSyncAction(dispatch)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {hasImagingIntegrationCapability && (
        <RefreshButtonArea loading={loading} onClick={doImagingSync}>
          <RefreshIcon loading={loading} />
          {fm('imaging.refreshImaging')}
        </RefreshButtonArea>
      )}
      <PlatformConditional platform={['sma', 'dmd']}>
        <React.Fragment>
          <DEXAHistory documents={DEXADocs as IDEXA[]} startEdit={startEdit} />

          <CMAPHistory documents={CMAPDocs as ICMAP[]} startEdit={startEdit} />

          <MuscleImagingHistory documents={MuscleImagingDocs as IMuscleImaging[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>

      <PlatformConditional platform="dmd">
        <EchocardiographyHistory documents={EchocardiographyDocs as IEchocardiography[]} startEdit={startEdit} />
      </PlatformConditional>

      <PlatformConditional platform={['sma', 'dmd']}>
        <React.Fragment>
          <ScoliosisHistory documents={ScoliosisDocs as IScoliosisRontgen[]} startEdit={startEdit} />
          <PelvisHistory documents={PelvisDocs as IPelvicRontgen[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>

      <PlatformConditional platform={['sma', 'ms', 'parkinson']}>
        <MriHistory documents={MriDocs as IMRI[]} startEdit={startEdit} />
      </PlatformConditional>

      <PlatformConditional platform="parkinson">
        <React.Fragment>
          <TTHistory documents={TTDocs as ITT[]} startEdit={startEdit} />
          <ScintigraphyHistory documents={ScintigraphyDocs as IScintigraphy[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>

      <PlatformConditional platform="ninmt">
        <React.Fragment>
          <MriHistory documents={MriDocs as IMRI[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>

      <PlatformConditional platform="mgravis">
        <React.Fragment>
          <ThymusCTHistory documents={ThymusCTDocs as IThymusCT[]} startEdit={startEdit} />
          <ThymusMRIHistory documents={ThymusMRIDocs as IThymusMRI[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IImagingHistory extends ICapabilityContextProps {
  documents: Array<TDocument>;
  startEdit: (document: TDocument) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default withCapabilities(History);
