/**
 * Create a gray bar with black text for histories
 */
import * as React from 'react';
import styled from 'styled-components';

import colors from '../../config/theme/colors';
import { Container, Item } from '../Grid';

const Bar = styled.div`
  background-color: ${colors.defaultBackground};
  width: auto !important;
  height: 5rem;
  margin: 0rem -2.5rem 1rem -2.5rem;
  padding: 0 2.5rem;
  font-weight: 600;
`;

const HistoryRowSubHeaderBar = ({ header }: IOwnProps): JSX.Element => (
  <Container>
    <Item xs={12}>
      <Bar>
        <Container alignItems="center" style={{ height: 'inherit' }}>
          <Item xs={true}>{header}</Item>
        </Container>
      </Bar>
    </Item>
  </Container>
);

interface IOwnProps {
  header: JSX.Element | string | number;
}

export default HistoryRowSubHeaderBar;
