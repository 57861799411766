import { mergeDeepRight } from 'ramda';

import { reduxActions } from './index';
import {
  createGrant,
  deleteGrant,
  fetchPatientData,
  fetchPatientGrants,
  patchPatient,
  patchPatientExitus,
  updateGrant,
} from './apiFetchers';
import { TDispatch } from '..';

import { getDescription } from '../../utility/randomUtil';

const getICD10DescriptionAndUpdateData = (data: IPatientUI, updateCallback: (data: IPatientUI) => void) => {
  if (data.reasonOfDeath?.reasonCode?.code) {
    // Get description string for icd-10 code
    getDescription(data.reasonOfDeath.reasonCode?.code)
      .then((d) => {
        const updatedReasonCode = {
          ...data.reasonOfDeath?.reasonCode,
          icd10description: d,
        } as Partial<IICDReasonCode>;
        const updatedData = mergeDeepRight(data, { reasonOfDeath: { reasonCode: updatedReasonCode } }) as IPatientUI;
        updateCallback(updatedData);
      })
      .catch(() => {
        return;
      });
  } else updateCallback(data);
};

const loadPatientData =
  () =>
  (dispatch: TDispatch): void => {
    fetchPatientData()
      .then((data) => {
        if (!data) return;
        getICD10DescriptionAndUpdateData(data, (data) => dispatch(reduxActions.loadData(data)));
      })
      .catch(() => {
        return;
      });
  };

const changePatientData =
  (data: Partial<IPatientUI>) =>
  async (dispatch: TDispatch): Promise<true | false> => {
    return await patchPatient(data)
      .then((res: boolean | null) => {
        if (res) {
          /*
           * The ICD-10 description is fetched every time patient data is edited, which is not optimal,
           * but still better than previously when it was fetched on every mount in several components.
           * Improve this at some point to fetch only if data is changed.
           */
          dispatch(reduxActions.changeData(data));
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  };

const clearPatientData =
  () =>
  (dispatch: TDispatch): void => {
    dispatch(reduxActions.clearData());
  };

const changePatientExitusData =
  (data: TExitusUI) =>
  async (dispatch: TDispatch): Promise<true | false> => {
    return await patchPatientExitus(data)
      .then((res: boolean | null) => {
        if (res) {
          /*
           * The ICD-10 description is fetched every time patient data is edited, which is not optimal,
           * but still better than previously when it was fetched on every mount in several components.
           * Improve this at some point to fetch only if data is changed.
           */
          dispatch(reduxActions.changeExitus(data));
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  };

const loadGrantsData =
  () =>
  (dispatch: TDispatch): void => {
    fetchPatientGrants()
      .then((data) => {
        if (!data) return;
        dispatch(reduxActions.loadGrants(data));
      })
      .catch(() => {
        dispatch(reduxActions.loadGrants([]));
      });
  };

const createNewGrant =
  (
    granteeOrg: TNeuroGrant['granteeOrg'],
    allow_write?: TNeuroGrant['allow_write'],
    allow_read?: TNeuroGrant['allow_read'],
  ) =>
  (dispatch: TDispatch): void => {
    const writeTypes = allow_write || [];
    const readTypes = allow_read || ['*'];
    createGrant(granteeOrg, writeTypes, readTypes)
      .then((data) => {
        if (!data) return;
        dispatch(reduxActions.createGrant(data));
      })
      .catch(() => {
        return;
      });
  };

const updateExistingGrant =
  (
    id: TNeuroGrant['id'],
    data: {
      granteeGroup?: TNeuroGrant['granteeGroup'];
      granteeOrg?: TNeuroGrant['granteeOrg'];
      allow_write?: TNeuroGrant['allow_write'];
      allow_read?: TNeuroGrant['allow_read'];
    },
  ) =>
  (dispatch: TDispatch): void => {
    updateGrant(id, data)
      .then((data) => {
        if (!data) return;
        dispatch(reduxActions.updateGrant(data));
      })
      .catch(() => {
        return;
      });
  };

const deleteExistingGrant =
  (id: TNeuroGrant['id']) =>
  (dispatch: TDispatch): void => {
    deleteGrant(id)
      .then((data) => {
        if (!data) return;
        dispatch(reduxActions.deleteGrant(data));
      })
      .catch(() => {
        return;
      });
  };

export const actions = {
  loadPatientData,
  changePatientData,
  clearPatientData,
  changePatientExitusData,
  loadGrantsData,
  createNewGrant,
  updateExistingGrant,
  deleteExistingGrant,
};
