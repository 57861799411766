import { Paper } from '@mui/material';
import * as React from 'react';

import colors from '../../config/theme/colors';
import ToolTip from '../ToolTip';
import { styled } from '@mui/material';
import { Container, Item } from 'Components/Grid';

const StyledRowContainer = styled(Container)`
  width: 100%;
`;
const StyledRowItem = styled(Item)`
  width: 100%;
`;

const StyledNumber = styled('div')`
  width: 3rem;
  height: 3rem;
  background-color: white;
  text-align: center;
  line-height: 2.6rem;
  border: solid 0.5px #cbcbcb;
  border-radius: 5rem;
  position: relative;
  font-size: 1.6rem;
  color: ${(props: { disabled?: boolean }): string => (props.disabled ? colors.gray : colors.primaryText)} !important;
  font-weight: ${(props: { disabled?: boolean }): string => (props.disabled ? 'normal' : 'bold')} !important;
`;

const StyledPaper = styled(Paper)`
  margin: -1.5rem 0 0 0;
  padding: 3rem 1rem 1rem 1rem;
  min-height: ${(props: IPaperProps): string => (props.height ? props.height + 'rem' : '15rem')};
  cursor: ${(props): string =>
    (props.viewing && props.viewing === 'true') || (props.disabled && props.disabled === true) ? '' : 'pointer'};
  font-size: 1.6rem;
  background-color: ${(props): string =>
    props.disabled && props.selected
      ? colors.gray
      : props.disabled
        ? colors.white
        : props.selected
          ? colors.primary
          : colors.white} !important;
  color: ${(props): string =>
    props.disabled && props.selected
      ? colors.darkGray
      : props.disabled
        ? colors.gray
        : props.selected
          ? colors.white
          : colors.primaryText} !important;
  &:hover {
    background-color: ${(props): string =>
      props.disabled && props.selected
        ? colors.gray
        : props.disabled
          ? colors.white
          : props.selected
            ? colors.primary
            : (props.viewing && props.viewing === 'true') || (props.disabled && props.disabled === true)
              ? colors.white
              : colors.lightestGray} !important;
  }
`;

interface IPaperProps {
  viewing: string;
  height?: number;
  disabled?: boolean;
  selected?: boolean;
}

const QuestionItem = ({
  qNumber,
  text,
  onClick,
  selected,
  height,
  viewing,
  disabled,
  disabledMessage,
  showValue,
}: IOwnProps): JSX.Element => {
  const Card = (
    <div>
      {/* Tooltip requires div as root element */}
      {(typeof qNumber === 'number' || showValue) && (
        <StyledRowContainer justifyContent="center" alignItems="center">
          <StyledRowItem xs={2}>
            <StyledNumber disabled={disabled}>{qNumber}</StyledNumber>
          </StyledRowItem>
        </StyledRowContainer>
      )}
      <StyledRowContainer>
        <StyledRowItem>
          <StyledPaper
            elevation={2}
            selected={selected}
            onClick={!disabled ? onClick : undefined}
            height={height}
            viewing={viewing ? 'true' : 'false'}
            disabled={disabled}
          >
            {text}
          </StyledPaper>
        </StyledRowItem>
      </StyledRowContainer>
    </div>
  );

  return disabled && disabledMessage ? (
    <ToolTip title={disabledMessage} content={Card} hover={true} cursor={'Default'} />
  ) : (
    Card
  );
};

interface IOwnProps {
  qNumber: number | string;
  text: string | JSX.Element;
  onClick?: () => void;
  selected?: boolean;
  height?: number;
  viewing?: boolean;
  disabled?: boolean;
  disabledMessage?: JSX.Element | string;
  showValue?: true;
}

export default QuestionItem;
