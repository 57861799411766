import * as React from 'react';
import styled from 'styled-components';

import FormRow from '../../../../../../components/FormRow';
import FormSectionBar from '../../../../../../components/FormSectionBar';
import FormSectionHistoryAcceptor from '../../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';

import { fm } from 'Components/FormatMessage';

const SubHeader = styled.div`
  margin: 1rem 0;
  font-weight: 600;
`;

const formFields = [
  'O1VerticalSupranuclearGazePalsy',
  'O2SlowVelocityOfVerticalSaccades',
  'O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia',
  'P1RepeatedUnprovokedFallsWithin3Years',
  'P2TendencyToFallOnThePulltestWithin3Years',
  'P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years',
  'A1ProgressiveGaitFreezingWithin3Years',
  'A2ParkinsonismAkineticrigidPredominantlyAxialAndLevodopaResistant',
  'A3ParkinsonismWithTremorAndOrAsymmetricAndOrLevodopaResponsive',
  'C1NonfluentAgrammaticVariantOfPrimaryLossOfGrammar',
  'C1ProgressiveApraxiaOfSpeech',
  'C2Apathy',
  'C2Bradyphrenia',
  'C2DysexecutiveSyndrome',
  'C2ReducedPhonemicVerbalFluency',
  'C2ImpulsivityDisinhibitionOrPerseveration',
  'C3OrobuccalOrLimbApraxia',
  'C3CorticalSensoryDeficit',
  'C3AlienLimbPhenomena',
  'C3LimbRigidity',
  'C3LimbAkinesia',
  'C3LimbMyoclonus',
  'CC1LevodopaResistance',
  'CC2HypokineticSpasticDysarthria',
  'CC3Dysphagia',
  'CC4Photophobia',
  'IF1PredominantMidbrainAtrophyOrHypometabolism',
  'IF2PostsynapticStriatalDopaminergicDegeneration',
];

const setAllUnknown = (formData: IFormContext['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData?.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  leftPadding = false,
  info,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  name: string;
  formData: IFormContext['formData'];
  viewing: boolean;
  excludedOpt?: boolean;
  leftPadding?: boolean;
  info?: JSX.Element;
  bottomMargin?: boolean;
}): JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={7}
    headerStyle={{ paddingLeft: leftPadding ? '1.5rem' : undefined }}
    info={info}
    bottomMargin={bottomMargin}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset={'yesNoUnknown'}
      height={3.5}
      width={9}
    />
  </FormRow>
);

const CoreClinicalFeatures = ({ documents, formData, view, editing }: IFormContext): JSX.Element => {
  const isViewing = !editing && !!view?.viewing;
  const docID = editing ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      {formData ? (
        <React.Fragment>
          <FormSectionBar header={'diagnosis.psp.coreClinicalFeatures.title'} />
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => fm('general.' + s)}
            header="diagnosis.psp.coreClinicalFeatures.coreClinicalFindings"
            hideCopyButton={isViewing}
          >
            {{
              ocularMotorDysfunction: {
                header: <SubHeader>{fm('diagnosis.psp.coreClinicalFeatures.ocularMotorDysfunction')}</SubHeader>,
              },
              O1VerticalSupranuclearGazePalsy: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.O1VerticalSupranuclearGazePalsy"
                    info={fm('diagnosis.psp.coreClinicalFeatures.O1VerticalSupranuclearGazePalsyInfo')}
                    name="O1VerticalSupranuclearGazePalsy"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              O2SlowVelocityOfVerticalSaccades: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.O2SlowVelocityOfVerticalSaccades"
                    info={fm('diagnosis.psp.coreClinicalFeatures.O2SlowVelocityOfVerticalSaccadesInfo')}
                    name="O2SlowVelocityOfVerticalSaccades"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia"
                    info={fm(
                      'diagnosis.psp.coreClinicalFeatures.O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxiaInfo',
                    )}
                    name="O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              posturalInstability: {
                header: <SubHeader>{fm('diagnosis.psp.coreClinicalFeatures.posturalInstability')}</SubHeader>,
              },
              P1RepeatedUnprovokedFallsWithin3Years: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.P1RepeatedUnprovokedFallsWithin3Years"
                    info={fm('diagnosis.psp.coreClinicalFeatures.P1RepeatedUnprovokedFallsWithin3YearsInfo')}
                    name="P1RepeatedUnprovokedFallsWithin3Years"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              P2TendencyToFallOnThePulltestWithin3Years: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.P2TendencyToFallOnThePulltestWithin3Years"
                    info={fm('diagnosis.psp.coreClinicalFeatures.P2TendencyToFallOnThePulltestWithin3YearsInfo')}
                    name="P2TendencyToFallOnThePulltestWithin3Years"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years"
                    info={fm(
                      'diagnosis.psp.coreClinicalFeatures.P3MoreThanTwoStepsBackwardOnThePulltestWithin3YearsInfo',
                    )}
                    name="P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              akinesia: {
                header: <SubHeader>{fm('diagnosis.psp.coreClinicalFeatures.akinesia')}</SubHeader>,
              },
              A1ProgressiveGaitFreezingWithin3Years: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.A1ProgressiveGaitFreezingWithin3Years"
                    info={fm('diagnosis.psp.coreClinicalFeatures.A1ProgressiveGaitFreezingWithin3YearsInfo')}
                    name="A1ProgressiveGaitFreezingWithin3Years"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              A2ParkinsonismAkineticrigidPredominantlyAxialAndLevodopaResistant: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.A2ParkinsonismAkineticrigidPredominantlyAxialAndLevodopaResistant"
                    info={fm(
                      'diagnosis.psp.coreClinicalFeatures.A2ParkinsonismAkineticrigidPredominantlyAxialAndLevodopaResistantInfo',
                    )}
                    name="A2ParkinsonismAkineticrigidPredominantlyAxialAndLevodopaResistant"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              A3ParkinsonismWithTremorAndOrAsymmetricAndOrLevodopaResponsive: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.A3ParkinsonismWithTremorAndOrAsymmetricAndOrLevodopaResponsive"
                    info={fm(
                      'diagnosis.psp.coreClinicalFeatures.A3ParkinsonismWithTremorAndOrAsymmetricAndOrLevodopaResponsiveInfo',
                    )}
                    name="A3ParkinsonismWithTremorAndOrAsymmetricAndOrLevodopaResponsive"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              cognitiveDysfunction: {
                header: <SubHeader>{fm('diagnosis.psp.coreClinicalFeatures.cognitiveDysfunction')}</SubHeader>,
              },
              c1: {
                header: (
                  <div style={{ marginBottom: '0.5rem' }}>
                    {fm('diagnosis.psp.coreClinicalFeatures.C1')}
                    <div style={{ fontSize: '1.4rem' }}>{fm('diagnosis.psp.coreClinicalFeatures.C1Info')}</div>
                  </div>
                ),
              },
              C1NonfluentAgrammaticVariantOfPrimaryLossOfGrammar: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C1NonfluentAgrammaticVariantOfPrimaryLossOfGrammar"
                    name="C1NonfluentAgrammaticVariantOfPrimaryLossOfGrammar"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C1ProgressiveApraxiaOfSpeech: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C1ProgressiveApraxiaOfSpeech"
                    name="C1ProgressiveApraxiaOfSpeech"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              c2: {
                header: (
                  <div style={{ marginBottom: '0.5rem' }}>
                    {fm('diagnosis.psp.coreClinicalFeatures.C2')}
                    <div style={{ fontSize: '1.4rem' }}>{fm('diagnosis.psp.coreClinicalFeatures.C2Info')}</div>
                  </div>
                ),
              },
              C2Apathy: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C2Apathy"
                    name="C2Apathy"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C2Bradyphrenia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C2Bradyphrenia"
                    name="C2Bradyphrenia"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C2DysexecutiveSyndrome: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C2DysexecutiveSyndrome"
                    name="C2DysexecutiveSyndrome"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C2ReducedPhonemicVerbalFluency: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C2ReducedPhonemicVerbalFluency"
                    name="C2ReducedPhonemicVerbalFluency"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C2ImpulsivityDisinhibitionOrPerseveration: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C2ImpulsivityDisinhibitionOrPerseveration"
                    name="C2ImpulsivityDisinhibitionOrPerseveration"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              c3: {
                header: (
                  <div style={{ marginBottom: '0.5rem' }}>
                    {fm('diagnosis.psp.coreClinicalFeatures.C3')}
                    <div style={{ fontSize: '1.4rem' }}>{fm('diagnosis.psp.coreClinicalFeatures.C3Info')}</div>
                  </div>
                ),
              },
              C3Cortical: {
                header: (
                  <div style={{ marginBottom: '0.5rem' }}>{fm('diagnosis.psp.coreClinicalFeatures.C3Cortical')}</div>
                ),
              },
              C3OrobuccalOrLimbApraxia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C3OrobuccalOrLimbApraxia"
                    name="C3OrobuccalOrLimbApraxia"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C3CorticalSensoryDeficit: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C3CorticalSensoryDeficit"
                    name="C3CorticalSensoryDeficit"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C3AlienLimbPhenomena: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C3AlienLimbPhenomena"
                    name="C3AlienLimbPhenomena"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C3Movement: {
                header: (
                  <div style={{ marginBottom: '0.5rem' }}>{fm('diagnosis.psp.coreClinicalFeatures.C3Movement')}</div>
                ),
              },
              C3LimbRigidity: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C3LimbRigidity"
                    name="C3LimbRigidity"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C3LimbAkinesia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C3LimbAkinesia"
                    name="C3LimbAkinesia"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                  />
                ),
              },
              C3LimbMyoclonus: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.C3LimbMyoclonus"
                    name="C3LimbMyoclonus"
                    formData={formData}
                    viewing={isViewing}
                    leftPadding
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => fm('general.' + s)}
            header="diagnosis.psp.coreClinicalFeatures.clinicalClues"
            hideCopyButton={isViewing}
          >
            {{
              CC1LevodopaResistance: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.CC1LevodopaResistance"
                    info={fm('diagnosis.psp.coreClinicalFeatures.CC1LevodopaResistanceInfo')}
                    name="CC1LevodopaResistance"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              CC2HypokineticSpasticDysarthria: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.CC2HypokineticSpasticDysarthria"
                    info={fm('diagnosis.psp.coreClinicalFeatures.CC2HypokineticSpasticDysarthriaInfo')}
                    name="CC2HypokineticSpasticDysarthria"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              CC3Dysphagia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.CC3Dysphagia"
                    info={fm('diagnosis.psp.coreClinicalFeatures.CC3DysphagiaInfo')}
                    name="CC3Dysphagia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              CC4Photophobia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.CC4Photophobia"
                    info={fm('diagnosis.psp.coreClinicalFeatures.CC4PhotophobiaInfo')}
                    name="CC4Photophobia"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => fm('general.' + s)}
            header="diagnosis.psp.coreClinicalFeatures.imagingFindings"
            hideCopyButton={isViewing}
          >
            {{
              imagingFindingsDescription: {
                header: (
                  <div style={{ marginTop: '0.5rem', marginBottom: '2.5rem' }}>
                    {fm('diagnosis.psp.coreClinicalFeatures.imagingFindingsDescription')}
                  </div>
                ),
              },
              IF1PredominantMidbrainAtrophyOrHypometabolism: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.IF1PredominantMidbrainAtrophyOrHypometabolism"
                    info={fm('diagnosis.psp.coreClinicalFeatures.IF1PredominantMidbrainAtrophyOrHypometabolismInfo')}
                    name="IF1PredominantMidbrainAtrophyOrHypometabolism"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              IF2PostsynapticStriatalDopaminergicDegeneration: {
                element: (
                  <MakeFormRow
                    title="diagnosis.psp.coreClinicalFeatures.IF2PostsynapticStriatalDopaminergicDegeneration"
                    info={fm('diagnosis.psp.coreClinicalFeatures.IF2PostsynapticStriatalDopaminergicDegenerationInfo')}
                    name="IF2PostsynapticStriatalDopaminergicDegeneration"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default withFormContext(CoreClinicalFeatures);
