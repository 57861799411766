import { DialogTitle, DialogContent, DialogActions, Dialog, Radio } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { formatTime } from 'neuro-utils';
import { equals } from 'ramda';
import { styled } from '@mui/system';

import {
  dialogTitle,
  dialogContent,
  dialogActions,
  dialogCancel,
  dialogDelete,
} from '../../../../config/theme/componentTheme';
import ActionButton from 'Components/ActionButton';

import MeasurementFormSingle from '../FormMeasurement/single';
import { Container, Item } from 'Components/Grid';
import { loincCodeToFieldName } from 'Routes/Background/utils';
import { actions } from 'Store/documents/actions';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import ToolTip from 'Components/ToolTip';
import { useAppDispatch } from 'Store/index';

const StyledContainerArea = styled('div')(({ theme }) => ({
  '& > div:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[500] + 22,
  },
}));
const StyledContainer = styled(Container)({
  cursor: 'pointer',
  padding: '0.6rem',
});

const EditDialog = ({ editingDialogDocuments, cancelEditing, confirmEdit }: IEditDialog) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const fm = (id?: string) => (id ? formatMessage({ id }) : '');

  const [chosenDoc, setChosenDoc] = React.useState<IMeasurement | null>(null);
  const [newValues, setNewValues] = React.useState<Partial<IMeasurement> | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState<boolean>(false);

  const changeValue = (values: TOnChangeValues) => {
    const field = Object.keys(values)[0];
    setNewValues({ ...newValues, [field]: values[field] });
  };

  const saveData = () => {
    if (chosenDoc && newValues?.value) {
      actions.modifyField({ id: chosenDoc._id, name: chosenDoc._type }, chosenDoc, { value: newValues?.value })(
        dispatch,
      );
    }
  };
  const saveNewDocument = () => {
    if (editingDialogDocuments && 'code' in editingDialogDocuments && newValues?.value) {
      actions.putDocument('measurement', { ...editingDialogDocuments, ...newValues })(dispatch);
    }
  };
  const deleteDocument = () => {
    if (chosenDoc) {
      actions.deleteDocument(
        { id: chosenDoc._id, name: chosenDoc._type },
        { reason: 'Manually delete measurement value' },
      )(dispatch);
    }
  };

  const documentsIsArray = Array.isArray(editingDialogDocuments);

  const cancel = () => {
    setChosenDoc(null);
    cancelEditing();
    setNewValues(null);
  };

  const deleteFunction = () => {
    setDeleteConfirmationOpen(false);
    deleteDocument();
    setChosenDoc(null);
    cancelEditing();
    setNewValues(null);
  };

  const confirm = () => {
    if (documentsIsArray) {
      confirmEdit(chosenDoc);
    } else if (editingDialogDocuments) {
      if (!editingDialogDocuments._id) {
        // If no id, then create new document
        saveNewDocument();
        setNewValues(null);
        setChosenDoc(null);
        confirmEdit(null);
      } else {
        // Edit old document
        saveData();
        setChosenDoc(null);
        confirmEdit(null);
        setNewValues(null);
      }
    }
  };

  return (
    <>
      <Dialog
        open={!!editingDialogDocuments}
        fullWidth
        maxWidth={!documentsIsArray && editingDialogDocuments ? 'sm' : 'xs'}
      >
        <DialogTitle style={dialogTitle}>
          {documentsIsArray
            ? fm('background.values')
            : editingDialogDocuments?._id
              ? fm(`background.editValue`)
              : fm(`background.addValue`)}
        </DialogTitle>
        <DialogContent style={dialogContent}>
          {documentsIsArray ? (
            <div>
              <div>{fm('background.chooseValue')}:</div>
              <br />
              <StyledContainerArea>
                {editingDialogDocuments.map((doc) => {
                  const locked = !!doc._lockedFor;
                  return (
                    <ToolTip
                      key={doc._id}
                      title={fm('background.dataFromIntegration')}
                      disabled={!locked}
                      hover
                      placement={'right-end'}
                      content={
                        <StyledContainer alignItems="center" onClick={!locked ? () => setChosenDoc(doc) : undefined}>
                          <Item xs={5}>{formatTime(doc.time)}</Item>
                          <Item xs={5}>
                            {doc.value} {(doc.code && loincCodeToFieldName(doc.code)?.unit) ?? ''}
                          </Item>
                          <Item xs={2}>
                            <Container justifyContent={'flex-end'}>
                              <Item>
                                <Radio checked={equals(doc, chosenDoc)} disabled={locked} style={{ padding: 0 }} />
                              </Item>
                            </Container>
                          </Item>
                        </StyledContainer>
                      }
                    />
                  );
                })}
              </StyledContainerArea>
            </div>
          ) : editingDialogDocuments ? (
            <div style={{ width: '100%', overflowX: 'hidden' }}>
              <MeasurementFormSingle
                formData={{
                  document: { ...editingDialogDocuments, ...newValues },
                  onChange: changeValue,
                }}
                editableFields={editingDialogDocuments._id ? ['value'] : ['time', 'value']}
              />
            </div>
          ) : null}
        </DialogContent>
        <DialogActions style={dialogActions}>
          <Container alignItems={'center'}>
            {!documentsIsArray && editingDialogDocuments?._id && (
              <Item xs={5}>
                <div style={dialogDelete} onClick={() => setDeleteConfirmationOpen(true)}>
                  {fm('general.delete')}
                </div>
              </Item>
            )}
            <Item xs={true}>
              <Container alignItems={'center'} justifyContent={'flex-end'}>
                <Item>
                  <div style={dialogCancel} onClick={cancel}>
                    {fm('general.cancel')}
                  </div>
                </Item>
                <Item>
                  <ActionButton
                    text={documentsIsArray ? 'general.choose' : 'general.save'}
                    onClick={confirm}
                    width={15}
                    height={4}
                    fontSize={16}
                  />
                </Item>
              </Container>
            </Item>
          </Container>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        text={fm('general.reallyWantToDelete')}
        cancel={{ callback: () => setDeleteConfirmationOpen(false) }}
        confirm={{ callback: () => deleteFunction() }}
      />
    </>
  );
};

interface IEditDialog {
  editingDialogDocuments: Partial<IMeasurement> | IMeasurement[] | null;
  cancelEditing: () => void;
  confirmEdit: (d: IMeasurement | IMeasurement[] | null) => void;
}

export default EditDialog;
