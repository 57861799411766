import * as React from 'react';
import { fm } from 'Components/FormatMessage';

import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import InputHistoryAcceptor from '../../../../components/InputHistoryAcceptor';
import PlatformConditional from '../../../../components/PlatformConditional';
import { partialDateToValue } from 'neuro-utils';
import { getKeys } from '../../../../utility/object';
import { rowData as allRowData, RowDataOther } from './rowData';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import EventList from './EventList';
import { capitalize } from 'Utility/string';
import { useSelector } from 'react-redux';
import { getFieldCondition } from 'Routes/Background/utils';
import { sharedRowData } from './sharedRowData';
import { pick } from 'ramda';
import { ICompactVaultUser } from 'neuro-data-structures';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => {
    return fm(`background.${opt}.${name}`);
  };

const FormInputRow = ({
  documents,
  formData,
  title,
  subject,
  type,
  options,
  placeholder,
  precision,
  optionFormat,
  condition,
  sizing,
  id,
  otherProps,
}: IFormInputRow & RowDataOther): JSX.Element | null => {
  return condition === undefined || condition ? (
    <FormRow
      id={id}
      title={`background.${title}`}
      info={subject === 'educationYears' ? fm('background.educationYearsInfo') : undefined}
    >
      <InputHistoryAcceptor
        documents={documents}
        type={type}
        name={subject}
        editing={true}
        formData={formData}
        options={options}
        placeholder={placeholder}
        precision={precision}
        optionFormatter={
          type === 'Radio' || type === 'Checkbox'
            ? optionFormat
              ? optionFormatter(`opts.${optionFormat}`)
              : optionFormatter(`opts.${subject}`)
            : undefined
        }
        sizing={sizing}
        {...otherProps}
      />
    </FormRow>
  ) : null;
};

const BackgroundFormDynamic = ({ formData, documents, isShared }: IOwnProps): JSX.Element => {
  const previousDocs =
    documents?.filter(
      (d) => partialDateToValue(d?.date) < partialDateToValue(formData?.document.date) && d?._editing === false,
    ) || undefined;

  const onDelete = (name: keyof IFormData<IBackground>['document'], index: number): void => {
    const array = formData.document[name];
    const data = Array.isArray(array) ? [...array] : [];
    data.splice(index, 1);
    formData.onChange && formData.onChange({ [name]: data });
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [deleteDialogData, setDeleteDialogData] = React.useState<
    { name: keyof IFormData<IBackground>['document']; index: number } | undefined
  >(undefined);

  const openDeleteDialog = (name: keyof IFormData<IBackground>['document'], index: number): void => {
    setDeleteDialogOpen(true);
    setDeleteDialogData({ name, index });
  };

  const deleteCancelCallback = (): void => {
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };

  const deleteConfirmCallback = (): void => {
    deleteDialogData && onDelete(deleteDialogData.name, deleteDialogData.index);
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };

  const selectedPlatform = useSelector((s: { session: ISessionStore }) => s.session.platforms?.selected);

  // If document is shared (My document), pick only shared fields from row data
  // Currently only supports S & R
  const rowData =
    isShared && selectedPlatform
      ? {
          ...allRowData,
          [selectedPlatform]: Object.fromEntries(
            getKeys(sharedRowData?.[selectedPlatform] ?? {}).map((key) => {
              if (allRowData?.[selectedPlatform]?.[key]) {
                return [key, pick(sharedRowData[selectedPlatform]?.[key] ?? [], allRowData?.[selectedPlatform]?.[key])];
              }
              return [key, allRowData[selectedPlatform][key]];
            }),
          ),
        }
      : allRowData;

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            name="date"
            editing={true}
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
      </FormSection>

      <PlatformConditional platform={['epilepsy', 'sleepApnea', 'parkinson']}>
        <React.Fragment>
          {selectedPlatform &&
            getKeys(rowData?.[selectedPlatform]).map((title, ind) => {
              return (
                <FormSection
                  key={`${String(title)}${ind}`}
                  header={title === 'basicInformation' ? '' : `background.${String(title)}`}
                >
                  <>
                    {getKeys(rowData?.[selectedPlatform][title]).map((key, index) => {
                      return (
                        <FormInputRow
                          key={index}
                          documents={documents}
                          formData={formData}
                          title={key === 'familyMembersParkinson' ? 'relatives' : `${String(key)}`}
                          type={rowData?.[selectedPlatform][title][key as string]?.['type']}
                          subject={key as string}
                          options={rowData?.[selectedPlatform][title][key as string]?.['options'] ?? undefined}
                          placeholder={rowData?.[selectedPlatform][title][key as string]?.['placeholder'] ?? undefined}
                          optionFormat={
                            rowData?.[selectedPlatform][title][key as string]?.['optionFormat'] ?? undefined
                          }
                          condition={getFieldCondition(key as keyof IBackground, formData.document, selectedPlatform)}
                          otherProps={rowData?.[selectedPlatform][title][key as string]?.['otherProps'] ?? undefined}
                        />
                      );
                    })}
                    {title === 'drivingHealth' &&
                    (selectedPlatform !== 'sleepApnea' || !isShared) &&
                    formData.document?.driversLicense === 'yes' &&
                    formData.document?.driversLicenseGroup?.includes('R1') ? (
                      <EventList
                        formData={formData}
                        previousDocs={previousDocs}
                        openDeleteDialog={openDeleteDialog}
                        title={'background.addDrivingBanR1'}
                        addButton={'background.addDrivingBanR1'}
                        eventKey={'drivingBanR1s'}
                        datesDontOverlap={true}
                      />
                    ) : (
                      <></>
                    )}
                    {title === 'drivingHealth' &&
                    (selectedPlatform !== 'sleepApnea' || !isShared) &&
                    formData.document?.driversLicense === 'yes' &&
                    formData.document?.driversLicenseGroup?.includes('R2') ? (
                      <EventList
                        formData={formData}
                        previousDocs={previousDocs}
                        openDeleteDialog={openDeleteDialog}
                        title={'background.addDrivingBanR2'}
                        addButton={'background.addDrivingBanR2'}
                        eventKey={'drivingBanR2s'}
                        datesDontOverlap={true}
                      />
                    ) : (
                      <></>
                    )}
                    {selectedPlatform === 'sleepApnea' &&
                    !isShared &&
                    title === 'drivingHealth' &&
                    formData.document?.driversLicense === 'yes' &&
                    formData.document?.professionalDriving === 'yes' ? (
                      <EventList
                        formData={formData}
                        previousDocs={previousDocs}
                        openDeleteDialog={openDeleteDialog}
                        title={'background.addNoProRequirements'}
                        addButton={'general.new'}
                        eventKey={'noProDrivingRequirements'}
                        datesDontOverlap={true}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                </FormSection>
              );
            })}
          <ConfirmationDialog
            open={deleteDialogOpen}
            text={fm('general.reallyWantToDelete')}
            confirm={{ callback: deleteConfirmCallback }}
            cancel={{ callback: deleteCancelCallback }}
          />
        </React.Fragment>
      </PlatformConditional>

      <PlatformConditional platform={['ms', 'huntington', 'sma', 'dmd', 'mgravis']}>
        <FormSection>
          <PlatformConditional platform="ms">
            <React.Fragment>
              <div style={{ overflow: 'hidden' }}>
                {Object.keys(rowData?.ms).map((key, index) => {
                  const options = Array.isArray(rowData?.ms[key]['options'])
                    ? rowData?.ms[key]['options'].filter((option: string) => {
                        return key === 'education' &&
                          formData.document?.education?.includes('postSecondaryQualification')
                          ? true
                          : option !== 'postSecondaryQualification';
                      })
                    : undefined;

                  return (
                    <React.Fragment key={index}>
                      {['pregnancy', 'nursing'].includes(key) ? (
                        <EventList
                          formData={formData}
                          previousDocs={previousDocs}
                          openDeleteDialog={openDeleteDialog}
                          title={`background.${key === 'pregnancy' ? 'pregnancies' : key}`}
                          addButton={`background.add${capitalize(key)}`}
                          eventKey={`${key === 'pregnancy' ? 'pregnancies' : key}` as keyof IBackground}
                          datesDontOverlap={true}
                        />
                      ) : (
                        <FormInputRow
                          documents={documents}
                          formData={formData}
                          title={key === 'familyMembersAnamnesisMS' ? 'relatives' : key}
                          type={rowData?.ms[key]['type']}
                          subject={key}
                          options={options}
                          placeholder={rowData?.ms[key]['placeholder'] ?? undefined}
                          optionFormat={rowData?.ms[key]['optionFormat'] ?? undefined}
                          condition={getFieldCondition(key as keyof IBackground, formData.document, 'ms')}
                          id={key + index}
                          otherProps={rowData.ms[key]?.['otherProps'] ?? undefined}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              <ConfirmationDialog
                open={deleteDialogOpen}
                text={fm('general.reallyWantToDelete')}
                confirm={{ callback: deleteConfirmCallback }}
                cancel={{ callback: deleteCancelCallback }}
              />
            </React.Fragment>
          </PlatformConditional>

          <PlatformConditional platform="huntington">
            <div style={{ overflow: 'hidden' }}>
              {Object.keys(rowData?.huntington).map((key, index) => {
                const options = Array.isArray(rowData?.huntington[key]['options'])
                  ? rowData?.huntington[key]['options'].filter((option: string) => {
                      return key === 'education' && formData.document?.education?.includes('postSecondaryQualification')
                        ? true
                        : option !== 'postSecondaryQualification';
                    })
                  : undefined;

                /** Mostly the key itself, but exceptions in a couple cases. */
                const getRowTitle = (key: string): string => {
                  switch (key) {
                    case 'familyMembersAnamnesisHuntington':
                      return 'relatives';
                    case 'contactPermit':
                      return 'contactPermitForResearch';
                    default:
                      return key;
                  }
                };

                return (
                  <FormInputRow
                    key={index}
                    documents={documents}
                    formData={formData}
                    title={getRowTitle(key)}
                    type={rowData?.huntington[key]['type']}
                    subject={key}
                    options={options}
                    placeholder={rowData?.huntington[key]['placeholder'] ?? undefined}
                    optionFormat={rowData?.huntington[key]['optionFormat'] ?? undefined}
                    condition={getFieldCondition(key as keyof IBackground, formData.document, 'huntington')}
                  />
                );
              })}
            </div>
          </PlatformConditional>
          <PlatformConditional platform="sma">
            {getKeys(rowData?.sma).map((key, index) => {
              const getRowTitle = (key: string | number): string => {
                switch (key) {
                  case 'familyMembers':
                    return 'relatives';
                  default:
                    return `${key}`;
                }
              };

              return (
                <FormInputRow
                  key={index}
                  documents={documents}
                  formData={formData}
                  title={getRowTitle(key)}
                  type={rowData.sma[key]?.['type']}
                  subject={`${key}`}
                  options={rowData.sma[key]?.['options'] ?? undefined}
                  placeholder={rowData.sma[key]?.['placeholder'] ?? undefined}
                  optionFormat={rowData.sma[key]?.['optionFormat'] ?? undefined}
                  condition={getFieldCondition(key as keyof IBackground, formData.document, 'sma')}
                  otherProps={rowData.sma[key]?.['otherProps'] ?? undefined}
                />
              );
            })}
          </PlatformConditional>
          <PlatformConditional platform="dmd">
            {getKeys(rowData?.dmd).map((key, index) => {
              const getRowTitle = (key: string | number): string => {
                switch (key) {
                  case 'familyMembers':
                    return 'relatives';
                  default:
                    return `${key}`;
                }
              };

              return (
                <FormInputRow
                  key={index}
                  documents={documents}
                  formData={formData}
                  title={getRowTitle(key)}
                  type={rowData.dmd[key]?.['type']}
                  subject={`${key}`}
                  options={rowData.dmd[key]?.['options'] ?? undefined}
                  placeholder={rowData.dmd[key]?.['placeholder'] ?? undefined}
                  optionFormat={rowData.dmd[key]?.['optionFormat'] ?? undefined}
                  condition={getFieldCondition(key as keyof IBackground, formData.document, 'dmd')}
                  otherProps={rowData.dmd[key]?.['otherProps'] ?? undefined}
                />
              );
            })}
          </PlatformConditional>
          <PlatformConditional platform={'mgravis'}>
            <>
              {getKeys(rowData?.mgravis).map((key, index) => {
                return (
                  <FormInputRow
                    key={index}
                    documents={documents}
                    formData={formData}
                    title={`${key}`}
                    type={rowData.mgravis[key]?.['type']}
                    subject={`${key}`}
                    options={rowData.mgravis[key]?.['options'] ?? undefined}
                    placeholder={rowData.mgravis[key]?.['placeholder'] ?? undefined}
                    optionFormat={rowData.mgravis[key]?.['optionFormat'] ?? undefined}
                    condition={getFieldCondition(key as keyof IBackground, formData.document, 'mgravis')}
                    otherProps={rowData.mgravis[key]?.['otherProps'] ?? undefined}
                  />
                );
              })}
            </>
            <EventList
              formData={formData}
              previousDocs={previousDocs}
              openDeleteDialog={openDeleteDialog}
              title={'background.pregnancies'}
              addButton={'background.addPregnancy'}
              eventKey={'pregnancies'}
              datesDontOverlap={true}
            />
          </PlatformConditional>
        </FormSection>
      </PlatformConditional>
    </React.Fragment>
  );
};

export interface IFormInputRow extends Omit<IOwnProps, 'users' | 'isShared'> {
  title: string;
  subject: string;
  type: 'Radio' | 'NumberField' | 'Checkbox' | 'PartialDate';
  options?: (string | number)[];
  placeholder?: string;
  precision?: number;
  optionFormat?: string;
  /** Whether to render the input row or not. */
  condition?: boolean;
  sizing?: { left: number; right: number };
  id?: string;
  dependentFieldsList?: (value: TFieldValue, prevValue: TFieldValue) => string[];
}

interface IOwnProps {
  formData: IFormData<IBackground>;
  documents: IBackground[];
  users: ICompactVaultUser[];
  // If editing/viewing My documents
  isShared?: boolean;
}

export default BackgroundFormDynamic;
