import { capitalize } from '@mui/material';
import { path } from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import CollapseElem from '../../../../../components/Collapse';
import { Container, Item } from '../../../../../components/Grid';
import colors from '../../../../../config/theme/colors';
import { exists, formatPartialDate, formatTime, isPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { TitleValueItem } from '../../../../Medication/utils/Regimen/regimenItems';
import { StyledBoldText, StyledTitleValueRow } from '../../../components';
import { columnHasNumberField, keysByType, keyToHeader } from '../../../utils';
import {
  defaultUnits,
  generatorsWithAutoStimNightAndScheduledSettings,
  generatorsWithAutoStimSettings,
} from '../../config';
import ContainerEvent from './ContainerEvent';

const StyledRowContainer = styled(Container as any)`
  margin-top: 0.5rem;
  align-items: baseline;
`;

const StyledRowHeader = styled(Item as any)`
  font-size: 1.65rem;
  color: ${colors.primary};
`;

const StyledRowValue = styled(Item as any)`
  font-weight: 600;
`;

const StyledRowContainerBar = styled(({ ...other }) => <Container {...other} />)`
  background-color: ${colors.defaultBackground};
  width: auto !important;
  height: 5rem;
  margin: 0 -2.5rem 3rem -2.5rem;
  padding: 0 2.5rem;
`;

const StyledBarHeader = styled.div`
  font-weight: 600;
  margin-bottom: -0.3rem;
`;

export const MakeHeaderBar = ({
  date,
  additionalInfo,
}: {
  date?: PartialDate;
  additionalInfo?: string | JSX.Element;
}): JSX.Element => (
  <StyledRowContainerBar alignItems="center">
    <Item>
      <StyledBarHeader>{formatPartialDate(date)}</StyledBarHeader>
    </Item>
    {additionalInfo && (
      <>
        <Item style={{ margin: '0 1rem' }}>{'-'}</Item>
        <Item>{additionalInfo}</Item>
      </>
    )}
  </StyledRowContainerBar>
);

export const Generator = ({ generator }: { generator: IVNSGenerator }): JSX.Element => (
  <React.Fragment>
    <MakeHeaderBar date={generator.date} />
    <StyledTitleValueRow title={fm('vns.generator')} value={generator?.generator} />
    <StyledTitleValueRow title={fm('vns.generatorSerialNo')} value={generator?.generatorSerialNo} />
    <StyledTitleValueRow
      title={fm('vns.generatorNextChangeDate')}
      value={generator?.generatorNextChangeDate ? formatPartialDate(generator?.generatorNextChangeDate) : undefined}
    />
  </React.Fragment>
);

const SettingView = ({ d, editIndex }: { d: IVNS; editIndex: number }): JSX.Element => {
  const separateNightSettings = path(['settings', editIndex, 'separateNightSettings'], d);
  const tachycardiaDetection = path(['settings', editIndex, 'tachycardiaDetection'], d);
  const scheduledProgramming = path(['settings', editIndex, 'scheduledProgramming'], d);

  const columns = ['normalMode', 'autoStimMode', 'magnetMode', 'initialization'].filter((column) =>
    column === 'autoStimMode' && tachycardiaDetection !== 'ON'
      ? false
      : column === 'initialization' && scheduledProgramming !== 'yes'
        ? false
        : true,
  );
  const rows = [
    'outputCurrent',
    'rate',
    'pulseWidth',
    'onTime',
    'offTime',
    'efficiencyCycle',
    'autoStimThreshold',
  ].filter((row) => (row === 'autoStimThreshold' && tachycardiaDetection !== 'ON' ? false : true));

  return (
    <div style={{ padding: '0 -3rem 0 -3rem', marginRight: '-3rem', marginBottom: '4.5rem' }}>
      <StyledRowContainer style={{ marginBottom: '3rem' }}>
        <StyledRowHeader xs={3}>{fm('vns.generator')}</StyledRowHeader>
        <StyledRowValue>
          {d.generators
            ?.map((generator: IVNSGenerator) => {
              if (generator.id === path(['settings', editIndex, 'generator'], d)) {
                return `${generator?.generator} - ${formatPartialDate(generator?.date)}`;
              }
              return undefined;
            })
            .filter((g) => g)
            .join('')}
        </StyledRowValue>
      </StyledRowContainer>
      {generatorsWithAutoStimNightAndScheduledSettings.includes(
        d.generators?.find((g) => g.id === path(['settings', editIndex, 'generator'], d))?.generator ?? '',
      ) && (
        <div style={{ marginBottom: '3rem' }}>
          <StyledRowContainer>
            <StyledRowHeader xs={3}>{fm('vns.separateNightSettings')}</StyledRowHeader>
            <StyledRowValue>
              {separateNightSettings ? fm(`vns.opts.yesNo.${separateNightSettings}`) : '-'}
            </StyledRowValue>
          </StyledRowContainer>
          {separateNightSettings === 'yes' &&
            ['Start', 'End'].map((name) => (
              <StyledRowContainer key={name}>
                <StyledRowHeader xs={3}>{fm(`vns.nightTime${name}`)}</StyledRowHeader>
                <StyledRowValue>
                  {path(['settings', editIndex, `nightTime${name}`], d)
                    ? formatTime(path(['settings', editIndex, `nightTime${name}`], d))
                    : '-'}
                </StyledRowValue>
              </StyledRowContainer>
            ))}
        </div>
      )}
      {generatorsWithAutoStimNightAndScheduledSettings
        .concat(generatorsWithAutoStimSettings)
        .includes(
          d.generators?.find((g) => g.id === path(['settings', editIndex, 'generator'], d))?.generator ?? '',
        ) && (
        <div style={{ marginBottom: '3rem' }}>
          <StyledRowContainer>
            <StyledRowHeader xs={3}>{fm('vns.tachycardiaDetection')}</StyledRowHeader>
            <StyledRowValue>{tachycardiaDetection ?? '-'}</StyledRowValue>
          </StyledRowContainer>
          {tachycardiaDetection === 'ON' && (
            <StyledRowContainer>
              <StyledRowHeader xs={3}>{fm('vns.heartRateDetectionSettingShort')}</StyledRowHeader>
              <StyledRowValue>{path(['settings', editIndex, 'heartRateDetectionSetting'], d) ?? '-'}</StyledRowValue>
            </StyledRowContainer>
          )}
        </div>
      )}
      {separateNightSettings !== 'yes' &&
        generatorsWithAutoStimNightAndScheduledSettings.includes(
          d.generators?.find((g) => g.id === path(['settings', editIndex, 'generator'], d))?.generator ?? '',
        ) && (
          <StyledRowContainer style={{ marginBottom: '3rem' }}>
            <StyledRowHeader xs={3}>{fm('vns.scheduledProgramming')}</StyledRowHeader>
            <StyledRowValue>{scheduledProgramming ? fm(`vns.opts.yesNo.${scheduledProgramming}`) : '-'}</StyledRowValue>
          </StyledRowContainer>
        )}
      <StyledRowContainer>
        <Item xs={3} />
        {columns.map(
          (column: string, index: number): JSX.Element => (
            <Item key={`${column}${index}`} xs={2}>
              <StyledBoldText style={{ fontSize: '1.65rem' }}>{fm(`vns.${column}`)}</StyledBoldText>
            </Item>
          ),
        )}
      </StyledRowContainer>
      <React.Fragment>
        {rows.map(
          (row: string, index: number): JSX.Element => (
            <React.Fragment key={`${row}${index}`}>
              <StyledRowContainer style={{ marginTop: '3rem' }}>
                <StyledRowHeader xs={3}>{fm(`vns.${row}`)}</StyledRowHeader>
                {columns.map((column) =>
                  columnHasNumberField(column, row) ? (
                    <StyledRowValue key={`${row}${capitalize(column)}`} xs={2}>
                      {path(['settings', editIndex, row, 'default', column], d) ?? '-'}
                      {exists(path(['settings', editIndex, row, 'default', column], d)) ? ` ${defaultUnits[row]}` : ''}
                    </StyledRowValue>
                  ) : (
                    <Item xs={3} key={`${row}${capitalize(column)}`} />
                  ),
                )}
              </StyledRowContainer>
              {!(scheduledProgramming === 'yes' && row === 'outputCurrent') && separateNightSettings === 'yes' && (
                <StyledRowContainer key={`night${row}${index}`}>
                  <Item xs={3}>- {fm('vns.night')}</Item>
                  {columns.map((column) =>
                    columnHasNumberField(column, row, true) ? (
                      <StyledRowValue key={`${row}${capitalize(column)}Night`} xs={2}>
                        {path(['settings', editIndex, row, 'night', column], d) ?? '-'}
                        {exists(path(['settings', editIndex, row, 'night', column], d)) ? ` ${defaultUnits[row]}` : ''}
                      </StyledRowValue>
                    ) : (
                      <Item xs={3} key={`${row}${capitalize(column)}Night`} />
                    ),
                  )}
                </StyledRowContainer>
              )}
              {row === 'outputCurrent' && scheduledProgramming === 'yes' && (
                <React.Fragment>
                  {((path(['settings', editIndex, row, 'scheduled'], d) as []) ?? [{}]).map(
                    (_: TAnyObject, i: number) => (
                      <React.Fragment key={`${row}Step${i}`}>
                        <StyledRowContainer>
                          <Item xs={1}>
                            - {fm('vns.step')}
                            {` ${i + 1}`}
                          </Item>
                          <Item xs={2} />
                          {columns.map((column) =>
                            columnHasNumberField(column, row) ? (
                              <StyledRowValue key={`${row}${capitalize(column)}Scheduled`} xs={2}>
                                {path(['settings', editIndex, row, 'scheduled', i, column], d) ?? '-'}
                                {exists(path(['settings', editIndex, row, 'scheduled', i, column], d))
                                  ? ` ${defaultUnits[row]}`
                                  : ''}
                              </StyledRowValue>
                            ) : column === 'initialization' ? (
                              <Item xs={3} key={`${row}${capitalize(column)}Scheduled`}>
                                <Container>
                                  <StyledRowValue xs={7}>
                                    {path(['settings', editIndex, row, 'scheduled', i, 'initializationDate'], d)
                                      ? formatPartialDate(
                                          path(['settings', editIndex, row, 'scheduled', i, 'initializationDate'], d),
                                        )
                                      : '-'}
                                  </StyledRowValue>
                                  <StyledRowValue xs={5}>
                                    <div style={{ marginLeft: '-7rem' }}>
                                      {path(['settings', editIndex, row, 'scheduled', i, 'initializationTime'], d)
                                        ? formatTime(
                                            path(['settings', editIndex, row, 'scheduled', i, 'initializationTime'], d),
                                          )
                                        : ''}
                                    </div>
                                  </StyledRowValue>
                                </Container>
                              </Item>
                            ) : column ? (
                              <Item xs={2} key={`${row}${capitalize(column)}Scheduled`} />
                            ) : undefined,
                          )}
                        </StyledRowContainer>
                      </React.Fragment>
                    ),
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ),
        )}
      </React.Fragment>
    </div>
  );
};

export const Setting = ({
  setting,
  d,
  editIndex,
}: {
  setting: IVNSSetting;
  d: IVNS;
  editIndex: number;
}): JSX.Element => (
  <React.Fragment>
    <MakeHeaderBar date={setting.date} />
    <SettingView d={d} editIndex={editIndex} />
  </React.Fragment>
);

type VNSEvent = IVNSModeActivationEvent | IVNSAdverseEffectEvent | IVNSTechnicalIssueEvent | IVNSDiscontinuationEvent;

const Event = ({ event, keys }: { event: VNSEvent; keys: Array<string> }): JSX.Element => (
  <React.Fragment>
    <MakeHeaderBar date={event.date} />
    <ContainerEvent>
      {keys.map((key, ind) => {
        let value = (event as { [key: string]: any })?.[key];
        if (['complications', 'effects'].includes(key)) {
          const name = key === 'complications' ? 'postoperativeComplication' : 'adverseEffect';
          value =
            !value || value?.length < 1 ? undefined : (
              <React.Fragment>
                {value.map((item: string, i: number) => (
                  <div key={`${name}${i}`} style={{ fontWeight: 600 }}>
                    {fm(`vns.opts.${name}.${item}`)}
                  </div>
                ))}
              </React.Fragment>
            );
        }
        if (typeof value === 'string' && key !== 'additionalInformation') {
          value = fm(`vns.opts.${keyToHeader(key)}.${value}`);
        }
        if (isPartialDate(value)) {
          value = formatPartialDate(value);
        }
        return (
          <Item xs={3} key={ind}>
            <TitleValueItem title={`vns.${keyToHeader(key)}`} value={value ?? '-'} />
          </Item>
        );
      })}
    </ContainerEvent>
  </React.Fragment>
);

export const EventListing = ({ events, type }: { events: Array<VNSEvent>; type: string }): JSX.Element => {
  const keys = (event: VNSEvent): Array<string> =>
    type === 'discontinuation'
      ? keysByType(type).filter((key) =>
          (event as IVNSDiscontinuationEvent).type !== 'generatorAndPartOfLeadRemoved'
            ? key !== 'lengthOfRemainingLead'
            : key,
        )
      : keysByType(type);
  return (
    <React.Fragment>
      {Array.isArray(events) && events.length > 0 && (
        <React.Fragment>
          <Event event={events[0]} keys={keys(events[0])} />
          {events.length > 1 && (
            <CollapseElem
              localeIDs={{ showMessage: `vns.show${capitalize(type)}s`, hideMessage: `vns.hide${capitalize(type)}s` }}
              amount={events.length - 1}
            >
              <React.Fragment>
                {events.slice(1).map((event, index) => (
                  <Event key={index} event={event} keys={keys(event)} />
                ))}
              </React.Fragment>
            </CollapseElem>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
