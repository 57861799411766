import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import styled from 'styled-components';
import { Container, Item } from 'Components/Grid';

const StyledLabel = styled.div`
  font-weight: 600;
`;

const instruction = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '-2.5rem' }}>
      <Item xs={2}>
        <StyledLabel>Instructions to patient</StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic', paddingLeft: '1rem' }}>
        <FormattedMessage id={'motor.labels.nsaa.' + locale} />
      </Item>
    </Container>
  </React.Fragment>
);

const details = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '-2.5rem' }}>
      <Item xs={2}>
        <StyledLabel>Test detail</StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic', paddingLeft: '1rem' }}>
        <FormattedMessage id={'motor.labels.nsaa.' + locale} />
      </Item>
    </Container>
  </React.Fragment>
);

const comments = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '1rem' }}>
      <Item xs={2}>
        <StyledLabel>Comments</StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic', paddingLeft: '1rem' }}>
        <FormattedMessage id={'motor.labels.nsaa.' + locale} />
      </Item>
    </Container>
  </React.Fragment>
);

export const steps = [
  'stand',
  'walk',
  'standUpFromChair',
  'standOnOneLegRight',
  'standOnOneLegLeft',
  'climbBoxStepRight',
  'climbBoxStepLeft',
  'descendBoxStepRight',
  'descendBoxStepLeft',
  'getsToSitting',
  'riseFromFloor',
  'liftsHead',
  'standsOnHeels',
  'jump',
  'hopLegRight',
  'hopLegLeft',
  'run10M',
] as Array<string | { [key: string]: string[] }>;

export const getStepContent = (
  stepName: string,
): {
  opts: (number | string)[];
  optsLocale: string;
  info?: string;
  height?: number;
  instruction?: JSX.Element;
  instruction2?: JSX.Element;
  instruction3?: JSX.Element;
} => ({
  opts: [0, 1, 2],
  optsLocale: stepName,
  instruction: instruction(`${stepName}Instruction`),
  instruction2: details(`${stepName}Detail`),
  instruction3: comments(`${stepName}Comment`),
});
