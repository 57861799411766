import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import browserLanguage from '../../../../utility/browserLanguage';
import { getICD10Codes } from 'Utility/randomUtil';
import { subtractYears, nowPartialDate } from 'neuro-utils';

const isGender = (n: unknown): n is 'male' | 'female' => n === 'male' || n === 'female';

const ProfileForm = ({
  editing,
  formValues,
  setProfileFormValues,
  exitusFormValues,
  setExitusFormValues,
}: IOwnProps): JSX.Element => {
  const [ICD10SearchString, setICD10SearchString] = React.useState<string>('');
  const [ICD10List, setICD10List] = React.useState<ICD10Item[]>([]);

  // Onchange function for profile values
  const onChangeFormValues = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (name === 'address' || name === 'addressDetail' || name === 'zipCode' || name === 'city') {
      const address = { ...formValues?.address, [name]: value };
      setProfileFormValues({ ...formValues, address } as IPatientUI);
    } else {
      setProfileFormValues({ ...formValues, [name]: value } as IPatientUI);
    }
  };

  type TReasonCode = { icd10code: string; icd10description: string };
  const isReasonCode = (reason: TFieldValue): reason is TReasonCode => {
    if (!reason) return false;
    if (Array.isArray(reason)) return false;
    if (typeof reason === 'object' && 'icd10code' in reason && 'icd10description' in reason) return true;
    return false;
  };

  const onChangeExitusValues = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];

    if (name === 'reason' || name === 'reasonOther' || name === 'reasonDetail') {
      const reasonOfDeath = { ...exitusFormValues?.reasonOfDeath, [name]: value };
      setExitusFormValues({ ...exitusFormValues, reasonOfDeath } as TExitusUI);
    } else if (name === 'reasonCode') {
      const reasonOfDeath = {
        ...exitusFormValues?.reasonOfDeath,
        reasonCode:
          value && isReasonCode(value)
            ? {
                code: value.icd10code,
                codeSystem: 'icd10',
                icd10description: value.icd10description,
              }
            : null,
      };
      setExitusFormValues({ ...exitusFormValues, reasonOfDeath } as TExitusUI);
    } else {
      setExitusFormValues({ ...exitusFormValues, [name]: value } as TExitusUI);
    }
  };

  const updateICD10Codes = React.useCallback((): void => {
    const locale = browserLanguage; // 'fi' or 'se' or 'en' and so on
    if (ICD10SearchString && ICD10SearchString?.length > 2) {
      getICD10Codes(ICD10SearchString, locale)
        .then((data) => {
          setICD10List(data);
        })
        .catch(() => {
          return;
        });
    }
  }, [ICD10SearchString]);

  const onChangeICD = (e: React.SyntheticEvent<Element, Event>, d: string): void => {
    d ? setICD10SearchString(d) : d === '' ? setICD10SearchString('') : undefined;
  };

  React.useEffect(() => {
    // Load ICD-10 after typing has been stopped
    const timeout = setTimeout(() => {
      updateICD10Codes();
    }, 750);
    return (): any => clearTimeout(timeout);
  }, [ICD10SearchString, updateICD10Codes]);

  const reasonCode = React.useMemo(
    () => ({
      icd10code: exitusFormValues?.reasonOfDeath?.reasonCode?.code || '',
      icd10description: exitusFormValues?.reasonOfDeath?.reasonCode?.icd10description || '',
      icd11code: exitusFormValues?.reasonOfDeath?.reasonCode?.icd11code || null,
    }),
    [exitusFormValues?.reasonOfDeath?.reasonCode],
  );
  return (
    <React.Fragment>
      <FormRow title="profile.patientId">
        <div style={{ fontWeight: 600 }}>{formValues?.id ? formValues?.id.substring(0, 8) : null}</div>
      </FormRow>
      <FormRow title="profile.ssn">
        <div style={{ fontWeight: 600 }}>{formValues?.ssn}</div>
      </FormRow>
      <FormRow title="profile.lastNames">
        <InputHandler
          type="TextField"
          editing={!!editing}
          name="lastNames"
          formData={{ onChange: onChangeFormValues, document: { lastNames: formValues?.lastNames } }}
          placeholder="profile.lastNames"
        />
      </FormRow>
      <FormRow title="profile.firstNames">
        <InputHandler
          type="TextField"
          editing={!!editing}
          name="firstNames"
          formData={{ onChange: onChangeFormValues, document: { firstNames: formValues?.firstNames } }}
          placeholder="profile.firstNames"
        />
      </FormRow>
      <FormRow title="profile.gender">
        <InputHandler
          type="Radio"
          editing={!!editing}
          name="gender"
          formData={{ onChange: onChangeFormValues, document: { gender: formValues?.gender } }}
          options={['male', 'female']}
          optionFormatter={(gender: string | number): JSX.Element => (
            <FormattedMessage id={`general.${isGender(gender) ? gender : 'na'}`} />
          )}
        />
      </FormRow>
      <FormRow title="profile.dateOfBirth">
        <InputHandler
          type="PartialDate"
          name="dateOfBirth"
          editing={!!editing}
          formData={{
            onChange: onChangeFormValues,
            document: { dateOfBirth: formValues?.dateOfBirth },
          }}
          dateHook={{ dateHookFloor: subtractYears(nowPartialDate(), 100) }}
          isNotCancellable={true}
        />
      </FormRow>
      <FormSection header="profile.addressInfo">
        <FormRow title="profile.addressStreet">
          <InputHandler
            type="TextField"
            editing={!!editing}
            name="address"
            formData={{ onChange: onChangeFormValues, document: { address: formValues?.address?.address } }}
            placeholder="profile.addressStreet"
          />
          <div style={{ marginTop: '2rem' }}>
            <InputHandler
              type="TextField"
              editing={!!editing}
              name="addressDetail"
              formData={{
                onChange: onChangeFormValues,
                document: { addressDetail: formValues?.address?.addressDetail },
              }}
              placeholder="profile.addressDetail"
            />
          </div>
        </FormRow>
        <FormRow title="profile.addressZip">
          <InputHandler
            type="TextField"
            editing={!!editing}
            name="zipCode"
            formData={{ onChange: onChangeFormValues, document: { zipCode: formValues?.address?.zipCode } }}
            placeholder="profile.addressZip"
            maxLength={5}
            format={/^[\W\d]*$/}
          />
        </FormRow>
        <FormRow title="profile.addressCity">
          <InputHandler
            type="TextField"
            editing={!!editing}
            name="city"
            formData={{ onChange: onChangeFormValues, document: { city: formValues?.address?.city } }}
            placeholder="profile.addressCity"
          />
        </FormRow>
        <FormRow title="profile.placeOfBirth">
          <InputHandler
            type="TextField"
            editing={!!editing}
            name="placeOfBirth"
            formData={{ onChange: onChangeFormValues, document: { placeOfBirth: formValues?.placeOfBirth } }}
            placeholder="profile.placeOfBirth"
          />
        </FormRow>
        <FormRow title="profile.countryOfBirth">
          <InputHandler
            type="TextField"
            editing={!!editing}
            name="countryOfBirth"
            formData={{
              onChange: onChangeFormValues,
              document: { countryOfBirth: formValues?.countryOfBirth },
            }}
            placeholder="profile.countryOfBirth"
          />
        </FormRow>
      </FormSection>
      <FormSection header="profile.exitus">
        {formValues?.isDead || exitusFormValues?.isDead || editing ? (
          <>
            <FormRow title="profile.exitus">
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="isDead"
                preset="yesno"
                formData={{ onChange: onChangeExitusValues, document: { isDead: exitusFormValues?.isDead } }}
              />
            </FormRow>
            <FormRow title="profile.dateOfDeath" condition={exitusFormValues?.isDead === true}>
              <InputHandler
                type="PartialDate"
                editing={!!editing}
                name="dateOfDeath"
                dateDefault="now"
                formData={{ onChange: onChangeExitusValues, document: { dateOfDeath: exitusFormValues?.dateOfDeath } }}
                isNotCancellable={true}
              />
            </FormRow>
            <FormRow title="profile.causeOfDeath" condition={exitusFormValues?.isDead === true}>
              <InputHandler
                type="Select"
                editing={!!editing}
                name="reason"
                formData={{
                  onChange: onChangeExitusValues,
                  document: { reason: exitusFormValues?.reasonOfDeath?.reason },
                }}
                placeholder="profile.causeOfDeath"
                options={['ICD-10', 'other']}
                optionFormatter={(n: string | number): JSX.Element => <FormattedMessage id={'profile.opts.' + n} />}
              />
            </FormRow>
            <FormRow
              title=""
              condition={exitusFormValues?.isDead === true && exitusFormValues?.reasonOfDeath?.reason === 'ICD-10'}
            >
              {!editing && exitusFormValues?.reasonOfDeath?.reasonCode?.code == null ? undefined : (
                <InputHandler
                  type="AutoCompleteSelect"
                  name="reasonCode"
                  editing={!!editing}
                  formData={{
                    onChange: onChangeExitusValues,
                    document: {
                      reasonCode: reasonCode,
                    },
                  }}
                  options={ICD10List as any}
                  width={32}
                  placeholder={'profile.causeOfDeath'}
                  labelID={'icd10description'}
                  groupID={'icd10code'}
                  onInputChange={onChangeICD}
                  getOptionLabel={(o: ICD10Item): string =>
                    `${o.icd10code}${o.icd10description ? ' - ' + o.icd10description : ''}`
                  }
                />
              )}
            </FormRow>
            <FormRow
              title="profile.deathOther"
              condition={exitusFormValues?.isDead === true && exitusFormValues?.reasonOfDeath?.reason === 'other'}
            >
              <InputHandler
                type="TextField"
                editing={!!editing}
                name="reasonOther"
                formData={{
                  onChange: onChangeExitusValues,
                  document: { reasonOther: exitusFormValues?.reasonOfDeath?.reasonOther },
                }}
                placeholder="profile.deathOther"
                width={30.4}
              />
            </FormRow>
            <FormRow title="profile.placeOfDeath" condition={exitusFormValues?.isDead === true}>
              <InputHandler
                type="Radio"
                name="placeOfDeath"
                editing={!!editing}
                formData={{
                  onChange: onChangeExitusValues,
                  document: { placeOfDeath: exitusFormValues?.placeOfDeath },
                }}
                options={['centralOrUniHospitalWard', 'controlDepartment', 'intensiveCareUnit', 'other']}
                optionFormatter={(o: string | number): JSX.Element => (
                  <FormattedMessage id={`profile.opts.placeOfDeath.${o}`} />
                )}
              />
            </FormRow>
            <FormRow title="profile.inpatientHospitalDays" condition={exitusFormValues?.isDead === true}>
              <InputHandler
                type="NumberField"
                name="inpatientHospitalDays"
                editing={!!editing}
                formData={{
                  onChange: onChangeExitusValues,
                  document: { inpatientHospitalDays: exitusFormValues?.inpatientHospitalDays },
                }}
                placeholder="general.count"
              />
            </FormRow>
            <FormRow title="profile.deathDetail" condition={exitusFormValues?.isDead === true}>
              <InputHandler
                type="TextArea"
                editing={!!editing}
                name="reasonDetail"
                formData={{
                  onChange: onChangeExitusValues,
                  document: { reasonDetail: exitusFormValues?.reasonOfDeath?.reasonDetail },
                }}
                placeholder="profile.deathDetail"
                width={30.4}
              />
            </FormRow>
          </>
        ) : undefined}
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: boolean;
  formValues?: Partial<IPatientUI>;
  setProfileFormValues: (data: IPatientUI) => void;
  exitusFormValues?: TExitusUI;
  setExitusFormValues: (data: TExitusUI) => void;
}

export default ProfileForm;
