import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistorySection from '../../../../components/HistorySection';

import { formatPartialDate, formatTime } from 'neuro-utils';
import { getPatientAgeInMonthsWithOneDecimal } from '../../../../utility/patientInfo';
import { chopINTENDScore, showPatientAgeInMonthsOnForm } from '../../utils';
import InfoPopper from '../../../../components/InfoPopper';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { Item } from '../../../../components/Grid';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const CHOPINTEND = ({ documents, startEdit, view }: IOwnProps): JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'motor.chopINTEND'} />}
      newButton={
        <DocumentCreationButton
          name="chop_intend"
          text={'motor.newChopINTEND'}
          onClick={startEdit({}, 'chop_intend')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id={'general.date'} />
            </Item>
            <Item xs={2}>
              <FormattedMessage id="general.timeOfDay" />
            </Item>
            <Item xs={2}>
              <FormattedMessage id="motor.hoursSinceLastFeedingShort" />
            </Item>
            <Item xs={2}>
              <FormattedMessage id={'motor.chopINTENDScore'} />
            </Item>
          </>
        }
        contentFormat={(d: ICHOPINTEND): JSX.Element => (
          <>
            <Item xs={2} id={'ciDate'}>
              {showPatientAgeInMonthsOnForm(d.date) ? (
                <FormattedMessage
                  id="motor.dateAndMonth"
                  values={{
                    date: formatPartialDate(d.date),
                    months: getPatientAgeInMonthsWithOneDecimal(d.date),
                  }}
                />
              ) : (
                formatPartialDate(d.date)
              )}
            </Item>
            <Item xs={2}>{d.time ? formatTime(d.time) : '-'}</Item>
            <Item xs={2}>{d.hoursSinceLastFeeding ?? '-'}</Item>
            <Item xs={2} id={'ciScore'}>
              {d?.manualScore ? (
                d.manualScore
              ) : chopINTENDScore(d) === 'notCounted' ? (
                <InfoPopper text="general.notFilled" color="primary" />
              ) : (
                chopINTENDScore(d)
              )}
            </Item>
          </>
        )}
        makeControls
        historyRowControls={(d: IControlProps) => (
          <div id={'ciHistoryControl'}>
            <HistoryRowControls document={d} startEdit={startEdit} view={view} />
          </div>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: ICHOPINTEND[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default CHOPINTEND;
