import * as React from 'react';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import QuestionAnswerPair from 'Components/_NewElements/QuestionAnswerPair';
import { ICompactVaultUser } from 'neuro-data-structures';
import { sleepApneaFirstVisitInquiry, ISleepApneaFirstVisitInquiry, isLocaleKey } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import { surveyCreator, surveyModifyer } from '../../HistoryRowData/SurveysHistory';
import TableContent from './components/TableContent';
import OptionChooser from 'Components/OptionChooser';

const selectFields: Array<keyof ISleepApneaFirstVisitInquiry> = ['workDaysSleepHours', 'daysOffSleepHours'];
const selectOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const allRadioFields: Array<keyof ISleepApneaFirstVisitInquiry> = [
  'daytimeSleepiness',
  'morningHeadaches',
  'tirednessOrNearMissWhileDriving',
  'involuntarySleepAttacks',
  'increasedIrritability',
  'memoryProblems',
  'cough',
  'heartburn',
  'snoring',
  'witnessedNocturnalApneas',
  'awakeningDueToSuffocationSensation',
  'nocturnalSweating',
  'nocturia',
  'restlessSleep',
  'dryMouthDuringNight',
  'erectileDysfunction',
  'urgeToMoveLegs',
  'urgeToMoveLegsDuringInactivity',
  'urgeToMoveLegsRelievedByMovement',
  'urgeToMoveLegsMainlyEveningNight',
];
const radioOptions = ['often', 'sometimes', 'never'];

const optionChooserFields: Array<keyof ISleepApneaFirstVisitInquiry> = ['daytimeSleepinessSeverity'];
const optionChooserOptions = [1, 2, 3];

const textAreaFields: Array<keyof ISleepApneaFirstVisitInquiry> = ['dailyLifeInterference'];

export const SleepApneaFirstVisitInquiry = ({ patientMyid, users }: IOwnProps): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing, setViewingObj, setEditingObj } = myServContext;

  const sleepApneaFirstVisitInquiryData = (editing?.data || viewing?.data) as ISleepApneaFirstVisitInquiry &
    IControlProps;
  const sleepApneaFirstVisitInquiryLocales = sleepApneaFirstVisitInquiry.locales;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...sleepApneaFirstVisitInquiryData,
      [field]: value,
    });
  };

  const radioFields = allRadioFields.filter(
    (f) =>
      ![
        'urgeToMoveLegsDuringInactivity',
        'urgeToMoveLegsRelievedByMovement',
        'urgeToMoveLegsMainlyEveningNight',
      ].includes(f) ||
      sleepApneaFirstVisitInquiryData?.urgeToMoveLegs === 'often' ||
      sleepApneaFirstVisitInquiryData?.urgeToMoveLegs === 'sometimes',
  );

  React.useEffect(() => {
    if (!!editing && !viewing && sleepApneaFirstVisitInquiryData?.urgeToMoveLegs === 'never') {
      setEditingData?.({
        ...sleepApneaFirstVisitInquiryData,
        urgeToMoveLegsDuringInactivity: undefined,
        urgeToMoveLegsRelievedByMovement: undefined,
        urgeToMoveLegsMainlyEveningNight: undefined,
      });
    }
  }, [sleepApneaFirstVisitInquiryData?.urgeToMoveLegs]);

  return (
    <React.Fragment>
      {viewing ? (
        <div style={{ marginBottom: '4rem' }}>
          <QuestionAnswerPair
            question={fm('myService.reporter')}
            answer={surveyCreator(sleepApneaFirstVisitInquiryData, patientMyid, users, fm)}
          />
          {surveyModifyer(sleepApneaFirstVisitInquiryData, patientMyid, users, fm) && (
            <QuestionAnswerPair
              question={fm('myService.edited')}
              answer={surveyModifyer(sleepApneaFirstVisitInquiryData, patientMyid, users, fm)}
            />
          )}
        </div>
      ) : (
        <div style={{ width: '80%', color: colors.tertiaryText, marginBottom: '2rem' }}>
          <p>{sleepApneaFirstVisitInquiryLocales[useLocale]['surveyInfo']}</p>
          <p>{sleepApneaFirstVisitInquiryLocales[useLocale]['surveyFillingInfo']}</p>
        </div>
      )}
      <BlockWrapper
        title={viewing ? formatPartialDate(sleepApneaFirstVisitInquiryData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({
                      type: 'sleepApneaFirstVisitInquiry',
                      data: sleepApneaFirstVisitInquiryData,
                    });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title="general.date" headerWidth={3.4}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: sleepApneaFirstVisitInquiryData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <TableContent width={90}>
          {
            [...selectFields, ...radioFields, ...optionChooserFields, ...textAreaFields].map((field) => {
              const inputType = selectFields.includes(field)
                ? 'Select'
                : radioFields.includes(field)
                  ? 'Radio'
                  : optionChooserFields.includes(field)
                    ? 'OptionChooser'
                    : textAreaFields.includes(field)
                      ? 'TextArea'
                      : undefined;

              if (!inputType) return {};

              return {
                style: { paddingBottom: viewing ? '0.5rem' : 0 },
                title: {
                  content: sleepApneaFirstVisitInquiryLocales[useLocale][`${field}.title`],
                  description: sleepApneaFirstVisitInquiryLocales[useLocale][`${field}.description`],
                  style: { paddingLeft: '0.5rem', paddingRight: '1rem' },
                },
                content: {
                  content:
                    inputType === 'OptionChooser' ? (
                      viewing ? (
                        <div style={{ fontWeight: 600 }}>
                          {sleepApneaFirstVisitInquiryLocales[useLocale][
                            `opts.${field}.${sleepApneaFirstVisitInquiryData?.[field]}`
                          ] ?? '-'}
                        </div>
                      ) : (
                        <OptionChooser
                          name={field}
                          formData={{
                            document: { [field]: sleepApneaFirstVisitInquiryData?.[field] },
                            onChange: onChangeSurveyForm,
                          }}
                          opts={optionChooserOptions}
                          optionFormatter={(name: string | number) =>
                            typeof name === 'number' ? (
                              <span>{sleepApneaFirstVisitInquiryLocales[useLocale][`opts.${field}.${name}`]}</span>
                            ) : (
                              <span />
                            )
                          }
                          viewing={!!viewing}
                          horizontal
                          minHeight={22}
                        />
                      )
                    ) : (
                      <InputHandler
                        type={inputType}
                        name={field}
                        editing={!!editing}
                        formData={{
                          onChange: onChangeSurveyForm,
                          document: { [field]: sleepApneaFirstVisitInquiryData?.[field] },
                        }}
                        options={
                          {
                            Select: selectOptions,
                            Radio: radioOptions,
                            TextArea: undefined,
                          }[inputType]
                        }
                        optionFormatter={
                          {
                            Select: (id: string | number) => fm('general.hours', { N: id }),
                            Radio: (id: string | number) =>
                              sleepApneaFirstVisitInquiryLocales[useLocale][`opts.oftenSometimesNever.${id}`],
                            TextArea: undefined,
                          }[inputType]
                        }
                        placeholder={
                          inputType === 'Select'
                            ? sleepApneaFirstVisitInquiryLocales[useLocale][`${field}.title`]
                            : undefined
                        }
                        disablePlaceholderFormatting
                      />
                    ),
                  xs: 5.5,
                  style: {
                    paddingTop: '0.3rem',
                    paddingLeft: '0.5rem',
                    paddingBottom: textAreaFields.includes(field) ? undefined : '1.5rem',
                    overflow: 'visible',
                  },
                },
              };
            }) as []
          }
        </TableContent>
      </BlockWrapper>
    </React.Fragment>
  );
};

interface IOwnProps {
  patientMyid: string;
  users: ICompactVaultUser[];
}
