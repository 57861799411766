import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import colors from '../../config/theme/colors';
import { styleDocument } from '../../config/theme/componentTheme';

const StyledDiv = styled.div`
  margin: 0 -${styleDocument.padding};
  padding: 3rem ${styleDocument.padding};
  border-top: 1px solid #c8c8c8;
`;

const StyledHeader = styled.div`
  color: ${colors.primary};
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const FormSection = ({
  header,
  condition = true,
  headerRef = undefined,
  children,
  style,
}: IOwnProps): JSX.Element | null =>
  condition ? (
    <StyledDiv ref={headerRef} style={style}>
      {header && (
        <StyledHeader>
          <FormattedMessage id={header} />
        </StyledHeader>
      )}
      {children}
    </StyledDiv>
  ) : null;

interface IOwnProps {
  header?: string;
  children: JSX.Element | Array<JSX.Element | undefined | false> | string | undefined | false;
  condition?: boolean;
  headerRef?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
}

export default FormSection;
