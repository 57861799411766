import { Paper, Collapse, styled } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import * as React from 'react';

import colors from '../../config/theme/colors';
import { Container, Item } from '../Grid';

interface IStyledNumberProps {
  longestValueLength?: number;
  horizontal?: boolean;
  minHeight?: number;
  viewing?: string;
  selected?: boolean;
}

const StyledNumber = styled('div')`
  width: ${(props: IStyledNumberProps): string =>
    props.longestValueLength && props.longestValueLength > 3 ? `${props.longestValueLength}` : '3'}rem;
  height: 3rem;
  background-color: white;
  text-align: center;
  line-height: ${(props): string => (props.horizontal ? '2.6rem' : '2.8rem')};
  border: solid 0.5px #cbcbcb;
  border-radius: 5rem;
  position: relative;
  z-index: 1;
`;

const StyledPaper = styled(Paper)`
  position: ${(props: IStyledNumberProps): string | undefined => (props.horizontal ? 'relative' : undefined)};
  bottom: ${(props): string | undefined => (props.horizontal ? '2.5rem' : undefined)};
  margin: ${(props): string =>
    props.horizontal
      ? '0 1rem 0 1rem'
      : props.longestValueLength && props.longestValueLength > 3
        ? '0 0 0 -3rem'
        : '0 0 0 -1.5rem'};
  padding: ${(props): string | undefined => (props.horizontal ? '3rem 1rem 2rem 3rem' : '1rem 1rem 1rem 3rem')};
  height: fit-content;
  min-height: ${(props): string | undefined =>
    props.horizontal && props.minHeight ? `${props.minHeight}rem` : undefined};
  cursor: ${(props): string => (props.viewing && props.viewing === 'true' ? '' : 'pointer')};
  background-color: ${(props): string => (props.selected ? colors.primary : colors.white)} !important;
  color: ${(props): string => (props.selected ? colors.white : colors.primaryText)} !important;
  &:hover {
    background-color: ${(props): string =>
      props.selected
        ? colors.primary
        : props.viewing && props.viewing === 'true'
          ? colors.white
          : colors.lightestGray} !important;
  }
`;

const StyledInstructions = styled(Paper)`
  padding: 1rem 1rem 1rem 3rem;
  min-height: 5rem;
  background-color: ${(props: IStyledNumberProps): string =>
    props.selected ? colors.primary : colors.white} !important;
  color: ${(props): string => (props.selected ? colors.white : colors.primaryText)} !important;
`;

const OptionItem = ({
  qNumber,
  text,
  onClick,
  selected,
  viewing,
  index,
  instruction,
  instructionsOpenIndex,
  handleInstructionsOpenClick,
  secondary,
  allInstructionsOpenAtOnce,
  longestValueLength,
  horizontal,
  minHeight,
  qNumberHidden,
}: IOptionItemProps): JSX.Element => (
  <div style={{ margin: '1rem 0' }}>
    <Container
      justifyContent={horizontal ? 'center' : 'flex-end'}
      alignItems={horizontal ? undefined : 'center'}
      style={!horizontal && secondary ? { marginLeft: horizontal ? undefined : '6rem', width: 'auto' } : {}}
    >
      {!qNumberHidden && (
        <Item>
          <StyledNumber longestValueLength={longestValueLength}>{qNumber}</StyledNumber>
        </Item>
      )}
      <Item xs={horizontal ? undefined : true}>
        <StyledPaper
          elevation={3}
          onClick={onClick}
          selected={selected}
          viewing={viewing ? 'true' : 'false'}
          longestValueLength={longestValueLength}
          horizontal={horizontal}
          minHeight={minHeight}
        >
          <Container
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{ minHeight: secondary && secondary === true ? '2.5rem' : '5rem' }}
          >
            <Item xs={11}>{text}</Item>
            {instruction && handleInstructionsOpenClick && !allInstructionsOpenAtOnce && (
              <Item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <HelpIcon
                  style={{ cursor: 'default', color: selected ? 'white' : colors.primary }}
                  onClick={(e): void => {
                    handleInstructionsOpenClick(index);
                    e.stopPropagation();
                  }}
                />
              </Item>
            )}
          </Container>
        </StyledPaper>
      </Item>
    </Container>
    {instruction && (
      <Container justifyContent="flex-end" alignItems="center" style={{ marginLeft: '6rem' }}>
        <Collapse in={instructionsOpenIndex === index || allInstructionsOpenAtOnce}>
          <Item xs={true} style={{ marginTop: '0.5rem' }}>
            <StyledInstructions elevation={2}>
              <Container alignItems="center" style={{ height: '100%' }}>
                <Item>{instruction}</Item>
              </Container>
            </StyledInstructions>
          </Item>
        </Collapse>
      </Container>
    )}
  </div>
);

interface IOptionItemProps {
  qNumber: number;
  text: string | JSX.Element;
  onClick?: () => void;
  selected?: boolean;
  viewing?: boolean;
  index: number;
  instruction?: JSX.Element | string | undefined;
  instructionsOpenIndex?: number | undefined;
  handleInstructionsOpenClick?: (state: number | undefined) => void;
  secondary: boolean;
  allInstructionsOpenAtOnce: boolean;
  longestValueLength: number;
  horizontal?: boolean;
  minHeight?: number;
  qNumberHidden?: boolean;
}

export default OptionItem;
