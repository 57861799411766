import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import { sortPartialDate } from 'neuro-utils';
import { equals } from 'ramda';
import * as React from 'react';
import browserLanguage from 'Utility/browserLanguage';
import colors from '../../../../../../config/theme/colors';
import { getICD10Codes } from 'Utility/randomUtil';

const IndicationForTreatmentForm = ({
  documents,
  editing,
  formData,
  fm,
  consumeTreatmentId,
}: IFormContext & {
  consumeTreatmentId: () => string | null;
}): JSX.Element => {
  const [ICD10List, setICD10List] = React.useState<Array<ICD10Item>>([]);
  const [ICD10SearchString, setICD10SearchString] = React.useState<string>('');
  const documentsOnMount = React.useRef(documents || null);
  const treatmentId = consumeTreatmentId() || formData.document?.treatmentPeriodId;

  const updateICD10Codes = React.useCallback((): void => {
    const locale = browserLanguage; // 'fi' or 'se' or 'en'
    if (ICD10SearchString && ICD10SearchString?.length > 2) {
      getICD10Codes(ICD10SearchString, locale)
        .then((data) => {
          setICD10List(data);
        })
        .catch(() => {
          return;
        });
    }
  }, [ICD10SearchString]);

  React.useEffect(() => {
    if (treatmentId) {
      // If treatment period id is available (consumed), set the treatment id
      if (!formData.document?.treatmentPeriodId) {
        formData.onChange?.({ treatmentPeriodId: treatmentId });
      }
    } else {
      const nearestId =
        Array.isArray(documents) &&
        documents
          .slice()
          .sort((d1, d2) => sortPartialDate(d2.date, d1.date))
          .find((d) => d._type === 'ninmtTreatmentPeriod' && sortPartialDate(formData.document.date, d.date) >= 0)?._id;
      formData.onChange?.({ treatmentPeriodId: nearestId ?? '' });
    }
  }, [treatmentId, formData]);

  const treatmentPeriodsSorted = documents
    ?.filter((d) => d._type === 'ninmtTreatmentPeriod')
    .sort((a, b) => sortPartialDate(a.date, b.date));

  const currentTreatmentNumber = (treatmentPeriodsSorted?.findIndex((d) => d._id === treatmentId) ?? 0) + 1;

  const onChangeICD = (e: React.SyntheticEvent<Element, Event>, d: string): void => {
    d && d !== 'undefined' ? setICD10SearchString(d) : d === '' ? setICD10SearchString('') : undefined;
  };

  React.useEffect(() => {
    // LOad ICD-10 after typing has been stopped
    const timeout = setTimeout(() => {
      updateICD10Codes();
    }, 750);
    if (equals(documents, documentsOnMount.current)) {
      return;
    }
    return (): any => clearTimeout(timeout);
  }, [documents, updateICD10Codes]);

  React.useEffect(() => {
    if (formData.document.indication && !formData.document.indication.includes('pain')) {
      setICD10SearchString('');
    }
  }, [formData.document.indication, setICD10SearchString]);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="diagnosis.ninmt.treatment">{`${fm('diagnosis.ninmt.title')} ${
          currentTreatmentNumber ?? '-'
        }`}</FormRow>
        <FormRow title="diagnosis.ninmt.indicationForTreatment.title">
          <div style={{ fontWeight: 600, marginBottom: '1rem' }}>
            {fm('diagnosis.ninmt.indicationForTreatment.somatic')}
          </div>
          <InputHandler
            type="Checkbox"
            name="indication"
            formData={formData}
            editing={!!editing}
            options={['pain', 'tinnitus', 'movementDisorders', 'strokeRehabilitation', 'treatmentResistantEpilepsy']}
            optionFormatter={(name: string | number): string =>
              fm(`diagnosis.ninmt.indicationForTreatment.opts.${name}`)
            }
            dependentFieldsList={
              !(formData.document.indication && formData.document.indication.includes('pain'))
                ? () => ['painSpecification', 'neuropathicPainFinding', 'neuropathicPainFindingCertainty', 'painICD10']
                : undefined
            }
            optionSpecificElements={[
              {
                pain: (
                  <>
                    <div style={{ marginBottom: '1rem' }}>
                      <p style={{ color: colors.tertiaryText }}>{fm('comorbidity.ICD-10')}</p>
                      <InputHandler
                        type="AutoCompleteSelect"
                        name="painICD10"
                        editing={!!editing}
                        formData={formData}
                        options={ICD10List as any}
                        placeholder={'comorbidity.chooseClassification2'}
                        labelID={'icd10description'}
                        groupID={'icd10code'}
                        onInputChange={onChangeICD}
                        getOptionLabel={(o: ICD10Item): string =>
                          `${o.icd10code}${o.icd10description ? ' - ' + o.icd10description : ''}`
                        }
                        width={30}
                      />
                    </div>
                    <InputHandler
                      type="Radio"
                      name="painSpecification"
                      options={['CRPS', 'fibromyalgia', 'neuropathicPain', 'headache', 'otherChronicPain']}
                      optionFormatter={(name: string | number): string =>
                        fm(`diagnosis.ninmt.indicationForTreatment.opts.${name}`)
                      }
                      formData={formData}
                      editing={!!editing}
                      dependentFieldsList={() => ['neuropathicPainFinding', 'neuropathicPainFindingCertainty']}
                      optionSpecificElements={[
                        {
                          neuropathicPain: (
                            <>
                              <p style={{ color: colors.tertiaryText }}>
                                {fm('diagnosis.ninmt.indicationForTreatment.neuropathicFinding')}
                              </p>
                              <InputHandler
                                type="Checkbox"
                                name="neuropathicPainFinding"
                                options={[
                                  'abnormalQST',
                                  'abnormalENMG',
                                  'abnormalSkinBiopsy',
                                  'abnormalEvokedResponse',
                                ]}
                                optionFormatter={(name: string | number): string =>
                                  fm(`diagnosis.ninmt.indicationForTreatment.opts.${name}`)
                                }
                                formData={formData}
                                editing={!!editing}
                              />
                              <p style={{ color: colors.tertiaryText }}>
                                {fm('diagnosis.ninmt.indicationForTreatment.neuropathicPainDiagnosisCertainty')}
                              </p>
                              <InputHandler
                                type="Radio"
                                name="neuropathicPainFindingCertainty"
                                options={[
                                  'certainNeuropathicPain',
                                  'probableNeuropathicPain',
                                  'possibleNeuropathicPain',
                                ]}
                                optionFormatter={(name: string | number): string =>
                                  fm(`diagnosis.ninmt.indicationForTreatment.opts.${name}`)
                                }
                                formData={formData}
                                editing={!!editing}
                              />
                            </>
                          ),
                        },
                      ]}
                    />
                  </>
                ),
              },
            ]}
          />
        </FormRow>
        <FormRow>
          <div style={{ fontWeight: 600, marginBottom: '1rem' }}>
            {fm('diagnosis.ninmt.indicationForTreatment.psychiatric')}
          </div>
          <InputHandler
            type="Checkbox"
            name="indication"
            formData={formData}
            editing={!!editing}
            options={[
              'depression',
              'negativeSymptomsOfSchizophrenia',
              'auditoryHallucinationsOfSchizophrenia',
              'tourette',
              'tics',
              'obsessiveCompulsiveDisorder',
              'postTraumaticStress',
              'anxiety',
              'dissosiation',
            ]}
            optionFormatter={(name: string | number): string =>
              fm(`diagnosis.ninmt.indicationForTreatment.opts.${name}`)
            }
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(IndicationForTreatmentForm);
