import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import colors from '../../../../config/theme/colors';

export const StyledCancel = styled.a`
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
  color: ${colors.primary};
  text-transform: uppercase;
  margin-right: 3rem;
`;

/**
 * COmponent for creating a symbolDigitPair
 * @param figure the figure that should be drawn
 * @param digit the digit to be shown in the pair
 * @param color color of the symboldigitpair: white, green, red or grayish blue
 */
export const SymbolDigitPair = ({ figure, digit, color }: ISymbolDigitPairProps): JSX.Element => (
  <Container
    style={{
      backgroundColor:
        color === 'blue' ? '#9cd0dc' : color === 'red' ? '#eb714c' : color === 'green' ? '#70ce78' : 'white',
    }}
  >
    <Item xs={12} style={{ border: '1px solid', padding: '0.5rem 0.5rem 0' }}>
      <img src={figure} />
    </Item>
    <Item xs={12} style={{ border: '1px solid' }}>
      <span style={{ fontWeight: 600, display: 'flex', justifyContent: 'center', fontSize: '5rem' }}>
        {digit ? digit : null}
      </span>
    </Item>
  </Container>
);

/**
 * COmponent for rendering the instructions page of sdmt test
 */
export const SDMTDialogInstructionPage = (): JSX.Element => {
  const instructions = [
    {
      text: 'cognition.sdmt.instructions',
      fontWeight: 100,
      fontSize: '2.2rem',
    },
    {
      text: 'cognition.sdmt.sdmtInstruction1',
      fontWeight: 100,
      fontSize: '1.6rem',
    },
    {
      text: 'cognition.sdmt.sdmtInstruction2',
      fontWeight: 600,
      fontSize: '1.6rem',
    },
    {
      text: 'cognition.sdmt.sdmtInstruction3',
      fontWeight: 600,
      fontSize: '1.6rem',
    },
  ];
  return (
    <>
      {instructions.map((d: { text: string; fontWeight: number; fontSize: string }) => (
        <Container key={d.text} style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
          <Item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center', fontWeight: d.fontWeight, fontSize: d.fontSize }}
          >
            <FormattedMessage id={d.text} />
          </Item>
        </Container>
      ))}
    </>
  );
};

/**
 * COmponent for rendering results page of sdmt test: contains just the test has ended texts and so on
 * @param correctAnswers number of correctly answered questions
 * @param allAnswers number of all answered questions
 */
export const SDMTResultsPage = ({ correctAnswers, allAnswers }: IResultsPageProps): JSX.Element => {
  return (
    <React.Fragment>
      <Container>
        <Item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '2rem',
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
        >
          <FormattedMessage id="cognition.sdmt.testHasEnded" />
        </Item>
      </Container>
      <Container>
        <Item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '2.2rem',
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
        >
          <FormattedMessage id="cognition.sdmt.testHasBeenCompleted" />
        </Item>
      </Container>
      <Container>
        <Item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '1.6rem',
            fontWeight: 600,
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
        >
          <FormattedMessage id="cognition.sdmt.testResults" values={{ all: allAnswers, correct: correctAnswers }} />
        </Item>
      </Container>
    </React.Fragment>
  );
};

interface ISymbolDigitPairProps {
  figure: any;
  digit: number;
  color: 'white' | 'green' | 'red' | 'blue';
}

interface IResultsPageProps {
  correctAnswers: number;
  allAnswers: number;
}
