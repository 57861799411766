import { Paper } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../ContentWrapper';

import { styleDocument } from '../../config/theme/componentTheme';

const StyledPaper = styled(({ ...other }) => <Paper {...other} />)`
  min-height: ${styleDocument.minHeight};
  font-size: ${styleDocument.fontSize};
`;

const StyledInnerArea = styled.div`
  padding: ${styleDocument.padding};
`;

const DocumentWrapper = ({ children }: IOwnProps): JSX.Element => (
  <ContentWrapper maxWidth="md">
    <StyledPaper elevation={1} square={true}>
      <StyledInnerArea>{children}</StyledInnerArea>
    </StyledPaper>
    <div style={{ minHeight: '6.5rem' }} />
  </ContentWrapper>
);

interface IOwnProps {
  children: JSX.Element | Array<JSX.Element[] | JSX.Element | null | undefined> | string | null;
}

export default DocumentWrapper;
