import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledLabel = styled.div`
  font-weight: 600;
`;

const instruction = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '2rem' }}>
      <Item xs={2}>
        <StyledLabel>Instructions</StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic', paddingLeft: '1rem' }}>
        <FormattedMessage id={'motorFunctionHuntington.labels.uhdrsMotorRatingScale.' + locale} />
      </Item>
    </Container>
  </React.Fragment>
);

export const steps = [
  { ocularPursuit: ['ocularPursuitHorizontal', 'ocularPursuitVertical'] },
  { saccadeInitiation: ['saccadeInitiationHorizontal', 'saccadeInitiationVertical'] },
  { saccadeVelocity: ['saccadeVelocityHorizontal', 'saccadeVelocityVertical'] },
  'dysarthria',
  'tongueProtrusion',
  { fingerTaps: ['fingerTapsRight', 'fingerTapsLeft'] },
  { pronateSupinateHands: ['pronateSupinateHandsRight', 'pronateSupinateHandsLeft'] },
  'luria',
  { rigidityArms: ['rigidityArmsRight', 'rigidityArmsLeft'] },
  'bradykinesiaBody',
  {
    maximalDystonia: [
      'maximalDystoniaTrunk',
      'maximalDystoniaRue',
      'maximalDystoniaLue',
      'maximalDystoniaRle',
      'maximalDystoniaLle',
    ],
  },
  {
    maximalChorea: [
      'maximalChoreaFace',
      'maximalChoreaBol',
      'maximalChoreaTrunk',
      'maximalChoreaRue',
      'maximalChoreaLue',
      'maximalChoreaRle',
      'maximalChoreaLle',
    ],
  },
  'gait',
  'tandemWalking',
  'retropulsionPullTest',
] as Array<string | { [key: string]: string[] }>;

const fieldsWithNoInstructions: string[] = ['dysarthria', 'tongueProtrusion'];

export const getStepContent = (
  stepName: string,
): {
  opts: (number | string)[];
  optsLocale: string;
  info?: string;
  instruction?: JSX.Element;
} => ({
  opts: [0, 1, 2, 3, 4],
  optsLocale: stepName,
  instruction: !fieldsWithNoInstructions.includes(stepName) ? instruction(`${stepName}Instruction`) : undefined,
});
