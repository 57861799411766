import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, Item } from '../../../../../components/Grid';
import HistoryRowBasicItem from '../../../../../components/HistoryRowBasicItem';

const endedTreatment = (d: IVitaminD | IPlasmaferesis): boolean => 'hasEnded' in d && d.hasEnded?.[0] === true;

const End = ({ d }: { d: IMedicationTreatmentsEndings }): JSX.Element => (
  <>
    <Item xs={2}>
      <HistoryRowBasicItem
        header={<FormattedMessage id="medication.otherTreatment.endedReason" />}
        value={
          <>
            {Array.isArray(d.endReason) &&
              d.endReason?.map((reason, i) => (
                <React.Fragment key={reason}>
                  <FormattedMessage id={`medication.opts.${reason}`} />
                  {`${i < (d.endReason?.length || 0) - 1 ? ', ' : ''}` /* Print commas between reasons */}
                </React.Fragment>
              ))}
          </>
        }
      />
    </Item>
    <Item xs={2}>
      <HistoryRowBasicItem
        header={<FormattedMessage id="medication.otherTreatment.endedReasonOther" />}
        value={d.endReasonOther}
      />
    </Item>
  </>
);

const VitaminD = ({ d }: { d: IVitaminD }): JSX.Element => {
  return (
    <>
      <Item xs={2}>
        <HistoryRowBasicItem header={<FormattedMessage id="medication.otherTreatment.dosage" />} value={d.dosage} />
      </Item>
      <Item xs={2}>
        <HistoryRowBasicItem
          header={<FormattedMessage id="medication.otherTreatment.frequency" />}
          value={d.frequency && <FormattedMessage id={'medication.otherTreatment.opts.' + d.frequency} />}
        />
      </Item>
      <Item xs={2}>
        <HistoryRowBasicItem
          header={<FormattedMessage id="medication.otherTreatment.otherFrequency" />}
          value={d.otherFrequency}
        />
      </Item>

      {endedTreatment(d) && <End d={d} />}
    </>
  );
};

const Plasmafaresis = ({ d }: { d: IPlasmaferesis }): JSX.Element => (
  <>
    <Item xs={2}>
      <HistoryRowBasicItem header={<FormattedMessage id="medication.otherTreatment.frequency" />} value={d.frequency} />
    </Item>

    {endedTreatment(d) && <End d={d} />}
  </>
);

const OtherTreatmentsHistoryRowData = ({ d }: IOwnProps): JSX.Element => {
  const ended = 'hasEnded' in d && d.hasEnded?.[0] === true;
  return (
    <Container>
      <Item xs={2}>
        <HistoryRowBasicItem
          header={<FormattedMessage id="medication.inUse" />}
          value={
            d.startDate ? (
              <div style={{ fontWeight: 600 }}>
                {!ended && (
                  <FormattedMessage
                    id="medication.startingDate"
                    values={{
                      date: `${formatPartialDate(d.startDate)}`,
                    }}
                  />
                )}
                {ended &&
                  `${formatPartialDate(d.startDate)}` + ' - ' + (d.endDate ? `${formatPartialDate(d.endDate)}` : '')}
              </div>
            ) : (
              '.'
            )
          }
        />
      </Item>
      {d.type === 'vitaminD' && <VitaminD d={d as IVitaminD} />}
      {d.type === 'plasmaferesis' && <Plasmafaresis d={d as IPlasmaferesis} />}
    </Container>
  );
};

interface IOwnProps {
  d: IMedicationOtherTreatment;
  ended?: boolean;
}

export default OtherTreatmentsHistoryRowData;
