import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistorySection from '../../../../components/HistorySection';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { Item } from '../../../../components/Grid';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import { calculateIadlScore } from 'Routes/DiagnosticSurvey/utils';
import InfoPopper from 'Components/InfoPopper';

const IADLHistory = ({ documents, startEdit, view }: IOwnProps): JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'diagnosticSurvey.iadl.title'} />}
      newButton={
        <DocumentCreationButton
          name="iadl"
          text={'diagnosticSurvey.iadl.newIADL'}
          onClick={startEdit({}, 'iadl')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <Item xs={true}>
            <FormattedMessage id={'diagnosticSurvey.iadl.score'} />
          </Item>
        }
        contentFormat={(d: IIADL): JSX.Element => (
          <React.Fragment>
            <Item xs={true}>
              {calculateIadlScore(d) ?? <InfoPopper text="diagnosticSurvey.iadl.notFilled" color="primary" />}
            </Item>
          </React.Fragment>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IIADL[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default IADLHistory;
