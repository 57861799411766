/*
 * Situational bar component for forms when additional separation between sections is required.
 */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';

import colors from '../../config/theme/colors';
import { Container, Item } from '../Grid';

const Bar = styled.div`
  font-weight: 600;
  width: auto !important;
  height: 5rem;
  margin: 0 -4rem;
  padding: 0 4rem;
  background-color: ${colors.primary};
  color: ${colors.white};
  font-size: 2.2rem;
`;

const IconArea = styled.div`
  width: 4rem;
  margin-left: -4rem;
`;

const OpenClosedIcon = ({ open }: { open: boolean }): JSX.Element | null => (
  <IconArea>
    <Container justifyContent="center">
      <Item>
        {open ? (
          <ArrowDropDown color="inherit" fontSize="large" style={{ display: 'block' }} />
        ) : (
          <ArrowRight color="inherit" fontSize="large" style={{ display: 'block' }} />
        )}
      </Item>
    </Container>
  </IconArea>
);

const FormSectionBar = ({ header, open }: IOwnProps): JSX.Element | null => (
  <Bar>
    {header && (
      <Container alignItems="center" style={{ height: '100%' }}>
        {open !== undefined && (
          <Item>
            <OpenClosedIcon open={open} />
          </Item>
        )}
        <Item xs={true}>
          <FormattedMessage id={header} />
        </Item>
      </Container>
    )}
  </Bar>
);

interface IOwnProps {
  header?: string;
  open?: boolean;
}

export default FormSectionBar;
