import { styled } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { IPatientTransfer } from 'neuro-data-structures';
import { formatPartialDate, partialDateFromDate } from 'neuro-utils';

import ActionButtonRounded from 'Components/ActionButtonRounded';
import DataTable from 'Components/DataTable';
import { Container, Item } from 'Components/Grid';
import TransferDialog from './TransferDialog';
import { fetchWithOptions } from 'Utility/fetch';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { makeLog } from 'Utility/logger';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import { assertCapabilities } from 'Store/index';
import PlatformCapabilities from '../../../../../config/capabilities';
import { useAppSelector as useSelector } from 'Store/index';

const fetchTransferHistory = (): Promise<Array<any>> => {
  return fetchWithOptions('/api/transfers/', { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res) => {
      if (res.status === 200) return res.json();
      else throw { message: 'Error fetching patient transfer history', stack: res };
    })
    .then((json) => {
      return json;
    })
    .catch((e) => {
      makeLog('Error', e);
      return [];
    });
};
const postTransferPatient = (sourceOrg: string): Promise<boolean> => {
  return fetchWithOptions(`/api/transferPatient/${sourceOrg}`, { neurojwt: parseJWTFromCookie() }, { method: 'POST' })
    .then((res) => {
      if (res.status === 200) return true;
      else throw { message: 'Error transferring patient', stack: res };
    })
    .catch((e) => {
      makeLog('Error', e);
      return false;
    });
};

const Header = styled('h2')(({ theme }) => ({ color: theme.palette.primary.main }));
const Text = styled('div')(({ theme }) => ({ color: theme.palette.grey[700] }));

const PatientTransfer = ({ capabilityGroups }: ICapabilityContextProps) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [transferStatus, setTransferStatus] = React.useState<'notStarted' | 'started' | 'done'>('notStarted');
  const [transferData, setTransferData] = React.useState<Array<IPatientTransfer>>([]);

  React.useEffect(() => {
    fetchTransferHistory().then((res) => {
      if (!Array.isArray(res)) return;
      const sortedData = res.sort((a, b) => b.transferTimestamp - a.transferTimestamp);
      setTransferData(sortedData);
    });
  }, []);

  const startTransfer = (sourceOrg: string) => {
    setTransferStatus('started');

    postTransferPatient(sourceOrg).then((res) => {
      if (res) {
        setTimeout(() => {
          setTransferStatus('done');
        }, 2000);
      }
    });
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
    setTransferStatus('notStarted');
  };

  const orgs = useSelector((s: IState) => s.session.orgGroupArray);

  const transferDisabled = !assertCapabilities([PlatformCapabilities.PATIENT_TRANSFER_INITIATE], capabilityGroups);

  return (
    <>
      <Container>
        <Item xs={8}>
          <Header>{fm('patientTransfer.transfer')}</Header>
          <Text>{fm('patientTransfer.transferInfotext')}</Text>

          <div style={{ margin: '3rem 0 6rem 0' }}>
            <ActionButtonRounded
              text="patientTransfer.startTransfer"
              onClick={() => setDialogOpen(true)}
              width={25}
              height={4}
              fontSize={16}
              filled
              disabled={transferDisabled}
              disabledTooltip={transferDisabled ? <>{fm('patientTransfer.noTransferCapability')}</> : undefined}
            />
          </div>

          <Header>{fm('patientTransfer.transfersHistory')}</Header>
          <DataTable
            headers={['date', 'organizationFrom', 'organizationTo']}
            headersFormatter={(h: string) => fm('patientTransfer.' + h)}
            data={{
              rowData: transferData.map((data) => [
                formatPartialDate(partialDateFromDate(new Date(data.transferTimestamp))),
                orgs ? orgs.find((o) => o.orgId === data.sourceOrg)?.displayName : data.sourceOrg,
                orgs ? orgs.find((o) => o.orgId === data.targetOrg)?.displayName : data.targetOrg,
              ]),
            }}
          />
        </Item>
      </Container>
      <TransferDialog
        open={dialogOpen}
        onClose={() => onCloseDialog()}
        transferStatus={transferStatus}
        startTransfer={startTransfer}
      />
    </>
  );
};

export default withCapabilities(PatientTransfer);
