import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import HistoryRowBasicItem from '../../../../../components/HistoryRowBasicItem';
import Collapse from '../../../../../components/Collapse';
import HistoryRowSubHeader from '../../../../../components/HistoryRowSubHeader';
import { Container, Item } from '../../../../../components/Grid';

import { formatPartialDate, formatTime, isPartialDate, isTime, sortPartialDate, sortTime } from 'neuro-utils';
import colors from '../../../../../config/theme/colors';

import { staticRegimenElements } from '../../config';
import { canEditLockedRegimen, getRegimenType } from '../../../utils';
import {
  getStrengthIndex,
  RegimenCustomHistory,
  RegimenOtherHistory,
  RegimenSingleDoseHistory,
  StrengthsItem,
  timesAndDosages,
  TitleValueItem,
} from '../../../utils/Regimen/regimenItems';

import MedicationAdverseEvents from './MedicationAdverseEvents';

import { StyledHistory } from '../../../../Diagnosis/utils/styled';
import { ICollapseStatus } from '../..';
import { smallActionButtonDimensions } from '../../../../../config/theme/componentTheme';
import ActionButton from '../../../../../components/ActionButton';
import ModifyLockedRegimenDialog from '../../Form/Medication/ModifyLockedRegimenDialog';
import DosingInstructions from '../../Form/Medication/RegimenDialog/components/DosingInstructions';
import { ContainerEvent, MakeHeaderBar } from './components';
import MedicationEndReasonDialog from 'Components/HistoryRowControls/MedicationEndReasonDialog';
import { isNil } from 'ramda';
import MedicationStartDialog from 'Components/HistoryRowControls/MedicationStartDialog';

const SubValue = styled.div`
  * > &:not(:last-of-type) {
    margin-bottom: 0.7rem;
  }
`;

const EventArea = styled.div`
  * > &:not(̣:first-of-type) {
    margin-top: 2.5rem;
  }
`;

const SubTitleValueItem = ({ title, value }: ITitleValueItemProps): JSX.Element => (
  <SubValue>
    <div style={{ fontSize: '1.4rem', color: colors.darkGray, fontWeight: 400 }}>
      {title ? <FormattedMessage id={title} /> : ''}
    </div>
    <div style={{ fontWeight: 600 }}>{value || '-'}</div>
  </SubValue>
);

interface ITitleValueItemProps {
  title?: string;
  value?: string | JSX.Element | number;
}

const RegimenOnDemand = ({ regimen }: { regimen: IRegimenBasics & IRegimenOnDemand }): JSX.Element => {
  return (
    <Container>
      <Item xs={2}>
        <TitleValueItem title="medication.strength" value={<StrengthsItem strengths={regimen.strengths} />} />
      </Item>
      <Item xs={2}>
        <TitleValueItem
          title="medication.regimenDosage"
          value={
            <React.Fragment>
              <SubTitleValueItem title="medication.doseSize" value={regimen.dose ? <span>{regimen?.dose}</span> : ''} />
              <SubTitleValueItem
                title="medication.maxDoses"
                value={
                  regimen.maxDoses && (
                    <span>
                      {regimen.maxDoses}
                      {regimen?.unit ? (
                        <>
                          {' '}
                          <FormattedMessage id={`medication.opts.unit.${regimen.unit}`} />
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  )
                }
              />
            </React.Fragment>
          }
        />
      </Item>
      <Item xs={6}>
        <TitleValueItem title="medication.regimenDetailsOther" value={regimen?.regimenDetailsOther} />
      </Item>
    </Container>
  );
};

const RegimenDefault = ({ regimen }: { regimen: IRegimenBasics & IRegimenDefault }): JSX.Element => {
  const dosages = regimen.dosages || [];
  const strengths = regimen.strengths || [];

  const hasTimes = !!dosages.find((d) => isTime(d.from));

  const sizing = dosages.length <= 3 ? 3 : true;

  return (
    <>
      <Container>
        <Item xs={2}>
          <TitleValueItem title="medication.doses.strengths" value={<StrengthsItem strengths={strengths} />} />
        </Item>
        {dosages.length > 0 ? (
          <Item xs={8}>
            <TitleValueItem
              title="medication.regimenDosage"
              value={
                <>
                  <Container style={{ fontWeight: 400, padding: '0 0.5rem 0.5rem 0.5rem' }}>
                    {hasTimes && (
                      <Item xs={3}>
                        <FormattedMessage id="medication.doses.time" />
                      </Item>
                    )}
                    {strengths.map((s) => (
                      <Item style={{ textAlign: 'right' }} key={getStrengthIndex(s)} xs={sizing}>
                        {getStrengthIndex(s, true)}
                      </Item>
                    ))}
                  </Container>
                  {dosages.map((d, i) => (
                    <Container
                      key={i}
                      style={{
                        padding: '0.5rem 0.5rem',
                        ...(i % 2 ? {} : { backgroundColor: colors.lightestGray }),
                      }}
                    >
                      {hasTimes && (
                        <Item xs={3}>
                          {timesAndDosages(dosages, regimen, strengths, sizing)[i].from}
                          {timesAndDosages(dosages, regimen, strengths, sizing)[i].to ? ' - ' : ''}
                          {timesAndDosages(dosages, regimen, strengths, sizing)[i].to
                            ? timesAndDosages(dosages, regimen, strengths, sizing)[i].to
                            : ''}
                        </Item>
                      )}
                      {timesAndDosages(dosages, regimen, strengths, sizing)[i].dosages}
                    </Container>
                  ))}
                </>
              }
            />
          </Item>
        ) : (
          <Item xs={2}>
            <TitleValueItem
              title="medication.doses.title"
              value={
                <span style={{ textTransform: 'lowercase' }}>
                  {regimen?.doses ? (
                    <>
                      {regimen.unit ? (
                        <span>
                          {`${regimen?.doses} `}
                          <FormattedMessage id={`medication.opts.unit.${regimen.unit}`} />
                        </span>
                      ) : (
                        <FormattedMessage id="medication.regimenDose" values={{ n: regimen?.doses }} />
                      )}
                      {regimen?.repeatNumber && (
                        <>
                          {` `}
                          <FormattedMessage id="medication.regimenDoseInterval" values={{ n: regimen?.repeatNumber }} />
                        </>
                      )}
                    </>
                  ) : (
                    !(regimen?.doses || regimen?.interval) && '-'
                  )}
                  &nbsp;
                  {regimen?.interval && <FormattedMessage id={`medication.opts.${regimen?.interval}`} />}
                </span>
              }
            />
          </Item>
        )}

        {dosages.length > 0 && ( // Show period only if dosages (program) exist
          <Item xs={2}>
            <TitleValueItem
              title="medication.doses.period"
              value={
                <>
                  {regimen.periodNumber && regimen.periodUnit && regimen.periodUnit !== undefined ? (
                    <>
                      <FormattedMessage id="medication.doses.every" />{' '}
                      {regimen.periodNumber === 1 ? '' : regimen.periodNumber}
                      {regimen.periodNumber === 1 ? ' ' : '. '}
                      <span style={{ textTransform: 'lowercase' }}>
                        <FormattedMessage id={`medication.doses.periodOpts.${regimen.periodUnit}`} />
                      </span>
                    </>
                  ) : regimen.periodNumber === null ||
                    (regimen.periodNumber === undefined && regimen.periodUnit && regimen.periodUnit !== undefined) ? (
                    <FormattedMessage id={`medication.doses.periodOpts.${regimen.periodUnit}`} />
                  ) : (
                    '-'
                  )}
                </>
              }
            />
          </Item>
        )}
        <Item>
          <TitleValueItem title="medication.regimenDetailsOther" value={regimen?.regimenDetailsOther} />
        </Item>
      </Container>
    </>
  );
};

const PauseEvents = ({ p }: { p: IMedicationPause }): JSX.Element => (
  <React.Fragment>
    <span style={{ fontWeight: 600 }}>
      <MakeHeaderBar date={p.startDate} additionalInfo={p.endDate && formatPartialDate(p.endDate)} />
    </span>
    <ContainerEvent key={p.id}>
      <Item xs={2}>
        <TitleValueItem
          title="medication.pausationReason"
          value={p.pausationReason && <FormattedMessage id={`medication.pausationReasons.${p.pausationReason}`} />}
        />
      </Item>
      <Item xs={2}>
        <TitleValueItem
          title="medication.pausationDate"
          value={
            isPartialDate(p.startDate) ? (
              <div>
                <div>{formatPartialDate(p.startDate)}</div>
                <div>{formatTime(p.startTime)}</div>
              </div>
            ) : undefined
          }
        />
      </Item>
      <Item xs={2}>
        <TitleValueItem
          title="medication.continuationDate"
          value={
            isPartialDate(p.endDate) ? (
              <div>
                <div>{formatPartialDate(p.endDate)}</div>
                <div>{formatTime(p.endTime)}</div>
              </div>
            ) : undefined
          }
        />
      </Item>
      <Item xs={2}>
        <TitleValueItem title="medication.pausationAdditionalInfoPlaceholder" value={p.additionalInfo} />
      </Item>
    </ContainerEvent>
  </React.Fragment>
);

const EditLockedRegimenButtonAndDialog = ({
  d,
  regimen,
}: {
  d: IMedication;
  regimen: IRegimenBasics & (IRegimenCustom | IRegimenDefault | IRegimenOnDemand | IRegimenSingleDose | IRegimenOther);
}): JSX.Element | null => {
  const [open, setOpen] = React.useState<boolean>(false);
  const openDialog = (thisOpen: boolean): void => {
    setOpen(thisOpen);
  };

  // Set this dynamically if needed for some other field
  const fieldName = 'infusionDoses' as keyof typeof regimen;

  return canEditLockedRegimen(d, regimen) ? (
    <>
      <div style={{ paddingRight: '4.5rem' }}>
        <ActionButton
          text={'general.edit'}
          onClick={(): void => {
            openDialog(true);
          }}
          {...smallActionButtonDimensions}
        />
      </div>
      <ModifyLockedRegimenDialog open={open} setOpenDialog={openDialog} regimen={regimen} d={d} field={fieldName} />
    </>
  ) : null;
};

const DosageAndRegimen = ({ d }: { d: IMedication }): JSX.Element => {
  const regimen = d.regimen?.[0] as Regimen;
  const regimenArr = (d.regimen as Regimen[])?.map((item) => item); // Creating new array. New and old array has own memory reference
  regimenArr?.shift();

  return (
    <React.Fragment>
      <Container alignItems="center">
        <Item xs={2}>
          <HistoryRowSubHeader header={<FormattedMessage id="medication.regimen" />} />
        </Item>
        <Item xs={true} style={{ margin: '0.1rem 0 1rem 1rem' }}>
          <DosingInstructions medication={d} />
        </Item>
      </Container>

      {regimen && (
        <MakeHeaderBar
          date={regimen?.date}
          dateExtended={regimen.time && ` (${formatTime(regimen.time)})`}
          additionalInfo={
            regimen?.regimenType && (
              <>
                <FormattedMessage id={`medication.regimenTypes.${regimen.regimenType}`} />
                {regimen.routeOfAdministration && (
                  <>
                    {' '}
                    (<FormattedMessage id={`medication.opts.mr.${regimen.routeOfAdministration}`} />)
                  </>
                )}
              </>
            )
          }
          newButton={<EditLockedRegimenButtonAndDialog d={d} regimen={regimen} />}
        />
      )}

      {/* First regimen */}
      {regimen?.regimenType === 'default' ? (
        <RegimenDefault regimen={regimen as IRegimenBasics & IRegimenDefault} />
      ) : regimen?.regimenType === 'onDemand' ? (
        <RegimenOnDemand regimen={regimen as IRegimenBasics & IRegimenOnDemand} />
      ) : regimen?.regimenType === 'custom' ? (
        <RegimenCustomHistory regimen={regimen as IRegimenBasics & IRegimenCustom} d={d} />
      ) : regimen?.regimenType === 'single-dose' ? (
        <RegimenSingleDoseHistory regimen={regimen as IRegimenBasics & IRegimenSingleDose} />
      ) : regimen?.regimenType === 'other' ? (
        <RegimenOtherHistory regimen={regimen as IRegimenBasics & IRegimenOther} />
      ) : getRegimenType(d?.medicationName) === 'static' ? (
        staticRegimenElements[d?.medicationName?.toUpperCase() || ''] || undefined
      ) : undefined}

      {d?.regimen && d.regimen.length > 1 ? (
        <>
          {regimenArr.map((regimen: Regimen) => (
            <div style={{ margin: '3rem 0 0 0' }} key={regimen.id}>
              <MakeHeaderBar
                date={regimen?.date}
                additionalInfo={
                  <>
                    <FormattedMessage id={`medication.regimenTypes.${regimen.regimenType}`} />
                    {regimen.routeOfAdministration ? ' (' + regimen.routeOfAdministration + ')' : ''}
                  </>
                }
                newButton={<EditLockedRegimenButtonAndDialog d={d} regimen={regimen} />}
              />
              {regimen?.regimenType === 'default' ? (
                <RegimenDefault regimen={regimen as IRegimenBasics & IRegimenDefault} />
              ) : regimen?.regimenType === 'onDemand' ? (
                <RegimenOnDemand regimen={regimen as IRegimenBasics & IRegimenOnDemand} />
              ) : regimen?.regimenType === 'custom' ? (
                <RegimenCustomHistory regimen={regimen as IRegimenBasics & IRegimenCustom} d={d} />
              ) : regimen?.regimenType === 'single-dose' ? (
                <RegimenSingleDoseHistory regimen={regimen as IRegimenBasics & IRegimenSingleDose} />
              ) : regimen?.regimenType === 'other' ? (
                <RegimenOtherHistory regimen={regimen as IRegimenBasics & IRegimenOther} />
              ) : getRegimenType(d?.medicationName) === 'static' ? (
                staticRegimenElements[d?.medicationName?.toUpperCase() || ''] || undefined
              ) : undefined}
            </div>
          ))}
        </>
      ) : null}
    </React.Fragment>
  );
};

const AdministrationEvents = ({ e }: IAdministrationEventProps): JSX.Element => (
  <EventArea>
    <span style={{ fontWeight: 600 }}>
      <MakeHeaderBar
        date={e.date}
        dateExtended={`
          ${isTime(e.startTime) ? ` (${formatTime(e.startTime)})` : ''}
          ${isPartialDate(e.endDate) ? ' - ' : ''}
          ${isPartialDate(e.endDate) ? formatPartialDate(e.endDate) : ''}
          ${isTime(e.endTime) ? ` (${formatTime(e.endTime)})` : ''}
        `}
      />
    </span>
    <ContainerEvent>
      <Item xs={2}>
        <TitleValueItem
          title="medication.administration.doseQuantity"
          value={
            <>
              {e.doseQuantity || null}
              {e.doseQuantityUnit ? <FormattedMessage id={`${e.doseQuantityUnit}`} /> : ''}
            </>
          }
        />
      </Item>
      <Item xs={2}>
        <TitleValueItem
          title="medication.administration.isExtraDose"
          value={<FormattedMessage id={`general.${e?.isExtraDose?.[0] ? 'yes' : 'no'}`} />}
        />
      </Item>
      <Item xs={2}>
        <TitleValueItem title="medication.additionalInformation" value={e?.additionalInformation} />
      </Item>
    </ContainerEvent>
  </EventArea>
);

interface IAdministrationEventProps {
  e: IAdministration;
}

const Administrations = ({ administrations }: IAdministrationProps): JSX.Element => {
  const sorted = Array.from(administrations).sort(
    (a, b) => sortPartialDate(b.date, a.date) || sortTime(b.startTime, a.startTime),
  );
  return (
    <React.Fragment>
      <HistoryRowSubHeader header={<FormattedMessage id="medication.administration.administrations" />} />

      {sorted.map((e: IAdministration) => (
        <AdministrationEvents key={e.id} e={e} />
      ))}
    </React.Fragment>
  );
};

interface IAdministrationProps {
  administrations: Array<IAdministration>;
}

const MedicationPausations = ({ pauses }: IMedicationPauseProps): JSX.Element => (
  <React.Fragment>
    <HistoryRowSubHeader header={<FormattedMessage id="medication.pauses" />} />
    {pauses.map((p: IMedicationPause) => (
      <PauseEvents key={p.id} p={p} />
    ))}
  </React.Fragment>
);

interface IMedicationPauseProps {
  pauses: Array<IMedicationPause>;
}

const FormattedMedicationUsagePeriod = ({
  d,
  dateFieldName,
  ended,
  paused,
}: {
  d: IOwnProps['d'];
  dateFieldName: 'startDate' | 'usageStartDate';
  ended: IOwnProps['ended'];
  paused: IOwnProps['paused'];
}): JSX.Element => {
  const name = dateFieldName as 'startDate' | 'usageStartDate';
  const includeStartTime = name === 'startDate' && isTime(d.startTime);
  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      {!paused && !ended && (
        <FormattedMessage
          id="medication.startingDate"
          values={{
            date: `${formatPartialDate(d[name])}${includeStartTime ? ` (${formatTime(d.startTime)})` : ''}`,
          }}
        />
      )}
      {paused &&
        d.pauses &&
        (name === 'startDate' ? (
          <FormattedMessage
            id="medication.pausingDate"
            values={{ date: formatPartialDate(d.pauses[d.pauses.length - 1].startDate) }}
          />
        ) : (
          `${formatPartialDate(d[name])}` + ' - ' + formatPartialDate(d.pauses[d.pauses.length - 1].startDate)
        ))}
      {ended &&
        `${formatPartialDate(d[name])}${includeStartTime ? ` (${formatTime(d.startTime)})` : ''}` +
          ' - ' +
          (isTime(d.endTime) ? '\n' : '') +
          (d.endDate ? `${formatPartialDate(d.endDate)}${isTime(d.endTime) ? ` (${formatTime(d.endTime)})` : ''}` : '')}
    </div>
  );
};

const MedicationHistoryRowData = ({
  d,
  ended = false,
  paused,
  startEdit,
  setMedID,
  adverseEvents,
  collapseStatus,
}: IOwnProps): JSX.Element => {
  const [medicationStartDialogOpen, setMedicationStartDialogOpen] = React.useState<boolean>(false);
  const [medicationEndDialogOpen, setMedicationEndDialogOpen] = React.useState<boolean>(false);
  const lockedFor = !isNil((d as IControlProps)._lockedFor);

  const medicationDialogOpener = (dialogType: 'start' | 'end') => {
    switch (dialogType) {
      case 'start':
        setMedicationStartDialogOpen(true);
        break;
      case 'end':
        setMedicationEndDialogOpen(true);
    }
  };

  return (
    <StyledHistory>
      <Container alignItems="baseline" style={{ margin: '0 0 0 0' }}>
        <Item xs={2}>
          <HistoryRowBasicItem
            header={<FormattedMessage id={`medication.${!isPartialDate(d.usageStartDate) ? 'inUse' : 'start'}`} />}
            value={
              d.startDate ? (
                <React.Fragment>
                  {!isPartialDate(d.usageStartDate) ? (
                    <FormattedMedicationUsagePeriod d={d} dateFieldName="startDate" ended={ended} paused={paused} />
                  ) : (
                    `${formatPartialDate(d.startDate)}${isTime(d.startTime) ? ` (${formatTime(d.startTime)})` : ''}`
                  )}
                </React.Fragment>
              ) : (
                '.'
              )
            }
          />
        </Item>
        {isPartialDate(d.usageStartDate) && (
          <Item xs={2}>
            <HistoryRowBasicItem
              header={<FormattedMessage id="medication.inUse" />}
              value={
                <FormattedMedicationUsagePeriod d={d} dateFieldName="usageStartDate" ended={ended} paused={paused} />
              }
            />
          </Item>
        )}
        <Item xs={isPartialDate(d.usageStartDate) ? 6 : 8}>
          <HistoryRowBasicItem
            header={<FormattedMessage id="medication.substances" />}
            value={d.medicationSubstances ? <div style={{ fontWeight: 600 }}>{d.medicationSubstances}</div> : undefined}
          />
        </Item>
        {lockedFor && (
          <Item xs={2}>
            <ActionButton
              width={15}
              height={3}
              fontSize={16}
              text="general.edit"
              onClick={() => medicationDialogOpener('start')}
            />
          </Item>
        )}
      </Container>
      {medicationStartDialogOpen && (
        <MedicationStartDialog document={d} openState={[medicationStartDialogOpen, setMedicationStartDialogOpen]} />
      )}
      <Collapse
        localeIDs={{ showMessage: 'medication.showDetails', hideMessage: 'medication.hideDetails' }}
        open={collapseStatus.collapseIDArray.includes(d._id)}
        openHandler={(): void => collapseStatus.setCollapseID(d._id)}
      >
        <div style={{ marginTop: '4rem' }}>
          {d.regimen && d.regimen?.length > 0 && <DosageAndRegimen d={d} />}

          {d.administration && d.administration.length > 0 && <Administrations administrations={d.administration} />}

          {d.pauses && d.pauses.length > 0 && <MedicationPausations pauses={d.pauses} />}

          <MedicationAdverseEvents
            medication={d}
            adverseEvents={adverseEvents}
            setMedID={setMedID}
            startEdit={startEdit}
          />

          {d.hasEnded?.[0] === true && (
            <>
              {medicationEndDialogOpen && (
                <MedicationEndReasonDialog
                  document={d}
                  openState={[medicationEndDialogOpen, setMedicationEndDialogOpen]}
                />
              )}
              <Container>
                <Item xs={true}>
                  <HistoryRowSubHeader header={<FormattedMessage id="medication.medicationEnded" />} />
                </Item>
                {lockedFor && (
                  <Item xs={3} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '4.5rem' }}>
                    <ActionButton
                      width={15}
                      height={3}
                      fontSize={16}
                      text={'medication.addEndReason'}
                      onClick={() => medicationDialogOpener('end')}
                    />
                  </Item>
                )}
              </Container>
              <Container>
                <Item xs={2}>
                  <div style={{ fontWeight: 600 }}>
                    {formatPartialDate(d.endDate)}
                    {`${d.endTime ? ` (${formatTime(d.endTime)})` : ''}`}
                  </div>
                </Item>
                <Item xs={8}>
                  <Container>
                    <Item xs={3}>
                      <FormattedMessage id="medication.endedReason" />
                    </Item>
                    <Item xs={true}>
                      <span style={{ fontWeight: 600 }}>
                        {d.endReason?.map((reason, i) => (
                          <React.Fragment key={reason}>
                            <FormattedMessage id={`medication.opts.${reason}`} />
                            {`${i < (d.endReason?.length || 0) - 1 ? ', ' : ''}` /* Print commas between reasons */}
                          </React.Fragment>
                        ))}
                      </span>
                    </Item>
                  </Container>
                  <Container>
                    <Item xs={3}>
                      <FormattedMessage id="medication.endedReasonOther" />
                    </Item>
                    <Item xs={true}>
                      <span style={{ fontWeight: 600 }}>{d.endReasonOther}</span>
                    </Item>
                  </Container>
                </Item>
              </Container>
            </>
          )}
        </div>
      </Collapse>
    </StyledHistory>
  );
};

interface IOwnProps {
  d: IMedication;
  ended?: boolean;
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
  setMedID: (id: string) => void;
  adverseEvents: IMedicationAdverseEvent[];
  paused?: boolean;
  collapseStatus: ICollapseStatus;
}

export default MedicationHistoryRowData;
