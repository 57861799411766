import * as React from 'react';
import QuestionAnswerPair from 'Components/_NewElements/QuestionAnswerPair';
import { surveyCreator, surveyModifyer } from 'Routes/MyService/Document/HistoryRowData/SurveysHistory';
import { ICompactVaultUser } from 'neuro-data-structures';
import InputHandler from 'Components/InputHandler';
import { exists, formatPartialDate, isPartialDate } from 'neuro-utils';
import { isLocaleKey, supplyShortagesInquiry } from 'neuro-schemas';
import { MyServiceContext } from '../..';
import TableContent from './components/TableContent';
import { TFieldConfig } from '../../config';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import FormRow from 'Components/FormRow';
import Unit from 'Components/Unit';

const fields: Array<TFieldConfig> = [
  {
    name: 'electrodePads',
    type: 'NumberField',
  },
  {
    name: 'salineSolution500ml',
    type: 'NumberField',
  },
  {
    name: 'salineSolution20ml',
    type: 'NumberField',
  },
  {
    name: 'syringes',
    type: 'CheckboxSingle',
  },
  {
    name: 'capCleaner',
    type: 'CheckboxSingle',
  },
  {
    name: 'batteries',
    type: 'CheckboxSingle',
  },
  {
    name: 'cables',
    type: 'CheckboxSingle',
  },
  {
    name: 'electrodes',
    type: 'CheckboxSingle',
  },
  {
    name: 'customsCertificate',
    type: 'CheckboxSingle',
  },
  {
    name: 'newTDCSCap',
    type: 'CheckboxSingle',
  },
  {
    name: 'other',
    type: 'TextArea',
  },
];

const ConditionalSupplyUnit = ({
  children,
  name,
  fm,
}: {
  children: JSX.Element;
  name: string;
  fm: (id: string) => string;
}): JSX.Element =>
  ['electrodePads', 'salineSolution500ml', 'salineSolution20ml'].includes(name) ? (
    <Unit
      unit={
        {
          electrodePads: fm('opts.packets'),
          salineSolution500ml: fm('opts.bottles'),
          salineSolution20ml: fm('opts.ampoules'),
        }[name] ?? ''
      }
    >
      {children}
    </Unit>
  ) : (
    children
  );

const SupplyShortagesInquiry = ({ patientMyid, users }: IOwnProps): JSX.Element => {
  const supplyShortagesInquiryLocales = supplyShortagesInquiry.locales;
  const myServiceContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing } = myServiceContext;

  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const fmSupplyShortagesInquiry = (id: string | number) => {
    return supplyShortagesInquiryLocales[useLocale][id];
  };

  const supplyShortagesInquiryData = ((editing?.data || viewing?.data) ?? {}) as ISupplyShortagesInquiry &
    IControlProps;

  const surveyData = {
    ...supplyShortagesInquiryData,
  };

  const isEditing = !viewing && !!editing;

  const handleFieldChange =
    () =>
    (e: TOnChangeValues): void => {
      const name = Object.keys(e)[0];
      const value = e[name];
      const { suppliesNeeded, suppliesDetails } = surveyData;
      let newSuppliesNeeded = Array.isArray(suppliesNeeded) ? JSON.parse(JSON.stringify(suppliesNeeded)) : [];
      const newSuppliesDetails =
        suppliesDetails && typeof suppliesDetails === 'object' ? JSON.parse(JSON.stringify(suppliesDetails)) : {};
      switch (name) {
        case 'electrodePads':
        case 'salineSolution500ml':
        case 'salineSolution20ml':
        case 'other': {
          const n = name === 'salineSolution500ml' || name === 'salineSolution20ml' ? 'salineSolution' : name;

          // Handle 'other' individually
          if (name === 'other' && value === '') {
            newSuppliesNeeded = newSuppliesNeeded.filter((s: string) => s !== n);
            newSuppliesDetails.other = null;
            setEditingData?.({ ...surveyData, suppliesNeeded: newSuppliesNeeded, suppliesDetails: newSuppliesDetails });
            break;
          }

          // Default handling of fields

          if (exists(value) && !newSuppliesNeeded.includes(n)) newSuppliesNeeded.push(n);
          else if (!exists(value)) newSuppliesNeeded = newSuppliesNeeded.filter((s: string) => s !== n);

          newSuppliesDetails[name] = value;

          setEditingData?.({ ...surveyData, suppliesNeeded: newSuppliesNeeded, suppliesDetails: newSuppliesDetails });
          break;
        }
        default: {
          if (value === true && !newSuppliesNeeded.includes(name)) newSuppliesNeeded.push(name);
          else if (value === false) newSuppliesNeeded = newSuppliesNeeded.filter((s: string) => s !== name);
          setEditingData?.({ ...surveyData, suppliesNeeded: newSuppliesNeeded });
        }
      }
    };

  const getFieldValue = (name: string) => {
    const { suppliesNeeded, suppliesDetails } = surveyData;
    switch (name) {
      case 'electrodePads':
      case 'salineSolution500ml':
      case 'salineSolution20ml':
      case 'other': {
        if (suppliesDetails && typeof suppliesDetails === 'object' && name in suppliesDetails) {
          return suppliesDetails[name as keyof typeof suppliesDetails];
        } else if (Array.isArray(suppliesNeeded) && (suppliesNeeded as string[]).includes(name)) {
          return 0;
        }
        return undefined;
      }
      default: {
        if (Array.isArray(suppliesNeeded) && (suppliesNeeded as string[]).includes(name)) {
          return true;
        }
        return undefined;
      }
    }
  };

  // Initialize document fields
  React.useEffect(() => {
    if (!viewing && surveyData.date && !surveyData.isSupplySufficient) {
      setEditingData?.({ ...surveyData, isSupplySufficient: 'no' });
    }
  }, [surveyData.date]);

  return (
    <React.Fragment>
      {viewing && (
        <div style={{ marginBottom: '4rem' }}>
          <QuestionAnswerPair
            question={fm('myService.reporter')}
            answer={surveyCreator(surveyData, patientMyid, users, fm)}
          />
          {surveyModifyer(surveyData, patientMyid, users, fm) && (
            <QuestionAnswerPair
              question={fm('myService.edited')}
              answer={surveyModifyer(surveyData, patientMyid, users, fm)}
            />
          )}
        </div>
      )}
      {!editing ? (
        <QuestionAnswerPair
          question={fm('general.date')}
          answer={isPartialDate(surveyData?.date) ? formatPartialDate(surveyData.date) : '-'}
        />
      ) : (
        <FormRow title="general.date" headerWidth={3.85} bottomMargin={false}>
          <InputHandler
            name="date"
            type="PartialDate"
            editing={!!editing}
            isNotCancellable={true}
            formData={{
              document: { date: surveyData?.date },
              onChange: (e: TOnChangeValues) => {
                const value = e.date;
                if (value && isPartialDate(value)) {
                  setEditingData?.({ ...surveyData, date: value });
                }
              },
            }}
            dateDefault="now"
          />
        </FormRow>
      )}
      <React.Fragment>
        <HistoryRowSubHeader header={fm('myService.supplies')} />
        <TableContent width={80} titleRow={undefined} disableTitleRowBorder compact>
          {
            fields.map((field, index, array) => {
              const fieldNamePath =
                field.name === 'salineSolution500ml' || field.name === 'salineSolution20ml'
                  ? 'suppliesDetails'
                  : 'opts';
              return {
                title: {
                  content: fmSupplyShortagesInquiry(`${fieldNamePath}.${field.name}`),
                  xs: 2,
                },
                content: {
                  content: (
                    <ConditionalSupplyUnit name={field.name} fm={fmSupplyShortagesInquiry}>
                      {/* Apply corrective positioning and padding to minimize differences in component size */}
                      <div
                        style={{
                          position: 'relative',
                          top: field.type === 'CheckboxSingle' ? '0.4rem' : undefined,
                          padding: field.type !== 'CheckboxSingle' ? '0.5rem 0 0.5rem 0' : undefined,
                        }}
                      >
                        <InputHandler
                          type={field.type as any}
                          name={field.name}
                          editing={isEditing}
                          formData={{
                            onChange: (e) => handleFieldChange()(e),
                            document: { [field.name]: getFieldValue(field.name) },
                          }}
                          options={field.options}
                          option={field.type === 'CheckboxSingle' ? 'true' : undefined}
                          optionFormatter={field.type === 'CheckboxSingle' ? () => fm('general.yes') : undefined}
                          placeholder={field.placeholder}
                          min={field.type === 'NumberField' ? 0 : undefined}
                          max={
                            {
                              electrodePads: 10,
                              salineSolution500ml: 10,
                              salineSolution20ml: 20,
                            }[field.name]
                          }
                          width={field.type === 'NumberField' ? 8 : undefined}
                          maxLength={field.type === 'TextArea' ? 100 : undefined}
                        />
                      </div>
                    </ConditionalSupplyUnit>
                  ),
                  style: {
                    overflow: 'visible',
                  },
                  xs: 3,
                },
                style: {
                  alignItems: index !== array.length - 1 ? 'center' : undefined,
                },
              };
            }) as []
          }
        </TableContent>
      </React.Fragment>
    </React.Fragment>
  );
};

interface IOwnProps {
  patientMyid: string;
  users: ICompactVaultUser[];
}

export default SupplyShortagesInquiry;
