import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { path } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import ActionButton from '../../../../../components/ActionButton';
import HistoryRowBasicItem from '../../../../../components/HistoryRowBasicItem';
import colors from '../../../../../config/theme/colors';
import QuestionStepper from '../../../../../components/QuestionStepper';
import { getStepContent, steps } from '../updrsIIISettings';
import { getStepContent as getStepContentOld, steps as stepsOld } from '../updrsIIIOldSettings';
import { actions as actionsD, TCreationPromiseType } from '../../../../../store/documents/actions';
import { actions } from '../../../../../store/form';
import { docHasValues, isUpdrsComplete, updrsScoreSum } from '../../../utils';
import InputHandler from '../../../../../components/InputHandler';
import FormRow from '../../../../../components/FormRow';
import {
  mdsUpdrsIIITestDescription,
  mdsUpdrsIIITestReferenceInfo,
  dyskinesiaQuestions,
  updrsIIITestReferenceInfo,
  StyledScore,
  StyledCancel,
  StyledUPDRSArea,
  scoreText,
  notFilledText,
  IEditingDocument,
} from './dialogComponents';
import InfoPopper from '../../../../../components/InfoPopper';
import { omitControlProps } from '../../../../../utility/documentHandling';
import { Container, Item } from '../../../../../components/Grid';

type Props = IOwnProps & IDispatchFromProps;

class UPDRSDialog extends React.Component<Props, IOwnState> {
  private dialogContent: HTMLElement | null;
  private dialogContentRef: (element: HTMLElement | null) => void;
  constructor(props: Props) {
    super(props);
    // deepcode ignore ReactStateFromProps: Needs rework to fix
    this.state = {
      formData: props.document || undefined,
      previousFormData: undefined,
      open: false,
      id: path(['_id'], props.document),
    };
    this.dialogContent = null;
    this.dialogContentRef = (element: HTMLElement | null): void => {
      this.dialogContent = element;
    };
  }

  // Use this to get retrospective checkbox status from beforeDoc and save it to afterDoc
  private updateRetroStatus = (): void => {
    if (this.props.retro !== this.state.formData?.retrospectiveData?.[0] && this.props.retro !== undefined) {
      this.setState({ formData: { retrospectiveData: [this.props.retro], ...this.state.formData } });
      this.onChange({ retrospectiveData: [this.props.retro] });
    }
  };

  /**
   * This function is called automatically if UPDRSIII dialog is updated in any way
   */
  public componentDidUpdate(): void {
    // Load form data
    if (this.state.formData && !this.state.formData._id && this.props.document) {
      this.setState({ formData: { ...this.state.formData, ...this.props.document } });
    }
  }

  public newCommitOrDocument = (id: string): void => {
    if (id && id !== 'noid') {
      // Create new commit for existing document
      const makeCommit = async (): Promise<TCreationPromiseType> =>
        await this.props.createCommit({ id, name: 'updrs_iii' }, this.props.document);
      makeCommit()
        .then((res: TCreationPromiseType) => {
          if (typeof res === 'object' && res?._cid) {
            this.setState({
              id,
              formData: this.props.document,
              previousFormData: this.props.document,
            });
            this.updateRetroStatus();
          } else if (res === 'Commit creation failed') {
            this.setState({ open: false });
          }
        })
        .catch(() => {
          this.setState({ open: false });
        });
    } else if (id === 'noid') {
      // Create new document
      const makeDocument = async (): Promise<TCreationPromiseType> =>
        await this.props.createDocument('updrs_iii', true);
      // ID for new document received
      makeDocument()
        .then((res: TCreationPromiseType) => {
          // Link ID to levodopatest|dbsEffect
          if (typeof res === 'object' && res._id && this.props.onChange) {
            // file deepcode ignore ReactNextState: Needs rework to fix
            this.setState({ id: res._id });

            // Save this id to parent doc
            this.props.onChange({ [this.props.name]: res._id });

            const initValues: { [key: string]: PartialDate | string | boolean[] } = {};

            // Set date and type for updrs automatically
            if (this.props.parentDate?.[0]) initValues.date = this.props.parentDate;
            if (this.props.retro !== undefined) initValues.retrospectiveData = [this.props.retro];

            initValues.testType = this.props.testType || 'MDSUPDRS';
            initValues.type = this.props.context;

            this.onChange(initValues, res._id);
            //this.updateRetroStatus();
          }
        })
        .catch(() => {
          return;
        });
    } else {
      this.setState({ id, formData: this.props.document });
    }
  };

  public openDialog = (id: string) => (): void => {
    this.setState({ open: true });
    !this.props.viewing && this.newCommitOrDocument(id);
  };

  public closeDialog =
    (cancel = false) =>
    (): void => {
      if (this.state.id && !this.props.viewing) {
        if (!cancel) {
          this.props.updateDocument(
            { id: this.state.id, name: 'updrs_iii' },
            omitControlProps(this.state.formData),
            true,
          );
          // Close commit when saving main document instead (in FormEditingHandler)
        } else {
          const id = this.state.id;
          if (this.state.formData === undefined || !this.props.document) {
            this.props.deleteDocument({ id: id, name: 'updrs_iii' }, { reason: 'Document creation canceled' });
          } else {
            if (this.state.previousFormData === undefined) {
              this.props.deleteCommit({ id: id, name: 'updrs_iii' });
            } else {
              this.props.updateDocument(
                { id: this.state.id, name: 'updrs_iii' },
                omitControlProps(this.state.previousFormData),
                true,
              );
            }
            this.props.clearFormValues(id);
            this.props.unsetEditing(id);
          }
        }
        this.setState({ id: undefined, formData: {} });
      }
      this.setState({ open: false });
    };

  public onChange = (values: TOnChangeValues, newId?: string): void => {
    const id = newId ? newId : this.state.id;
    if (id && id !== 'noid') {
      this.setState({ formData: { ...this.state.formData, ...values } });
      this.props.updateDocument(
        { id: id, name: 'updrs_iii' },
        omitControlProps({
          ...this.state.formData,
          ...values,
        }), // Remove control props again
      );
    }
  };

  /**
   * This function is called automatically when UPDRSIII dialog
   * is mounted / created.
   *    E.G: Page has been refreshed by the browser.
   */
  public componentDidMount() {
    if (this.props.document) {
      const id = path(['_id'], this.props.document) as string;
      if (this.props.document?._editing === true) {
        this.props.document && this.props.loadFormValues(this.props.document);
        this.openDialog(id)();
      }
    }
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        {/* Dialog page */}
        <Dialog open={this.state.open} maxWidth="lg" fullWidth={true} PaperProps={{ square: true }}>
          {/* Top field with 'retro data' - checkbox */}
          <DialogContent ref={this.dialogContentRef} style={{ padding: '3rem', fontSize: '1.6rem' }}>
            {this.state.id && this.state.id !== 'noid' ? (
              <React.Fragment>
                {this.props.testType === 'UPDRS' && (
                  <FormRow title="updrs.totalScoreEntry">
                    <InputHandler
                      type="Checkbox"
                      editing={!this.props.viewing}
                      name="retrospectiveData"
                      formData={{
                        document:
                          this.props.retro || this.props.retro === false
                            ? { retrospectiveData: [this.props.retro] }
                            : this.state.formData || {},
                        onChange: this.props.retro || this.props.retro === false ? undefined : this.onChange,
                      }}
                      preset="yesForceEn"
                      disabled={this.props.retro === true || this.props.retro === false}
                    />
                  </FormRow>
                )}

                {/* MDS - UPRDSIII */}
                {(this.state.formData as IUPDRSIII)?.retrospectiveData?.[0] !== true && (
                  <div>
                    <div style={{ color: colors.primary, fontSize: '2.0rem', fontWeight: 600 }}>
                      <FormattedMessage id={`updrs.${this.props.testType === 'UPDRS' ? 'updrsIII' : 'mds'}`} />
                    </div>
                    {this.props.testType !== 'UPDRS' ? (
                      mdsUpdrsIIITestDescription
                    ) : (
                      <div style={{ margin: '0rem 0 1rem 0' }} />
                    )}
                    {this.props.testType === 'UPDRS' ? (
                      <QuestionStepper
                        formData={{
                          document: this.state.formData || {},
                          onChange: this.onChange,
                        }}
                        steps={stepsOld}
                        pageType={'updrs'}
                        docType={'iii'}
                        getStepContent={getStepContentOld}
                        viewing={this.props.viewing}
                        parent={this.dialogContent}
                      />
                    ) : (
                      <QuestionStepper
                        formData={{
                          document: this.state.formData || {},
                          onChange: this.onChange,
                        }}
                        steps={steps}
                        pageType={'updrs'}
                        docType={'iii'}
                        getStepContent={getStepContent}
                        viewing={this.props.viewing}
                        parent={this.dialogContent}
                      />
                    )}
                    <div>
                      {this.props.testType !== 'UPDRS' &&
                        dyskinesiaQuestions(!this.props.viewing, {
                          onChange: this.onChange,
                          document: this.state.formData,
                        })}
                    </div>
                    <div>
                      {this.props.testType === 'UPDRS' ? updrsIIITestReferenceInfo : mdsUpdrsIIITestReferenceInfo}
                    </div>
                  </div>
                )}
                {/* UPRDSIII */}
                {(this.state.formData as IUPDRSIII)?.retrospectiveData?.[0] === true && (
                  <React.Fragment>
                    <div style={{ color: colors.primary, fontSize: '2.0rem', fontWeight: 600, margin: '0 0 1rem 0' }}>
                      <FormattedMessage id="updrs.updrsIII" />
                    </div>
                    {!this.props.viewing && (
                      <FormRow title="updrs.manualScoreEng">
                        <InputHandler
                          type="NumberField"
                          editing={!this.props.viewing}
                          name="manualScore"
                          formData={{
                            document: this.state.formData || {},
                            onChange: this.onChange,
                          }}
                          placeholder="updrs.scoreManual"
                          min={0}
                          max={108}
                          width={12.6}
                          height={3}
                          maxLength={3}
                        />
                      </FormRow>
                    )}
                    {updrsIIITestReferenceInfo}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <FormattedMessage id="updrs.waitForID" />
            )}
          </DialogContent>
          {/* DialogAction field... */}
          <DialogActions
            style={{
              padding: '3rem 5rem',
            }}
          >
            <Container justifyContent="flex-end" alignItems="center">
              {!this.props.viewing ? (
                <React.Fragment>
                  {this.state.id && this.state.id !== 'noid' ? (
                    <React.Fragment>
                      <Item xs={6}>
                        {/* ...with save and cancel buttons */}
                        {(this.state.formData as IUPDRSIII)?.retrospectiveData?.[0] !== true && (
                          <Container>
                            <Item xs={3}>{scoreText}</Item>
                            <Item xs={9}>
                              <StyledScore>
                                {this.props.document && isUpdrsComplete(this.props.document, this.props.testType) ? (
                                  updrsScoreSum(this.props.document)
                                ) : (
                                  <InfoPopper text="general.notFilled" color="primary" />
                                )}
                              </StyledScore>
                            </Item>
                          </Container>
                        )}
                      </Item>
                      <Item xs={6}>
                        <Container justifyContent="flex-end" alignItems="center">
                          <Item>
                            <StyledCancel onClick={this.closeDialog(true)}>
                              <FormattedMessage id="general.cancel" />
                            </StyledCancel>
                          </Item>
                          <Item>
                            <ActionButton
                              text="general.accept"
                              onClick={this.closeDialog()}
                              width={12}
                              height={3}
                              fontSize={16}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </React.Fragment>
                  ) : undefined}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* ...with close button */}
                  <Container>
                    <Item xs={10}>
                      {((this.state.formData as IUPDRSIII)?.retrospectiveData?.[0] !== true &&
                        docHasValues(this.state.formData as IUPDRSIII)) ||
                      ((this.state.formData as IUPDRSIII)?.retrospectiveData?.[0] === true &&
                        (this.props.document?.manualScore || this.props.document?.manualScore === 0)) ? (
                        <Container>
                          <Item xs={2}>{scoreText}</Item>
                          <Item xs={10}>
                            {docHasValues(this.state.formData as IUPDRSIII) ? (
                              <StyledScore>{this.props.document ? updrsScoreSum(this.props.document) : 0}</StyledScore>
                            ) : this.props.document?.manualScore || this.props.document?.manualScore === 0 ? (
                              <StyledScore>{this.props.document?.manualScore}</StyledScore>
                            ) : (
                              ''
                            )}
                          </Item>
                        </Container>
                      ) : (
                        <StyledScore>{notFilledText}</StyledScore>
                      )}
                    </Item>
                    <Item xs={2}>
                      <Container justifyContent="flex-end">
                        <Item>
                          <ActionButton
                            text="general.close"
                            onClick={this.closeDialog(true)}
                            width={12}
                            height={3}
                            fontSize={16}
                          />
                        </Item>
                      </Container>
                    </Item>
                  </Container>
                </React.Fragment>
              )}
            </Container>
          </DialogActions>
        </Dialog>

        {/* Dialog fields of Levodopa and DBS-Effect test forms */}
        {this.state.formData === undefined || !this.props.document ? (
          <StyledUPDRSArea filled={!!this.state.formData}>
            <Container justifyContent="flex-end" alignItems="center">
              <Item xs={8}>{notFilledText}</Item>
              <Item xs={4}>
                <Container justifyContent="flex-end">
                  <Item>
                    {!this.props.viewing && (
                      <ActionButton
                        text="updrs.newUpdrsIII"
                        onClick={this.openDialog('noid')}
                        width={13}
                        height={3}
                        fontSize={16}
                      />
                    )}
                  </Item>
                </Container>
              </Item>
            </Container>
          </StyledUPDRSArea>
        ) : (
          <StyledUPDRSArea filled={!!this.state.formData}>
            <Container alignItems="center">
              <Item xs={8}>
                {((this.props.document.manualScore || this.props.document.manualScore === 0) &&
                  this.props.document.retrospectiveData?.[0] === true) ||
                (this.props.document.retrospectiveData?.[0] !== true && docHasValues(this.props.document)) ? (
                  <HistoryRowBasicItem
                    header={
                      this.props.document.retrospectiveData?.[0] !== true ? (
                        <FormattedMessage
                          id={`updrs.${this.props.testType === 'UPDRS' ? 'scoreManual' : 'scoreMDS'}`}
                        />
                      ) : (this.props.document.manualScore || this.props.document.manualScore === 0) ??
                        !docHasValues(this.props.document) ? (
                        <FormattedMessage id="updrs.scoreManual" />
                      ) : (
                        <FormattedMessage id="updrs.notFilled" />
                      )
                    }
                    value={
                      this.props.document &&
                      this.props.document.retrospectiveData?.[0] !== true &&
                      isUpdrsComplete(this.props.document, this.props.testType) ? (
                        `${updrsScoreSum(this.props.document)}`
                      ) : this.props.document.manualScore || this.props.document.manualScore === 0 ? (
                        `${this.props.document.manualScore ?? ''}`
                      ) : (
                        <FormattedMessage id="general.notFilled" />
                      )
                    }
                  />
                ) : (
                  <FormattedMessage id="updrs.notFilled" />
                )}
              </Item>
              <Item xs={4}>
                <Container justifyContent="flex-end">
                  <Item>
                    <ActionButton
                      text={!this.props.viewing ? 'general.edit' : 'general.view'}
                      onClick={this.openDialog(path(['_id'], this.props.document) as string)}
                      width={13}
                      height={3}
                      fontSize={16}
                    />
                  </Item>
                </Container>
              </Item>
            </Container>
          </StyledUPDRSArea>
        )}
      </React.Fragment>
    );
  }
}

interface IOwnState {
  formData?: IUPDRSIII | any;
  previousFormData?: IUPDRSIII | any;
  open: boolean;
  id?: string;
}

interface IDispatchFromProps {
  unsetEditing: (id: string) => void;
  clearFormValues: (id: string) => void;
  loadFormValues: (document: { _id: string }) => void;
  createDocument: (name: string, noFormUpdate?: boolean) => any;
  createCommit: (editingDocument: IEditingDocument, oldData?: IControlProps) => any;
  updateDocument: (editingDocument: IEditingDocument, formData?: TAnyObject, noFormUpdate?: boolean) => any;
  closeCommit: (editingDocument: IEditingDocument, formData?: TAnyObject) => void;
  deleteCommit: (editingDocument: IEditingDocument) => void;
  deleteDocument: (editingDocument: IEditingDocument, reason: { [key: string]: string | PartialDate }) => void;
}

const mapDispatchToProps = (dispatch: any): any => ({
  loadFormValues: (document: { _id: string }): void => dispatch(actions.checkForLoadFormValues(document)),
  clearFormValues: (id: string): void => dispatch(actions.clearFormValues(id)),
  unsetEditing: (id: string): void => dispatch(actions.unsetEditing(id)),

  createDocument: (name: string, noFormUpdate?: boolean): string =>
    dispatch(actionsD.createDocument(name, noFormUpdate)),
  createCommit: (editingDocument: IEditingDocument, oldData?: IControlProps): string =>
    dispatch(actionsD.createCommit(editingDocument, oldData)),
  updateDocument: (editingDocument: IEditingDocument, formData?: TAnyObject): string =>
    dispatch(actionsD.updateDocumentData(editingDocument, formData)),
  closeCommit: (editingDocument: IEditingDocument, formData?: TAnyObject): void =>
    dispatch(actionsD.closeCommit(editingDocument, formData)),
  deleteCommit: (editingDocument: IEditingDocument): void => dispatch(actionsD.deleteCommit(editingDocument)),
  deleteDocument: (editingDocument: IEditingDocument, reason: { [key: string]: string | PartialDate }): void =>
    dispatch(actionsD.deleteDocument(editingDocument, reason)),
});

interface IOwnProps {
  name: string;
  parentDate?: PartialDate;
  onChange: IFormData['onChange'];
  document?: IUPDRSIII;
  viewing?: boolean;
  context: string;
  retro?: boolean;
  testType?: 'UPDRS' | 'MDSUPDRS';
}

export default connect<TAnyObject, IDispatchFromProps, IOwnProps>(null, mapDispatchToProps)(UPDRSDialog);
