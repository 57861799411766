import * as React from 'react';
import styled from 'styled-components';

import { Menu, MenuItem, Paper } from '@mui/material';
import colors from '../../../config/theme/colors';
import { FormattedMessage } from 'react-intl';
import { Clear } from '@mui/icons-material';
import { isEmpty, omit, path } from 'ramda';
import { formatPartialDate } from 'neuro-utils';
import { Container, Item } from '../../../components/Grid';

export const StyledDiv = styled.div`
  display: table-cell;
`;

const StyledColorBox = styled.div`
  min-width: 4rem;
`;

export const StyledText = styled.div`
  color: ${colors.primary};
  font-size: 1.65rem;
  font-weight: 600;
`;

export const StyledClearIcon = styled(Clear)`
  display: block;
  width: 3rem;
  color: ${colors.secondaryText};
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

export const StyledTabArea = styled(({ ...other }) => <Paper square={true} {...omit(['tabState'], other)} />)``;

export const StyledTab = styled(Item as any)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${colors.primary};
  &:hover {
    cursor: pointer;
    background-color: rgba(4, 90, 139, 0.08);
  }
`;

export const settingsDescription = (lfpRegistration?: boolean): JSX.Element => (
  <React.Fragment>
    <Container justifyContent="flex-start" style={{ marginTop: '2rem' }}>
      <Item xs={2}>
        <StyledColorBox style={{ backgroundColor: colors.dbsUndefined }}>&nbsp;</StyledColorBox>
      </Item>
      <Item style={{ marginLeft: '2rem' }}>
        <FormattedMessage id="dbs.notInUse" />
      </Item>
    </Container>
    <Container justifyContent="flex-start" style={{ marginTop: '2rem' }}>
      <Item xs={2}>
        <StyledColorBox style={{ backgroundColor: colors.dbsPositive }}>&nbsp;</StyledColorBox>
      </Item>
      <Item style={{ marginLeft: '2rem' }}>
        <FormattedMessage id="dbs.positive" />
      </Item>
    </Container>
    <Container justifyContent="flex-start" style={{ marginTop: '2rem' }}>
      <Item xs={2}>
        <StyledColorBox style={{ backgroundColor: colors.dbsNegative }}>&nbsp;</StyledColorBox>
      </Item>
      <Item style={{ marginLeft: '2rem' }}>
        <FormattedMessage id="dbs.negative" />
      </Item>
    </Container>
    {lfpRegistration === true && (
      <Container justifyContent="flex-start" style={{ marginTop: '2rem' }}>
        <Item xs={2}>
          <StyledColorBox>
            <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 600 }}>R</div>
          </StyledColorBox>
        </Item>
        <Item style={{ marginLeft: '2rem' }}>
          <FormattedMessage id="dbs.registering" />
        </Item>
      </Container>
    )}
  </React.Fragment>
);

const SettingMenuContents = (
  settings: IDBSSetting[] | undefined,
  currentPrograms: IProgram[],
  selectedProgram: number,
  toggleSettingMenu: (e: React.MouseEvent<SVGSVGElement | HTMLElement>) => void,
  onClickSetting: (setting: { setting: string; program: number }) => void,
): JSX.Element | undefined => {
  const menuItem = (text: string | JSX.Element, onClick: (e: React.MouseEvent<HTMLElement>) => void): JSX.Element => (
    <MenuItem onClick={onClick}>{text}</MenuItem>
  );

  const defaultProgramNames = ['programA', 'programB', 'programC', 'programD'];

  const currentProgramsFiltered = currentPrograms.map((program: IProgram, index: number) =>
    program &&
    index !== selectedProgram &&
    path([selectedProgram, 'lfpRegistration'], currentPrograms) === path(['lfpRegistration'], program)
      ? program
      : undefined,
  );

  const latestPrevious = Array.isArray(settings) ? settings[0] : undefined;

  return !latestPrevious &&
    (!Array.isArray(currentProgramsFiltered) || currentProgramsFiltered.filter((program) => program).length < 1) ? (
    <MenuItem style={{ outline: 'none' }}>
      <FormattedMessage id="dbs.noSettings" />
    </MenuItem>
  ) : (
    <div>
      {Array.isArray(currentProgramsFiltered) && currentProgramsFiltered.filter((program) => program).length > 0 && (
        <React.Fragment>
          <MenuItem style={{ outline: 'none', color: colors.tertiaryText }}>
            <FormattedMessage id="dbs.current" />
          </MenuItem>
          {currentProgramsFiltered.map(
            (program: IProgram | undefined, index: number) =>
              program && (
                <div key={index}>
                  {menuItem(
                    isEmpty(program.programName) ? (
                      <FormattedMessage id={`dbs.opts.defaultProgramNames.${defaultProgramNames[index]}`} />
                    ) : (
                      program.programName ?? ''
                    ),
                    (e: React.MouseEvent<HTMLElement>): void => {
                      onClickSetting({ setting: 'current', program: index });
                      toggleSettingMenu(e);
                    },
                  )}
                </div>
              ),
          )}
        </React.Fragment>
      )}
      {latestPrevious && (
        <React.Fragment>
          <MenuItem style={{ outline: 'none', color: colors.tertiaryText }}>
            <FormattedMessage id="dbs.previous" />
            &nbsp;
            {latestPrevious?.generatorAdjustedDate
              ? `- ${formatPartialDate(latestPrevious?.generatorAdjustedDate)}`
              : ''}
          </MenuItem>
          {Array.isArray(latestPrevious.programs) &&
            latestPrevious.programs
              .filter(
                (program: IProgram) =>
                  path([selectedProgram, 'lfpRegistration'], currentPrograms) === path(['lfpRegistration'], program),
              )
              .map((program: IProgram, index: number) => (
                <div key={(program?.programName ?? '') + index}>
                  {menuItem(program?.programName ?? '', (e: React.MouseEvent<HTMLElement>): void => {
                    onClickSetting({ setting: 'previous', program: latestPrevious.programs?.indexOf(program) ?? 0 });
                    toggleSettingMenu(e);
                  })}
                </div>
              ))}
        </React.Fragment>
      )}
    </div>
  );
};

export const SettingSelectMenu = ({
  settings,
  currentPrograms,
  selectedProgram,
  toggleSettingMenu,
  showMenu,
  onClickSetting,
}: {
  settings: IDBSSetting[] | undefined;
  currentPrograms: IProgram[];
  selectedProgram: number;
  toggleSettingMenu: (e: React.MouseEvent<SVGSVGElement | HTMLElement>) => void;
  showMenu: SVGSVGElement | HTMLElement | null;
  onClickSetting: (setting: { setting: string; program: number }) => void;
}): JSX.Element => (
  <Menu
    open={!!showMenu}
    anchorEl={showMenu}
    onClose={toggleSettingMenu}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    PaperProps={{ style: { width: 'max-content', border: '0px' } }}
  >
    {SettingMenuContents(settings, currentPrograms, selectedProgram, toggleSettingMenu, onClickSetting)}
  </Menu>
);

export const StyledRow = ({
  firstElement,
  secondElement,
  thirdElement,
  parentCSS,
  childCSS,
}: IStyledRowProps): JSX.Element => {
  return (
    <Container style={parentCSS ? parentCSS : undefined}>
      <Item xs={4}>{firstElement}</Item>
      <Item xs={4} style={childCSS ? childCSS : undefined}>
        {secondElement}
      </Item>
      <Item xs={4} style={childCSS ? childCSS : undefined}>
        {thirdElement}
      </Item>
    </Container>
  );
};

interface IStyledRowProps {
  firstElement: string | JSX.Element | undefined;
  secondElement: string | JSX.Element | undefined;
  thirdElement: string | JSX.Element | undefined;
  parentCSS?: any;
  childCSS?: any;
}
