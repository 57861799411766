import { styled } from '@mui/system';
import InfoPopper from 'Components/InfoPopper';
import OptionChooser from 'Components/OptionChooser';
import { exists } from 'neuro-utils';
import * as React from 'react';
import { calculateIadlScore, iadlNumberFormatter } from 'Routes/DiagnosticSurvey/utils';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';

const ReferenceText = styled('div')({ color: colors.tertiaryText });

const iadlFields = [
  { name: 'phoneUsage', options: ['1a', '1b', '1c', '0'] },
  { name: 'shopping', options: ['1', '0a', '0b', '0c'] },
  { name: 'cooking', options: ['1', '0a', '0b', '0c'] },
  { name: 'housekeeping', options: ['1a', '1b', '1c', '1d', '0'] },
  { name: 'laundering', options: ['1a', '1b', '0'] },
  { name: 'transportation', options: ['1a', '1b', '1c', '0a', '0b'] },
  { name: 'responsibilityOfOwnMedication', options: ['1', '0a', '0b'] },
  { name: 'abilityToTakeCareOfFinancialMatters', options: ['1a', '1b', '0'] },
];

const IADLForm = ({ formData, view, fm }: IFormContext<IIADL>): JSX.Element => {
  const viewing = !!view?.viewing;

  const notFilledFields = iadlFields.filter(
    (field) => !formData.document[field.name as keyof typeof formData.document],
  );

  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormSection>
        {iadlFields.map((field, index) => (
          <FormRow key={field.name + index} title={`diagnosticSurvey.iadl.${field.name}.title`} bottomMargin={true}>
            <OptionChooser
              formData={formData}
              name={field.name}
              opts={field.options}
              optionFormatter={(name: string | number) => (
                <span>{fm(`diagnosticSurvey.iadl.${field.name}.opts.${name}`)}</span>
              )}
              numberFormatter={iadlNumberFormatter}
              viewing={viewing}
            />
          </FormRow>
        ))}
      </FormSection>
      <FormSection>
        <FormRow title="diagnosticSurvey.iadl.score">
          {exists(calculateIadlScore(formData.document)) ? (
            <div style={{ fontWeight: 600 }}>{calculateIadlScore(formData.document)}</div>
          ) : (
            <InfoPopper
              text={
                <div style={{ whiteSpace: 'pre-line' }}>
                  <div style={{ fontWeight: 600 }}>{fm('diagnosticSurvey.iadl.notFilledPartial')}</div>
                  {notFilledFields.map((field) => `${fm(`diagnosticSurvey.iadl.${field.name}.title`)}\n`).join('')}
                </div>
              }
              color="primary"
            />
          )}
        </FormRow>
        <ReferenceText>{fm('diagnosticSurvey.iadl.referenceText')}</ReferenceText>
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(IADLForm);
