import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import InfoPopper from '../../../../components/InfoPopper';

import { isUpdrsComplete, updrsScoreSum } from '../../utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const UPDRSIII = ({ documents, startEdit, view }: IOwnProps): JSX.Element => {
  documents = documents ? documents.filter((d) => d.type !== 'levodopa' && d.type !== 'dbs') : [];
  return (
    <HistorySection
      headerText={<FormattedMessage id={'updrs.updrsIII'} />}
      newButton={
        <DocumentCreationButton
          name="updrs_iii"
          text={'updrs.newUpdrsIII'}
          onClick={startEdit({}, 'updrs_iii')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={4}>
              <FormattedMessage id={'updrs.scoreMDS'} />
            </Item>
            <Item xs={4}>
              <FormattedMessage id={'updrs.scoreManual'} />
            </Item>
          </>
        }
        contentFormat={(d: IUPDRSIII): JSX.Element => (
          <>
            <Item xs={4}>
              {d.testType === 'MDSUPDRS' ? (
                isUpdrsComplete(d, d.testType) ? (
                  updrsScoreSum(d)
                ) : (
                  <InfoPopper text="general.notFilled" color="primary" />
                )
              ) : (
                '-'
              )}
            </Item>
            <Item xs={4}>
              {d.testType === 'UPDRS' ? (
                d.retrospectiveData?.[0] === true ? (
                  d.manualScore
                ) : isUpdrsComplete(d, d.testType) ? (
                  updrsScoreSum(d)
                ) : (
                  <InfoPopper text="general.notFilled" color="primary" />
                )
              ) : (
                '-'
              )}
            </Item>
          </>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IUPDRSIII[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default UPDRSIII;
