import * as React from 'react';

import FormEditingHandler from '../../../containers/FormEditingHandler';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryRow from '../../../components/HistoryRow';
import CollapseElem from '../../../components/Collapse';
import HistoryRowControls from '../../../components/HistoryRowControls';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';

import HistoryRowData from './HistoryRowData';
import LifestyleFormDynamic from './FormDynamic';
import DocumentCreationButton from '../../../components/DocumentCreationButton';

const Lifestyle = ({ documents }: IOwnProps): JSX.Element => {
  documents && documents.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();
  const first = documents?.[0] && documents[0];
  const restArr = documents && documents.slice(1);

  return (
    <FormEditingHandler name="lifestyle" documents={documents}>
      {(editing, startEdit, formData): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'lifestyle'}
            headerId={'lifestyle.title'}
            editing={editing}
            editButtons={
              <div>
                <DocumentCreationButton name="lifestyle" text={'general.new'} onClick={startEdit({})} />
              </div>
            }
          />

          {editing ? <LifestyleFormDynamic formData={formData} documents={documents} /> : undefined}

          {!editing && documents && documents.length > 0 ? (
            <>
              <HistoryRow
                headerText={first.date ? formatPartialDate(first.date) : undefined}
                key={first._id}
                rowButton={<HistoryRowControls document={first} startEdit={startEdit} />}
                controlsMargin={false}
              >
                <HistoryRowData d={first} docs={documents} startEdit={startEdit} />
              </HistoryRow>
              {documents.length > 1 && (
                <CollapseElem
                  localeIDs={{ showMessage: 'lifestyle.showPastDocuments', hideMessage: 'lifestyle.hidePastDocuments' }}
                  amount={restArr.length}
                >
                  {restArr.map((d: ILifestyle) => (
                    <HistoryRow
                      headerText={d.date ? formatPartialDate(d.date) : undefined}
                      key={d._id}
                      rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                      controlsMargin={false}
                    >
                      <HistoryRowData d={d} docs={documents} startEdit={startEdit} />
                    </HistoryRow>
                  ))}
                </CollapseElem>
              )}
            </>
          ) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: ILifestyle[];
}

export default Lifestyle;
