import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container, Item } from '../../../../components/Grid';
import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
const StyledHistory = styled.div``;

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const Diet = ({ document }: IOwnProps): JSX.Element => {
  return (
    <StyledHistory>
      <Container>
        <Item xs={5}>
          <HistoryRowBasicItem
            header={fm('dietTherapy.diet')}
            value={document.diet ? fm(`dietTherapy.opts.${document.diet}`) : '-'}
          />
        </Item>
        <Item>
          <HistoryRowBasicItem
            header={fm('dietTherapy.reason')}
            value={
              <Item>
                {Array.isArray(document.reason)
                  ? document.reason.map((r, i) => <div key={i}>{fm(`dietTherapy.opts.${r}`)}</div>)
                  : '-'}
              </Item>
            }
          />
        </Item>
      </Container>
    </StyledHistory>
  );
};

interface IOwnProps {
  document: IDietTherapy;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default Diet;
