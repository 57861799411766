import { isEmpty, omit, path } from 'ramda';
import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import InputHandler from '../../../../components/InputHandler';
import { SimpleRowInput } from '../../../../components/SimpleRowInput';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { Container, Item } from '../../../../components/Grid';
import FormRow from '../../../../components/FormRow';
import { createID } from '../../../../utility/appendIDs';
import { formatPartialDate } from 'neuro-utils';
import ActionButton from '../../../../components/ActionButton';

const StyledButton = styled.button`
  font-weight: 600;
  text-align: center;
  border: 1px solid ${colors.primary};
  color: ${colors.primary};
  background-color: inherit;
  font-family: inherit;
  font-size: 16px;
  width: 20rem;
  height: 3.6rem;
  cursor: pointer;
  &:hover {
    background-color: rgba(4, 90, 139, 0.1);
  }
  &:active {
    animation-name: onclick;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
  }
  @keyframes onclick {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const EventStyle = styled.div`
  &:hover {
    animation-name: onHover;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    border: 0.1rem solid;
    border-color: ${colors.lightGray};
    border-radius: 1rem 1rem 1rem 1rem;
    box-shadow: 0.2rem 0.2rem 0.2rem ${colors.lightGray};
  }
  width: 42rem;
  margin-bottom: 1rem;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border: 0.1rem solid;
  border-color: transparent;
  @keyframes onHover {
    from {
      border: 0.1rem solid;
      border-color: transparent;
      box-shadow: 0.2rem 0.2rem 0.2rem transparent;
    }
    to {
      border: 0.1rem solid;
      border-color: ${colors.lightGray};
      box-shadow: 0.2rem 0.2rem 0.2rem ${colors.lightGray};
    }
  }
`;

const PreviousValueText = styled.div`
  font-size: 1.4rem;
  color: ${colors.secondaryText};
`;

/**
 * Component for driving ban or pregnancy event
 *
 * @param {{ formData: IFormData<IBackground>; item: IDrivingBan | IPregnancyMS | INursing; index: number; openDeleteDialog: (name: keyof IFormData<IBackground>['document'], index: number) => void; eventKey: keyof IFormData<IBackground>['document']; datesDontOverlap: boolean }} props
 * @returns {JSX.Element}
 */
const Event = ({ formData, item, index, openDeleteDialog, eventKey, datesDontOverlap }: IEventProps): JSX.Element => {
  const [hideControls, setHideControls] = React.useState<boolean>(true);

  const onChangeDateRanges =
    (index: number) =>
    (values: TOnChangeValues): void => {
      const valuesName = Object.keys(values)[0];
      const value = values[valuesName];
      if (formData.onChange) {
        const newDrivingBans = JSON.parse(JSON.stringify(path(['document', eventKey], formData)));
        newDrivingBans[index][valuesName] = value;
        formData.onChange({ [eventKey]: newDrivingBans });
      }
    };

  return (
    <React.Fragment>
      <EventStyle
        key={index}
        onMouseEnter={(): void => setHideControls(false)}
        onMouseLeave={(): void => setHideControls(true)}
      >
        <SimpleRowInput
          clickHandler={(): void => openDeleteDialog(eventKey, index)}
          controlStyle={{ marginLeft: '6.6rem' }}
          hideControls={hideControls}
          name={eventKey}
          firstItem={
            <>
              <div>
                <FormattedMessage id="background.banStartDate" />
              </div>
              <div style={{ marginBottom: '0.5rem' }}>
                <InputHandler
                  name="startDate"
                  type="PartialDate"
                  editing={true}
                  formData={{
                    onChange: onChangeDateRanges(index),
                    document: {
                      [eventKey]: {
                        [index]: {
                          startDate: path([eventKey, index, 'startDate'], formData.document),
                        },
                      },
                    },
                  }}
                  value={item.startDate}
                  isNotCancellable={true}
                  dateHook={
                    datesDontOverlap
                      ? {
                          dateHookCeiling: item.endDate,
                        }
                      : undefined
                  }
                />
              </div>
              <div>
                <FormattedMessage id="background.banEndDate" />
              </div>
              <div>
                <InputHandler
                  name="endDate"
                  type="PartialDate"
                  editing={true}
                  formData={{
                    onChange: onChangeDateRanges(index),
                    document: {
                      [eventKey]: {
                        [index]: {
                          endDate: path([eventKey, index, 'endDate'], formData.document),
                        },
                      },
                    },
                  }}
                  value={item.endDate}
                  dateHook={
                    datesDontOverlap
                      ? {
                          dateHookFloor: item.startDate,
                        }
                      : undefined
                  }
                />
              </div>
            </>
          }
        />
      </EventStyle>
    </React.Fragment>
  );
};

interface IEventProps {
  formData: IFormData<IBackground>;
  item: IDrivingBan | IPregnancyMS | INursing;
  index: number;
  openDeleteDialog: (name: keyof IFormData<IBackground>['document'], index: number) => void;
  eventKey: keyof IFormData<IBackground>['document'];
  datesDontOverlap?: boolean;
}

/**
 * Type guard for IDrivingBan or IPregnancyMS or INursing
 *
 * @param {unknown} d
 * @returns {d is IDrivingBan | IPregnancyMS | INursing}
 */
const isValidValueType = (d: unknown): d is IDrivingBan | IPregnancyMS | INursing =>
  typeof d === 'object' &&
  d !== null &&
  !Array.isArray(d) &&
  (Object.keys(d).includes('startDate') || Object.keys(d).includes('endDate'));

/**
 * Component for previous value info tag
 *
 * @param {{ reviousDocs: Array<IBackground>; draw: boolean; eventKey: string }} param props
 * @returns {JSX.Element}
 */
const PreviousValue = ({ previousDocs, draw, eventKey }: IPreviousValueProps): JSX.Element => {
  const previousValues: { event?: Array<IDrivingBan | IPregnancyMS | INursing>; date: PartialDate | undefined } =
    previousDocs.map((d: IBackground) => {
      const events = path([eventKey], d);
      if (Array.isArray(events) && events.length > 0) {
        const validEvents: Array<IDrivingBan | IPregnancyMS | INursing> = events.filter(
          (b: unknown): b is IDrivingBan | IPregnancyMS | INursing => !isEmpty(b) && isValidValueType(b),
        );
        return { event: validEvents, date: d.date };
      }
      return { date: d.date };
    })[0];

  return (
    <React.Fragment>
      {draw && previousValues && previousValues.event && previousValues.event.length > 0 ? (
        <PreviousValueText>
          <FormattedMessage id="general.previousData" />:
          <div style={{ fontWeight: 600 }}>
            {previousValues.event.map((e: IDrivingBan | IPregnancyMS | INursing, i: number) => (
              <div key={i}>
                {e.startDate ? formatPartialDate(e.startDate) : '-'} - {e.endDate ? formatPartialDate(e.endDate) : '-'}
              </div>
            ))}
          </div>
          {<div>{formatPartialDate(previousValues.date)}</div>}
        </PreviousValueText>
      ) : null}
    </React.Fragment>
  );
};

interface IPreviousValueProps {
  previousDocs: Array<IBackground>;
  draw: boolean;
  eventKey: string;
}

/**
 * Component for event list element
 *
 * @param {{ formData: IFormData<IBackground>; previousDocs: Array<IBackground>; (name: keyof IFormData<IBackground>['document'], index: number) => void; title: string; eventKey: keyof IFormData<IBackground>['document'] }} props
 * @returns {JSX.Element}
 */
const EventList = ({
  formData,
  previousDocs,
  openDeleteDialog,
  title,
  addButton,
  eventKey,
  datesDontOverlap,
  intl,
}: IEventListProps): JSX.Element => {
  const fm = (id: string): string => intl.formatMessage({ id: id });

  const drivingBans = path(['document', eventKey], formData);

  const onNewRowAdd = (formData: IEventListProps['formData']): void => {
    const drivingBans = path(['document', eventKey], formData);
    let items = [];
    items = Array.isArray(drivingBans) ? drivingBans : [];
    items = [...items, { startDate: '', endDate: '', id: createID() }];
    formData.onChange && formData.onChange({ [eventKey]: items });
  };

  const onConfirmSet = (formData: IEventListProps['formData'], set: any): void => {
    onNewRowAdd(formData);
    const setWithNoIDs = set?.map((obj: any) => omit(['id'], obj));
    const setWithNewIDs = setWithNoIDs?.map((o: any) => Object.assign({ id: createID(), ...o }));
    if (formData.onChange) {
      formData.onChange({ [eventKey]: setWithNewIDs });
    }
  };

  const onDeleteAll = (name: string): void => {
    formData.onChange && formData.onChange({ [name]: null });
  };

  return (
    <React.Fragment>
      <Container style={{ marginBottom: '4.5rem' }}>
        <Item xs={12}>
          <FormRow title={title}>
            <Container>
              <Item xs={!drivingBans || isEmpty(drivingBans) ? 5 : 7}>
                <div>
                  {Array.isArray(drivingBans) &&
                    drivingBans.map((item: IDrivingBan | IPregnancyMS | INursing, index: number) => (
                      <Event
                        key={item.id}
                        formData={formData}
                        item={item}
                        index={index}
                        openDeleteDialog={openDeleteDialog}
                        eventKey={eventKey}
                        datesDontOverlap={datesDontOverlap}
                      />
                    ))}
                </div>
                <div>
                  <div onClick={(): void => onNewRowAdd(formData)}>
                    <StyledButton> {fm(addButton)}</StyledButton>
                  </div>
                </div>
              </Item>
              <Item xs={!drivingBans || isEmpty(drivingBans) ? 7 : 5} style={{ paddingLeft: '2rem' }}>
                <Container>
                  <Item xs={8}>
                    <PreviousValue
                      previousDocs={previousDocs}
                      draw={!drivingBans || isEmpty(drivingBans)}
                      eventKey={eventKey}
                    />
                  </Item>
                  <Item xs={4}>
                    <Container justifyContent="flex-end">
                      <Item>
                        {(!drivingBans || isEmpty(drivingBans)) &&
                        previousDocs
                          .map((d) => ({ bans: path([eventKey], d), date: d.date }))
                          .filter((ban) => ban && ban.bans && !isEmpty(ban.bans)).length > 0 ? (
                          <ActionButton
                            text="general.confirm"
                            width={8}
                            height={3}
                            fontSize={14}
                            onClick={() => {
                              onConfirmSet(
                                formData,
                                previousDocs
                                  .map((d) => ({ bans: path([eventKey], d), date: d.date }))
                                  .filter((ban) => ban && ban.bans && !isEmpty(ban.bans))[0].bans,
                              );
                            }}
                          />
                        ) : drivingBans && !isEmpty(drivingBans) ? (
                          <ActionButton
                            text="general.clear"
                            width={8}
                            height={3}
                            fontSize={14}
                            onClick={() => onDeleteAll(eventKey)}
                          />
                        ) : undefined}
                      </Item>
                    </Container>
                  </Item>
                </Container>
              </Item>
            </Container>
          </FormRow>
        </Item>
      </Container>
    </React.Fragment>
  );
};

interface IEventListProps {
  formData: IFormData<IBackground>;
  previousDocs: Array<IBackground>;
  openDeleteDialog: (name: keyof IFormData<IBackground>['document'], index: number) => void;
  title: string;
  addButton: string;
  eventKey: keyof IFormData<IBackground>['document'];
  datesDontOverlap?: boolean;
  intl: IntlShape;
}

export default injectIntl(EventList);
