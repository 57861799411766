import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import arrowIcon from './down-arrow-circle.svg';

import colors from '../../config/theme/colors';
import { getSectionTitle } from '../../utility/randomUtil';
import { Container, Item } from '../Grid';
import { screenBreakpoints } from '../../config/theme/componentTheme';

const StyledMenu = styled.div`
  cursor: pointer;
`;

const StyledMenuItem = styled(({ ...other }) => <MenuItem {...other} />)`
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  color: ${colors.primaryText} !important;
`;

const ArrowIcon = styled(({ ...other }) => <img src={arrowIcon} {...other} />)`
  margin-left: 1.5rem;
  height: 2rem;
  display: block;
`;

// Control section text length while in history view
const SectionText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: ${screenBreakpoints.xl}px) {
    max-width: 50rem !important;
  }
  @media only screen and (max-width: ${screenBreakpoints.lg}px) {
    max-width: 45rem !important;
  }
  @media only screen and (max-width: ${screenBreakpoints.md}px) {
    max-width: 30rem !important;
  }
`;

const makeItems = (onClick: () => void, names: string[], platform?: Platform): JSX.Element[] =>
  names.map((n) => (
    <Link to={'/' + n} key={n} style={{ backgroundColor: '#fff' }}>
      <StyledMenuItem onClick={onClick}>
        <FormattedMessage id={`${n}.${getSectionTitle(n, platform)}`} />
      </StyledMenuItem>
    </Link>
  ));

const NavigationMenu = ({ current, names, platform }: IOwnProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const openMenu = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (): void => {
    setAnchorEl(null);
  };

  const section = `${current}.${getSectionTitle(current, platform)}`;

  return (
    <StyledMenu>
      <div onClick={openMenu}>
        <Container justifyContent="flex-start" alignItems="center" style={{ height: '100%' }}>
          <Item>
            <SectionText>
              <FormattedMessage id={section} />
            </SectionText>
          </Item>
          <Item>
            <ArrowIcon />
          </Item>
        </Container>
      </div>
      <Menu
        id="navigationMenu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        transitionDuration={10}
      >
        {makeItems(closeMenu, names, platform)}
      </Menu>
    </StyledMenu>
  );
};

interface IOwnProps {
  current: string;
  names: string[];
  platform?: Platform;
}

export default NavigationMenu;
