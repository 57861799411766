import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { path } from 'ramda';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';

import { getActiveTreatments, getEndedTreatments, getRightDocumentsForPlatform, TDocument } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const TreatmentDash = ({ documents }: IOwnProps): JSX.Element => {
  const treatmentDocs = getRightDocumentsForPlatform(documents);

  const sortedArr = treatmentDocs
    ?.sort((n1, n2) => sortPartialDate('date' in n1 ? n1.date : n1.date, 'date' in n2 ? n2.date : n2.date))
    .reverse();

  const activeTreatments = getActiveTreatments(sortedArr);
  const endedTreatments = getEndedTreatments(sortedArr);

  const treatmentsToBeShown = Array.isArray(activeTreatments) ? activeTreatments.slice(0, 5) : undefined;

  return (
    <React.Fragment>
      {treatmentsToBeShown && (
        <TileContentMaker
          type="recentList"
          data={treatmentsToBeShown.map((t) => ({
            date: isPartialDate(t.date) ? formatPartialDate(t.date) : '',
            title: (
              <>
                {t._type === 'oxygenTherapy' && (t as IOxygenTherapy).oxygenTherapyType ? (
                  <FormattedMessage id={`treatment.oxygenTherapy.opts.${(t as IOxygenTherapy).oxygenTherapyType}`} />
                ) : (
                  <FormattedMessage id={`treatment.${t._type}.title`} />
                )}
                {'otherTreatmentType' in t ? (
                  <>
                    <span>: </span>
                    <FormattedMessage id={`treatment.otherTreatment.opts.${t.otherTreatmentType}`} />
                  </>
                ) : (
                  ''
                )}
                {t._type === 'papTherapy' && path(['devices', 0, 'type'], t) ? (
                  <>
                    <span>: </span>
                    <FormattedMessage id={`treatment.papTherapy.opts.${path(['devices', 0, 'type'], t)}`} />
                  </>
                ) : (
                  ''
                )}
              </>
            ),
            endDate: 'endDate' in t && isPartialDate(t.endDate) ? formatPartialDate(t.endDate) : '',
            key: t._id,
          }))}
        />
      )}
      <TileContentMaker
        type="specificListNoDate"
        data={[
          {
            title: <FormattedMessage id="treatment.endedTreatmentsAmount" />,
            value: `${endedTreatments.length}`,
            key: 'endedTreatmentsAmount',
          },
        ]}
      />
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: (TDocument & IDiagnosis)[];
}

export default TreatmentDash;
