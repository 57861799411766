import DataTable from 'Components/DataTable';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import QuestionAnswerPair from 'Components/_NewElements/QuestionAnswerPair';
import { ICompactVaultUser } from 'neuro-data-structures';
import { ISatisfactionWithCare, satisfactionWithCare, isLocaleKey } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';
import { surveyCreator, surveyModifyer } from '../../HistoryRowData/SurveysHistory';

export const SatisfactionWithCare = ({ patientMyid, users }: IOwnProps): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing, setViewingObj, setEditingObj } = myServContext;
  const swcData = (editing?.data || viewing?.data) as ISatisfactionWithCare & IControlProps;
  const swcLocales = satisfactionWithCare.locales;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';
  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...swcData,
      [field]: value,
    });
  };

  return (
    <>
      {viewing && (
        <div style={{ marginBottom: '4rem' }}>
          <QuestionAnswerPair
            question={fm('myService.reporter')}
            answer={surveyCreator(swcData, patientMyid, users, fm)}
          />
          {surveyModifyer(swcData, patientMyid, users, fm) && (
            <QuestionAnswerPair
              question={fm('myService.edited')}
              answer={surveyModifyer(swcData, patientMyid, users, fm)}
            />
          )}
        </div>
      )}
      <BlockWrapper
        title={viewing ? formatPartialDate(swcData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'satisfactionWithCare', data: swcData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'} headerWidth={4.2}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: swcData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <div style={{ width: '60%', marginBottom: '2rem', marginTop: viewing ? '6rem' : undefined }}>
          <DataTable
            headers={['', '']}
            data={{
              rowData: [
                [
                  swcLocales[useLocale]['title'],
                  <InputHandler
                    key="satisfactionWithCare"
                    name="satisfactionWithCare"
                    type="Radio"
                    editing={!!editing}
                    options={['satisfied', 'somewhatSatisfied', 'dissatisfied']}
                    optionFormatter={(o) => swcLocales[useLocale][`opts.${o}`]}
                    formData={{
                      document: { satisfactionWithCare: swcData?.satisfactionWithCare },
                      onChange: onChangeSurveyForm,
                    }}
                  />,
                ],
                [
                  swcLocales[useLocale]['remarkableHarmFromCare'],
                  <InputHandler
                    key="harm"
                    name="remarkableHarmFromCare"
                    type="Radio"
                    editing={!!editing}
                    options={['yes', 'no']}
                    optionFormatter={(o) => swcLocales[useLocale][`opts.${o}`]}
                    formData={{
                      document: { remarkableHarmFromCare: swcData?.remarkableHarmFromCare },
                      onChange: onChangeSurveyForm,
                    }}
                  />,
                ],
              ],
              rowStyle: viewing ? undefined : [{ rowAlign: 'top' }, { rowAlign: 'top' }],
            }}
          />
        </div>
      </BlockWrapper>
    </>
  );
};

interface IOwnProps {
  patientMyid: string;
  users: ICompactVaultUser[];
}
