// Define which fields to pick for shared form (for My documents)
export const sharedRowData: Partial<{
  [key in Platform]: { [key: string]: string[] };
}> = {
  sleepApnea: {
    drivingHealth: ['driversLicense', 'annualDrivingKM', 'driversLicenseGroup', 'professionalDriving'],
    smoking: ['smoker', 'smokingStartYear', 'cigaretteAmountPerDay', 'smokingEndYear'],
    intoxicantUsage: ['averageWeeklyAlcohol', 'otherIntoxicants', 'whichIntoxicants'],
    employment: ['employment'],
  },
};
