import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => {
    return <FormattedMessage id={`imaging.${opt}.${name}`} />;
  };

const TTForm = ({ formData }: IOwnProps): JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={true}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="imaging.atrophy">
        <InputHandler
          type="Radio"
          editing={true}
          name="atrophy"
          formData={formData}
          options={['no', 'appropriateForAge', 'pronouncedCommon', 'focal']}
          optionFormatter={optionFormatter('opts.atrophy')}
        />
      </FormRow>
      {formData.document.atrophy === 'focal' && (
        <FormRow title="imaging.atrophyLocalization">
          <InputHandler type="TextArea" editing={true} name="atrophyLocalization" formData={formData} />
        </FormRow>
      )}
      <FormRow title="imaging.otherClinicallyRelevantFindingFound" id={'otherRelevant'}>
        <InputHandler
          type="Radio"
          editing={true}
          name="otherClinicallyRelevantFindingFound"
          formData={formData}
          options={['yes', 'no']}
          optionFormatter={optionFormatter('opts.yesNo')}
        />
      </FormRow>
      {formData.document.otherClinicallyRelevantFindingFound === 'yes' && (
        <FormRow title="imaging.whatFinding">
          <InputHandler type="TextArea" editing={true} name="otherClinicallyRelevantFinding" formData={formData} />
        </FormRow>
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<ITT>;
}

export default TTForm;
