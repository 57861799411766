import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch as useDispatch } from 'Store/index';
import styled from 'styled-components';
import { flatten, path } from 'ramda';

import { actions } from '../../store/form';

import colors from '../../config/theme/colors';
import { styleDocument } from '../../config/theme/componentTheme';
import { exists, formatPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import ActionButton from '../ActionButton';
import Tooltip from '../../components/ToolTip';
import { appendIDs } from '../../utility/appendIDs';
import { Container, Item } from 'Components/Grid';

const StyledDiv = styled.div`
  margin: 0 -${styleDocument.padding};
  padding: 3rem ${styleDocument.padding};
  border-top: 1px solid #c8c8c8;
`;

const StyledHeader = styled.div`
  color: ${colors.primary};
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const PreviousValueText = styled.div`
  font-size: 1.4rem;
  color: ${colors.secondaryText};
`;

const PreviousValueDate = styled.span`
  font-size: 1.4rem;
  color: ${colors.secondaryText};
`;

const displayPreviousValue = (
  prevValue?: TPrevValue,
  optionFormatter?: (s: string, field?: string) => JSX.Element | string,
  saveSingleValue?: (name: string) => () => void,
): JSX.Element | undefined =>
  prevValue ? (
    <PreviousValueText>
      {prevValue && (
        <React.Fragment>
          <div style={{ fontWeight: 600 }}>
            {exists(prevValue.value) ? (
              <Tooltip
                description={
                  prevValue.date ? <PreviousValueDate>{formatPartialDate(prevValue?.date)}</PreviousValueDate> : ''
                }
                content={
                  <span onClick={saveSingleValue && saveSingleValue(prevValue.name)}>
                    {optionFormatter ? optionFormatter(prevValue.value, prevValue.name) : prevValue.value}
                  </span>
                }
                hover={true}
              />
            ) : (
              ''
            )}
          </div>
        </React.Fragment>
      )}
    </PreviousValueText>
  ) : undefined;

const renderWithHistory = (
  element?: JSX.Element,
  prevValue?: TPrevValue,
  optionFormatter?: IOwnProps['optionFormatter'],
  hideHistory?: boolean,
  commitsLength?: number,
  saveSingleValue?: (name: string) => () => void,
): JSX.Element => (
  <Container>
    <Item xs={true}>{element}</Item>
    <Item xs={2} style={{ marginTop: '0.7rem' }}>
      {!hideHistory && (commitsLength ?? 0) <= 1 && (
        <Container justifyContent="flex-start">
          <Item>{displayPreviousValue(prevValue, optionFormatter, saveSingleValue)}</Item>
        </Container>
      )}
    </Item>
  </Container>
);

const FormSectionHistoryAcceptor = ({
  documents,
  formData,
  optionFormatter,
  header,
  condition = true,
  headerRef = undefined,
  children,
  documentID,
  hideCopyButton = false,
  name,
  prevDocOnly = false,
  prevDocWithField = false,
}: IOwnProps): JSX.Element | null => {
  // Previous values based on date
  const [prevValues, setPrevValues] = React.useState<TPrevValue[]>([]);

  const childrenIdValuePairs = React.useRef(
    appendIDs(Object.keys(children).map((key) => path([key, 'element', 'props', 'name'], children) ?? key) as string[]),
  );

  const commitsLength = documents?.find((d: TDocument) => d._id === documentID)?._commitsLength ?? 0;

  React.useEffect(() => {
    if (formData?.document.date) {
      // All documents previous to editing documents date
      let previousDocs =
        documents?.filter((d) => partialDateToValue(d?.date) < partialDateToValue(formData?.document.date)) || [];

      if (previousDocs.length === 0) {
        setPrevValues([]);
        return;
      }

      // Filter documents being edited (current form basically)
      previousDocs = previousDocs.filter((d) => !d._editing);

      // Sort by date
      previousDocs.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();

      // Takes in account only the latest document
      if (prevDocOnly) {
        previousDocs = [previousDocs[0]];
      }

      const valueArray = [] as TPrevValue[];

      // Fetch field names from inside groups
      const groupNames = Object.keys(children).map((c) =>
        children[c] && children[c].group ? Object.keys((children[c].group as IGroupElement).children) : null,
      );
      const filteredGroupNames = flatten(groupNames.filter((n) => n !== null)) as string[];

      // Get previous values for all children
      let names =
        (Object.keys(children).map((key) => path([key, 'element', 'props', 'name'], children) ?? key) as string[]) ||
        [];
      names = [...names, ...filteredGroupNames];

      // Takes in account only the latest document with some of the fields filled
      // Useful if FormSectionHistoryAcceptor has multiple InputHandlers inside
      if (prevDocWithField) {
        const prevDocWithSomeOfField = previousDocs.find((d) => names.some((n) => d[n]));
        previousDocs = prevDocWithSomeOfField ? [prevDocWithSomeOfField] : [];
      }

      names.forEach((n) => {
        // Get first occurance of the value from previous docs
        previousDocs.some((d) => {
          // Access nested property if given
          if (name) {
            if (exists(path(name.split('.').concat(n), d))) {
              const value = path(name.split('.').concat(n), d);
              valueArray.push({ name: n, date: d['date'], value });
              return true;
            } else return false;
          } else if (exists(d[n])) {
            valueArray.push({ name: n, date: d['date'], value: d[n] });
            return true;
          } else return false;
        });
      });
      setPrevValues(valueArray);
    } else {
      setPrevValues([]);
    }
  }, [formData?.document.date, children, documents, name]);

  const dispatch = useDispatch();
  const saveValues = (): void => {
    let values = {};
    let nestedObj = {};
    prevValues &&
      prevValues.forEach((p) => {
        // Get previous value
        values = { ...values, [p.name]: p.value };
      });
    // Construct nested property if given
    if (name) {
      const path = name.split('.');
      path.reverse();
      nestedObj = path.reduce(
        (prev, current, currentIndex) => ({ [current]: currentIndex === 0 ? values : { ...prev } }),
        {},
      );

      values = nestedObj;
    }
    documentID && dispatch(actions.updateFormValuesBatch(values, documentID));
  };

  const saveSingleValue = (name: string) => (): void => {
    const value = prevValues.find((v) => v.name === name);
    if (value && documentID) dispatch(actions.updateFormValues(documentID, { [name]: value.value }));
  };

  const renderElement = (el: IElement, name: string): JSX.Element | null => {
    return (
      el &&
      (el.element && el.condition !== false
        ? renderWithHistory(
            el.element,
            prevValues.find((p) => p.name === name),
            el.optionFormatter || optionFormatter, // Use field specific optF or section specific
            hideCopyButton,
            commitsLength,
            saveSingleValue,
          )
        : el.header && el.condition !== false
          ? el.header
          : null)
    );
  };

  return condition ? (
    <StyledDiv ref={headerRef}>
      <StyledHeader>
        <Container>
          <Item xs={10}>{header && <FormattedMessage id={header} />}</Item>

          {prevValues.length > 0 && (
            <Item xs={2}>
              <Container justifyContent="flex-start">
                {!hideCopyButton && commitsLength <= 1 && (
                  <Item>
                    <ActionButton
                      text="general.copyPrevious"
                      onClick={saveValues}
                      width={16}
                      height={3}
                      fontSize={14}
                    />
                  </Item>
                )}
              </Container>
              <Container
                justifyContent="flex-start"
                style={{ color: colors.secondaryText, fontSize: '1.4rem', marginTop: '1rem', fontWeight: 1 }}
              >
                {!hideCopyButton && commitsLength <= 1 && (
                  <React.Fragment>
                    <Item xs={5}>
                      <FormattedMessage id="general.previousData" />
                      {':'}
                    </Item>
                    <Item xs={7}>
                      <div style={{ marginLeft: '1rem' }}>
                        {prevValues[0].date ? ` ${formatPartialDate(prevValues[0].date)}` : ''}
                      </div>
                    </Item>
                  </React.Fragment>
                )}
              </Container>
            </Item>
          )}
        </Container>
      </StyledHeader>

      {Array.isArray(childrenIdValuePairs.current) &&
        childrenIdValuePairs.current.map((k) => (
          <React.Fragment key={k.id}>
            {children[k.value] && children[k.value].group && children[k.value].group?.condition !== false
              ? children[k.value].group?.groupElement(
                  <React.Fragment>
                    {(children[k.value].group as IGroupElement).children &&
                      Object.keys((children[k.value].group as IGroupElement).children).map((c) => (
                        <React.Fragment key={c}>
                          {renderElement((children[k.value].group as IGroupElement).children[c], c)}
                        </React.Fragment>
                      ))}
                  </React.Fragment>,
                )
              : renderElement(children[k.value], k.value)}
          </React.Fragment>
        ))}
    </StyledDiv>
  ) : null;
};

type TPrevValue = { name: string; date?: PartialDate; value?: any };

type TDocument = IControlProps & { [key: string]: any; date?: PartialDate };

export interface IElement {
  group?: IGroupElement;
  element?: JSX.Element; // Field element
  optionFormatter?: (s: string, field?: string) => JSX.Element;
  header?: JSX.Element; // Header element in case we just want to render a text without other stuff
  condition?: boolean; // If false dont render anything
}

interface IGroupElement {
  groupElement: (elem: JSX.Element | undefined) => JSX.Element;
  children: { [key: string]: IElement };
  condition?: boolean;
}

interface IOwnProps {
  documents?: TDocument[];
  documentID?: string;
  formData: IFormData<{ [key: string]: any; date?: PartialDate }>;
  children: {
    [key: string]: IElement;
  };
  optionFormatter?: (s: string, field?: string) => JSX.Element | string;
  header?: string;
  /** Whether to show this section at all, default true */
  condition?: boolean; // Can hide whole section
  headerRef?: React.RefObject<HTMLDivElement>;
  hideCopyButton?: boolean;
  /** Path for nested data structures */
  name?: string;
  // Finds values of the latest document only
  prevDocOnly?: boolean;
  // Finds the values of the latest document with some of the fields in FormSectionHistoryAcceptors children
  prevDocWithField?: boolean;
}

export default FormSectionHistoryAcceptor;
