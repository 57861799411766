import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import ItemList from '../../../../components/ItemList';
import { SimpleRowInput } from '../../../../components/SimpleRowInput';
import colors from '../../../../config/theme/colors';
import { createID } from '../../../../utility/appendIDs';
import { StyledSelect } from './components';
import StyledTextField from './styledtextfield';

const StyledNewRowButton = styled.div`
  font-size: 1.5rem;
  &:hover {
    cursor: pointer;
    color: ${colors.primary};
  }
`;

const geneOptions: TGene[] = [
  'snca',
  'lrrk2',
  'park2',
  'pink1',
  'park7',
  'atp13a2',
  'pla2g6',
  'fbxo7',
  'c9orf72',
  'prnp',
  'apoe4heterotsygote',
  'apoe4homotsygote',
  'app',
  'psen1',
  'psen2',
  'dap12',
  'trem2',
];

// Function for adding a new row to list of gene-mutation pairs
const onNewRowAdd = (form: string, formData: IOwnProps['formData']): void => {
  let items = [];
  if (form === 'mutations') {
    items = formData.document.mutations ? formData.document.mutations : [];
    items = [...items, ''];
    formData.onChange && formData.onChange({ mutations: items });
  } else if (form === 'wesGeneVusFindingPairs') {
    items = formData.document.wesGeneVusFindingPairs ? formData.document.wesGeneVusFindingPairs : [];
    items = [...items, { gene: '', vusFinding: '', id: createID() }];
    formData.onChange && formData.onChange({ wesGeneVusFindingPairs: items });
  } else if (form === 'wesGeneMutationPairs') {
    items = formData.document.wesGeneMutationPairs ? formData.document.wesGeneMutationPairs : [];
    items = [...items, { gene: '', otherGene: '', mutation: '', id: createID() }];
    formData.onChange && formData.onChange({ wesGeneMutationPairs: items });
  } else if (form === 'panelGeneMutationPairs') {
    items = formData.document.panelGeneMutationPairs ? formData.document.panelGeneMutationPairs : [];
    items = [...items, { gene: '', otherGene: '', mutation: '', id: createID() }];
    formData.onChange && formData.onChange({ panelGeneMutationPairs: items });
  }
};

const onFormDataChange = (
  formData: IOwnProps['formData'],
  index: number,
  form: string,
  field: string | undefined,
  e: string,
): void => {
  const value = e ? e : '';
  if (form === 'mutations') {
    const items = formData.document.mutations && [...formData.document.mutations];
    const item = value;
    items?.splice(index, 1, item);
    formData.onChange && formData.onChange({ mutations: items });
  } else if (form === 'wesGeneMutationPairs') {
    const items = formData.document.wesGeneMutationPairs && [...formData.document.wesGeneMutationPairs];
    const item = {
      gene: field === 'gene' ? (value as TGene | 'other') : items?.[index].gene ? items[index].gene : 'other',
      otherGene: field === 'otherGene' ? value : items?.[index].otherGene ? items[index].otherGene : '',
      mutation: field === 'mutation' ? value : items?.[index].mutation ? items[index].mutation : '',
      id: items?.[index].id || createID(),
    };
    items?.splice(index, 1, item);
    formData.onChange && formData.onChange({ wesGeneMutationPairs: items });
  } else if (form === 'wesGeneVusFindingPairs') {
    const items = formData.document.wesGeneVusFindingPairs && [...formData.document.wesGeneVusFindingPairs];
    const item = {
      gene: field === 'gene' ? value : items?.[index]?.gene ? items?.[index]?.gene : '',
      vusFinding: field === 'vusFinding' ? value : items?.[index].vusFinding ? items[index].vusFinding : '',
      id: items?.[index].id || createID(),
    };
    items?.splice(index, 1, item);
    formData.onChange && formData.onChange({ wesGeneVusFindingPairs: items });
  } else if (form === 'panelGeneMutationPairs') {
    const items = formData.document.panelGeneMutationPairs && [...formData.document.panelGeneMutationPairs];
    const item = {
      gene: field === 'gene' ? (value as TGene | 'other') : items?.[index].gene ? items[index].gene : 'other',
      otherGene: field === 'otherGene' ? value : items?.[index].otherGene ? items[index].otherGene : '',
      mutation: field === 'mutation' ? value : items?.[index].mutation ? items[index].mutation : '',
      id: items?.[index].id || createID(),
    };
    items?.splice(index, 1, item);
    formData.onChange && formData.onChange({ panelGeneMutationPairs: items });
  }
};

const GeneTestForm = ({ editing, formData }: IOwnProps): JSX.Element => {
  // Function for deleting a mutation, a gene-mutation pair or a gene-vusFinding pair from formdata
  const onDelete = (name: string, index: number): void => {
    if (name === 'mutations') {
      const mutations = formData.document.mutations ? [...formData.document.mutations] : [];
      mutations.splice(index, 1);
      formData.onChange && formData.onChange({ [name]: mutations });
    } else if (name === 'panelGeneMutationPairs') {
      const pairs = formData.document.panelGeneMutationPairs ? [...formData.document.panelGeneMutationPairs] : [];
      pairs.splice(index, 1);
      formData.onChange && formData.onChange({ [name]: pairs });
    } else if (name === 'wesGeneMutationPairs') {
      const pairs = formData.document.wesGeneMutationPairs ? [...formData.document.wesGeneMutationPairs] : [];
      pairs.splice(index, 1);
      formData.onChange && formData.onChange({ [name]: pairs });
    } else if (name === 'wesGeneVusFindingPairs') {
      const pairs = formData.document.wesGeneVusFindingPairs ? [...formData.document.wesGeneVusFindingPairs] : [];
      pairs.splice(index, 1);
      formData.onChange && formData.onChange({ [name]: pairs });
    }
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [deleteDialogData, setDeleteDialogData] = React.useState<{ name: string; index: number } | undefined>(
    undefined,
  );

  const openDeleteDialog = (name: string, index: number): void => {
    setDeleteDialogOpen(true);
    setDeleteDialogData({ name, index });
  };

  const deleteCancelCallback = (): void => {
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };

  const deleteConfirmCallback = (): void => {
    deleteDialogData && onDelete(deleteDialogData.name, deleteDialogData.index);
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title="geneTest.geneTestType">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="geneTestType"
            formData={formData}
            options={['singleGeneTest', 'genePanelResearch', 'wes']}
            optionFormatter={(name: string | number): JSX.Element => <FormattedMessage id={`geneTest.opts.${name}`} />}
          />
        </FormRow>
      </FormSection>
      {formData.document.geneTestType && formData.document.geneTestType === 'singleGeneTest' ? (
        <FormSection>
          <FormRow title="geneTest.gene">
            <InputHandler
              type="Radio"
              editing={!!editing}
              name="gene"
              formData={formData}
              options={[...geneOptions, 'other']}
              optionFormatter={(name: string | number): JSX.Element => (
                <FormattedMessage id={`geneTest.opts.${name}`} />
              )}
              placeholder="geneTest.genePlaceholder"
              twoColumns
              optionSpecificElements={[
                {
                  other: (
                    <InputHandler
                      type="TextField"
                      name="whichGene"
                      editing={true}
                      placeholder={'geneTest.whichGene'}
                      formData={formData}
                    />
                  ),
                },
              ]}
            />
          </FormRow>
          <FormRow title="geneTest.mutationFound">
            <InputHandler
              type="Radio"
              editing={!!editing}
              name="mutationFound"
              formData={formData}
              preset="yesno"
              optionFormatter={(name: string | number): JSX.Element => (
                <FormattedMessage id={`geneTest.opts.${name === 'true' ? 'yes' : 'no'}`} />
              )}
            />
          </FormRow>
          {formData.document.mutationFound && formData.document.mutationFound === true ? (
            <React.Fragment>
              <FormRow title="geneTest.mutations">
                <div>
                  {Array.isArray(formData.document.mutations) &&
                    formData.document.mutations?.map((item: any, index: number) => (
                      <div key={index} style={{ paddingTop: '0.5rem' }}>
                        <SimpleRowInput
                          clickHandler={(): void => openDeleteDialog('mutations', index)}
                          name="mutations"
                          firstItem={
                            <StyledTextField
                              value={item}
                              onChange={(e: string): void =>
                                onFormDataChange(formData, index, 'mutations', undefined, e)
                              }
                              placeholder="geneTest.mutation"
                              width={20}
                            />
                          }
                        />
                      </div>
                    ))}
                </div>
                <div>
                  <StyledNewRowButton
                    onClick={(): void => onNewRowAdd('mutations', formData)}
                    style={{ paddingTop: '1rem' }}
                  >
                    <FormattedMessage id="geneTest.newMutation" />
                  </StyledNewRowButton>
                </div>
              </FormRow>
            </React.Fragment>
          ) : undefined}
        </FormSection>
      ) : formData.document.geneTestType && formData.document.geneTestType === 'genePanelResearch' ? (
        <FormSection>
          <FormRow title="geneTest.genePanel">
            <InputHandler
              type="TextField"
              editing={!!editing}
              name="genePanel"
              formData={formData}
              placeholder="geneTest.genePanel"
            />
          </FormRow>
          <FormRow title="geneTest.genesIncluded">
            <InputHandler
              type="Checkbox"
              editing={!!editing}
              name="genesIncluded"
              formData={formData}
              options={[...geneOptions, 'allGenesNotKnown', 'other']}
              optionFormatter={(name: string | number): JSX.Element => (
                <FormattedMessage id={`geneTest.opts.${name}`} />
              )}
              twoColumns
              optionSpecificElements={[
                {
                  other: (
                    <InputHandler
                      type="TextField"
                      name="whichGene"
                      editing={true}
                      placeholder={'geneTest.whichGene'}
                      formData={formData}
                    />
                  ),
                },
              ]}
            />
          </FormRow>
          <FormRow title="geneTest.mutationFound">
            <InputHandler
              type="Radio"
              editing={!!editing}
              name="mutationFound"
              formData={formData}
              preset="yesno"
              optionFormatter={(name: string | number): JSX.Element => (
                <FormattedMessage id={`geneTest.opts.${name === 'true' ? 'yes' : 'no'}`} />
              )}
            />
          </FormRow>
          {formData.document.mutationFound && formData.document.mutationFound === true ? (
            <FormRow>
              <ItemList
                headers={{
                  firstHeader: <FormattedMessage id="geneTest.gene" />,
                  secondHeader: <FormattedMessage id="geneTest.whichOtherGene" />,
                  thirdHeader: <FormattedMessage id="geneTest.mutation" />,
                  style: { color: colors.secondaryText, paddingBottom: '0.5rem' },
                }}
                items={formData.document.panelGeneMutationPairs?.map((item: any, index: number) => {
                  return {
                    firstItem: (
                      <StyledSelect
                        value={item.gene}
                        onChange={(e: unknown): void =>
                          onFormDataChange(formData, index, 'panelGeneMutationPairs', 'gene', e as string)
                        }
                        placeholder={<FormattedMessage id="geneTest.gene" />}
                        options={
                          formData.document.genesIncluded ? [...formData.document.genesIncluded, 'other'] : ['other']
                        }
                      />
                    ),
                    secondItem:
                      item.gene === 'other' ? (
                        <StyledTextField
                          value={item.otherGene}
                          onChange={(e: string): void =>
                            onFormDataChange(formData, index, 'panelGeneMutationPairs', 'otherGene', e)
                          }
                          placeholder="geneTest.whichOtherGene"
                          width={15}
                        />
                      ) : undefined,
                    thirdItem: (
                      <StyledTextField
                        value={item.mutation}
                        onChange={(e: string): void =>
                          onFormDataChange(formData, index, 'panelGeneMutationPairs', 'mutation', e)
                        }
                        placeholder="geneTest.mutation"
                        width={15}
                      />
                    ),
                    itemOnClick: (): void => openDeleteDialog('panelGeneMutationPairs', index),
                  };
                })}
                button={<FormattedMessage id="geneTest.newRow" />}
                buttonOnClick={(): void => onNewRowAdd('panelGeneMutationPairs', formData)}
              />
            </FormRow>
          ) : undefined}
        </FormSection>
      ) : formData.document.geneTestType && formData.document.geneTestType === 'wes' ? (
        <FormSection>
          <FormRow title="geneTest.whereWasResearchMade">
            <InputHandler
              type="TextField"
              editing={!!editing}
              name="whereWasResearchMade"
              formData={formData}
              placeholder="geneTest.whereWasResearchMade"
            />
          </FormRow>
          <FormRow title="geneTest.mutationFound" id={'mutation'}>
            <InputHandler
              type="Radio"
              editing={!!editing}
              name="mutationFound"
              formData={formData}
              preset="yesno"
              optionFormatter={(name: string | number): JSX.Element => (
                <FormattedMessage id={`geneTest.opts.${name === 'true' ? 'yes' : 'no'}`} />
              )}
            />
          </FormRow>
          {formData.document.mutationFound && formData.document.mutationFound === true ? (
            <FormRow>
              <ItemList
                headers={{
                  firstHeader: <FormattedMessage id="geneTest.gene" />,
                  secondHeader: <FormattedMessage id="geneTest.whichOtherGene" />,
                  thirdHeader: <FormattedMessage id="geneTest.mutation" />,
                  style: { color: colors.secondaryText, paddingBottom: '0.5rem' },
                }}
                items={formData.document.wesGeneMutationPairs?.map((item: any, index: number) => {
                  return {
                    firstItem: (
                      <StyledSelect
                        value={item.gene}
                        onChange={(e: unknown): void =>
                          onFormDataChange(formData, index, 'wesGeneMutationPairs', 'gene', e as string)
                        }
                        placeholder={<FormattedMessage id="geneTest.gene" />}
                        options={[...geneOptions, 'other']}
                      />
                    ),
                    secondItem:
                      item?.gene === 'other' ? (
                        <StyledTextField
                          value={item.otherGene}
                          onChange={(e: string): void =>
                            onFormDataChange(formData, index, 'wesGeneMutationPairs', 'otherGene', e)
                          }
                          placeholder="geneTest.whichOtherGene"
                          width={15}
                        />
                      ) : undefined,
                    thirdItem: (
                      <StyledTextField
                        value={item.mutation}
                        onChange={(e: string): void =>
                          onFormDataChange(formData, index, 'wesGeneMutationPairs', 'mutation', e)
                        }
                        placeholder="geneTest.mutation"
                        width={15}
                      />
                    ),
                    itemOnClick: (): void => openDeleteDialog('wesGeneMutationPairs', index),
                    index: index,
                  };
                })}
                button={<FormattedMessage id="geneTest.newRow" />}
                buttonOnClick={(): void => onNewRowAdd('wesGeneMutationPairs', formData)}
              />
            </FormRow>
          ) : undefined}
          <FormRow title="geneTest.vusFindingFound" id={'vus'}>
            <InputHandler
              type="Radio"
              editing={!!editing}
              name="vusFindingFound"
              formData={formData}
              preset="yesno"
              optionFormatter={(name: string | number): JSX.Element => (
                <FormattedMessage id={`geneTest.opts.${name === 'true' ? 'yes' : 'no'}`} />
              )}
            />
          </FormRow>
          {formData.document.vusFindingFound && formData.document.vusFindingFound === true ? (
            <FormRow>
              <ItemList
                headers={{
                  firstHeader: <FormattedMessage id="geneTest.whichGene" />,
                  secondHeader: <FormattedMessage id="geneTest.vusFinding" />,
                  style: { color: colors.secondaryText, paddingBottom: '0.5rem' },
                }}
                items={formData.document.wesGeneVusFindingPairs?.map((item: any, index: number) => {
                  return {
                    firstItem: (
                      <StyledTextField
                        value={item.gene}
                        onChange={(e: string): void =>
                          onFormDataChange(formData, index, 'wesGeneVusFindingPairs', 'gene', e)
                        }
                        placeholder="geneTest.gene"
                        width={15}
                      />
                    ),
                    secondItem: (
                      <StyledTextField
                        value={item.vusFinding}
                        onChange={(e: string): void =>
                          onFormDataChange(formData, index, 'wesGeneVusFindingPairs', 'vusFinding', e)
                        }
                        placeholder="geneTest.vusFinding"
                        width={15}
                      />
                    ),
                    itemOnClick: (): void => openDeleteDialog('wesGeneVusFindingPairs', index),
                  };
                })}
                button={<FormattedMessage id="geneTest.newRow" />}
                buttonOnClick={(): void => onNewRowAdd('wesGeneVusFindingPairs', formData)}
              />
            </FormRow>
          ) : undefined}
        </FormSection>
      ) : undefined}

      <ConfirmationDialog
        open={deleteDialogOpen}
        text={<FormattedMessage id="general.reallyWantToDelete" />}
        confirm={{ callback: deleteConfirmCallback }}
        cancel={{ callback: deleteCancelCallback }}
      />
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<IGeneTest>;
}

export default GeneTestForm;
