import * as React from 'react';
import { sortPartialDate, formatPartialDate } from 'neuro-utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { fm } from 'Components/FormatMessage';
import { headerText } from 'Utility/ninmtUtil';
import { DescriptionText } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';
import { FormattedMessage } from 'react-intl';

const RTMSData = ({ document }: { document: IRTMS }): JSX.Element => {
  const sessionDates = Array.isArray(document?.sessions)
    ? document?.sessions
        .map((d) => `${d.date ? formatPartialDate(d.date) : '-'}\n`)
        .slice(0, 6)
        .join('')
    : '-';

  const sessionCount = Array.isArray(document?.sessions) ? document?.sessions.length : '-';

  return (
    <TileContentMaker
      type="bigHeaderInfo"
      bigHeaderText={
        document?.type
          ? fm(`rtms.opts.${document?.type}`)
          : !document.hasEnded
            ? fm('rtms.rtmsOngoing')
            : fm('rtms.rtmsHasEnded')
      }
      bigHeaderValue={headerText(document)}
      data={
        'sessions' in document
          ? [
              {
                title: fm('rtms.sessionsAndProtocols'),
                value: <div style={{ whiteSpace: 'pre-line' }}>{document?.hasEnded ? sessionCount : sessionDates}</div>,
                key: 'sessions',
              },
            ]
          : []
      }
    />
  );
};

const RTMSDash = ({ documents }: IOwnProps): JSX.Element | undefined => {
  const sortedArr = documents
    ?.filter((d) => d._type === 'rtms')
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const sortedNinmtTreatmentPeriod = documents
    ?.filter((d) => d._type === 'ninmtTreatmentPeriod')
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const latestRTMS = sortedArr.find((d) => !d.hasEnded) ?? sortedArr?.[0];

  return (
    <React.Fragment>
      {sortedArr?.length > 0 && sortPartialDate(sortedNinmtTreatmentPeriod?.[0]?.date, latestRTMS.date) === 1 ? (
        <DescriptionText>
          <FormattedMessage id="rtms.noActiveTreatmentPeriod" />
        </DescriptionText>
      ) : (
        <RTMSData document={latestRTMS} />
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<IRTMS>;
}

export default RTMSDash;
