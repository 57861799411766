import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowSubHeader from '../../../../../components/HistoryRowSubHeader';
import { Container, Item } from '../../../../../components/Grid';
import { smallActionButtonDimensions } from '../../../../../config/theme/componentTheme';
import findMatchingAdverseEvents from '../../../utils/findMatchingAdverseEvents';
import { MakeHeaderBar } from './components';
import AdverseEventListItem from './AdverseEventListItem';
import { isPartialDate, sortPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../../components/DocumentCreationButton';

const MedicationAdverseEvents = ({
  medication,
  adverseEvents,
  startEdit,
  setMedID,
}: IMedicationAdverseEventsProps): JSX.Element | null => {
  if (!medication._id || !medication.startDate || !isPartialDate(medication.startDate)) return null;
  const medID = medication._id;

  const matchedAdverseEvents = findMatchingAdverseEvents(adverseEvents, medID).sort((a, b) =>
    sortPartialDate(b.date, a.date),
  );

  const headEvents = matchedAdverseEvents;

  return (
    <React.Fragment>
      <HistoryRowSubHeader
        header={
          <Container justifyContent="space-between">
            <Item>
              <FormattedMessage id="medication.events" />
            </Item>
          </Container>
        }
      />

      <MakeHeaderBar
        title={
          <FormattedMessage
            id={headEvents.length > 0 ? 'medication.currentMedicationPeriod' : 'medication.noAdverseEvents'}
          />
        }
        newButton={
          medID ? (
            <div style={{ paddingRight: '4.5rem' }}>
              <DocumentCreationButton
                name="adverseEffect"
                text={'general.new'}
                onClick={(e: React.MouseEvent): void => {
                  setMedID(medID);
                  startEdit({}, 'adverseEffect')(e);
                }}
                {...smallActionButtonDimensions}
              />
            </div>
          ) : undefined
        }
      />

      {headEvents.map((ev) => (
        <AdverseEventListItem key={ev._id} adverseEvent={ev} startEdit={startEdit} />
      ))}
    </React.Fragment>
  );
};

interface IMedicationAdverseEventsProps {
  medication: IMedication;
  adverseEvents: IMedicationAdverseEvent[];
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
  setMedID: (id: string) => void;
}

export default MedicationAdverseEvents;
