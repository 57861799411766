import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';

const PelvisForm = ({ formData }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={true}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="imaging.hipSubluxationLuxation">
      <InputHandler
        type="Radio"
        editing={true}
        name="hipSubluxationLuxation"
        formData={formData}
        options={['yes', 'no', 'unknown']}
        optionFormatter={(name: string | number): JSX.Element => <FormattedMessage id={`general.${name}`} />}
      />
    </FormRow>
    {formData.document.hipSubluxationLuxation === 'yes' && (
      <FormRow title="imaging.migrationPercentage">
        <InputHandler
          type="NumberField"
          editing={true}
          name="migrationPercentage"
          formData={formData}
          placeholder="imaging.migrationPercentagePlaceholder"
          width={10.4}
          height={3}
          maxLength={5}
          min={0}
          max={1000}
          precision={2}
        />
      </FormRow>
    )}
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IPelvicRontgen>;
}

export default PelvisForm;
