import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import styled from 'styled-components';
import { Container, Item } from '../../../../../../../components/Grid';
import HistoryRowControls from '../../../../../../../components/HistoryRowControls';
import colors from '../../../../../../../config/theme/colors';
import { IHistoryContext } from '../../../../../../../containers/FormContextHandler';
import { resultPicker } from '../../../../../utils/FTD';
import { fm } from 'Components/FormatMessage';

const StyledWrapper = styled(({ ...other }) => <Container {...other} />)`
  align-items: center;
  * > &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

const FTDDiagnosticCriteriaHistory = ({ d, startEdit, view }: IOwnProps): JSX.Element => {
  const rows1 = [
    'criteriaForCertainFTDFulfilled',
    'criteriaForProbableFTDFulfilled',
    'criteriaForPossibleFTDFulfilled',
  ];
  const rows2 = ['coreCriteriaForFTDFulfilled', 'exclusionCriteriaForFTDFulfilled'];
  return (
    <React.Fragment>
      {rows1.map((row, index) => (
        <StyledWrapper key={row} style={{ marginBottom: index === 0 ? '1.6rem' : undefined }}>
          <Item xs={2}>{index === 0 && d.date && formatPartialDate(d.date)}</Item>
          <Item xs={4}>
            <span style={{ color: colors.tertiaryText, fontWeight: 400 }}>{fm(`diagnosis.ftd.summary.${row}`)}</span>
          </Item>
          <Item xs={3}>{fm(`diagnosis.opts.yesNoUnknown.${resultPicker(d, row)}`)}</Item>
          <Item xs={3}>
            {startEdit && index === 0 ? <HistoryRowControls document={d} startEdit={startEdit} view={view} /> : null}
          </Item>
        </StyledWrapper>
      ))}
      {!rows1.some((row) => resultPicker(d, row) === 'yes') &&
        rows2.map((row) => (
          <StyledWrapper key={row}>
            <Item xs={2} />
            <Item xs={4}>
              <span style={{ color: colors.tertiaryText, fontWeight: 400 }}>{fm(`diagnosis.ftd.summary.${row}`)}</span>
            </Item>
            <Item xs={3}>{fm(`diagnosis.opts.yesNoUnknown.${resultPicker(d, row)}`)}</Item>
            <Item xs={3} />
          </StyledWrapper>
        ))}
    </React.Fragment>
  );
};

interface IOwnProps {
  d: IFTD;
  startEdit: IHistoryContext['startEdit'];
  view: IHistoryContext['view'];
}

export default FTDDiagnosticCriteriaHistory;
