import * as React from 'react';
import { styled } from '@mui/system';
import InputHandler from 'Components/InputHandler';
import colors from '../../../../../../config/theme/colors';
import { MyServiceContext } from '../../..';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import ActionButton from 'Components/ActionButton';
import { dialogContent, dialogActions, dialogCancel } from '../../../../../../config/theme/componentTheme';
import FormRow from 'Components/FormRow';
import { exists } from 'neuro-utils';
import SearchDialog from 'Components/SearchDialog';
import { fetchWithOptions } from 'Utility/fetch';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { ITreatmentMonitoringInquiry } from 'neuro-schemas';
import { TFieldConfig } from 'Routes/MyService/Document/config';
import { AddCircle } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

const StyledTableRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'formType',
})(({ formType }: { formType?: 'ninmtPreInquiry' | 'treatmentMonitoringInquiry' }) => ({
  display: 'grid',
  alignItems: 'center',
  padding: '0.5rem 1rem 0.5rem 0',
  gridTemplateColumns: formType === 'ninmtPreInquiry' ? '3.1fr 2fr 2fr' : '3fr 2.2fr 2.5fr 1fr',
}));

const StyledTableItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'colSpan',
})(({ colSpan = 1 }: { colSpan?: 1 | 2 }) => ({
  display: 'flex',
  gridColumn: colSpan > 1 ? '2 / span 2' : undefined,
}));

const search = (currentSearchString: string): Promise<any> =>
  fetchWithOptions(
    `/api/medication/search?search=${currentSearchString}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  ).then(
    (res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw { status: res.status, fullResponse: res };
      }
    },
    (error: Error) => {
      throw error;
    },
  );

const StyledAddButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled?: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  color: `${disabled ? colors.gray : colors.primary}`,
  userSelect: 'none' as const,
  '&:hover': {
    cursor: disabled ? 'default' : 'pointer',
  },
}));

export const AddButton = ({
  fm,
  disabled,
  text = 'myService.ninmt.addSymptom',
}: {
  fm: (m: string, n?: string | number | { [key: string]: JSX.Element } | undefined) => string;
  disabled?: boolean;
  text?: string;
}): JSX.Element => {
  return (
    <StyledAddButton disabled={disabled}>
      <AddCircle />
      <span style={{ marginLeft: '0.7rem' }}>{fm(text)}</span>
    </StyledAddButton>
  );
};

const MedicationDialog = ({
  document,
  onChange,
  fm,
  dialogOpen,
  setDialogOpen,
  deleteDisabled,
  fields,
}: IMedicationDialog): JSX.Element => {
  // Temporary data when dialog is open
  const [data, setData] = React.useState<
    NonNullable<ININMTPreInquiry['medication'] | ITreatmentMonitoringInquiry['medication']>[0]
  >(JSON.parse(JSON.stringify(document ?? {})));

  const handleChange = (e: TOnChangeValues) => {
    const name = Object.keys(e)[0];
    const value = e[name];
    setData((prev: NonNullable<ININMTPreInquiry['medication'] | ITreatmentMonitoringInquiry['medication']>[0]) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const id = 'id' in data ? data?.id : uuidv4();
    const dataWithId = { ...data, id };
    onChange({ medication: dataWithId });
    setDialogOpen(false);
  };

  // Maybe hacky...
  const handleCancel = () => {
    onChange({ cancel: undefined });
    setDialogOpen(false);
  };

  const handleDelete = () => {
    onChange({ medication: null });
    setDialogOpen(false);
  };

  // Reset data when dialog is opened (again)
  React.useEffect(() => {
    if (dialogOpen) setData(JSON.parse(JSON.stringify(document ?? {})));
  }, [dialogOpen]);

  return (
    <Dialog
      open={dialogOpen}
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{ square: true }}
      onClose={() => setDialogOpen(false)}
    >
      <DialogContent style={dialogContent}>
        {fields.map((field: (typeof fields)[0], index: number) =>
          field.name === 'medicationName' ? (
            <React.Fragment key={index}>
              <div style={{ marginBottom: '1.5rem' }}>
                <SearchDialog
                  formData={{
                    document: { medication: data?.medication },
                    onChange: (e) => handleChange(e),
                  }}
                  searchFn={search}
                  resultToDoc={{
                    name: 'medicationName',
                    dosageForm: 'dosageForm',
                  }}
                  title="medication.chooseMedication"
                  info="medication.medDialogInfoText"
                  customDialog
                />
              </div>
              <FormRow title={`myService.ninmt.${field.name}`}>
                <span style={{ fontWeight: 600 }}>{data?.[field.name as keyof typeof document] ?? '-'}</span>
              </FormRow>
            </React.Fragment>
          ) : (
            <FormRow key={index} title={`myService.ninmt.${field.name}`}>
              <InputHandler
                type={field.type as any}
                name={field.name}
                editing={true}
                formData={{
                  document: { [field.name]: data?.[field.name as keyof typeof document] },
                  onChange: (e) => handleChange(e),
                }}
                options={field?.options}
                optionFormatter={field?.optionFormatter}
                placeholder={field.placeholder}
              />
            </FormRow>
          ),
        )}
      </DialogContent>
      <DialogActions style={{ ...dialogActions, justifyContent: 'space-between' }}>
        <div>
          {!deleteDisabled && (
            <ActionButton
              text="general.delete"
              onClick={handleDelete}
              width={12}
              height={3}
              fontSize={16}
              colorScheme="error"
            />
          )}
        </div>
        <div>
          <div style={{ ...dialogCancel, display: 'inline-block' }} onClick={handleCancel}>
            {fm('general.cancel')}
          </div>
          <div style={{ display: 'inline-block' }}>
            <ActionButton text="general.accept" onClick={handleSave} width={12} height={3} fontSize={16} />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const MedicationWindow = ({
  parentDocument,
  document = [],
  dialogOpen,
  setDialogOpen,
  fields,
  formType,
}: IMedicationWindow): JSX.Element => {
  // Used to distinct adding and editing
  const [addingNew, setAddingNew] = React.useState<boolean>(true);
  // Which medication is being edited
  const [currentIndex, setCurrentIndex] = React.useState<number | undefined>(undefined);

  const myServiceContext = React.useContext(MyServiceContext);
  const { setEditingData, fm, viewing } = myServiceContext;

  const handleChange = (e: TOnChangeValues): void => {
    const name = Object.keys(e)[0];
    const value = e[name];
    // Delete medication from array if nullified
    if (value === null && exists(currentIndex)) {
      setEditingData?.({ ...parentDocument, medication: document.filter((_, i) => i !== currentIndex) });
    } else if (value) {
      const newDocument = JSON.parse(JSON.stringify(document));
      if (exists(currentIndex)) {
        newDocument[currentIndex as number] = value;
      } else {
        newDocument.unshift(value);
      }
      setEditingData?.({ ...parentDocument, medication: newDocument });
    }
    setCurrentIndex(undefined);
  };

  const tableHeaders =
    formType === 'ninmtPreInquiry'
      ? ['medicationName', 'medicationDosage']
      : ['medicationName', 'medicationDosage', 'onDemandDosing'];

  return (
    <div style={{ width: formType === 'ninmtPreInquiry' ? '70%' : '90%' }}>
      {document.length > 0 && (
        <React.Fragment>
          <StyledTableRow formType={formType} style={{ borderBottom: `1px solid ${colors.darkGray}` }}>
            {tableHeaders.map((name: string, index: number) => (
              <StyledTableItem
                key={index}
                style={{
                  paddingRight: index === 1 ? '1.5rem' : undefined,
                  color: colors.darkGray,
                }}
              >
                {fm(`myService.ninmt.${name}`)}
              </StyledTableItem>
            ))}
          </StyledTableRow>
          {document.map((d, index) => (
            <StyledTableRow
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? colors.lightestGray : undefined,
              }}
              formType={formType}
            >
              {fields.map((field: (typeof fields)[0], i: number) => (
                <StyledTableItem
                  key={i}
                  style={{
                    paddingLeft: i === 0 ? '1.5rem' : undefined,
                    paddingRight: i === 1 ? '1.5rem' : undefined,
                    fontWeight: i === 0 ? 600 : undefined,
                  }}
                >
                  {d?.[field.name as keyof typeof d] || '-'}
                  {field.name === 'medicationName' && d?.dosageForm ? ` (${d.dosageForm})` : ''}
                </StyledTableItem>
              ))}
              {!viewing && (
                <StyledTableItem style={{ justifyContent: 'flex-end' }}>
                  <ActionButtonRounded
                    text="general.edit"
                    width={8}
                    height={3.5}
                    fontSize={16}
                    uppercase={false}
                    onClick={() => {
                      setCurrentIndex(index);
                      setAddingNew(false);
                      setDialogOpen(true);
                    }}
                  />
                </StyledTableItem>
              )}
            </StyledTableRow>
          ))}
          <StyledTableRow />
        </React.Fragment>
      )}
      <MedicationDialog
        document={exists(currentIndex) ? document[currentIndex as number] ?? {} : {}}
        onChange={handleChange}
        fm={fm}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        deleteDisabled={addingNew}
        fields={fields}
        formType={formType}
      />
    </div>
  );
};

interface IMedicationDialog {
  document: NonNullable<ININMTPreInquiry['medication'] | ITreatmentMonitoringInquiry['medication']>[0];
  onChange: (e: TOnChangeValues) => void;
  fm: (m: string) => string;
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteDisabled: boolean;
  fields: TFieldConfig[];
  formType: 'ninmtPreInquiry' | 'treatmentMonitoringInquiry';
}

interface IMedicationWindow {
  parentDocument: (ININMTPreInquiry | ITreatmentMonitoringInquiry) & IControlProps;
  document: ININMTPreInquiry['medication'] | ITreatmentMonitoringInquiry['medication'];
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fields: TFieldConfig[];
  formType: 'ninmtPreInquiry' | 'treatmentMonitoringInquiry';
}

export default MedicationWindow;
