import * as React from 'react';
import { path } from 'ramda';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { steps as mgcSteps, getStepContent, getStepsLocalesGetter } from '../mgc';
import { useIntl } from 'react-intl';
import { isMGCComplete, mgcScore } from 'Routes/FunctionalPerformanceMgravis/utils';
import InfoPopper from 'Components/InfoPopper';
import QuestionStepperSimpler from 'Components/QuestionStepperSimpler';
import colors from '../../../../../config/theme/colors';

const notFilled = (doc: IMGC) => {
  return mgcSteps.filter((step) => {
    const value = path([step], doc);
    return !(typeof value === 'number');
  });
};

const MGCForm = ({ formData, viewing }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (s: string) => formatMessage({ id: s });

  const [manualScoreOpen, setManualScoreOpen] = React.useState<boolean>(
    (formData.document.manualScore || formData.document.manualScore === 0) && !isMGCComplete(formData.document)
      ? true
      : false,
  );
  const toggleSetManualScore = (values: TOnChangeValues) => {
    if (Array.isArray(values['totalScoreOnly']) && values['totalScoreOnly'][0] === true) setManualScoreOpen(true);
    else setManualScoreOpen(false);
  };
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>

      <FormRow title="functionalPerformanceMgravis.manualScore">
        <InputHandler
          type="Checkbox"
          editing={!viewing}
          name="totalScoreOnly"
          formData={{ onChange: toggleSetManualScore, document: { totalScoreOnly: [manualScoreOpen] } }}
          preset="yes"
        />
      </FormRow>

      {manualScoreOpen ? (
        <FormRow title="functionalPerformanceMgravis.labels.mgc.score">
          <InputHandler
            type="NumberField"
            editing={!viewing}
            name="manualScore"
            formData={formData}
            min={0}
            max={50}
            placeholder={'functionalPerformanceMgravis.labels.mgc.score'}
          />
        </FormRow>
      ) : (
        <>
          <FormSection>
            <QuestionStepperSimpler
              viewing={viewing}
              formData={formData}
              steps={mgcSteps}
              stepsLocales={getStepsLocalesGetter(fm)}
              getStepContent={getStepContent(fm)}
            />
          </FormSection>
          <FormSection>
            <FormRow title="functionalPerformanceMgravis.labels.mgc.score">
              <span style={{ fontWeight: 600 }}>
                {isMGCComplete(formData.document) ? (
                  mgcScore(formData.document)
                ) : (
                  <InfoPopper
                    text={
                      <div>
                        <div>{fm('general.notFilled')}</div>
                        <div>{fm('functionalPerformanceMgravis.notFilled')}</div>
                        {notFilled(formData.document).map((nf) => (
                          <div key={nf}>{fm('functionalPerformanceMgravis.labels.mgc.' + nf)}</div>
                        ))}
                      </div>
                    }
                    color="primary"
                  />
                )}
              </span>
            </FormRow>
          </FormSection>
        </>
      )}
      <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
        {fm('functionalPerformanceMgravis.labels.mgc.disclaimer')}
      </span>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IMGC>;
  viewing?: boolean;
}

export default MGCForm;
