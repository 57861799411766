import * as React from 'react';
import { sortPartialDate, formatPartialDate } from 'neuro-utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { fm } from 'Components/FormatMessage';
import { headerText } from 'Utility/ninmtUtil';
import { DescriptionText } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';
import { FormattedMessage } from 'react-intl';

const TDCSData = ({ document }: { document: ITDCS }): JSX.Element => {
  const sesDates = Array.isArray(document?.sessions) ? document?.sessions.map((d) => d.date) : [];
  const otherEvents = Array.isArray(document?.otherEvents) ? document?.otherEvents.map((d) => d.date) : [];

  const eventDates = [...sesDates, ...otherEvents];

  const eventDatesString = eventDates
    .map((ed) => `${ed ? formatPartialDate(ed) : '-'}\n`)
    .slice(0, 6)
    .join('');

  const sessionCount = Array.isArray(document?.sessions) ? document?.sessions.length : '-';

  return (
    <TileContentMaker
      type="bigHeaderInfo"
      bigHeaderText={
        document?.type
          ? fm(`tdcs.opts.${document?.type}`)
          : !document.hasEnded
            ? fm('tdcs.tdcsOngoing')
            : fm('tdcs.tdcsHasEnded')
      }
      bigHeaderValue={headerText(document)}
      data={
        'sessions' in document
          ? [
              {
                title: fm('tdcs.sessionsAndProtocols'),
                value: (
                  <div style={{ whiteSpace: 'pre-line' }}>{document?.hasEnded ? sessionCount : eventDatesString}</div>
                ),
                key: 'sessions',
              },
            ]
          : []
      }
    />
  );
};

const TDCSDash = ({ documents }: IOwnProps): JSX.Element | undefined => {
  const sortedArr = documents
    ?.filter((d) => d._type === 'tdcs')
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const sortedNinmtTreatmentPeriod = documents
    ?.filter((d) => d._type === 'ninmtTreatmentPeriod')
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const latestTDCS = sortedArr.find((d) => !d.hasEnded) ?? sortedArr?.[0];

  return (
    <React.Fragment>
      {sortedArr?.length > 0 && sortPartialDate(sortedNinmtTreatmentPeriod?.[0]?.date, latestTDCS.date) === 1 ? (
        <DescriptionText>
          <FormattedMessage id="tdcs.noActiveTreatmentPeriod" />
        </DescriptionText>
      ) : (
        <TDCSData document={latestTDCS} />
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<ITDCS>;
}

export default TDCSDash;
