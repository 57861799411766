import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';

const StyledHistory = styled.div`
  margin-bottom: 0.5rem;
`;

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const GeneralHealthHistoryRowData = ({ d }: IOwnProps): JSX.Element => {
  return (
    <StyledHistory>
      <Container alignItems="center">
        <Item xs={4}>
          <HistoryRowBasicItem
            header={fm('generalhealth.clinicianRatingTitle')}
            value={
              d?.clinicianRating && (
                <div>
                  <span style={{ fontWeight: 600 }}>{d?.clinicianRating}</span>
                  {' - '}
                  {fm(`generalhealth.opts.clinicianRating.${d.clinicianRating}`)}
                </div>
              )
            }
          />
        </Item>
        <Item xs={4}>
          <HistoryRowBasicItem
            header={fm('generalhealth.patientRatingTitle')}
            value={
              d?.patientRating && (
                <div>
                  <span style={{ fontWeight: 600 }}>{d?.patientRating}</span>
                  {' - '}
                  {fm(`generalhealth.opts.patientRating.${d.patientRating}`)}
                </div>
              )
            }
          />
        </Item>
      </Container>
    </StyledHistory>
  );
};

interface IOwnProps {
  d: IGeneralHealth;
}

export default GeneralHealthHistoryRowData;
