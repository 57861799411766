import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import PlatformConditional from '../../../../../components/PlatformConditional';
import { FormattedMessage } from 'react-intl';
import FormSection from '../../../../../components/FormSection';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import ToolTip from '../../../../../components/ToolTip';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => {
    return <FormattedMessage id={`imaging.${opt}.${name}`} />;
  };

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const StyledSubHeader = styled.div`
  color: ${colors.tertiaryText};
  font-size: 2rem;
  margin-bottom: 0.6rem;
`;

const StyledSubHeaderCentered = styled(StyledSubHeader as any)`
  display: flex;
  justify-content: center;
  align-content: flex-start;
`;

const StyledDiv = styled.div`
  margin-top: 4rem;
`;

const StyledDivCentered = styled.div`
  display: flex;
  justify-content: center;
  align-content: flex-start;
`;

const StyledRowTitle = styled.span`
  color: ${colors.tertiaryText};
`;

const StyledTable = styled.table`
  margin-left: -0.4rem;
`;
const StyledTableBody = styled.tbody``;
const StyledTableRow = styled.tr`
  display: table-row;
  vertical-align: center;
  &:first-of-type {
    vertical-align: top;
  }
`;
const StyledTableColumn = styled.td`
  display: table-cell;
  &:first-of-type {
    width: 32%;
  }
  &:nth-of-type(3) {
    padding: 0 0 0 1rem;
  }
  &:nth-of-type(4) {
    padding: 0 1rem 0 1rem;
  }
`;

const lesionOptions = ['X', 'N/A', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '10-20', '21-40', '>40'];

const LesionsFormTitleRow = ({
  title,
  lesionsTitle,
  deltaSymbol,
  diameterSymbol,
  trendTitle,
}: ILesionsFormTitleRow): JSX.Element => (
  <StyledTableRow>
    <StyledTableColumn>
      <StyledSubHeader>{fm(title)}</StyledSubHeader>
    </StyledTableColumn>
    <StyledTableColumn>
      <StyledSubHeaderCentered>{lesionsTitle && fm(lesionsTitle)}</StyledSubHeaderCentered>
    </StyledTableColumn>
    <StyledTableColumn>
      <ToolTip
        description={fm('imaging.lesionsDelta')}
        content={<StyledSubHeaderCentered>{deltaSymbol}</StyledSubHeaderCentered>}
        hover={true}
      />
    </StyledTableColumn>
    <StyledTableColumn>
      <ToolTip
        description={fm('imaging.lesionsMaxDiameter')}
        content={<StyledSubHeaderCentered>{diameterSymbol}</StyledSubHeaderCentered>}
        hover={true}
      />
    </StyledTableColumn>
    <StyledTableColumn>
      <StyledSubHeaderCentered>{trendTitle && fm(trendTitle)}</StyledSubHeaderCentered>
    </StyledTableColumn>
  </StyledTableRow>
);

const LesionsFormDataRow = ({ formData, rowTitle, lesions, titlePadding }: ILesionsFormDataRow): JSX.Element => (
  <React.Fragment>
    <StyledTableRow>
      <StyledTableColumn style={{ paddingRight: titlePadding }}>
        <StyledRowTitle>{fm(rowTitle)}</StyledRowTitle>
      </StyledTableColumn>
      <StyledTableColumn>
        <StyledDivCentered>
          <InputHandler
            type="RadioButtonRow"
            editing={true}
            name={`${lesions}.lesions`}
            formData={formData}
            options={lesionOptions}
            tooltips={[
              { index: 0, message: fm('imaging.opts.notDoneUnknown.notDone') },
              { index: 1, message: fm('imaging.opts.notDoneUnknown.unknown') },
            ]}
          />
        </StyledDivCentered>
      </StyledTableColumn>
      <StyledTableColumn>
        <InputHandler
          type="NumberField"
          editing={true}
          name={`${lesions}.lesionsDelta`}
          formData={formData}
          width={3}
        />
      </StyledTableColumn>
      <StyledTableColumn>
        <InputHandler
          type="NumberField"
          editing={true}
          name={`${lesions}.lesionsMaxDiameter`}
          formData={formData}
          width={3}
        />
      </StyledTableColumn>
      <StyledTableColumn>
        <StyledDivCentered>
          <InputHandler
            type="RadioTrendRow"
            editing={true}
            name={`${lesions}.lesionsTrend`}
            formData={formData}
            options={['decreased', 'same', 'increased']}
          />
        </StyledDivCentered>
      </StyledTableColumn>
    </StyledTableRow>
    <StyledTableRow>
      <StyledTableColumn colSpan={5}>
        <div style={{ marginBottom: '1.5rem' }}></div>
      </StyledTableColumn>
    </StyledTableRow>
  </React.Fragment>
);

const MriForm = ({ formData, disabledFields = [] }: IOwnProps): JSX.Element => {
  return (
    <React.Fragment>
      <PlatformConditional platform="ms">
        <React.Fragment>
          <FormRow title="general.date" headerWidth={4}>
            <InputHandler
              type="PartialDate"
              editing={!disabledFields.includes('date')}
              name="date"
              formData={formData}
              dateDefault="now"
              isNotCancellable={true}
            />
          </FormRow>
          <FormRow title="imaging.procedureCode" headerWidth={4}>
            <InputHandler
              type="TextField"
              editing={!disabledFields.includes('procedureCode')}
              name="procedureCode"
              formData={formData}
              placeholder={'imaging.procedureCodePlaceholder'}
            />
          </FormRow>
          <FormRow title="imaging.tesla" headerWidth={4}>
            <InputHandler
              type="RadioRow"
              editing={true}
              name="tesla"
              formData={formData}
              options={['0.5', '1.0', '1.5', '3.0']}
            />
          </FormRow>
          <FormRow title="imaging.wasGadoliniumGiven" headerWidth={4}>
            <InputHandler
              type="Radio"
              editing={true}
              name="wasGadoliniumGiven"
              formData={formData}
              options={['yes', 'no', 'unknown']}
              optionFormatter={optionFormatter('opts.yesNoUnknown')}
            />
          </FormRow>

          <FormSection header="imaging.brain">
            <StyledTable>
              <StyledTableBody>
                <LesionsFormTitleRow
                  title="imaging.brainT2Flair"
                  lesionsTitle="imaging.lesions"
                  deltaSymbol={<>&Delta;</>}
                  diameterSymbol="Ø"
                  trendTitle="imaging.trend"
                />
                <LesionsFormDataRow formData={formData} rowTitle="imaging.totalLesions" lesions="brainT2Flair.total" />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.infratentorialLesions"
                  lesions="brainT2Flair.infratentorial"
                />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.supratentorialLesions"
                  lesions="brainT2Flair.supratentorial"
                />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.intraAndJuxtacorticalLesions"
                  lesions="brainT2Flair.intraAndJuxtacortical"
                />
                <LesionsFormTitleRow title="imaging.brainT1" />
                <LesionsFormDataRow formData={formData} rowTitle="imaging.totalLesions" lesions="brainT1.total" />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.gadoliniumEnhancedLesions"
                  lesions="brainT1.gadoliniumEnhanced"
                />
                <StyledTableRow>
                  <StyledTableColumn style={{ verticalAlign: 'top' }}>
                    <StyledDiv>
                      <StyledRowTitle>{fm('imaging.brainVolume')}</StyledRowTitle>
                    </StyledDiv>
                  </StyledTableColumn>
                  <StyledTableColumn colSpan={4}>
                    <StyledDiv>
                      <InputHandler
                        type="NumberField"
                        editing={true}
                        name="brainVolume"
                        formData={formData}
                        width={12}
                      />
                      <StyledDiv style={{ marginTop: '1rem' }}>
                        <InputHandler
                          type="RadioTrendRow"
                          editing={true}
                          name="brainVolumeTrend"
                          formData={formData}
                          options={['decreased', 'same', 'increased']}
                          invert
                        />
                      </StyledDiv>
                    </StyledDiv>
                  </StyledTableColumn>
                </StyledTableRow>
              </StyledTableBody>
            </StyledTable>
          </FormSection>
          <FormSection header="imaging.spinalCord">
            <StyledTable>
              <StyledTableBody>
                <LesionsFormTitleRow
                  title="imaging.spinalCordT2"
                  lesionsTitle="imaging.lesions"
                  deltaSymbol={<>&Delta;</>}
                  diameterSymbol="Ø"
                  trendTitle="imaging.trend"
                />
                <LesionsFormDataRow formData={formData} rowTitle="imaging.totalLesions" lesions="spinalCordT2.total" />
                <LesionsFormTitleRow title="imaging.spinalCordT1" />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.gadoliniumEnhancedLesions"
                  lesions="spinalCordT1.gadoliniumEnhanced"
                />
              </StyledTableBody>
            </StyledTable>
          </FormSection>
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform={['sma', 'dmd', 'parkinson']}>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={true}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
      </PlatformConditional>
      <PlatformConditional platform="parkinson">
        <React.Fragment>
          <FormRow title="imaging.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous" id={'microAngiopathic'}>
            <InputHandler
              type="Radio"
              editing={true}
              name="wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous"
              formData={formData}
              options={['yes', 'fazekas0']}
              optionFormatter={optionFormatter('opts.yesFazekas0')}
            />
          </FormRow>
          {formData.document.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous === 'yes' && (
            <React.Fragment>
              <FormRow title="imaging.wasFazekasClassificationDone" id={'fazekas'}>
                <InputHandler
                  type="Radio"
                  editing={true}
                  name="wasFazekasClassificationDone"
                  formData={formData}
                  options={['yes', 'no']}
                  optionFormatter={optionFormatter('opts.yesNo')}
                />
              </FormRow>
              {formData.document.wasFazekasClassificationDone === 'yes' && (
                <FormRow title="imaging.fazekasGrade" id={'fazekasGrade'}>
                  <InputHandler
                    type="Radio"
                    editing={true}
                    name="fazekasGrade"
                    formData={formData}
                    options={['fazekas1', 'fazekas2', 'fazekas3']}
                    optionFormatter={optionFormatter('opts.fazekas')}
                  />
                </FormRow>
              )}
            </React.Fragment>
          )}
          <FormRow title="imaging.atrophy">
            <InputHandler
              type="Radio"
              editing={true}
              name="atrophy"
              formData={formData}
              options={['no', 'appropriateForAge', 'pronouncedCommon', 'focal']}
              optionFormatter={optionFormatter('opts.atrophy')}
            />
          </FormRow>
          {formData.document.atrophy === 'focal' && (
            <FormRow title="imaging.atrophyLocalization">
              <InputHandler type="TextArea" editing={true} name="atrophyLocalization" formData={formData} />
            </FormRow>
          )}
          <FormRow title="imaging.otherClinicallyRelevantFindingFound" id={'otherRelevant'}>
            <InputHandler
              type="Radio"
              editing={true}
              name="otherClinicallyRelevantFindingFound"
              formData={formData}
              options={['yes', 'no']}
              optionFormatter={optionFormatter('opts.yesNo')}
            />
          </FormRow>
          {formData.document.otherClinicallyRelevantFindingFound === 'yes' && (
            <FormRow title="imaging.whatFinding">
              <InputHandler type="TextArea" editing={true} name="otherClinicallyRelevantFinding" formData={formData} />
            </FormRow>
          )}
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform="ninmt">
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={true}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IMRI>;
  disabledFields?: string[];
}

interface ILesionsFormTitleRow {
  title: string;
  lesionsTitle?: string;
  deltaSymbol?: JSX.Element;
  diameterSymbol?: string;
  trendTitle?: string;
}

interface ILesionsFormDataRow extends IOwnProps {
  rowTitle: string;
  lesions: string | number;
  titlePadding?: string;
}

export default MriForm;
