import * as React from 'react';
import EventStepper, { EventStepperGroup } from '../../../../../components/EventStepper';
import InputHandler from '../../../../../components/InputHandler';
import { formatPartialDate, partialDateToValue } from 'neuro-utils';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from 'Components/EventStepper/components';
import Treatments from './components/Treatments';
import { TDCSContext } from '../..';
import TabContent from 'Components/TabContent';
import Treatment from './components/Treatment';
import { equals } from 'ramda';
import {
  addingTreatmentSessionDisabledReason,
  findPrecedingDoctorsOrder,
  getSessionNumber,
  subjectOfTreatmentTitle,
} from 'Utility/ninmtUtil';
import { RenderAlerts } from 'Components/Alerts';
import PatientsRating from './components/PatientsRating';
import PatientsRatings from './components/PatientsRatings';
import colors from '../../../../../config/theme/colors';
import FormRow from 'Components/FormRow';

const Sessions = (): JSX.Element => {
  const tdcsContext = React.useContext(TDCSContext);
  const { formData, fm, doctorsOrders, contraIndications, symptomsAndLocations, setDoctorsOrdersReview, setAnchor } =
    tdcsContext;

  // If all doctor's orders are "completed", no review is required
  const noReviewRequired = doctorsOrders.every((d) => d.completed);

  const buttonDisabledReason = addingTreatmentSessionDisabledReason('tdcs', doctorsOrders, contraIndications, formData);

  // Add session createDate for more accurate sorting
  const creationMutator = (
    event: ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent,
  ): ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent => {
    return { ...event, createDate: Date.now() };
  };

  // Optionally require subjects of treatment etc.
  const precedingDoctorsOrder = (index?: number, reqFields?: 'endTDCS'[]) =>
    findPrecedingDoctorsOrder('tdcs', doctorsOrders, formData.document.sessions ?? [], index ?? 0, reqFields);

  const precedingDocOrdBeforeAddingNewSes = React.useRef<IDoctorsOrder | undefined>(precedingDoctorsOrder(0, []));

  const [dateDefault, setDateDefault] = React.useState<'now' | PartialDate>('now');
  const [eventType, setEventType] = React.useState<string | undefined>(undefined);
  const [disableType, setDisableType] = React.useState<boolean>(false);

  const handleSessionTypeChange = (values: TOnChangeValues) => {
    const value = Object.values(values)?.[0];
    if (typeof value === 'string') setEventType(value);
  };

  // Capping the session date
  React.useEffect(() => {
    if (precedingDocOrdBeforeAddingNewSes.current && precedingDocOrdBeforeAddingNewSes.current.tdcs?.endTDCS) {
      setDateDefault(precedingDocOrdBeforeAddingNewSes.current.date ?? 'now');
    } else {
      // If preceding order document has not ended tdcs but future one has ended cap it to that
      const futureOrders = doctorsOrders.filter(
        (d) =>
          partialDateToValue(d.date) >
          partialDateToValue(formData.document.sessions?.[0]?.date ?? formData.document.date),
      );
      setDateDefault(futureOrders.find((o) => o.tdcs?.endTDCS)?.date ?? 'now');
    }
  }, [precedingDocOrdBeforeAddingNewSes.current, formData.document.date]);

  const hasDeletedSessions =
    Array.isArray(formData.document.sessions) &&
    formData.document.sessions.length > 0 &&
    formData.document.sessions.some((s) => s.removeTS || s.removeInfo);

  // Hide/filter deleted sessions
  React.useEffect(() => {
    if (hasDeletedSessions) {
      formData.onChange?.({ sessions: formData.document.sessions?.filter((s) => !(s.removeTS || s.removeInfo)) });
    }
  }, [hasDeletedSessions]);

  return (
    <React.Fragment>
      <FormRow title="tdcs.newSession">
        <InputHandler
          name="typeOfEvent"
          type="Radio"
          options={['treatmentMonitoring', 'doctorInterview', 'nurseReception', 'session', 'unusedSession']}
          editing={true}
          formData={{
            document: { typeOfEvent: eventType },
            onChange: handleSessionTypeChange,
          }}
          optionFormatter={(o) => fm(`tdcs.opts.eventTypes.${o}`)}
          disabledOptions={
            disableType
              ? ['treatmentMonitoring', 'doctorInterview', 'nurseReception', 'session', 'unusedSession']
              : undefined
          }
        />
      </FormRow>
      <EventStepperGroup
        allClosed
        previousEventsCollapseLimit={5}
        previousEventsCollapseMessage={{ showMessage: 'tdcs.showAllSessions', hideMessage: 'tdcs.hideAllSessions' }}
      >
        <EventStepper
          name="sessions"
          formData={formData}
          mutators={{ creationMutator }}
          customCreateEventItem={
            noReviewRequired
              ? undefined
              : () => {
                  // If all doctor's orders are not completed, review is required
                  setAnchor('sessionsAndProtocols');
                  setDoctorsOrdersReview({ active: true, completed: false });
                }
          }
          deleteDialogMode="full"
          stepLabelText={(d: ITDCSSession): string =>
            `${formatPartialDate(d.date)} - ${fm('tdcs.opts.eventTypes.session')}`
          }
          stepContent={(d: ITDCSSession): JSX.Element => (
            <React.Fragment>
              {['patientsRating', 'additionalInformation', 'subjectOfTreatment'].map((key, index) => {
                const value = d[key as keyof ITDCSSession];
                switch (key) {
                  case 'patientsRating': {
                    return (value as [])?.filter((s) => s).length > 0 ? (
                      <React.Fragment key={`${key}${index}`}>
                        <StepperHeaderValuePair
                          header={
                            <div style={{ whiteSpace: 'nowrap', fontWeight: 400 }}>
                              {fm('tdcs.patientsRating.title')}
                            </div>
                          }
                          value={''}
                        />
                        <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                          <TabContent>
                            {
                              (value as [])?.map((s: ITDCSRating, i: number) => ({
                                key: `${s.id}${i}`,
                                id: `${s.id}Tab${i}`,
                                title: s.symptom
                                  ? fm(`tdcs.patientsRating.opts.${s.symptom}`)
                                  : fm('tdcs.patientsRating.symptomPlaceholder'),
                                content: (
                                  <PatientsRating document={s} onChange={(): string => ''} editingEvent={false} />
                                ),
                                count: i,
                              })) as []
                            }
                          </TabContent>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`${key}${index}`} />
                    );
                  }
                  case 'subjectOfTreatment': {
                    const currentIndex = formData.document.sessions?.findIndex((s) => equals(s, d));
                    return (value as [])?.filter((s) => s).length > 0 ? (
                      <React.Fragment key={`${key}${index}`}>
                        <StepperHeaderValuePair header={fm('tdcs.subjectOfTreatment.title')} value={''} />
                        <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                          <TabContent>
                            {(value as [])?.map((s: ITDCSTreatment, i: number) => ({
                              key: `${s.id}${i}`,
                              id: `${s.id}Tab${i}`,
                              title: subjectOfTreatmentTitle(s, 'tdcs', fm),
                              ended: s.deleted,
                              content: (
                                <Treatment
                                  document={s}
                                  onChange={(): string => ''}
                                  session={d}
                                  sessionNumber={getSessionNumber(
                                    formData.document?.sessions ?? [],
                                    currentIndex ?? 0,
                                    s,
                                  )}
                                  precedingDoctorsOrder={precedingDoctorsOrder(currentIndex)}
                                  editingEvent={false}
                                />
                              ),
                              count: i,
                            }))}
                          </TabContent>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`${key}${index}`} />
                    );
                  }
                  default: {
                    return (
                      <StepperHeaderValuePair
                        key={`${key}${index}`}
                        header={fm(`tdcs.${key === 'additionalInformation' ? 'additionalInformationSession' : key}`)}
                        value={value || '-'}
                      />
                    );
                  }
                }
              })}
            </React.Fragment>
          )}
          addNewTextHeader=""
          addNewTextButton="tdcs.newSession"
          buttonDisabled={buttonDisabledReason ? true : eventType !== 'session' ? true : false}
          hideNewButton={!(eventType === 'session' || !eventType)}
          buttonDisabledMessage={
            eventType !== 'session' && !buttonDisabledReason ? fm('tdcs.chooseEventType') : undefined
          }
          buttonDisabledElement={
            buttonDisabledReason ? (
              <RenderAlerts
                alerts={[{ textID: `tdcs.${buttonDisabledReason}Message`, severity: 'info' }]}
                elevation={0}
                width={80}
              />
            ) : undefined
          }
          cancelExtraAction={() => {
            setDisableType(false);
            setEventType(undefined);
          }}
          saveExtraAction={() => {
            setDisableType(false);
            setEventType(undefined);
          }}
          addNewExtraAction={() => setDisableType(true)}
          startEditExtraAction={() => setDisableType(true)}
          previousEventsTextHeader="tdcs.previousSessions"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: { date: formData.document.sessions?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.tdcs?.endTDCS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={<div style={{ whiteSpace: 'nowrap' }}>{fm('tdcs.patientsRating.title')}</div>}
                  input={<></>}
                />
                <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                  <PatientsRatings
                    editIndex={index}
                    formData={formData}
                    fm={fm}
                    symptomsAndLocations={symptomsAndLocations}
                  />
                </div>
                <StepperHeaderFormInputPair
                  header={fm('tdcs.additionalInformationSession')}
                  input={
                    <InputHandler
                      type="TextArea"
                      editing={true}
                      name="additionalInformation"
                      formData={{
                        onChange,
                        document: {
                          additionalInformation: formData.document.sessions?.[index]?.additionalInformation || '',
                        },
                      }}
                      placeholder="tdcs.additionalInformationSession"
                    />
                  }
                />
                <StepperHeaderFormInputPair header={fm('tdcs.subjectOfTreatment.title')} input={<></>} />
                <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                  <Treatments editIndex={index} formData={formData} fm={fm} doctorsOrders={doctorsOrders} />
                </div>
              </React.Fragment>
            );
          }}
        />
        <EventStepper
          name="unusedSessions"
          formData={formData}
          mutators={{ creationMutator }}
          stepLabelText={(d: ITDCSUnusedSession): string =>
            `${formatPartialDate(d.date)} - ${fm('tdcs.opts.eventTypes.unusedSession')}`
          }
          stepContent={(d: ITDCSUnusedSession): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={fm('tdcs.unusedSessionReason')}
                value={d.reason ? fm(`tdcs.opts.unusedSessionReason.${d.reason}`) : '-'}
              />
              {d.reason && (
                <React.Fragment>
                  <StepperHeaderValuePair
                    header={fm('tdcs.unusedSessionReasonDetails')}
                    value={d.reasonDetails ? fm(`tdcs.opts.unusedSessionReasonDetails.${d.reasonDetails}`) : '-'}
                  />
                  {d.reasonDetails === 'other' && (
                    <StepperHeaderValuePair
                      header={fm('tdcs.unusedSessionReasonDetailsOther')}
                      value={d.reasonDetailsOther && d.reasonDetailsOther.length > 0 ? d.reasonDetailsOther : '-'}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          addNewTextHeader=""
          addNewTextButton="tdcs.newSession"
          buttonDisabled={eventType !== 'unusedSession'}
          hideNewButton={eventType !== 'unusedSession'}
          buttonDisabledMessage={fm('tdcs.chooseEventType')}
          previousEventsTextHeader="tdcs.previousUnusedSessions"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            const patientInducedReasonDetails = [
              'forgotten',
              'sick',
              'blockedByOtherReason',
              'confusionAboutSessionTime',
              'unknown',
              'other',
            ];
            const unitInducedReasonDetails = [
              'staffShortage',
              'deviceRelated',
              'cancelledDueToEmergencyPatient',
              'confusionInSessionBooking',
              'other',
            ];

            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: { date: formData.document.unusedSessions?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.tdcs?.endTDCS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('tdcs.unusedSessionReason')}
                  input={
                    <InputHandler
                      type="Radio"
                      editing={true}
                      name="reason"
                      formData={{
                        onChange,
                        document: {
                          reason: formData.document.unusedSessions?.[index]?.reason || '',
                          reasonDetails: formData.document.unusedSessions?.[index]?.reasonDetails,
                          reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther,
                        },
                      }}
                      options={['patientInduced', 'unitInduced']}
                      optionFormatter={(id: string | number) => fm(`tdcs.opts.unusedSessionReason.${id}`)}
                      dependentFieldsRemovalWarning={true}
                      dependentFieldsList={
                        formData.document.unusedSessions?.[index]?.reasonDetails !== 'other'
                          ? () => ['reasonDetails', 'reasonDetailsOther']
                          : undefined
                      }
                    />
                  }
                />
                {formData.document.unusedSessions?.[index]?.reason && (
                  <React.Fragment>
                    <StepperHeaderFormInputPair
                      header={fm('tdcs.unusedSessionReasonDetails')}
                      input={
                        <InputHandler
                          type="Radio"
                          editing={true}
                          name="reasonDetails"
                          formData={{
                            onChange,
                            document: {
                              reasonDetails: formData.document.unusedSessions?.[index]?.reasonDetails || '',
                              reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther,
                            },
                          }}
                          options={
                            formData.document.unusedSessions?.[index]?.reason === 'patientInduced'
                              ? patientInducedReasonDetails
                              : unitInducedReasonDetails
                          }
                          optionFormatter={(id: string | number) => fm(`tdcs.opts.unusedSessionReasonDetails.${id}`)}
                          dependentFieldsRemovalWarning={true}
                          dependentFieldsList={() => ['reasonDetailsOther']}
                        />
                      }
                    />
                    {formData.document.unusedSessions?.[index]?.reasonDetails === 'other' && (
                      <StepperHeaderFormInputPair
                        header={fm('tdcs.unusedSessionReasonDetailsOther')}
                        input={
                          <InputHandler
                            type="TextArea"
                            editing={true}
                            name="reasonDetailsOther"
                            formData={{
                              onChange,
                              document: {
                                reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther || '',
                              },
                            }}
                            placeholder="tdcs.unusedSessionReasonDetailsOther"
                          />
                        }
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          }}
          theme={{ highlightColor: colors.highlight.light }}
        />
        <EventStepper
          name="otherEvents"
          formData={formData}
          mutators={{ creationMutator }}
          stepLabelText={(d: ITDCSOtherEvent): string =>
            `${formatPartialDate(d.date)} - ${fm(`tdcs.opts.eventTypes.${d.type}`)}`
          }
          stepContent={(d: ITDCSOtherEvent): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={fm('tdcs.typeOfEvent')}
                value={d.type ? fm(`tdcs.opts.eventTypes.${d.type}`) : '-'}
              />
              <React.Fragment>
                <StepperHeaderValuePair header={fm('tdcs.details')} value={d.details ?? '-'} />
              </React.Fragment>
            </React.Fragment>
          )}
          addNewTextHeader=""
          addNewTextButton="tdcs.newSession"
          buttonDisabled={!['treatmentMonitoring', 'doctorInterview', 'nurseReception'].includes(eventType ?? '')}
          buttonDisabledMessage={fm('tdcs.chooseEventType')}
          previousEventsTextHeader="tdcs.previousSessions"
          hideNewButton={!['treatmentMonitoring', 'doctorInterview', 'nurseReception'].includes(eventType ?? '')}
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            if (!formData.document.otherEvents?.[index].type) {
              onChange?.({ type: eventType });
            }
            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: { date: formData.document.otherEvents?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.tdcs?.endTDCS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('tdcs.details')}
                  input={
                    <InputHandler
                      type="TextArea"
                      editing={true}
                      name="details"
                      formData={{
                        onChange,
                        document: {
                          details: formData.document.otherEvents?.[index]?.details || '',
                        },
                      }}
                    />
                  }
                />
              </React.Fragment>
            );
          }}
        />
      </EventStepperGroup>
    </React.Fragment>
  );
};

export default Sessions;
