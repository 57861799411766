import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';

const SettingsEventContent = ({ settings }: IOxygenTherapySettingsProps): JSX.Element => (
  <React.Fragment>
    <StepperHeaderValuePair
      header={<FormattedMessage id={'treatment.oxygenTherapy.settings.implementationOfTreatment'} />}
      value={
        settings.implementationOfTreatment ? (
          <FormattedMessage id={`treatment.oxygenTherapy.opts.${settings.implementationOfTreatment}`} />
        ) : null
      }
    />
    <StepperHeaderValuePair
      header={<FormattedMessage id={'treatment.oxygenTherapy.settings.flow'} />}
      value={
        settings.flow || settings.flow === 0 ? (
          <>
            {`${settings.flow} `}
            <FormattedMessage
              id={
                settings && settings.implementationOfTreatment === 'venturiMask'
                  ? 'treatment.oxygenTherapy.settings.percent'
                  : 'treatment.oxygenTherapy.settings.litersPerMinute'
              }
            />
          </>
        ) : null
      }
    />
    <StepperHeaderValuePair
      header={<FormattedMessage id={'treatment.oxygenTherapy.settings.humidifierInUse'} />}
      value={
        settings.humidifierInUse || settings.humidifierInUse === false ? (
          <FormattedMessage id={`general.${settings.humidifierInUse}`} />
        ) : null
      }
    />
  </React.Fragment>
);

interface IOxygenTherapySettingsProps {
  settings: IOxygenTherapySettings;
}

const SettingsFormContent = ({
  thisSetting,
  onChange,
  mainDate,
}: {
  thisSetting?: IOxygenTherapySettings;
  onChange: IFormData['onChange'];
  mainDate?: PartialDate;
}): JSX.Element => {
  const locPath = 'treatment.oxygenTherapy.settings';

  return (
    <>
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`general.date`} />}
        input={
          <InputHandler
            type="PartialDate"
            editing={true}
            name="date"
            formData={{
              onChange,
              document: {
                date: thisSetting?.date,
              },
            }}
            dateDefault={mainDate || 'now'}
            dateHook={{ dateHookFloor: mainDate }}
            isNotCancellable={true}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.implementationOfTreatment`} />}
        input={
          <InputHandler
            type="Radio"
            editing={true}
            name="implementationOfTreatment"
            formData={{
              onChange,
              document: {
                implementationOfTreatment: thisSetting?.implementationOfTreatment,
              },
            }}
            options={[
              'tracheostomy',
              'fullFaceMask',
              'nasalMouthMask',
              'nasalMask',
              'nasalPlugs',
              'oxygenMustache',
              'oxygenNose',
              'mouthPiece',
              'venturiMask',
            ]}
            optionFormatter={(name: string | number): string | JSX.Element => (
              <FormattedMessage id={`treatment.oxygenTherapy.opts.${name}`} />
            )}
            dependentFieldsList={(value, prevValue): string[] => {
              // Clear flow field if venturiMask selected or unselected
              return value === 'venturiMask' && prevValue !== 'venturiMask'
                ? ['flow']
                : prevValue === 'venturiMask' && value !== 'venturiMask'
                  ? ['flow']
                  : [];
            }}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.flow`} />}
        input={
          <Unit
            unit={
              <FormattedMessage
                id={
                  thisSetting && thisSetting.implementationOfTreatment === 'venturiMask'
                    ? `${locPath}.percent`
                    : `${locPath}.litersPerMinute`
                }
              />
            }
          >
            <InputHandler
              type="NumberField"
              editing={true}
              name="flow"
              formData={{
                onChange,
                document: {
                  flow: thisSetting?.flow,
                },
              }}
              precision={2}
              max={thisSetting && thisSetting.implementationOfTreatment === 'venturiMask' ? 100 : undefined}
              placeholder={`${locPath}.flow`}
            />
          </Unit>
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.humidifierInUse`} />}
        input={
          <InputHandler
            type="Radio"
            editing={true}
            name="humidifierInUse"
            formData={{
              onChange,
              document: {
                humidifierInUse: thisSetting?.humidifierInUse,
              },
            }}
            preset={'yesno'}
          />
        }
      />
    </>
  );
};

const locPath = 'treatment.oxygenTherapy.';

const OxygenTherapySettings = ({ formData, view }: IFormContext<IOxygenTherapy, any>): JSX.Element => {
  return (
    <FormSection header={locPath + 'settings.' + 'title'}>
      <EventStepper
        name="settings"
        formData={formData}
        stepLabelText={(d: IOxygenTherapySettings): string => formatPartialDate(d.date)}
        stepContent={(d: IOxygenTherapySettings): JSX.Element => <SettingsEventContent settings={d} />}
        addNewTextHeader={locPath + 'settings.newSettings'}
        addNewTextButton={locPath + 'settings.newSettings'}
        latestEventTextHeader={locPath + 'settings.currentSettings'}
        previousEventsTextHeader={locPath + 'settings.previousSettings'}
        noPreviousEventsTextHeader={locPath + 'settings.noPreviousSettings'}
        separateLatest={true}
        editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
          const thisSetting = formData.document.settings?.[index];
          return (
            <SettingsFormContent
              thisSetting={thisSetting}
              onChange={onChange}
              mainDate={
                formData.document.settings && formData.document.settings.length < 2 ? formData.document.date : undefined
              }
            />
          );
        }}
        viewing={!!view?.viewing}
      />
    </FormSection>
  );
};

export default withFormContext(OxygenTherapySettings);
