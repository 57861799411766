import { Container, Item } from 'Components/Grid';
import { path } from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import FormRow from '../../../../../components/FormRow';
import FormSectionHistoryAcceptor, { IElement } from '../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { FTDFormContent as sectionData } from '../../../utils/definitions';
import {
  coreCriteriaAndCriteriaForPossibleFTDFulfilled,
  coreCriteriaAndCriteriaForProbableFTDFulfilled,
  resultPicker,
} from '../../../utils/FTD';
import { fm } from 'Components/FormatMessage';

import { ReferenceText } from '../../../utils/styled';

const SubHeader = styled.div`
  margin: 1rem 0 2rem 0;
`;

const MakeFormRowCounter = ({
  title,
  description,
  content,
}: {
  title: string;
  description?: JSX.Element;
  content: JSX.Element;
}): JSX.Element => {
  return (
    <FormRow
      title={title}
      description={description}
      headerWidth={6}
      headerStyle={{ fontWeight: 400, color: colors.primaryText }}
    >
      {content}
    </FormRow>
  );
};

const possibleOrProbable = (
  possible: 'yes' | 'no' | 'unknown',
  probable: 'yes' | 'no' | 'unknown',
): 'yes' | 'no' | 'unknown' =>
  [possible, probable].includes('yes') ? 'yes' : [possible, probable].includes('no') ? 'no' : 'unknown';

const MakeFormRow = ({
  title,
  description,
  section,
  name,
  formData,
  editing,
  viewing,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  section: string;
  name: string;
  formData: IFormData<IFTD>;
  editing: boolean;
  viewing: boolean;
  bottomMargin?: boolean;
}): JSX.Element => (
  <FormRow title={title} description={description} headerWidth={6} bottomMargin={bottomMargin}>
    {editing ? (
      <InputHandler
        editing={!viewing}
        formData={{
          onChange: formData?.onChange,
          document: {
            [section]: {
              [name]: path([section, name], formData?.document),
            },
          },
        }}
        name={`${section}.${name}`}
        type="RadioButtonRow"
        preset="yesNoUnknown"
        height={3.5}
        width={9}
      />
    ) : (
      <span style={{ fontWeight: viewing ? undefined : 600 }}>
        {fm(
          `diagnosis.opts.yesNoUnknown.${
            name === 'theCriteriaForPossibleFTDInSectionIIAreMet'
              ? coreCriteriaAndCriteriaForPossibleFTDFulfilled(formData?.document)
              : possibleOrProbable(
                  coreCriteriaAndCriteriaForPossibleFTDFulfilled(formData?.document),
                  coreCriteriaAndCriteriaForProbableFTDFulfilled(formData?.document),
                )
          }`,
        )}
      </span>
    )}
  </FormRow>
);

const setAllUnknown = (formData: IFormData<IFTD>, viewing: boolean): void => {
  if (!viewing) {
    Object.keys(sectionData)
      .filter((key) => key !== 'summary')
      .forEach((section) => {
        sectionData[section]['criteria'] &&
          sectionData[section]['criteria']?.forEach((row) => {
            const document = formData?.document as { [key: string]: any };
            if (
              (!document[section] || !document[section][row]) &&
              !['theCriteriaForPossibleFTDInSectionIIAreMet', 'criteriaForPossibleOrProbableFTDFulfilled'].includes(row)
            ) {
              formData?.onChange?.({
                [section]: { ...document[section], [row]: 'unknown' },
              });
            }
          });
      });
  }
};

const FTDForm = ({ documents, formData, editing, view }: IFormContext<IFTD>): JSX.Element => {
  const isViewing = !editing && !!view?.viewing;
  const docID = editing ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      {formData ? (
        <React.Fragment>
          <FormRow title="general.date">
            <InputHandler
              type="PartialDate"
              editing={!isViewing}
              name="date"
              formData={formData}
              dateDefault="now"
              isNotCancellable={true}
            />
          </FormRow>

          {Object.keys(sectionData).map((section): JSX.Element => {
            const subHeader = sectionData[section]['subHeader']
              ? {
                  subHeader: {
                    header: (
                      <SubHeader>{fm(`diagnosis.ftd.${section}.${sectionData[section]['subHeader']}`)}</SubHeader>
                    ),
                  },
                }
              : {};
            const rows = sectionData[section]['criteria']
              ? sectionData[section]['criteria']?.reduce(
                  (current, key) => {
                    current[key] = {
                      element: (
                        <MakeFormRow
                          title={`diagnosis.ftd.${section}.${key}`}
                          section={section}
                          name={key}
                          formData={formData}
                          editing={
                            [
                              'theCriteriaForPossibleFTDInSectionIIAreMet',
                              'criteriaForPossibleOrProbableFTDFulfilled',
                            ].includes(key)
                              ? false
                              : true
                          }
                          viewing={isViewing}
                          description={
                            [
                              'theCriteriaForPossibleFTDInSectionIIAreMet',
                              'criteriaForPossibleOrProbableFTDFulfilled',
                            ].includes(key)
                              ? fm(`diagnosis.ftd.autoFillDescription`)
                              : undefined
                          }
                        />
                      ),
                    };
                    return current;
                  },
                  {} as { [key: string]: TAnyObject },
                )
              : {};

            const resultsToDisplay = sectionData[section]['result']
              ? section === 'summary'
                ? resultPicker(formData.document, 'criteriaForCertainFTDFulfilled') === 'yes'
                  ? sectionData[section]['result']?.slice(0, 1)
                  : resultPicker(formData.document, 'criteriaForProbableFTDFulfilled') === 'yes'
                    ? sectionData[section]['result']?.slice(0, 2)
                    : resultPicker(formData.document, 'criteriaForPossibleFTDFulfilled') === 'yes'
                      ? sectionData[section]['result']?.slice(0, 3)
                      : sectionData[section]['result']?.slice(0, 6)
                : sectionData[section]['result']
              : undefined;

            const result = resultsToDisplay
              ? resultsToDisplay?.reduce(
                  (current, key) => {
                    current[key] = {
                      element: (
                        <MakeFormRowCounter
                          title={`diagnosis.ftd.${section}.${key}`}
                          content={
                            <div style={{ fontWeight: 600, marginBottom: '4.5rem' }}>
                              {fm(`diagnosis.opts.yesNoUnknown.${resultPicker(formData.document, key)}`)}
                            </div>
                          }
                        />
                      ),
                    };
                    return current;
                  },
                  {} as { [key: string]: TAnyObject },
                )
              : {};

            const sectionContent = { ...subHeader, ...rows, ...result } as { [key: string]: IElement };

            return (
              <FormSectionHistoryAcceptor
                key={section}
                name={section}
                formData={formData}
                documentID={docID}
                documents={documents}
                optionFormatter={(s: string): JSX.Element => fm(`general.${s}`)}
                header={`diagnosis.ftd.${section}.title`}
                hideCopyButton={isViewing}
              >
                {sectionContent}
              </FormSectionHistoryAcceptor>
            );
          })}

          <Container>
            <Item style={{ padding: '2rem 0' }}>
              <ReferenceText>{fm('diagnosis.ftd.referenceText')}</ReferenceText>
            </Item>
          </Container>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default withFormContext(FTDForm);
