import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import {
  findActiveMedications,
  getDiseaseModifyingMedications,
  medicationEnded,
  medicationSubstanceCategories,
} from '../utils';
import PlatformConditional from '../../../components/PlatformConditional';
import { sortPartialDate, formatPartialDate, isPartialDate } from 'neuro-utils';
import colors from '../../../config/theme/colors';
import { FormattedMessage } from 'react-intl';
import { styleTile } from '../../../config/theme/componentTheme';
import { getLEDDTimeseries } from 'neuro-calculation-commons';
import styled from 'styled-components';
import { path } from 'ramda';
import { sortDocuments } from '../../../utility/randomUtil';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { DescriptionText } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';
import { patientHasSleepApneaDiagnosis } from 'Routes/Treatment/utils';
import { fm } from 'Components/FormatMessage';

const SmallText = styled.div`
  color: ${colors.primaryText};
  font-size: ${styleTile.smallFont};
`;

const startDatePicker = (document: IMedication): { title: JSX.Element; value: string; key: string } => {
  const dateFieldName = isPartialDate(document?.usageStartDate) ? 'usageStartDate' : 'startDate';
  const dateValue = document?.[dateFieldName];
  return {
    title: fm(`medication.${dateFieldName}`),
    value: isPartialDate(dateValue) ? formatPartialDate(dateValue) : '-',
    key: dateFieldName,
  };
};

const SmaDmdTile = ({ platform, document }: { platform: 'sma' | 'dmd'; document?: IMedication }) => {
  if (!document?.medicationName) {
    return (
      <SmallText>
        <FormattedMessage id="medication.noDiseaseModifyingMedications" />
      </SmallText>
    );
  }

  const data = [startDatePicker(document)];

  if (platform === 'sma' && Array.isArray(document.administration) && document.administration.length > 0) {
    document.administration.forEach((administration, index) => {
      data.push({
        title: fm('medication.administration.header'),
        value: formatPartialDate(administration?.date as PartialDate),
        key: `administration${index + 1}`,
      });
    });
  }

  return <TileContentMaker type="bigHeaderInfo" bigHeaderText={document.medicationName ?? null} data={data} />;
};

const ParkinsonTile = ({
  documents,
  parkinsonCategories,
}: {
  documents: Array<IMedication>;
  parkinsonCategories?: {
    levodopa?: any;
    maobInhibitors?: any;
    dopamineAgonists?: any;
  };
}) => {
  return (
    <TileContentMaker
      type="bigHeaderInfo"
      bigHeaderText={
        <React.Fragment>
          {(Array.isArray(documents) &&
            documents.length > 0 &&
            path([0, 1], getLEDDTimeseries(documents as any).reverse())) ??
            '-'}{' '}
          mg
        </React.Fragment>
      }
      bigHeaderTextSubheader={'LEDD'}
      data={[
        {
          title: fm('medication.medicine.levodopaMedications'),
          value: parkinsonCategories?.levodopa || 0,
          key: 'levodopaMedications',
        },
        {
          title: fm('medication.medicine.maobInhibitors'),
          value: parkinsonCategories?.maobInhibitors || 0,
          key: 'maobInhibitors',
        },
        {
          title: fm('medication.medicine.dopamineAgonists'),
          value: parkinsonCategories?.dopamineAgonists || 0,
          key: 'dopamineAgonists',
        },
      ]}
    />
  );
};

const MsTile = ({ documents }: { documents: Array<IMedication> }): JSX.Element => {
  const formatDosage = (regimen: Regimen, substances: string): string => {
    if (regimen) {
      switch (regimen.regimenType) {
        case 'default': {
          const strengths = (regimen as IRegimenDefault)?.strengths?.[0];
          if (!strengths) return '-';
          const substanceArray = substances.split('; ');
          let dosage = '';
          substanceArray.forEach((substance, index) =>
            index > 0 && strengths?.[substance]
              ? (dosage += ` / ${strengths[substance]}`)
              : (dosage += strengths?.[substance]),
          );
          return dosage;
        }
        case 'custom':
        case 'onDemand':
        case 'single-dose':
        default:
          return '-';
      }
    }
    return '-';
  };

  const formatReason = (
    primaryReason: IMedication['endReasonPrimary'],
    reasons: string[] | undefined,
  ): JSX.Element | undefined => {
    if (Array.isArray(reasons) && reasons.length > 0) {
      if (reasons.length < 2) {
        return fm(`medication.opts.${primaryReason ?? reasons[0]}`);
      } else {
        return primaryReason ? fm(`medication.opts.${primaryReason}`) : fm('medication.endedReasonMultiple');
      }
    } else {
      return undefined;
    }
  };

  const diseaseModifyingMedications = documents ? getDiseaseModifyingMedications(documents, 'ms') : [];
  diseaseModifyingMedications
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n1.startDate, n2.startDate))
    .reverse();

  const activeDiseaseModifyingMedications = diseaseModifyingMedications.filter(
    (medication: IMedication) => medicationEnded(medication) !== true,
  );

  const endedDiseaseModifyingMedications = diseaseModifyingMedications
    .filter((medication: IMedication) => medicationEnded(medication) === true)
    .sort((n1, n2) => sortPartialDate(n1.endDate, n2.endDate))
    .reverse();

  return diseaseModifyingMedications.length > 0 ? (
    <React.Fragment>
      {activeDiseaseModifyingMedications.length > 0 &&
        activeDiseaseModifyingMedications.map((medication: IMedication, index: number) => {
          return (
            index < 2 && (
              <React.Fragment key={medication?._id}>
                <TileContentMaker
                  type="bigHeaderInfo"
                  bigHeaderText={medication.medicationName ?? null}
                  bigHeaderTextSubheader={fm('medication.activeMedication')}
                  bigHeaderValue={
                    Array.isArray(medication?.regimen)
                      ? formatDosage(
                          medication.regimen?.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse()[0],
                          medication?.medicationSubstances ?? '',
                        )
                      : '-'
                  }
                  bigHeaderValueSubheader={fm('medication.regimenDosage')}
                  data={[startDatePicker(medication)]}
                />

                {diseaseModifyingMedications?.length === 1 && (
                  <DescriptionText>
                    <FormattedMessage id="medication.noPreviousDiseaseModifyingMedications" />
                  </DescriptionText>
                )}
              </React.Fragment>
            )
          );
        })}
      <div style={{ height: '1rem' }}></div>
      {activeDiseaseModifyingMedications.length < 2 && endedDiseaseModifyingMedications.length > 0 && (
        <TileContentMaker
          type="bigHeaderInfo"
          active={false}
          bigHeaderText={endedDiseaseModifyingMedications[0]?.medicationName ?? null}
          bigHeaderTextSubheader={fm('medication.previousMedication')}
          bigHeaderValue={
            Array.isArray(endedDiseaseModifyingMedications[0]?.regimen)
              ? formatDosage(
                  endedDiseaseModifyingMedications[0].regimen
                    ?.sort((n1, n2) => sortPartialDate(n1.date, n2.date))
                    .reverse()[0],
                  endedDiseaseModifyingMedications[0]?.medicationSubstances ?? '',
                )
              : '-'
          }
          bigHeaderValueSubheader={fm('medication.regimenDosage')}
          data={[
            {
              title: fm('medication.endedDate'),
              value: endedDiseaseModifyingMedications[0]?.endDate
                ? formatPartialDate(endedDiseaseModifyingMedications[0]?.endDate)
                : '-',
              key: 'endedDate',
            },
            {
              title: fm('medication.endedReason'),
              value:
                formatReason(
                  endedDiseaseModifyingMedications[0]?.endReasonPrimary,
                  endedDiseaseModifyingMedications[0]?.endReason,
                ) ?? '-',
              key: 'endedReason',
            },
          ]}
        />
      )}
    </React.Fragment>
  ) : (
    <SmallText>
      <FormattedMessage id="medication.noDiseaseModifyingMedications" />
    </SmallText>
  );
};

const EpilepsyTile = ({ documents }: { documents: Array<IMedication> }): JSX.Element => {
  const epilepsyMedications =
    documents && documents.length > 0
      ? getDiseaseModifyingMedications(documents, 'epilepsy').filter(
          (d) => d.hasEnded?.[0] !== true && !['N05CD08', 'N05BA01'].includes(d.atc ?? ''),
        )
      : [];

  const latestMedication = epilepsyMedications.sort((a, b) =>
    sortDocuments([{ type: 'date', sortField: 'startDate' }])(a, b),
  )[0];

  const numberOfMedicines = epilepsyMedications.length;

  return (
    <React.Fragment>
      {latestMedication && (
        <TileContentMaker
          type="bigHeaderInfo"
          bigHeaderText={latestMedication.medicationName || ''}
          data={[startDatePicker(latestMedication)]}
        />
      )}

      {!numberOfMedicines && (
        <DescriptionText>
          <FormattedMessage id="medication.noDiseaseModifyingMedications" />
        </DescriptionText>
      )}
    </React.Fragment>
  );
};

const SleepApneaTile = ({ documents }: { documents: Array<IMedication> }): JSX.Element => {
  const allDocs = useSelector((s: { documents: IDocumentStore }) => s.documents.sortedAndMergedDocuments);
  const diagnosisDocs: Array<IDiagnosis> = (allDocs || []).filter((doc) => doc._type === 'diagnosis');

  const sleepApneaMedications =
    documents && documents.length > 0
      ? getDiseaseModifyingMedications(documents, 'sleepApnea').filter((m) =>
          !patientHasSleepApneaDiagnosis(diagnosisDocs)
            ? m.hasEnded?.[0] !== true && m.atc !== 'N06BA14'
            : m.hasEnded?.[0] !== true,
        )
      : [];

  const latestMedication = sleepApneaMedications.sort((a, b) =>
    sortDocuments([{ type: 'date', sortField: 'startDate' }])(a, b),
  )[0];

  const numberOfMedicines = sleepApneaMedications.length;

  return (
    <React.Fragment>
      {latestMedication && (
        <TileContentMaker
          type="bigHeaderInfo"
          bigHeaderText={latestMedication.medicationName || ''}
          data={[startDatePicker(latestMedication)]}
        />
      )}

      {!numberOfMedicines && (
        <DescriptionText>
          <FormattedMessage id="medication.noDiseaseModifyingMedications" />
        </DescriptionText>
      )}
    </React.Fragment>
  );
};

const GeneralTile = ({
  documents,
  platform,
  showOtherActiveMedications,
}: {
  documents: Array<IMedication>;
  platform: Platform;
  showOtherActiveMedications: boolean;
}): JSX.Element => {
  const platformMedications: IMedication[] =
    documents && documents.length > 0
      ? getDiseaseModifyingMedications(documents, platform).filter((d) => d.hasEnded?.[0] !== true)
      : [];

  const latestMedication = platformMedications.sort((a, b) =>
    sortDocuments([{ type: 'date', sortField: 'startDate' }])(a, b),
  )[0];

  const numberOfMedicines = platformMedications.length;

  return (
    <React.Fragment>
      {latestMedication ? (
        <TileContentMaker
          type="bigHeaderInfo"
          bigHeaderText={latestMedication.medicationName || ''}
          data={[
            startDatePicker(latestMedication),
            ...(showOtherActiveMedications
              ? [
                  {
                    title: fm('medication.activeMedications'),
                    value: numberOfMedicines,
                    key: 'activeMedications',
                  },
                ]
              : []),
          ]}
        />
      ) : (
        <DescriptionText>
          <FormattedMessage id="medication.noDiseaseModifyingMedications" />
        </DescriptionText>
      )}
    </React.Fragment>
  );
};

// Return latest SMA/DMD medication sorted its administration dates
const findLatestSmaDmdMedication = (platform: string, documents: IMedication[]): IMedication | undefined => {
  /** Medications visualized in tile are different from graph, so define them here */
  const atcCodesToVisualize: { [key: string]: string[] } = {
    sma: [
      'M09AX07', // nusinerseeni
      'M09AX09', // onasemnogeeniabeparvoveekki
    ],
    dmd: [
      'M09AX03', // atalureeni
    ],
  };

  const medications = (documents ?? [])
    .filter((document) => {
      if (document.isClinicalStudy?.[0] === true) {
        return medicationEnded(document) !== true;
      }
      return atcCodesToVisualize[platform].includes(document.atc ?? '') && medicationEnded(document) !== true;
    })
    .sort((n1, n2) => sortPartialDate(n2.startDate, n1.startDate));

  if (medications.length > 0) {
    if (medications[0].administration)
      medications[0].administration.sort((n1, n2) => sortPartialDate(n2.date, n1.date));

    return medications[0];
  } else {
    return undefined;
  }
};

const MedicationDash = ({ documents: documentsRedux }: IOwnProps): JSX.Element | null => {
  const documents: IMedication[] | undefined = JSON.parse(JSON.stringify(documentsRedux));

  // Only use documents owned by current organization
  const orgId = useSelector((s: IState) => s.session.data?.orgid);
  const selectedMedicationOrg = useSelector((s: IState) => s.session.selectedMedicationOrg) || orgId;
  const thisOrgDocuments = (documents || []).filter(
    (d) =>
      d._ownerOrg === selectedMedicationOrg ||
      (d._pastOwners && Object.keys(d._pastOwners).some((org) => org === selectedMedicationOrg)),
  );

  const activeMedications = findActiveMedications(thisOrgDocuments);

  const smaMedication = findLatestSmaDmdMedication('sma', thisOrgDocuments);
  const dmdMedication = findLatestSmaDmdMedication('dmd', thisOrgDocuments);

  return thisOrgDocuments ? (
    <React.Fragment>
      <PlatformConditional platform="sma">
        <SmaDmdTile platform="sma" document={smaMedication} />
      </PlatformConditional>

      <PlatformConditional platform="dmd">
        <SmaDmdTile platform="dmd" document={dmdMedication} />
      </PlatformConditional>

      <PlatformConditional platform="parkinson">
        <ParkinsonTile
          documents={activeMedications}
          parkinsonCategories={{
            levodopa: medicationSubstanceCategories(activeMedications)?.levodopa,
            maobInhibitors: medicationSubstanceCategories(activeMedications)?.maobInhibitors,
            dopamineAgonists: medicationSubstanceCategories(activeMedications)?.dopamineAgonists,
          }}
        />
      </PlatformConditional>

      <PlatformConditional platform="ms">
        <MsTile documents={thisOrgDocuments} />
      </PlatformConditional>

      <PlatformConditional platform="epilepsy">
        <EpilepsyTile documents={thisOrgDocuments} />
      </PlatformConditional>

      <PlatformConditional platform="huntington">
        <GeneralTile documents={thisOrgDocuments} platform="huntington" showOtherActiveMedications={true} />
      </PlatformConditional>

      <PlatformConditional platform="sleepApnea">
        <SleepApneaTile documents={thisOrgDocuments} />
      </PlatformConditional>

      <PlatformConditional platform="ninmt">
        <GeneralTile documents={thisOrgDocuments} platform="ninmt" showOtherActiveMedications={false} />
      </PlatformConditional>

      <PlatformConditional platform="mgravis">
        <GeneralTile documents={thisOrgDocuments} platform="mgravis" showOtherActiveMedications={false} />
      </PlatformConditional>
    </React.Fragment>
  ) : null;
};

interface IOwnProps {
  documents?: IMedication[];
}

export default MedicationDash;
