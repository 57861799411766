import * as React from 'react';
import { useIntl } from 'react-intl';
import { field } from '../../../../config/theme/componentTheme';
import { styled } from '@mui/material';

// This one is in separate file so that localizations work

const StyledInput = styled('input')`
  width: ${(props: { width?: number }): string => (props.width ? props.width + 'rem' : field.width)};
  height: ${field.height};
  border-radius: ${field.borderRadius};
  font-weight: ${field.fontWeight};
  text-align: left;
  font-family: 'Titillium Web';
  letter-spacing: ${field.letterSpacing};
  box-shadow: ${field.boxShadow};
  border: ${field.border};
  padding: ${field.padding};
`;

const StyledTextField = ({ value, onChange, placeholder, width }: ITextfieldInputProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <StyledInput
      type="text"
      value={value}
      placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(e.currentTarget.value);
      }}
      width={width}
    />
  );
};

export default StyledTextField;

interface ITextfieldInputProps {
  value?: string;
  onChange: (e: string) => void;
  placeholder?: string;
  width?: number;
  fullWidth?: boolean;
}
